// Library components
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

import Skeleton from './Skeleton';

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    padding: theme.spacing(1.5),
    width: '100%',
  },
  tableHeader: {
    backgroundColor: '#0409210a',
    height: '44px',
  },
  tableRows: {
    height: '48px',
  },
}));

const TableSkeleton = ({ rows }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.tableContainer}>
      <Grid container spacing={3}>
        <Grid item xs={12} className={classes.tableHeader} />
        {Array.from({ length: rows }, (_, index) => index + 1).map((tableHead) => (
          <Grid key={`skeleton-body-${tableHead}`} item xs={12}>
            <Skeleton variant="rect" width="100%" height={35} animation="wave" />
          </Grid>
        ))}
      </Grid>
    </Paper>
  );
};

TableSkeleton.defaultProps = {
  rows: 10,
};

export default React.memo(TableSkeleton);
