import { authService } from '@yojee/api/authService';
import { BaseService } from '@yojee/api/baseService';

export class DriverProcessService extends BaseService {
  constructor({ authService }) {
    super();
    this.authService = authService;
  }

  getSubTaskRules = () => {
    return this.authService
      .get(this.getUmbrellaApiUrl('dispatcher/sub_task_rules'))
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  getLstTaskExceptionWithPaginate = (data) => {
    return this.authService
      .get(this.getUmbrellaApiUrl('dispatcher/task_exception_reasons'), data)
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };

  getLstTaskExceptionWithFilter = (data) => {
    return this.authService
      .post(this.getUmbrellaApiUrl('dispatcher/task_exception_reasons/filter'), data)
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };

  updateTaskException = ({ exceptionId, exception }) => {
    return this.authService
      .put(this.getUmbrellaApiUrl(`dispatcher/task_exception_reasons/${exceptionId}`), exception)
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  createTaskException = (exception) => {
    return this.authService
      .post(this.getUmbrellaApiUrl(`dispatcher/task_exception_reasons`), exception)
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  deleteTaskException = ({ exceptionId }) => {
    return this.authService
      .delete(this.getUmbrellaApiUrl(`dispatcher/task_exception_reasons/${exceptionId}`))
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  createSubTaskRule = (rule) => {
    return this.authService
      .post(this.getUmbrellaApiUrl(`dispatcher/sub_task_rules`), rule)
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  updateSubTaskRule = (rule) => {
    return this.authService
      .put(this.getUmbrellaApiUrl(`dispatcher/sub_task_rules/${rule.id}`), rule)
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  deleteSubTaskRule = (rule) => {
    return this.authService
      .delete(this.getUmbrellaApiUrl(`dispatcher/sub_task_rules/${rule.id}`))
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  updateMarkArrivalDeparture = (data) => {
    return this.authService
      .put(this.getUmbrellaApiUrl('dispatcher/company/settings'), {
        settings: { company: data },
      })
      .then((response) => {
        return this.extractData(response);
      })
      .catch((error) => this.handleError(error));
  };

  updateDisplaySetting = (data) => {
    return this.authService
      .put(this.getUmbrellaApiUrl('dispatcher/company/display_settings'), data)
      .then((response) => {
        return this.extractData(response);
      })
      .catch((error) => this.handleError(error));
  };

  batchUpload = ({ file, format }) => {
    const uploadJson = new FormData();
    uploadJson.set('file', file);
    uploadJson.set('format', format);

    return this.authService
      .post(this.getUmbrellaApiUrl(`dispatcher/task_exception_reasons/batches`), uploadJson, {
        'Content-Type': 'multipart/form-data',
      })
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };

  checkBatchStatus = ({ batchId }) => {
    return this.authService
      .get(this.getUmbrellaApiUrl(`dispatcher/task_exception_reasons/batches/${batchId}/status`))
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };

  downloadTaskExceptionTemplateSample = ({ format }) => {
    return this.getUmbrellaApiUrl(`public/task_exception_reasons/download_sample?format=${format}`);
  };
}

export const driverProcessService = new DriverProcessService({ authService });
