const defaultState = {
  versions: [],
  podContent: {},
  isChanged: false,
  selectedVersion: 0,
};

export const init = () => {
  return defaultState;
};

const ACTION_HANDLERS = {
  GET_POD_VERSION_SUCCESS: (state, payload) => {
    return {
      ...state,
      versions: payload.data,
    };
  },
  GET_POD_CONTENT_SUCCESS: (state, payload) => {
    return {
      ...state,
      podContent: payload.data,
      selectedVersion: payload.selectedVersion,
    };
  },
};

const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};

export default reducer;
