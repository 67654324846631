const defaultState = {
  hubs: [],
  regions: [],
  loading: {
    getHubs: false,
    getRegions: false,
  },
};

export const networkMessagesErrorEvents = {
  GET_HUBS_FAILED: ({ error }) => error,
  GET_REGIONS_FAILED: ({ error }) => error,
};

export const init = () => {
  return defaultState;
};

const ACTION_HANDLERS = {
  START_LOADING: (state, sagaData) => {
    return {
      ...state,
      loading: { ...state.loading, [sagaData.loadingAction]: true },
    };
  },
  GET_HUBS_SUCCESSFUL: (state, sagaData) => {
    return {
      ...state,
      hubs: sagaData.data,
      loading: {
        ...state.loading,
        getHubs: false,
      },
    };
  },
  GET_HUBS_FAILED: (state) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        getHubs: false,
      },
    };
  },
  GET_REGIONS_SUCCESSFUL: (state, sagaData) => {
    return {
      ...state,
      regions: sagaData.data,
      loading: {
        ...state.loading,
        getRegions: false,
      },
    };
  },
  GET_REGIONS_FAILED: (state) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        getRegions: false,
      },
    };
  },
};

const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};
export default reducer;
