import Cookies from 'js-cookie';

import { getJwtData, saveJwtData } from '@yojee/auth/jwt-storage';
import { getSlugFromUrl } from '@yojee/auth/utils/AuthUtils';
import Utilities from '@yojee/helpers/utilities';
import { LocalStorage } from '@yojee/local-storage/local-storage';

function addPartnerJWTToLocalStorage() {
  const params = new URLSearchParams(window.location.search);
  const partnerJWT = params.get('partner_jwt');

  LocalStorage.setItem('partner_jwt', partnerJWT);
}

function addPartnerJWTToReduxStore(defaultState) {
  const params = new URLSearchParams(window.location.search);
  const partnerJWT = params && params.get('partner_jwt');

  return partnerJWT ? { ...defaultState, partnerJwt: partnerJWT } : defaultState;
}

function updateJwtToken(defaultState) {
  const params = new URLSearchParams(window.location.search);
  const jwtTokenParam = params && (params.get('partner_jwt') || params.get('tracking_jwt'));
  let newDefaultState = { ...defaultState };

  if (LocalStorage.hasItem('jwt_token') && !jwtTokenParam) {
    try {
      const jwt_token = LocalStorage.getItem('jwt_token');
      newDefaultState = { ...newDefaultState, jwt: jwt_token };
    } catch (e) {
      LocalStorage.removeItem('jwt_token');
    }
  }

  return newDefaultState;
}

function updateJwtDataFromLocalstorage(defaultState) {
  const params = new URLSearchParams(window.location.search);
  const jwtTokenParam = params && (params.get('partner_jwt') || params.get('tracking_jwt'));
  let newDefaultState = { ...defaultState };

  if (LocalStorage.hasItem('access_token') && !jwtTokenParam) {
    try {
      const loginData = {};
      loginData.token = LocalStorage.getItem('access_token');
      loginData.jwtData = getJwtData();
      loginData.dispatcher_companies = LocalStorage.getItem('dispatcher_companies');
      loginData.dispatcher_info = {
        ...(newDefaultState?.['dispatcher_info'] ?? {}),
        data: {
          ...(newDefaultState?.['dispatcher_info']?.data ?? {}),
          ...(LocalStorage.getItem('current_user') || {}),
        },
      };
      newDefaultState = { ...newDefaultState, ...loginData };
    } catch (e) {
      LocalStorage.removeItem('access_token');
    }
  }

  return newDefaultState;
}

function updateDataInCookiesToLocalstorage() {
  const cookieExists = Cookies.get('company_slug') === getSlugFromUrl() && Cookies.get('jwt_data');

  if (cookieExists) {
    const jwtData = JSON.parse(Cookies.get('jwt_data'));
    saveJwtData(jwtData);

    const _companies = Cookies.get('dispatcher_companies');
    const dispatcherCompanies = _companies && _companies !== 'undefined' ? JSON.parse(_companies) : {};
    LocalStorage.setItem('dispatcher_companies', dispatcherCompanies);

    Cookies.remove('jwt_data', { domain: `.${Utilities.domain()}` });
    Cookies.remove('company_slug', { domain: `.${Utilities.domain()}` });
    Cookies.remove('dispatcher_companies', { domain: `.${Utilities.domain()}` });
  }
}

export function initAuthenticationState(defaultState) {
  updateDataInCookiesToLocalstorage();

  defaultState = updateJwtDataFromLocalstorage(defaultState);
  defaultState = updateJwtToken(defaultState);
  defaultState = addPartnerJWTToReduxStore(defaultState);
  addPartnerJWTToLocalStorage();

  return defaultState;
}
