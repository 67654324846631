import { Trans } from 'react-i18next';

export const MAX_RATE_TABLES = 1;

export const volumetricUnicode = '\u00B3';

export const MESSAGE = {
  conditionsEmptyMessage: <Trans>You don’t have conditions yet</Trans>,
  calculatorsEmptyMessage: <Trans>You don’t have calculators yet</Trans>,
  rateTableEmptyMessage: <Trans>You don’t have rate table yet</Trans>,
  copyRatingEntry: (
    <Trans>
      You are copying this Rate Card to a new one, do you want Expiry Date to be set automatically for the Old one?
    </Trans>
  ),
  deletedSumCalcMesg: (
    <Trans>
      Are you sure you want to delete this sum? All belonging calculators will release to main table. Please check,
      because this action cannot be undone.
    </Trans>
  ),
  deletedLastCalcOfSumMesg: (
    <Trans>
      Are you sure you want to delete this calculator when it also deletes this sum assembly? Please check, because this
      action cannot be undone.
    </Trans>
  ),
  deletedCalcMesg: (
    <Trans>Are you sure you want to delete this calculator? Please check, because this action cannot be undone.</Trans>
  ),
  deleteRateTableMesg: (
    <Trans>Are you sure you want to delete this table? Please check, because this action cannot be undone.</Trans>
  ),
  cannotAddBreakpoint: (
    <Trans>
      If you want to adjust the number of breakpoints, please do so in the first dimension. This will also change the
      number of breakpoints in the second dimension.
    </Trans>
  ),
  confirmSaveRateCard: (
    <Trans>
      You have not saved your work yet. Are you sure you want to leave this page? Please check, if you leave, all your
      change will not be saved.
    </Trans>
  ),
  calculatorTab: {
    sumTab: {
      error: {
        emptySumChildren: <Trans>A sum needs at least one calculator.</Trans>,
      },
      tooltip: {
        sameCurrency: <Trans>You must select calculators which have the same currency to put into sum</Trans>,
      },
    },
  },
};

export const IGNORED_VARIABLE_TYPES = ['generic', 'has_liquids', 'is_dangerous', 'is_refrigerated', 'item_type'];

export const UNIT_SYMBOL = {
  kilometer: 'km',
  meter: 'm',
  centimeter: 'cm',
  millimeter: 'mm',
  inch: 'in',
  foot: 'ft',
  yard: 'yd',
  mile: 'mi',
  kilogram: 'kg',
  gram: 'g',
  cubic_millimeter: `mm${volumetricUnicode}`,
  cubic_centimeter: `cm${volumetricUnicode}`,
  cubic_meter: `m${volumetricUnicode}`,
  cubic_inch: `in${volumetricUnicode}`,
  cubic_foot: `ft${volumetricUnicode}`,
  second: 's',
  minute: 'min',
  hour: 'h',
  day: 'd',
  week: 'wk',
};

export const SI_UNIT = {
  weight: {
    name: 'kilogram',
    symbol: 'kg',
  },
  distance: {
    name: 'meter',
    symbol: 'm',
  },
  volume: {
    name: 'cubic_meter',
    symbol: `m${volumetricUnicode}`,
  },
  duration: {
    name: 'second',
    symbol: 's',
  },
};
