import { Typography } from '@material-ui/core';
import React from 'react';

/**
 *
 *
 * @param {import('react').ComponentProps<typeof Typography>} props
 * @return {*}
 */
const Title = (props) => {
  return <Typography {...props} />;
};

export default Title;
