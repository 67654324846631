import { makeStyles } from '@material-ui/styles';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  circle: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    backgroundColor: theme.palette.primary.main,
    borderRadius: '50%',
    position: 'relative',
    marginRight: theme.spacing(2),
  },
  centerCircle: {
    color: 'white',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));

const CircleNumber = (props) => {
  const classes = useStyles();
  return (
    <span className={classes.circle}>
      <span className={classes.centerCircle}>{props.getValue()?.length || 0}</span>
    </span>
  );
};
export default CircleNumber;
