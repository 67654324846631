import { authService } from './authService/index';
import { BaseService } from './baseService/index';

export class TasksSearchService extends BaseService {
  constructor({ authService }) {
    super();
    this.authService = authService;
  }

  search = (body = {}, { relatedLegs, onlySameLeg, includeTransferProperties } = {}) => {
    return this.authService
      .post(
        this.getUmbrellaApiUrl(
          `dispatcher/tasks/query?only_same_leg=${onlySameLeg || false}&fetch_legs=${relatedLegs || false}${
            includeTransferProperties ? '&include_transfer_properties=true&with_transfer_tasks=true' : ''
          }`
        ),
        body
      )
      .catch((error) => this.handleError(error));
  };
}

export const tasksSearchService = new TasksSearchService({ authService });
