import { call, delay, put, takeLatest } from 'redux-saga/effects';

import { bulkUpdateService } from '@yojee/api/bulkUpdateService';

import { bulkDeleteRequestFail, clearMessage, updateBulkSelection } from './bulkActions';

export default function* sagas() {
  yield takeLatest('BULK_DELETE_REQUEST', bulkDeleteVerify);
  yield takeLatest('UPDATE_ALL_REQUEST', bulkUpdateAllRequestSaga);
}

const bulkRequestTypes = {
  schedule: {
    pending: 'REQUEST_DELETE_SCHEDULE_IN_PROCESS',
    success: 'BULK_REQUEST_DELETE_SCHEDULE_SUCCESS',
    failure: 'BULK_REQUEST_DELETE_SCHEDULE_ERROR',
  },
  worker: {
    pending: 'REQUEST_DELETE_WORKER_IN_PROCESS',
    success: 'BULK_REQUEST_DELETE_WORKER_SUCCESS',
    failure: 'BULK_REQUEST_DELETE_WORKER_ERROR',
  },
  vehicle: {
    pending: 'REQUEST_DELETE_VEHICLE_IN_PROCESS',
    success: 'BULK_REQUEST_DELETE_VEHICLE_SUCCESSFUL',
    failure: 'BULK_REQUEST_DELETE_VEHICLE_FAIL',
  },
  address: {
    pending: 'REQUEST_DELETE_ADDRESS_IN_PROCESS',
    success: 'BULK_REQUEST_DELETE_ADDRESS_SUCCESSFUL',
    failure: 'BULK_REQUEST_DELETE_ADDRESS_FAIL',
  },
  task_exception_reason: {
    pending: 'REQUEST_DELETE_TASK_EXCEPTION_IN_PROCESS',
    success: 'BULK_REQUEST_DELETE_TASK_EXCEPTION_SUCCESSFUL',
    failure: 'BULK_REQUEST_DELETE_TASK_EXCEPTION_FAIL',
  },
  tag: {
    pending: 'REQUEST_DELETE_TAG_IN_PROCESS',
    success: 'BULK_REQUEST_DELETE_TAG_SUCCESSFUL',
    failure: 'BULK_REQUEST_DELETE_TAG_FAIL',
  },
  sender: {
    pending: 'REQUEST_DELETE_SENDER_IN_PROCESS',
    success: 'BULK_REQUEST_DELETE_SENDER_SUCCESSFUL',
    failure: 'BULK_REQUEST_DELETE_SENDER_FAIL',
  },
  sender_organisation: {
    pending: 'REQUEST_DELETE_SENDER_ORGANISATION_IN_PROCESS',
    success: 'BULK_REQUEST_DELETE_SENDER_ORGANISATION_SUCCESSFUL',
    failure: 'BULK_REQUEST_DELETE_SENDER_ORGANISATION_FAIL',
  },
  worker_team: {
    pending: 'REQUEST_DELETE_WORKER_TEAM_IN_PROCESS',
    success: 'BULK_REQUEST_DELETE_WORKER_TEAM_SUCCESS',
    failure: 'BULK_REQUEST_DELETE_WORKER_TEAM_ERROR',
  },
  charge_code: {
    pending: 'REQUEST_DELETE_CHARGE_CODE_IN_PROCESS',
    success: 'BULK_REQUEST_DELETE_CHARGE_CODE_SUCCESS',
    failure: 'BULK_REQUEST_DELETE_CHARGE_CODE_FAIL',
  },
  address_item: {
    pending: 'REQUEST_DELETE_ADDRESS_ITEM_IN_PROCESS',
    success: 'BULK_REQUEST_DELETE_ADDRESS_ITEM_SUCCESS',
    failure: 'BULK_REQUEST_DELETE_ADDRESS_ITEM_FAIL',
  },
  service_type: {
    pending: 'REQUEST_DELETE_SERVICE_TYPE_IN_PROCESS',
    success: 'BULK_REQUEST_DELETE_SERVICE_TYPE_SUCCESS',
    failure: 'BULK_REQUEST_DELETE_SERVICE_TYPE_FAIL',
  },
  custom_field: {
    pending: 'REQUEST_DELETE_CUSTOM_FIELD_IN_PROCESS',
    success: 'BULK_REQUEST_DELETE_CUSTOM_FIELD_IN_SUCCESS',
    failure: 'BULK_REQUEST_DELETE_CUSTOM_FIELD_FAIL',
  },
  service_time: {
    pending: 'REQUEST_DELETE_SERVICE_TIME_IN_PROCESS',
    success: 'BULK_REQUEST_DELETE_SERVICE_TIME_SUCCESS',
    failure: 'BULK_REQUEST_DELETE_SERVICE_TIME_FAIL',
  },
  operation: {
    pending: 'REQUEST_DELETE_OPERATION_IN_PROCESS',
    success: 'BULK_REQUEST_DELETE_OPERATION_SUCCESS',
    failure: 'BULK_REQUEST_DELETE_OPERATION_FAIL',
  },
  document: {
    pending: 'REQUEST_DELETE_DOCUMENT_IN_PROCESS',
    success: 'BULK_REQUEST_DELETE_DOCUMENT_SUCCESS',
    failure: 'BULK_REQUEST_DELETE_DOCUMENT_FAIL',
  },
  dispatch_rule: {
    pending: 'REQUEST_DELETE_DISPATCH_RULE_IN_PROCESS',
    success: 'BULK_REQUEST_DELETE_DISPATCH_RULE_SUCCESS',
    failure: 'BULK_REQUEST_DELETE_DISPATCH_RULE_FAIL',
  },
  saved_filter: {
    pending: 'REQUEST_DELETE_SAVED_FILTER_IN_PROCESS',
    success: 'BULK_REQUEST_DELETE_SAVED_FILTER_SUCCESS',
    failure: 'BULK_REQUEST_DELETE_SAVED_FILTER_FAIL',
  },
  item_type: {
    pending: 'REQUEST_DELETE_ITEM_TYPE_IN_PROCESS',
    success: 'BULK_REQUEST_DELETE_ITEM_TYPE_SUCCESS',
    failure: 'BULK_REQUEST_DELETE_ITEM_TYPE_FAIL',
  },
  document_classification: {
    pending: 'REQUEST_DELETE_DOCUMENT_CODE_IN_PROCESS',
    success: 'BULK_REQUEST_DELETE_DOCUMENT_CODE_SUCCESS',
    failure: 'BULK_REQUEST_DELETE_DOCUMENT_CODE_FAIL',
  },
};

function formatVerifyMessageError(errors) {
  const errorCode = {};
  errors.forEach((error) => {
    Object.values(error.message).forEach((value) => {
      if (!errorCode[value]) {
        errorCode[value] = [error.id];
      } else {
        errorCode[value].push(error.id);
      }
    });
  });
  return Object.keys(errorCode)
    .map((key) => `[${errorCode[key]}] ${key}`)
    .join('\n');
}

function* bulkDeleteVerify(action) {
  yield put({ type: 'START_BULK_LOADING', key: 'bulkDeleteAllRequest' });
  const data = action.payload;
  data.path = 'dispatcher/bulk_actions/bulk_delete/verify_related_data';
  try {
    const errorList = yield call(bulkUpdateService.postData, data);
    if (errorList.length) {
      const errorMessage = formatVerifyMessageError(errorList);
      yield put({ type: bulkRequestTypes[data.type]?.failure, payload: errorMessage });
    } else {
      yield call(bulkDeleteRequestSaga, action);
    }
  } catch (error) {
    data.onFail?.(error);
    yield put(bulkDeleteRequestFail(error));
    yield put({ type: bulkRequestTypes[data.type]?.failure });
  }
}

function* bulkDeleteRequestSaga(action) {
  const data = action.payload;
  try {
    data.path = 'dispatcher/bulk_actions/bulk_delete';
    const status = yield call(bulkUpdateService.deleteData, data);
    if (status.id) {
      data.onProgress?.();
      yield put({ type: bulkRequestTypes[data.type]?.pending });
      yield call(checkBulkDeleteStatus, status.id, data.type, data.ids, data.onSuccess, data.onFail);
    }
  } catch (error) {
    data.onFail?.(error);
    yield put(bulkDeleteRequestFail(error));
  }
}

function* checkBulkDeleteStatus(status, page, ids, onSuccess, onFail) {
  let completed = false;
  let count = 0;
  while (!completed) {
    yield delay(2000);
    const path = `dispatcher/bulk_actions/status/${status}`;
    try {
      if (count++ > 10) throw new Error('TIME_OUT'); // throw error and stop loop if longer than 20s
      const data = yield call(bulkUpdateService.checkDeleteProcess, { path, type: 'GET' });
      if (data.progress === 'completed') {
        completed = true;
        yield put({ type: 'CHECK_BULK_DELETE_SUCCESS' });
        yield put({ type: bulkRequestTypes[page]?.success, payload: ids });
        onSuccess?.();
        yield put(updateBulkSelection([]));
        yield delay(2000);
        yield put(clearMessage());
      }
    } catch (error) {
      completed = true;
      yield put(bulkDeleteRequestFail(error));
      yield put({ type: bulkRequestTypes[page]?.failure });
      onFail?.(error);
      yield delay(2000);
      yield put(clearMessage());
    }
  }
}

function* bulkUpdateAllRequestSaga(action) {
  try {
    yield put({ type: 'START_BULK_LOADING', key: 'bulkUpdateAllRequest' });
    const data = action.payload;
    data.path = 'dispatcher/bulk_action/bulk_update';
    const status = yield call(bulkUpdateService.updateAllFields, data);
    if (status.batch_id) {
      yield call(checkBulkEditAllStatus, status.batch_id);
    }
  } catch (error) {
    yield put({ type: 'BULK_UPDATE_ALL_REQUEST_FAILED' });
  }
}

function* checkBulkEditAllStatus(status) {
  let completed = false;
  let count = 0;
  while (!completed) {
    yield delay(2000);
    const path = `dispatcher/bulk_action/bulk_update/${status}/status`;
    try {
      if (count++ > 10) throw new Error('TIME_OUT'); // throw error and stop loop if longer than 20s
      const data = yield call(bulkUpdateService.fetchData, { path, type: 'GET' });
      if (data.status === 'completed') {
        completed = true;
        yield put({ type: 'BULK_UPDATE_ALL_REQUEST_SUCCESSFUL' });
        yield delay(2000);
        yield put(clearMessage());
      } else if (data.status === 'failed') {
        completed = true;
        yield put({ type: 'BULK_UPDATE_ALL_REQUEST_FAILED' });
        yield delay(2000);
        yield put(clearMessage());
      }
    } catch (error) {
      completed = true;
      yield put({ type: 'BULK_UPDATE_ALL_REQUEST_FAILED' });
      yield delay(2000);
      yield put(clearMessage());
    }
  }
}
