import { call, put, select, takeLatest } from 'redux-saga/effects';

import { batchUpload } from '@yojee/ui/onboarding/helpers/batchUploadHelper';

import { driverProcessService } from '../../services/driverProcessService';

export const exceptions = (state) => state.exceptions;

export default function* sagas() {
  yield takeLatest('GET_LIST_EXCEPTIONS', getListTaskException);
  yield takeLatest('UPDATE_TASK_EXCEPTION', updateTaskException);
  yield takeLatest('DELETE_TASK_EXCEPTION', deleteTaskException);
  yield takeLatest('CREATE_TASK_EXCEPTION', createTaskException);
  yield takeLatest('TASK_EXCEPTION_BATCH_UPLOAD', taskExceptionBatchUpload);
  yield takeLatest('DOWNLOAD_TASK_EXCEPTION_TEMPLATE_SAMPLE', downloadTaskExceptionTemplateSample);
}

function* getListTaskException(action) {
  try {
    const payload = action.payload || {};
    const { q = {}, ...restPayload } = payload;

    const lstTaskException = yield call(driverProcessService.getLstTaskExceptionWithFilter, {
      ...restPayload,
      ...q,
    });
    yield put({
      type: 'GET_LIST_EXCEPTIONS_SUCCESS',
      result: lstTaskException,
    });
  } catch (error) {
    yield put({
      type: 'GET_LIST_EXCEPTIONS_ERROR',
      error: `Get ERROR: ${error.message}`,
    });
  }
}

function* updateTaskException({ exception }) {
  try {
    yield put({
      type: 'SET_CREATE_UPDATE_STATUS',
      payload: 'loading',
    });
    const result = yield call(driverProcessService.updateTaskException, {
      exceptionId: exception.id,
      exception,
    });
    yield put({ type: 'UPDATE_TASK_EXCEPTION_SUCCESS', result });
    yield put({
      type: 'SET_CREATE_UPDATE_STATUS',
      payload: 'normal',
    });
    const {
      pagination: { current_page, limit_value },
    } = yield select(exceptions);
    yield put({
      type: 'GET_LIST_EXCEPTIONS',
      payload: { page: current_page, page_size: limit_value },
    });
  } catch (error) {
    yield put({
      type: 'UPDATE_TASK_EXCEPTION_ERROR',
      error: `Update ERROR: ${error.message}`,
    });
  }
}

function* deleteTaskException({ exception }) {
  try {
    yield call(driverProcessService.deleteTaskException, {
      exceptionId: exception.id,
    });
    yield put({ type: 'DELETE_TASK_EXCEPTION_SUCCESS', result: exception.id });
    const {
      pagination: { current_page, limit_value },
    } = yield select(exceptions);
    yield put({
      type: 'GET_LIST_EXCEPTIONS',
      payload: { page: current_page, page_size: limit_value },
    });
  } catch (error) {
    yield put({
      type: 'DELETE_TASK_EXCEPTION_ERROR',
      error: `Delete ERROR: ${error.message}`,
    });
  }
}

function* createTaskException({ exception }) {
  try {
    yield put({
      type: 'SET_CREATE_UPDATE_STATUS',
      payload: 'loading',
    });
    const result = yield call(driverProcessService.createTaskException, exception);
    yield put({ type: 'CREATE_TASK_EXCEPTION_SUCCESS', result });
    yield put({
      type: 'SET_CREATE_UPDATE_STATUS',
      payload: 'normal',
    });
    const {
      pagination: { current_page, limit_value },
    } = yield select(exceptions);
    yield put({
      type: 'GET_LIST_EXCEPTIONS',
      payload: { page: current_page, page_size: limit_value },
    });
  } catch (error) {
    yield put({
      type: 'CREATE_TASK_EXCEPTION_ERROR',
      error: `Create ERROR: ${error.message}`,
    });
  }
}

function* taskExceptionBatchUpload(info) {
  const { file, format } = info.uploadData;
  yield batchUpload(
    { file, format },
    {
      uploadApiMethod: driverProcessService.batchUpload,
      statusCheckApiMethod: driverProcessService.checkBatchStatus,
      events: {
        started: 'TASK_EXCEPTION_BATCH_UPLOAD_STARTED',
        processing: 'TASK_EXCEPTION_BATCH_UPLOAD_PROCESSING',
        succeeded: 'TASK_EXCEPTION_BATCH_UPLOAD_SUCCEEDED',
        failed: 'TASK_EXCEPTION_BATCH_UPLOAD_FAILED',
      },
    }
  );
}

function downloadTaskExceptionTemplateSample(format) {
  window.open(driverProcessService.downloadTaskExceptionTemplateSample(format), '_self');
}
