import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const CheckPathChange = () => {
  const navigate = useNavigate();

  const _handleFrameMessages = (e) => {
    if (!e || !e.data || !e.data.type) {
      return;
    }
    const { data } = e;
    if (data.type === 'NAVIGATE_MANAGE') {
      const { pathname: path } = data.data;
      if (path !== `/manage${window.location.pathname}`) {
        navigate(path.replace('/manage', ''));
      }
    }
  };

  useEffect(() => {
    window.addEventListener('message', _handleFrameMessages, false);
    return () => window.removeEventListener('message', _handleFrameMessages, false);
  }, []);

  return null;
};

export default CheckPathChange;
