import ActionTypes from '../saga/ActionTypes';

export const generateMessagesEvents = (resource, resourceTitle = '') => {
  if (!resourceTitle) {
    resourceTitle = resource;
  }
  const actionTypes = new ActionTypes({ resource });

  return {
    success: {
      [actionTypes.getActionType('CREATE').success()]: `${resourceTitle} created`,
      [actionTypes.getActionType('UPDATE').success()]: `${resourceTitle} updated`,
      [actionTypes.getActionType('DELETE').success()]: `${resourceTitle} deleted`,
    },
    error: {
      [actionTypes.getActionType('DELETE').failed()]: ({ payload: { errorMessage } }) => errorMessage,
      [actionTypes.getActionType('DELETE').failed()]: ({ payload: { errorMessage } }) => errorMessage,
      [actionTypes.getActionType('DELETE').failed()]: ({ payload: { errorMessage } }) => errorMessage,
    },
  };
};

export default function generateReducer(resource) {
  const actionTypes = new ActionTypes({ resource });

  const defaultState = {
    list: [],
    pagination: {
      total_count: 0,
      total_pages: 0,
      current_page: 1,
      limit_value: 25,
    },
    filter: {
      query: undefined,
      page: 1,
      page_size: 25,
    },
    openDialog: false,
    inProgress: false,
    actionProgress: false,
    resource: null,
  };

  const completeAction = (state, { payload }) => {
    return {
      ...state,
      resource: { ...payload },
      actionProgress: false,
    };
  };

  const startProgress = (state) => {
    return {
      ...state,
      inProgress: true,
    };
  };

  const endProgress = (state) => {
    return {
      ...state,
      inProgress: false,
    };
  };

  const startActionProgress = (state) => {
    return {
      ...state,
      actionProgress: true,
    };
  };

  const endActionProgress = (state) => {
    return {
      ...state,
      actionProgress: false,
    };
  };

  const ACTION_HANDLERS = {
    /******* GET LIST ********/
    [actionTypes.getActionType('LIST').start()]: startProgress,
    [actionTypes.getActionType('LIST').success()]: (state, { payload }) => {
      const { data, pagination } = payload;
      return {
        ...state,
        list: [...data],
        pagination: pagination,
        inProgress: false,
      };
    },
    [actionTypes.getActionType('LIST').failed()]: endProgress,
    /******* ADD RESOURCE ********/
    [actionTypes.getActionType('ADD').start()]: (state) => ({
      ...state,
      list: [],
      inProgress: false,
      openDialog: true,
      resource: null,
    }),

    /******* EDIT RESOURCE ********/
    [actionTypes.getActionType('EDIT').start()]: (state, { payload }) => {
      const { resource } = payload;
      return {
        ...state,
        list: [],
        inProgress: false,
        openDialog: true,
        resource,
      };
    },

    /******* CREATE RESOURCE ********/
    [actionTypes.getActionType('CREATE').start()]: startActionProgress,
    [actionTypes.getActionType('CREATE').success()]: completeAction,
    [actionTypes.getActionType('CREATE').failed()]: endActionProgress,

    /******* UPDATE RESOURCE ********/
    [actionTypes.getActionType('UPDATE').start()]: startActionProgress,
    [actionTypes.getActionType('UPDATE').success()]: completeAction,
    [actionTypes.getActionType('UPDATE').failed()]: endActionProgress,

    /******* GET RESOURCE ********/
    [actionTypes.getActionType('GET').start()]: startProgress,
    [actionTypes.getActionType('GET').success()]: completeAction,
    [actionTypes.getActionType('GET').failed()]: endProgress,

    /******* DELETE RESOURCE ********/
    [actionTypes.getActionType('DELETE').start()]: startProgress,
    [actionTypes.getActionType('DELETE').success()]: (state) => {
      return {
        ...state,
        list: [],
        resource: null,
        inProgress: false,
      };
    },
    [actionTypes.getActionType('DELETE').failed()]: endProgress,
  };

  return (state = defaultState, action = {}) => {
    const handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : state;
  };
}
