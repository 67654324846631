export default class BaseActionTypes {
  constructor({ prefix = '' }) {
    this.prefix = `${prefix}_`;
  }

  resetState = () => `${this.prefix}RESET_STATE`;
  updateState = () => `${this.prefix}UPDATE_STATE`;
  setError = () => `${this.prefix}SET_ERROR`;
  startProgress = () => `${this.prefix}START_PROGRESS`;
  endProgress = () => `${this.prefix}END_PROGRESS`;

  custom = (actionName) => `${this.prefix}${actionName}`;
  clearMessage = () => this.custom('CLEAR_MESSAGE');
  reset = () => this.custom('RESET');

  getList = () => new SubType(`${this.prefix}REQUEST_GET_LIST`);
  customWithSubType = (actionName) => new SubType(`${this.prefix}${actionName}`);
}

class SubType {
  constructor(actionName) {
    this.actionName = actionName;
  }

  start = () => `${this.actionName}_START`;
  loading = () => `${this.actionName}_LOADING`;
  success = () => `${this.actionName}_SUCCESS`;
  failed = () => `${this.actionName}_FAIL`;
  custom = (name) => `${this.actionName}_${name}`;
}
