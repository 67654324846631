import MuiCircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';

const CircularLoading = (props) => {
  return <MuiCircularProgress {...props} />;
};

CircularLoading.defaultProps = {
  size: 24,
};

export default CircularLoading;
