const defaultState = {
  inProgress: {
    getVAS: false,
    createVAS: false,
    updateVAS: false,
    toggleVASStatus: [],
  },
  vas: {
    list: [],
    query: '',
    pagination: {
      current_page: 1,
      limit_value: 25,
      total_count: 0,
      total_pages: 0,
    },
  },
};

export const financeMessagesSuccessEvents = {
  REQUEST_CREATE_VAS_SUCCESSFUL: 'VAS was successfully created',
  REQUEST_UPDATE_VAS_SUCCESSFUL: 'VAS was successfully updated',
  REQUEST_TOGGLE_VAS_STATUS_SUCCESSFUL: ({ isActive }) =>
    `VAS was successfully ${isActive ? 'deactivated' : 'activated'}`,
};

export const financeMessagesErrorEvents = {
  REQUEST_GET_VAS_FAILED: 'Something went wrong',
  REQUEST_CREATE_VAS_FAILED: ({ error }) => error.message,
  REQUEST_UPDATE_VAS_FAILED: ({ error }) => error.message,
  REQUEST_TOGGLE_VAS_STATUS_FAILED: ({ error }) => error.message,
};

export const init = () => {
  return defaultState;
};

const ACTION_HANDLERS = {
  REQUEST_GET_VAS_SUCCESSFUL: (state, { data, pagination, q }) => {
    return {
      ...state,
      vas: {
        list: data,
        pagination,
        query: q,
      },
      inProgress: {
        ...state.inProgress,
        getVAS: false,
      },
    };
  },
  REQUEST_GET_VAS_FAILED: (state) => {
    return {
      ...state,
      inProgress: {
        ...state.inProgress,
        getVAS: false,
      },
    };
  },
  REQUEST_CREATE_VAS_SUCCESSFUL: (state) => {
    return {
      ...state,
      inProgress: {
        ...state.inProgress,
        createVAS: false,
      },
    };
  },
  REQUEST_CREATE_VAS_FAILED: (state) => {
    return {
      ...state,
      inProgress: {
        ...state.inProgress,
        createVAS: false,
      },
    };
  },
  REQUEST_UPDATE_VAS_SUCCESSFUL: (state) => {
    return {
      ...state,
      inProgress: {
        ...state.inProgress,
        updateVAS: false,
      },
    };
  },
  REQUEST_UPDATE_VAS_FAILED: (state) => {
    return {
      ...state,
      inProgress: {
        ...state.inProgress,
        updateVAS: false,
      },
    };
  },
  REQUEST_TOGGLE_VAS_STATUS_SUCCESSFUL: (state, { id }) => {
    return {
      ...state,
      inProgress: {
        ...state.inProgress,
        toggleVASStatus: state.inProgress.toggleVASStatus.filter((el) => el !== id),
      },
    };
  },
  REQUEST_TOGGLE_VAS_STATUS_FAILED: (state, { id }) => {
    return {
      ...state,
      inProgress: {
        ...state.inProgress,
        toggleVASStatus: state.inProgress.toggleVASStatus.filter((el) => el !== id),
      },
    };
  },
  SET_VAS_IN_PROGRESS: (state, { key }) => {
    return {
      ...state,
      inProgress: {
        ...state.inProgress,
        [key]: true,
      },
    };
  },
  REQUEST_TOGGLE_VAS_STATUS_IN_PROGRESS: (state, { id }) => {
    return {
      ...state,
      inProgress: {
        ...state.inProgress,
        toggleVASStatus: [...state.inProgress.toggleVASStatus, id],
      },
    };
  },
};

const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};

export default reducer;
