import { authService } from '@yojee/api/authService';
import { BaseService } from '@yojee/api/baseService';

export class BillingPartyService extends BaseService {
  constructor({ authService: constructorAuthService }) {
    super();
    this.authService = constructorAuthService;
  }

  listBillingParty = (params) => {
    return this.authService
      .get(this.getUmbrellaApiUrlV4(`company/sender/${params.data.sender}/billing_parties`))
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  createBillingParty = (billing) => {
    return this.authService
      .post(this.getUmbrellaApiUrlV4(`company/sender/${billing.data.sender}/billing_parties`), billing)
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  updateBillingParty = (billing) => {
    return this.authService
      .put(
        this.getUmbrellaApiUrlV4(`company/sender/${billing.data.sender}/billing_parties/${billing.data.id}`),
        billing
      )
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  activateBillingParty = (billing) => {
    return this.authService
      .put(this.getUmbrellaApiUrlV4(`company/sender/${billing.data.sender}/billing_party/${billing.data.id}/activate`))
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  deactivateBillingParty = (billing) => {
    return this.authService
      .put(
        this.getUmbrellaApiUrlV4(`company/sender/${billing.data.sender}/billing_party/${billing.data.id}/deactivate`)
      )
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  checkValidContactBillingParty = (params) => {
    return this.authService
      .get(this.getUmbrellaApiUrlV4(`company/sender/billing_party/get_by_contact`), params)
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };
}

export const billingPartyService = new BillingPartyService({ authService });
