import React from 'react';
import { Trans } from 'react-i18next';

import { PACKING_MODE_OPTIONS } from '../constants';

export const TABS = {
  MATCHING_CRITERIA: 0,
  CALCULATORS: 1,
  RATE_TABLES: 2,
};

export const RATE_TYPE_OPTIONS = [
  { label: <Trans>Buy</Trans>, value: 'buy' },
  { label: <Trans>Sell</Trans>, value: 'sell' },
  { label: <Trans>Internal Cost</Trans>, value: 'internal_cost' },
];

export const CALCULATOR_FORM = {
  name: {
    label: <Trans>Name</Trans>,
    key: 'name',
    placeholder: 'Add text',
    value: '',
    error: '',
    isValid: false,
    validators: [],
    touched: false,
    required: true,
    type: 'text',
    gridSize: {
      xs: 12,
      md: 6,
      lg: 3,
    },
    tabIndx: TABS.MATCHING_CRITERIA,
    dataCy: 'rc-name',
  },
  perspective_type: {
    label: <Trans>Rate type</Trans>,
    key: 'perspective_type',
    value: null,
    error: '',
    isValid: false,
    validators: [],
    touched: false,
    required: true,
    type: 'select',
    options: RATE_TYPE_OPTIONS,
    gridSize: {
      xs: 12,
      md: 6,
      lg: 3,
    },
    tabIndx: TABS.MATCHING_CRITERIA,
    dataCy: 'rc-rate-type',
    optionDataCy: 'rate-type-item',
  },
  senders: {
    label: <Trans>Sender(s)</Trans>,
    placeholder: <Trans>All</Trans>,
    key: 'senders',
    value: [],
    error: '',
    isValid: false,
    validators: [],
    touched: false,
    type: 'async_select',
    gridSize: {
      xs: 12,
      md: 6,
    },
    tabIndx: TABS.MATCHING_CRITERIA,
    dataCy: 'rc-sender',
    optionDataCy: 'sender-item',
  },
  partners: {
    label: <Trans>Partner(s)</Trans>,
    placeholder: <Trans>All</Trans>,
    key: 'partners',
    value: [],
    error: '',
    isValid: false,
    validators: [],
    touched: false,
    type: 'async_select',
    gridSize: {
      xs: 12,
      md: 6,
    },
    tabIndx: TABS.MATCHING_CRITERIA,
    dataCy: 'rc-partner',
    optionDataCy: 'partner-item',
  },
  start_date: {
    label: <Trans>Start Date</Trans>,
    key: 'start_date',
    placeholder: 'DD/MM/YYYY',
    value: null,
    error: '',
    isValid: false,
    validators: [],
    touched: false,
    required: true,
    type: 'date_picker',
    gridSize: {
      xs: 12,
      md: 6,
      lg: 3,
    },
    tabIndx: TABS.MATCHING_CRITERIA,
    dataCy: 'rc-start-date',
  },
  end_date: {
    label: <Trans>End Date</Trans>,
    key: 'end_date',
    placeholder: 'DD/MM/YYYY',
    value: null,
    error: '',
    isValid: false,
    validators: [],
    touched: false,
    type: 'date_picker',
    gridSize: {
      xs: 12,
      md: 6,
      lg: 3,
    },
    tabIndx: TABS.MATCHING_CRITERIA,
    dataCy: 'rc-end-date',
  },
  service_type_name: {
    label: <Trans>Service Type(s)</Trans>,
    placeholder: <Trans>All</Trans>,
    key: 'service_type_name',
    value: [],
    error: '',
    isValid: false,
    validators: [],
    touched: false,
    type: 'async_select',
    gridSize: {
      xs: 12,
      md: 6,
      lg: 3,
    },
    tabIndx: TABS.MATCHING_CRITERIA,
    dataCy: 'rc-service-type',
    optionDataCy: 'service-type-item',
  },
  packing_mode: {
    label: <Trans>Packing Mode(s)</Trans>,
    placeholder: <Trans>All</Trans>,
    key: 'packing_mode',
    value: [],
    error: '',
    isValid: false,
    validators: [],
    touched: false,
    isMulti: true,
    multiCheckbox: true,
    showSum: true,
    type: 'select',
    options: Object.keys(PACKING_MODE_OPTIONS).map((key) => ({ label: PACKING_MODE_OPTIONS[key], value: key })),
    gridSize: {
      xs: 12,
      md: 6,
      lg: 3,
    },
    tabIndx: TABS.MATCHING_CRITERIA,
    dataCy: 'rc-packing-mode',
    optionDataCy: 'packing-mode-item',
  },
  from_address: {
    label: <Trans>From address</Trans>,
    key: 'from_address',
    value: {},
    error: '',
    isValid: false,
    validators: [],
    touched: false,
    type: 'address',
    gridSize: {
      xs: 12,
      md: 6,
    },
    tabIndx: TABS.MATCHING_CRITERIA,
  },
  to_address: {
    label: <Trans>To address</Trans>,
    key: 'to_address',
    value: {},
    error: '',
    isValid: false,
    validators: [],
    touched: false,
    type: 'address',
    gridSize: {
      xs: 12,
      md: 6,
    },
    tabIndx: TABS.MATCHING_CRITERIA,
  },
  resolution_type: {
    label: <Trans>Resolution type</Trans>,
    key: 'resolution_type',
    value: 'order',
    error: '',
    isValid: false,
    validators: ['required'],
    touched: false,
    type: undefined,
    tabIndx: TABS.MATCHING_CRITERIA,
  },
  rate_calculations: {
    label: '',
    key: 'rate_calculations',
    value: [],
    error: '',
    isValid: false,
    validators: [],
    touched: false,
    type: undefined,
    tabIndx: TABS.CALCULATORS,
  },
  active: {
    label: '',
    key: 'active',
    value: null,
    error: '',
    isValid: false,
    validators: [],
    touched: false,
    type: undefined,
  },
};

const filterOptions = (listOptions, inputValue) =>
  (listOptions ?? []).filter((i) => i.label.toLowerCase().includes(inputValue.toLowerCase()));

export const loadOptions = (listOptions) => (inputValue, callback) => {
  callback(filterOptions(listOptions, inputValue));
};
