export const defaultState = {
  checkedRows: [],
  inProgress: {
    bulkUpdateAllRequest: false,
    bulkDeleteAllRequest: false,
  },
  bulkMessagesSuccess: '',
  bulkMessagesFail: '',
  openBulkDeleteDialog: false,
  openChooseBulkUpdateDialog: '',

  checkedFieldsUpdate: [],
};

export const bulkMessagesSuccessEvents = {
  BULK_UPDATE_ALL_REQUEST_SUCCESSFUL: 'Update successfully!',
  CHECK_BULK_DELETE_SUCCESS: 'Deleted successfully!',
  BULK_UPDATE_ALL_REQUEST_FAILED: null,
  CHECK_BULK_DELETE_FAIL: null,
};

export const bulkMessagesErrorEvents = {
  BULK_UPDATE_ALL_REQUEST_SUCCESSFUL: null,
  CHECK_BULK_DELETE_SUCCESS: null,
  BULK_UPDATE_ALL_REQUEST_FAILED: 'Update fail!',
  CHECK_BULK_DELETE_FAIL: ({ error }) => error?.message || 'Delete fail!',
};

const ACTION_HANDLERS = {
  UPDATE_ROW: (state, row) => {
    return {
      ...state,
      checkedRows: row.payload,
    };
  },
  BULK_UPDATE_ALL_REQUEST_SUCCESSFUL: (state) => {
    return {
      ...state,
      inProgress: {
        ...state.inProgress,
        bulkUpdateAllRequest: false,
      },
      bulkMessagesSuccess: 'Update successfully!',
      bulkMessagesFail: '',
    };
  },
  BULK_UPDATE_ALL_REQUEST_FAILED: (state) => {
    return {
      ...state,
      inProgress: {
        ...state.inProgress,
        bulkUpdateAllRequest: false,
      },
      bulkMessagesSuccess: '',
      bulkMessagesFail: 'Update fail!',
    };
  },
  CHECK_BULK_DELETE_SUCCESS: (state) => {
    return {
      ...state,
      inProgress: {
        ...state.inProgress,
        bulkDeleteAllRequest: false,
      },
    };
  },
  CHECK_BULK_DELETE_FAIL: (state) => {
    return {
      ...state,
      inProgress: {
        ...state.inProgress,
        bulkDeleteAllRequest: false,
      },
    };
  },
  START_BULK_LOADING: (state, { key }) => {
    return {
      ...state,
      inProgress: {
        ...state.inProgress,
        [key]: true,
      },
    };
  },
  SET_CHECKED_FIELDS_UPDATE: (state, { fields }) => {
    return { ...state, checkedFieldsUpdate: fields };
  },
  SET_OPEN_CHOOSE_BULK_UPDATE_DIALOG: (state, { value }) => {
    return { ...state, openChooseBulkUpdateDialog: value };
  },
  SET_OPEN_BULK_DELETE_DIALOG: (state) => {
    return { ...state, openBulkDeleteDialog: true };
  },
  SET_CLOSE_BULK_DELETE_DIALOG: (state) => {
    return { ...state, openBulkDeleteDialog: false };
  },
  CLEAR_MESSAGE: (state) => {
    return { ...state, bulkMessagesSuccess: '', bulkMessagesFail: '' };
  },
};

const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};

export default reducer;
