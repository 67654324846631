import { authService } from '@yojee/api/authService';
import { BaseService } from '@yojee/api/baseService';

export class ManifestService extends BaseService {
  constructor({ services }) {
    super();
    this.authService = services;
  }

  getManifest = ({ q, page, page_size }) => {
    return this.authService
      .get(this.getUmbrellaApiUrl('dispatcher/manifests'), {
        page,
        page_size,
        ...(q ? { q } : {}),
      })
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };

  generatePDF = ({ worker_id, type }) => {
    return this.authService
      .get(
        this.getUmbrellaApiUrl('dispatcher/manifests/worker/' + worker_id + '/generate'),
        {
          format: 'pdf',
          type,
        },
        {
          responseType: 'blob',
          'Content-Type': 'application/pdf',
        }
      )
      .then((response) => {
        return response;
      })
      .catch((error) => this.handleError(error));
  };
}

export const manifestService = new ManifestService({ services: authService });
