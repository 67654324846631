import { authService } from '@yojee/api/authService';
import { BaseService } from '@yojee/api/baseService';

export class PodService extends BaseService {
  constructor({ authService }) {
    super();
    this.authService = authService;
  }

  getAllVersions = () => {
    return this.authService
      .get(this.getUmbrellaApiUrl('dispatcher/company/pod_templates/versions'))
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  getPodContentByVersion = (id) => {
    return this.authService
      .get(this.getUmbrellaApiUrl(`dispatcher/company/pod_templates/${id}`))
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  createNewPod = (data) => {
    return this.authService
      .post(this.getUmbrellaApiUrl('dispatcher/company/pod_templates'), data)
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  updatePod = (id, data) => {
    return this.authService
      .put(this.getUmbrellaApiUrl(`dispatcher/company/pod_templates/${id}/set_active`))
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  removePod = (id) => {
    return this.authService
      .delete(this.getUmbrellaApiUrl(`dispatcher/company/pod_templates/${id}`))
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };
}

export const podService = new PodService({ authService });
