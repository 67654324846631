import { saveAs } from 'file-saver';

import { authService as authServiceApi } from '@yojee/api/authService';
import { BaseService } from '@yojee/api/baseService';

export class ChargeCodeService extends BaseService {
  constructor({ authService }) {
    super();
    this.authService = authService;
  }

  batchUpload = ({ file, format }) => {
    const uploadJson = new FormData();
    uploadJson.set('file', file);
    uploadJson.set('format', format);

    return this.authService
      .post(this.getUmbrellaApiUrl(`dispatcher/rates/rate_charge_types/batches`), uploadJson, {
        'Content-Type': 'multipart/form-data',
      })
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };

  checkBatchStatus = ({ batchId }) => {
    return this.authService
      .get(this.getUmbrellaApiUrl(`dispatcher/rates/rate_charge_types/batches/${batchId}/status`))
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };

  downloadSample = (format) => {
    return this.authService
      .get(
        this.getUmbrellaApiUrl(`dispatcher/rates/rate_charge_types/batches/download_sample`),
        { format: format },
        {
          responseType: 'blob',
        }
      )
      .then((response) => {
        saveAs(response.data, 'Template.' + format);
      });
  };
}

export const chargeCodeService = new ChargeCodeService({ authService: authServiceApi });
