import { getSlugFromUrl } from '@yojee/auth/utils/AuthUtils';

import { authService } from './authService/index';
import { BaseService } from './baseService/index';

export class ItemsTypeApi extends BaseService {
  constructor({ authService }) {
    super();
    this.authService = authService;
  }

  fetchItemTypes = () => {
    return this.authService
      .get(this.getUmbrellaApiUrl(`dispatcher/item_types`))
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  getPublicOrderItemTypes = () => {
    const requestPayload = { company_slug: getSlugFromUrl() };

    return this.authService
      .get(this.getUmbrellaApiUrl('public/orders/item_types'), requestPayload)
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };
}

export const itemTypesApi = new ItemsTypeApi({ authService });
