import { authService } from '@yojee/api/authService';
import { BaseService } from '@yojee/api/baseService';

export class SenderOrganisationService extends BaseService {
  constructor({ authService }) {
    super();
    this.authService = authService;
  }

  listAllSenderOrganisations = ({ q, page_size = 1000 }) => {
    return this.authService
      .get(this.getUmbrellaApiUrl(`dispatcher/organisations`), { page_size, q })
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  loadSenderOrganisation = (id) => {
    return this.authService
      .get(this.getUmbrellaApiUrl(`dispatcher/organisations/${id}`))
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  listSenderOrganisations = (params) => {
    params['with_users'] = true;
    return this.authService
      .get(this.getUmbrellaApiUrl('dispatcher/organisations'), params)
      .then((response) => {
        return this.extractData(response);
      })
      .catch((error) => this.handleError(error));
  };

  createSenderOrganisation = (sender) => {
    return this.authService
      .post(this.getUmbrellaApiUrl('dispatcher/organisations'), sender)
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  updateSenderOrganisation = (sender) => {
    return this.authService
      .put(this.getUmbrellaApiUrl(`dispatcher/organisations/${sender.id}`), sender)
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  deleteSenderOrganisation = (organisation) => {
    return this.authService
      .delete(
        this.getUmbrellaApiUrl(`dispatcher/senders/${organisation.sender.id}`, {
          user_profile_id: organisation.sender.user_profile_id,
        })
      )
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  createSenderOrganisationUser = (sender) => {
    return this.authService
      .post(this.getUmbrellaApiUrl(`dispatcher/organisations/${sender.organisation_id}/senders`), sender)
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  updateSenderOrganisationUser = (sender) => {
    return this.authService
      .put(
        this.getUmbrellaApiUrl(`dispatcher/organisations/${sender.organisation_id}/senders/${sender.user_profile_id}`),
        sender
      )
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  deleteSenderOrganisationUser = ({ user_profile_id, sender_id }) => {
    return this.authService
      .delete(this.getUmbrellaApiUrl(`dispatcher/senders/${sender_id}`), {
        params: { user_profile_id: user_profile_id },
      })
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  resetPasswordSenderOrganisation = (userProfileId) => {
    return this.authService
      .post(this.getUmbrellaApiUrlV4(`company/organisation/user/${userProfileId}/reset_password`))
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  batchUpload = ({ file, format, type }) => {
    const uploadJson = new FormData();
    uploadJson.set('file', file);
    uploadJson.set('format', format);
    uploadJson.set('type', type);

    return this.authService
      .post(this.getUmbrellaApiUrl(`dispatcher/organisations/batches`), uploadJson, {
        'Content-Type': 'multipart/form-data',
      })
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };

  checkBatchStatus = ({ batchId }) => {
    return this.authService
      .get(this.getUmbrellaApiUrl(`dispatcher/organisations/batches/${batchId}/status`))
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };

  downloadTemplateSample = ({ format, type }) => {
    return this.getUmbrellaApiUrl(`public/organisations/download_sample?format=${format}&type=${type}`);
  };

  getSenderOrganisationTemplates = ({ payload }) => {
    return this.authService
      .get(this.getUmbrellaApiUrlV4(`company/template_types`), payload)
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };

  linkTemplates = ({ params, orgId }) => {
    return this.authService
      .post(this.getUmbrellaApiUrlV4(`company/organisations/${orgId}/booking_template_types`), params)
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };

  unLinkTemplates = ({ params, orgId }) => {
    return this.authService
      .delete(this.getUmbrellaApiUrlV4(`company/organisations/${orgId}/booking_template_types`), { params: params })
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };

  setDefaultTemplates = ({ templateId, orgId }) => {
    return this.authService
      .put(this.getUmbrellaApiUrlV4(`company/organisations/${orgId}/booking_template_types/${templateId}`))
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };

  linkItemTypes = ({ params, orgId }) => {
    return this.authService
      .post(this.getUmbrellaApiUrlV4(`company/organisations/${orgId}/item_types`), params)
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };

  unLinkItemTypes = ({ params, orgId }) => {
    return this.authService
      .delete(this.getUmbrellaApiUrlV4(`company/organisations/${orgId}/item_types`), { params: params })
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };

  setDefaultItemTypes = ({ itemTypeId, orgId }) => {
    return this.authService
      .put(this.getUmbrellaApiUrlV4(`company/organisations/${orgId}/item_types/${itemTypeId}`))
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };

  getSenderOrganisationServiceType = ({ payload }) => {
    return this.authService
      .get(this.getUmbrellaApiUrlV4(`company/service_types`), payload)
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };

  linkServiceTypes = ({ params, orgId }) => {
    return this.authService
      .post(this.getUmbrellaApiUrlV4(`company/organisations/${orgId}/service_types`), params)
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };

  unLinkServiceTypes = ({ params, orgId }) => {
    return this.authService
      .delete(this.getUmbrellaApiUrlV4(`company/organisations/${orgId}/service_types`), { params: params })
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };

  setDefaultServiceTypes = ({ serviceTypeId, orgId }) => {
    return this.authService
      .put(this.getUmbrellaApiUrlV4(`company/organisations/${orgId}/service_types/${serviceTypeId}`))
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };
}

export const senderOrganisationService = new SenderOrganisationService({ authService });
