import { isArray, isEqual, isObject, transform } from 'lodash-es';

export const getAllValuePaths = (tree) => {
  const paths = [];
  const nodes = [
    {
      obj: tree,
      path: [],
    },
  ];

  while (nodes.length > 0) {
    const currentNode = nodes.pop();
    Object.keys(currentNode.obj).forEach((k) => {
      const path = currentNode.path.concat(k);
      if (typeof currentNode.obj[k] === 'object') {
        nodes.unshift({ obj: currentNode.obj[k], path });
      } else {
        paths.push(path);
      }
    });
  }

  return paths;
};

/**
 * Deep diff between two object, using lodash
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {Object}        Return a new object who represent the diff
 */
export const difference = (object, base) => {
  return transform(object, (result, value, key) => {
    if (!isEqual(value, base[key])) {
      result[key] = isObject(value) && isObject(base[key]) ? difference(value, base[key]) : value;
    }
  });
};

export const removeDeepByKey = (obj, keyToBeRemoved) => {
  return transform(obj, function (result, value, key) {
    if (isObject(value)) {
      value = removeDeepByKey(value, keyToBeRemoved);
    }
    if (key !== keyToBeRemoved) {
      if (isArray(obj)) result.push(value);
      else result[key] = value;
    }
  });
};
