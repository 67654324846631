import debounce from 'lodash-es/debounce';
import React from 'react';
import { Trans } from 'react-i18next';

import { ratingsService } from '@yojee/api/ratingsService';
import { RATE_TYPE_OPTIONS } from '@yojee/helpers/rating/rateDetailHelper';
import { DEBOUNCE_SEARCH_TIME } from '@yojee/ui/base/customHooks/useTable';

const GROUP_OPTIONS = {
  SENDERS: 'senders',
  PARTNERS: 'partners',
};

export const ALL_OPTION = { label: <Trans>All</Trans>, value: 'all' };

const STATUS_OPTIONS = [
  ALL_OPTION,
  {
    label: <Trans>Active</Trans>,
    value: 'active',
  },
  {
    label: <Trans>Inactive</Trans>,
    value: 'inactive',
  },
];

const loadSenderOptions = (inputValue) => {
  // This is currently calling organisations.
  return ratingsService.getSenders(inputValue).then((senders) => {
    return senders.data.map((sender) => ({
      value: sender.id,
      label: sender.organisation_name,
      groupOpt: GROUP_OPTIONS.SENDERS,
    }));
  });
};

const loadPartnerOptions = (inputValue) => {
  return ratingsService.getPartners(inputValue).then((partners) => {
    return partners.data.map((partner) => ({
      value: partner.company_id,
      label: partner.company_name,
      groupOpt: GROUP_OPTIONS.PARTNERS,
    }));
  });
};

const loadSendersAndPartnersOptions = debounce((inputValue, callback) => {
  try {
    const promises = [loadSenderOptions(inputValue), loadPartnerOptions(inputValue)];
    Promise.all(promises).then(([senderOptions, partnerOptions]) => {
      callback([
        { label: <Trans>Sender/Upstream Partners</Trans>, options: senderOptions },
        { label: <Trans>Downstream Partners</Trans>, options: partnerOptions },
      ]);
    });
  } catch (error) {
    console.error(error);
  }
}, DEBOUNCE_SEARCH_TIME);

const sendersAndPartnersTransformFn = (selectedOpts) => {
  const initialVals = { sender_ids: undefined, partner_ids: undefined };

  if (Array.isArray(selectedOpts) && selectedOpts.length > 0) {
    return selectedOpts.reduce((vals, { value, groupOpt }) => {
      if (groupOpt === GROUP_OPTIONS.SENDERS) {
        if (vals.sender_ids) vals.sender_ids.push(value);
        else vals.sender_ids = [value];
      } else if (groupOpt === GROUP_OPTIONS.PARTNERS) {
        if (vals.partner_ids) vals.partner_ids.push(value);
        else vals.partner_ids = [value];
      }

      return vals;
    }, initialVals);
  }

  return initialVals;
};

export const RATINGS_FILTERS = {
  perspectiveType: {
    type: 'select',
    values: [ALL_OPTION, ...RATE_TYPE_OPTIONS],
    transformFn: (rateType) => ({ perspective_type: rateType.value }),
    label: <Trans>Rate Type</Trans>,
    filterKey: 'perspective_type',
    wrapperGridSize: 6,
  },
  status: {
    type: 'select',
    values: STATUS_OPTIONS,
    transformFn: (status) => ({ active: status.value === 'active' }),
    label: <Trans>Status</Trans>,
    filterKey: 'active',
    wrapperGridSize: 6,
  },
  startDate: {
    type: 'date',
    defaultValue: null,
    label: <Trans>Start Date</Trans>,
    filterKey: 'start_date',
    wrapperGridSize: 6,
    placeholder: 'DD/MM/YYYY',
  },
  endDate: {
    type: 'date',
    defaultValue: null,
    label: <Trans>End Date</Trans>,
    filterKey: 'end_date',
    wrapperGridSize: 6,
    placeholder: 'DD/MM/YYYY',
  },
  senders_and_partners: {
    type: 'async_select',
    loadOptions: loadSendersAndPartnersOptions,
    label: <Trans>Sender(s)/Partner(s)</Trans>,
    isMulti: true,
    defaultOptions: true,
    transformFn: sendersAndPartnersTransformFn,
    filterKey: 'senders_and_partners',
    wrapperGridSize: 12,
  },
  condition: {
    type: 'query',
    display: false,
    transformFn: ({ logic: rule }) => ({ rule }),
    filterKey: 'condition',
  },
};

export const CONTAINER_TYPE_FILTERS = {
  code: {
    type: 'text',
    defaultValue: '',
    label: <Trans>Code</Trans>,
    filterKey: 'code',
    wrapperGridSize: 12,
  },
  length: {
    type: 'number',
    defaultValue: '',
    label: <Trans>Length</Trans>,
    filterKey: 'length',
  },
  height: {
    type: 'number',
    defaultValue: '',
    label: <Trans>Height</Trans>,
    filterKey: 'height',
  },
  group: {
    type: 'text',
    defaultValue: '',
    label: <Trans>Group</Trans>,
    filterKey: 'group',
  },
};
