import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { getPrefixSelector } from '../../../../selectors';
import isSelectedMenu from '../utils/isSelectedMenu';

export const updatePathToExplore = (path) => {
  window.parent.postMessage(
    {
      type: 'UPDATE_PATH',
      path: `manage${path}`,
    },
    '*'
  );
};

export default function useMenuItemLogic(menuItem) {
  const { partnerRestrict, key: menuKey } = menuItem;
  const { pathname } = useLocation();
  const prefix = useSelector(getPrefixSelector);
  const link = partnerRestrict ? '' : `${prefix}/${menuKey}`;

  const isSelected = isSelectedMenu(menuItem, pathname, prefix);

  const onClickLeafMenu = () => {
    if (partnerRestrict) {
      window.parent.postMessage({ type: 'OPEN_FEATURES_DIALOG' }, '*');
    } else {
      updatePathToExplore(`/${menuKey}`);
    }
  };

  return {
    isSelected,
    link: menuItem.children ? '' : link,
    onClickLeafMenu,
  };
}
