import { call, put, takeLatest } from 'redux-saga/effects';

import { addressService } from '@yojee/api/addressService';

import { ADDRESS_PICKER_ACTION_TYPES as TYPES } from './actions';

export default function* saga() {
  yield takeLatest(TYPES.SAVE_TO_ADDRESS_BOOK, saveToAddressBook);
}

function* saveToAddressBook({ payload }) {
  try {
    const data = yield call(addressService.createAddress, payload);
    yield put({ type: TYPES.SAVE_TO_ADDRESS_BOOK_SUCCESS, data });
    yield put({ type: TYPES.CLEAR_ADDRESS_PICKER_MESSAGE });
  } catch (error) {
    console.log(error);
    yield put({ type: TYPES.SAVE_TO_ADDRESS_BOOK_ERROR, message: error.message });
  }
}
