import { call, put, takeLatest } from 'redux-saga/effects';

import networkService from '../../services/networkService';

export default function* sagas() {
  yield takeLatest('GET_HUBS', getHubs);
  yield takeLatest('GET_REGIONS', getRegions);
}

function* getHubs() {
  try {
    yield put({ type: 'START_LOADING', loadingAction: 'getHubs' });
    const data = yield call(networkService.getHubs);
    yield put({ type: 'GET_HUBS_SUCCESSFUL', data });
  } catch (error) {
    yield put({ type: 'GET_HUBS_FAILED', error });
  }
}

function* getRegions() {
  try {
    yield put({ type: 'START_LOADING', loadingAction: 'getRegions' });
    const data = yield call(networkService.getRegions);
    yield put({ type: 'GET_REGIONS_SUCCESSFUL', data });
  } catch (error) {
    yield put({ type: 'GET_REGIONS_FAILED', error });
  }
}
