import './LoadingOverlay.scss';

import React from 'react';
import ReactLoadingOverlay from 'react-loading-overlay';

import Spinner from './Spinner';

const LoadingOverlay = ({ text = null, active, children }) => {
  return (
    <ReactLoadingOverlay
      active={active}
      fadeSpeed={0}
      spinner={<Spinner />}
      children={children}
      styles={{
        wrapper: {
          width: '100%',
          ...(active ? { zIndex: 100 } : {}),
        },
        overlay: (base) => ({
          ...base,
          zIndex: '2000',
          height: '200vh',
          maxHeight: '100%',
        }),
      }}
      text={text}
    />
  );
};

export default LoadingOverlay;
