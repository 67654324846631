import { t } from 'i18next';

import { ALL_OPTION } from '../utils/filtersConfig';

const defaultState = {
  loading: {
    listRatingEntries: false,
    createRatingEntry: false,
    updateRatingEntry: false,
    deleteRatingEntry: false,
    fetchRatingEntry: false,
    listRateChargeTypes: false,
    fetchRatingMetadata: false,
    copyRatingEntry: false,
  },
  ratingEntriesData: [],
  filter: {
    perspective_type: ALL_OPTION,
    active: ALL_OPTION,
    service_type_name: '',
    senders: null,
    partners: null,
    senders_and_partners: [],
    start_date: undefined,
    end_date: undefined,
    sort_name_by: 'asc',
  },
  advancedFilter: {
    condition: undefined,
    jsonTree: undefined,
  },
  pagination: { current_page: 1, limit_value: 25, total_count: 0, total_pages: 0 },
  error: {
    failed: false,
  },
  ratingEntryData: null,
  ratingEntryIssues: [],
  rateEntryChargeTypesData: [],
  metadata: {},
  basicFilterCounter: 0,
  advancedFilterCounter: 0,
};

export const ratingsMessagesSuccessEvents = {
  CREATE_RATING_ENTRY_SUCCESSFUL: ({ isShowSuccessfulMesg }) =>
    isShowSuccessfulMesg ? t('Rate entry created successfully') : null,
  UPDATE_RATING_ENTRY_SUCCESSFUL: ({ isShowSuccessfulMesg }) =>
    isShowSuccessfulMesg ? t('Rate entry updated successfully') : null,
  DELETE_RATING_ENTRY_SUCCESSFUL: t('Rate entry deleted successfully'),
  COPY_RATING_ENTRY_SUCCESSFUL: t('Rate entry copied successfully'),
};

export const ratingsMessagesErrorEvents = {
  LIST_RATING_ENTRIES_FAILED: ({ error }) => error?.message ?? t('Failed to get sender info'),
  FETCH_RATING_METADATA_FAILED: ({ error }) => error?.message ?? t('Failed to get rate metadata'),
  LIST_RATE_CHARGES_TYPES_FAILED: ({ error }) => error?.message ?? t('Failed to get rate charges types'),
  FETCH_RATING_ENTRY_FAILED: ({ error }) => error?.message ?? t('Failed to fetch rating entry'),
  CREATE_RATING_ENTRY_FAILED: (data) => data?.error?.message ?? t('Error creating rate entry.'),
  UPDATE_RATING_ENTRY_FAILED: (data) => data?.error?.message ?? t('Error updating rating entry.'),
  DELETE_RATING_ENTRY_FAILED: t('Error deleting rate entry.'),
  COPY_RATING_ENTRY_FAILED: t('Error copying rate entry.'),
  DEACTIVATE_RATING_ENTRY_FAILED: ({ error }) => error?.message ?? t('Failed to deactivate rate card.'),
  ACTIVATE_RATING_ENTRY_FAILED: ({ error }) => error?.message ?? t('Failed to activate rate card.'),
};

export const init = () => {
  return defaultState;
};

const ACTION_HANDLERS = {
  LIST_RATING_ENTRIES_SUCCESSFUL: (state, sagaData) => {
    const { ratingEntriesData: data, params } = sagaData;
    return {
      ...state,
      loading: {
        ...state.loading,
        listRatingEntries: false,
      },
      ratingEntriesData: data && data['data'],
      ratingEntryData: null,
      pagination: data && data['pagination'],
      filter: params,
      advancedFilter: {
        condition: params.condition,
        jsonTree: params.jsonTree,
      },
    };
  },
  CLEAR_RATING_ENTRIES: (state) => {
    return {
      ...state,
      ...init(),
    };
  },
  CLEAR_RATING_ENTRY_ISSUES: (state) => {
    return {
      ...state,
      ratingEntryIssues: [],
    };
  },
  LIST_RATING_ENTRIES_FAILED: (state, sagaData) => {
    const { params } = sagaData;
    return {
      ...state,
      loading: {
        ...state.loading,
        listRatingEntries: false,
      },
      filter: params,
      advancedFilter: {
        condition: params.condition,
        jsonTree: params.jsonTree,
      },
      error: {
        failed: true,
      },
    };
  },
  FETCH_RATING_METADATA_SUCCESSFULLY: (state, sagaData) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        fetchRatingMetadata: false,
      },
      metadata: sagaData.ratingMetadata,
    };
  },
  FETCH_RATING_METADATA_FAILED: (state) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        fetchRatingMetadata: false,
      },
      error: {
        failed: true,
      },
    };
  },
  LIST_RATE_CHARGES_TYPES_SUCCESSFUL: (state, sagaData) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        listRateChargeTypes: false,
      },
      rateEntryChargeTypesData: sagaData.rateEntryChargeTypesData,
    };
  },
  LIST_RATE_CHARGES_TYPES_FAILED: (state) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        listRateChargeTypes: false,
      },
      error: {
        failed: true,
      },
    };
  },
  FETCH_RATING_ENTRY_SUCCESSFUL: (state, sagaData) => {
    const data = sagaData.ratingEntryData;
    return {
      ...state,
      loading: {
        ...state.loading,
        fetchRatingEntry: false,
      },
      ratingEntryData: data,
    };
  },
  FETCH_RATING_ENTRY_FAILED: (state) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        fetchRatingEntry: false,
      },
      error: {
        failed: true,
      },
    };
  },
  SET_RATING_ENTRY_LOADING: (state, sagaData) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        [sagaData.key]: true,
      },
    };
  },
  CLEAR_RATING_ENTRY_MESSAGE: (state) => {
    return {
      ...state,
      successMessage: undefined,
      errorMessage: undefined,
    };
  },
  CREATE_RATING_ENTRY_FAILED: (state) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        createRatingEntry: false,
      },
    };
  },
  UPDATE_RATING_ENTRY_FAILED: (state) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        updateRatingEntry: false,
      },
    };
  },
  DELETE_RATING_ENTRY_FAILED: (state) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        deleteRatingEntry: false,
      },
    };
  },
  CREATE_RATING_ENTRY_SUCCESSFUL: (state, { ratingEntryData }) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        createRatingEntry: false,
      },
      ratingEntryData,
    };
  },
  DELETE_RATING_ENTRY_SUCCESSFUL: (state) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        deleteRatingEntry: false,
      },
      ratingEntryData: null,
    };
  },
  UPDATE_RATING_ENTRY_SUCCESSFUL: (state, { ratingEntryData }) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        updateRatingEntry: false,
      },
      ratingEntryData,
    };
  },
  COPY_RATING_ENTRY_SUCCESSFUL: (state) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        copyRatingEntry: false,
      },
    };
  },
  COPY_RATING_ENTRY_FAILED: (state) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        copyRatingEntry: false,
      },
    };
  },
  RATING_ENTRY_ISSUES: (state, { issues, keyLoading }) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        [keyLoading]: false,
      },
      ratingEntryIssues: issues,
    };
  },
};

const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};

export default reducer;
