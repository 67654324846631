import moment from 'moment';

import { capitalizeFirstLetter } from '@yojee/helpers/string-helper';

import { RATE_CHARGE_TYPES } from '../constants';

export const pipe = (...restArgs) => restArgs.reduce((input, fn) => fn(input));

export const split = (str, separator = '_') => (str || '').split(separator);
export const join = (arr, separator = ' ') => (arr || []).join(separator);
export const capitalize = (arrStr) => arrStr.map((str) => capitalizeFirstLetter(str));

const formatDate = (date, format = 'yy-MM-DD') => moment(date).format(format);

export const startOfDate = (date) => moment(date).startOf('date');
export const endOfDate = (date) => moment(date).endOf('date');

export const today = () => new Date();

export const isBeforeToday = (date) => moment(formatDate(date)).isBefore(formatDate(today()));
export const isAfterToday = (date) => moment(formatDate(date)).isAfter(formatDate(today()));

export const repeatFnCall = (fn, n = 1) => {
  fn();
  if (n > 1) repeatFnCall(fn, n - 1);
};

export const isSellRateCard = (type) => type === RATE_CHARGE_TYPES.sell;
export const isBuyRateCard = (type) => type === RATE_CHARGE_TYPES.buy;
export const isInternalCostRateCard = (type) => type === RATE_CHARGE_TYPES.internal_cost;
