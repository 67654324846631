import Grid from '@material-ui/core/Grid';
import { Trans } from 'react-i18next';

import { Input as TextField, PhoneNumber } from '@yojee/ui/base';
import Title from '@yojee/ui/base/furnitures/Typography/Title';

import { useCompanySettingsStyles } from './helper';

const CompanyInformation = ({
  company,
  cip,
  taskServiceTime,
  setTaskServiceTime,
  address,
  setAddress,
  phone,
  setPhone,
}) => {
  const classes = useCompanySettingsStyles();

  return (
    <Grid container spacing={2} className={classes.section}>
      <Grid item xs={12}>
        <Title variant="h4">
          <Trans>Information</Trans>
        </Title>
      </Grid>
      <Grid item xs={6}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <TextField label={<Trans>Company name</Trans>} required disabled fullWidth value={company.name || ''} />
          </Grid>
          <Grid item xs={6}>
            <TextField label={<Trans>CIP</Trans>} required disabled fullWidth value={cip || ''} />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label={<Trans>Task Service Time</Trans>}
              fullWidth
              type="number"
              InputProps={{
                inputProps: {
                  min: 0,
                },
              }}
              onChange={({ target }) => {
                setTaskServiceTime(target.value);
              }}
              value={taskServiceTime}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <TextField
              label={<Trans>Company Address</Trans>}
              fullWidth
              value={address}
              onChange={({ target: { value } }) => {
                setAddress(value);
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <PhoneNumber
              label={<Trans>Company Phone</Trans>}
              value={phone}
              onChange={(phone) => {
                setPhone(phone);
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CompanyInformation;
