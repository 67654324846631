export class CommonService {
  getSlugFromUrl = () => {
    if (window) {
      const parts = window.location.hostname.split('.');
      return parts[1] && ['test', 'localhost', 'manage', 'dispatcher'].some((val) => parts[1].includes(val))
        ? parts[0]
        : 'yojee';
    }
    return null;
  };

  static setFavicon = (favicons) => {
    const defaultFavicon = document.querySelector("link[rel*='icon']");
    const head = document.getElementsByTagName('head')[0];
    if (defaultFavicon) {
      head.removeChild(defaultFavicon);
    }
    Object.keys(favicons).forEach((sizes) => {
      const link = document.createElement('link');
      link.type = 'image/png';
      link.rel = 'shortcut icon';
      link.href = favicons[sizes];
      link.setAttribute('sizes', sizes);
      head.appendChild(link);
    });
  };
}

export const commonService = new CommonService({});
