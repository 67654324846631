import React from 'react';
import { useLocation } from 'react-router-dom';

import usePreviousValue from '@yojee/helpers/hooks/usePreviousValue';

import RealErrorBoundary from './RealErrorBoundary';

// This wrapper error boundary will reset the "real" error boundary
// whenever a pathname change happens _after_ an error
const CustomErrorBoundary = ({ children, resetKeys, CustomFallbackComponent, onReset }) => {
  const [key, setKey] = React.useState(0);
  const { pathname } = useLocation();
  const previousPathname = usePreviousValue(pathname);
  const [trackPathChange, setTrackPathChange] = React.useState(false);

  React.useEffect(() => {
    if (trackPathChange && previousPathname !== pathname) {
      setKey((prevKey) => prevKey + 1);
      setTrackPathChange(false);
    }
  }, [trackPathChange, previousPathname, pathname]);

  return (
    <RealErrorBoundary
      key={key}
      setTrackPathChange={setTrackPathChange}
      resetKeys={resetKeys}
      onReset={onReset}
      CustomFallbackComponent={CustomFallbackComponent}
    >
      {children}
    </RealErrorBoundary>
  );
};

export default CustomErrorBoundary;
