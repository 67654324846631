export const bulkDeleteRequest = (payload) => {
  return { type: 'BULK_DELETE_REQUEST', payload };
};

export const bulkDeleteRequestFail = (error) => {
  return { type: 'CHECK_BULK_DELETE_FAIL', error };
};

export const updateBulkSelection = (payload) => {
  return { type: 'UPDATE_ROW', payload };
};

export const updateAllRequest = (payload) => {
  return { type: 'UPDATE_ALL_REQUEST', payload };
};

export const setOpenBulkDeleteDialog = () => {
  return { type: 'SET_OPEN_BULK_DELETE_DIALOG' };
};

export const setCloseBulkDeleteDialog = () => {
  return { type: 'SET_CLOSE_BULK_DELETE_DIALOG' };
};

export const setOpenChooseBulkUpdateDialog = ({ value }) => {
  return { type: 'SET_OPEN_CHOOSE_BULK_UPDATE_DIALOG', value };
};

export const setCheckedFieldsUpdate = ({ fields }) => {
  return { type: 'SET_CHECKED_FIELDS_UPDATE', fields };
};

export const clearMessage = () => {
  return { type: 'CLEAR_MESSAGE' };
};
