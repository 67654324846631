import Grid from '@material-ui/core/Grid';
import { Trans } from 'react-i18next';

import { useLanguageOptions } from '@yojee/helpers/languages';
import { ReactSelect, Switch } from '@yojee/ui/base';
import Title from '@yojee/ui/base/furnitures/Typography/Title';

import { fixSelectedOption } from './fixSelectedOption';
import { useCompanySettingsStyles } from './helper';

const CompanyTrackingPage = ({
  isLiveTrackingEnabled,
  setIsLiveTrackingEnabled,
  isSenderInfoAndAddressShown,
  setIsSenderInfoAndAddressShown,
  trackingPageLanguage,
  setTrackingPageLanguage,
}) => {
  const classes = useCompanySettingsStyles();
  const languageOptions = useLanguageOptions();

  return (
    <Grid spacing={2} container className={classes.section}>
      <Grid item xs={12}>
        <Title variant="h4">
          <Trans>Live Tracking Page</Trans>
        </Title>
      </Grid>
      <Grid item xs={12}>
        <Switch
          label={<Trans>Enable live tracking</Trans>}
          checked={isLiveTrackingEnabled}
          onChange={() => setIsLiveTrackingEnabled((prevValue) => !prevValue)}
        />
      </Grid>
      <Grid item xs={12}>
        <Switch
          label={<Trans>Show Origin Address on Live Tracking Page</Trans>}
          checked={isSenderInfoAndAddressShown}
          onChange={() => setIsSenderInfoAndAddressShown((prevValue) => !prevValue)}
        />
      </Grid>
      <Grid item xs={3}>
        <ReactSelect
          isRawValue
          value={fixSelectedOption(trackingPageLanguage, languageOptions)}
          disabled={languageOptions.length === 1}
          onChange={(data) => {
            setTrackingPageLanguage(data.value);
          }}
          label={<Trans>Live Tracking Page Language</Trans>}
          options={languageOptions}
        />
      </Grid>
    </Grid>
  );
};

export default CompanyTrackingPage;
