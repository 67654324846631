import { call, put, takeLatest } from 'redux-saga/effects';

import { billingPartyService } from '../../services/billingPartyService';

export default function* sagas() {
  yield takeLatest('REQUEST_LIST_BILLING_PARTY', listBillingParty);
  yield takeLatest('REQUEST_CREATE_BILLING_PARTY', createBillingParty);
  yield takeLatest('REQUEST_UPDATE_BILLING_PARTY', updateBillingParty);
  yield takeLatest('REQUEST_ACTIVATE_BILLING_PARTY', activateBillingParty);
  yield takeLatest('REQUEST_DEACTIVATE_BILLING_PARTY', deactivateBillingParty);
  yield takeLatest('REQUEST_UPDATE_DEFAULT_BILLING_PARTY', updateDefaultBillingParty);
  yield takeLatest('REQUEST_CHECK_VALID_CONTACT_BILLING_PARTY', checkValidBillingParty);
}

function* listBillingParty(action) {
  try {
    yield put({ type: 'SET_BILLING_PARTY_LOADING', key: 'listBillingParty' });
    const data = yield call(billingPartyService.listBillingParty, action.payload);
    yield put({ type: 'LIST_BILLING_PARTY_SUCCESSFUL', data });
  } catch (error) {
    yield put({ type: 'LIST_BILLING_PARTY_FAILED', error });
  }
}

function* createBillingParty(action) {
  try {
    yield put({ type: 'SET_BILLING_PARTY_LOADING', key: 'createBillingParty' });
    const data = yield call(billingPartyService.createBillingParty, action.payload);
    yield put({ type: 'CREATE_BILLING_PARTY_SUCCESSFUL', data });
    yield put({ type: 'REQUEST_LIST_BILLING_PARTY', payload: action.payload });
  } catch (error) {
    yield put({ type: 'CREATE_BILLING_PARTY_FAILED', error });
  }
}

function* updateBillingParty(action) {
  try {
    yield put({ type: 'SET_BILLING_PARTY_LOADING', key: 'updateBillingParty' });
    const data = yield call(billingPartyService.updateBillingParty, action.payload);
    yield put({ type: 'UPDATE_BILLING_PARTY_SUCCESSFUL', data });
    yield put({ type: 'REQUEST_LIST_BILLING_PARTY', payload: action.payload });
  } catch (error) {
    yield put({ type: 'UPDATE_BILLING_PARTY_FAILED', error });
  }
}

function* updateDefaultBillingParty(action) {
  try {
    yield put({ type: 'SET_BILLING_PARTY_LOADING', key: 'updateDefaultBillingParty' });
    let data;
    if (action.payload.data.curDefault.id) {
      data = yield call(billingPartyService.updateBillingParty, {
        data: action.payload.data.curDefault,
      });
    }
    if (data || !action.payload.data.curDefault.id) {
      yield put({
        type: 'REQUEST_UPDATE_BILLING_PARTY',
        payload: {
          data: action.payload.data.newDefault,
        },
      });
    }
  } catch (error) {
    yield put({ type: 'UPDATE_BILLING_PARTY_FAILED', error });
  }
}

function* activateBillingParty(action) {
  try {
    yield put({ type: 'SET_BILLING_PARTY_LOADING', key: 'activateBillingParty' });
    const data = yield call(billingPartyService.activateBillingParty, action.payload);
    yield put({ type: 'ACTIVATE_BILLING_PARTY_SUCCESSFUL', data });
    yield put({ type: 'REQUEST_LIST_BILLING_PARTY', payload: action.payload });
  } catch (error) {
    yield put({ type: 'ACTIVATE_BILLING_PARTY_FAILED', error });
  }
}

function* deactivateBillingParty(action) {
  try {
    yield put({ type: 'SET_BILLING_PARTY_LOADING', key: 'activateBillingParty' });
    const data = yield call(billingPartyService.deactivateBillingParty, action.payload);
    yield put({ type: 'ACTIVATE_BILLING_PARTY_SUCCESSFUL', data });
    yield put({ type: 'REQUEST_LIST_BILLING_PARTY', payload: action.payload });
  } catch (error) {
    yield put({ type: 'ACTIVATE_BILLING_PARTY_FAILED', error });
  }
}

function* checkValidBillingParty(action) {
  const { phone, email, onSuccess, onFail } = action.payload;
  try {
    yield put({ type: 'SET_BILLING_PARTY_LOADING', key: 'checkValidContactBillingParty' });
    const payload = { phone, email };
    const data = yield call(billingPartyService.checkValidContactBillingParty, payload);
    yield put({ type: 'CHECK_VALID_CONTACT_BILLING_PARTY_SUCCESSFUL', data });
    onSuccess?.(data);
  } catch (error) {
    yield put({ type: 'CHECK_VALID_CONTACT_BILLING_PARTY_FAILED', error });
    onFail?.(error);
  }
}
