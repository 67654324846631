// @ts-nocheck
import languageData from 'langs/data';

type LanguageSample = {
  name: string;
  local: string;
  '1': string;
  '2': string;
  '2T': string;
  '3': string;
};

const languages = languageData as Array<LanguageSample>;

export const languageDB = languages.reduce((acc, value) => {
  const langCode = value['1'];
  acc[langCode] = value;

  return acc;
}, {} as Record<string, LanguageSample>);
