import last from 'lodash/last';
import PropTypes from 'prop-types';
import React from 'react';
import { components } from 'react-select';

const ValueContainer = ({ showSum, children, ...restProps }) => {
  const { length } = restProps.getValue();
  const selectOptionsAndInput = React.Children.map(children, (child) => {
    if (child && [components.SingleValue].indexOf(child.type) === -1) {
      return child;
    }
    return null;
  });

  const searchInput = last(selectOptionsAndInput);

  if (showSum && length >= 1) {
    return (
      <components.ValueContainer {...restProps}>
        {length} selected
        {searchInput}
      </components.ValueContainer>
    );
  }

  return <components.ValueContainer {...restProps}>{selectOptionsAndInput}</components.ValueContainer>;
};

ValueContainer.defaultProps = {
  showSum: true,
};

ValueContainer.propTypes = {
  showSum: PropTypes.bool,
};

export default ValueContainer;
