import MuiFormHelperText from '@material-ui/core/FormHelperText';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';

import Input from '../../furnitures/Input';

const styles = (theme) => ({
  root: {
    width: '100%',
  },
});

const DefaultTimePicker = (props) => {
  const { classes, className, helperText, error, ...rest } = props;
  return (
    <>
      <Input
        label="&nbsp;"
        type="time"
        className={clsx(classes.root, className)}
        inputProps={{
          step: 300, // 5 min
          'data-cy': rest?.dataCy,
        }}
        error={error}
        {...rest}
      />
      {helperText && <MuiFormHelperText error={error}>{helperText}</MuiFormHelperText>}
    </>
  );
};
export default withStyles(styles)(DefaultTimePicker);
