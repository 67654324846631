import { uuidv4 } from '@yojee/helpers/uuidv4';

import { INVOICE_TYPES } from './constant';

export const COLUMN_KEY = {
  order: 'order_id',
  leg: 'leg',
  charge_code: 'charge_code',
  description: 'description',
  sell: 'sell',
  cost: 'cost',
  margin: 'margin',
  currency: 'currency',
  billing_party_id: 'billing_party_id',
  payment_party: 'payment_party',
  invoice_number: 'invoice_number',
  actions: 'actions',
};

export const CHARGE_TYPES = {
  systemCharge: 'v4Charge',
  v3Charge: 'v3Charge',
  oldManualCharge: 'oldManualCharge',
  newManualCharge: 'newManualCharge',
  subCharge: 'subCharge',
};

export const CHARGE_STATUS = {
  invalidCharge: 'invalidCharge',
  deletedCharge: 'deleted',
  editedCharge: 'editedCharge',
  newValidManualCharge: 'newValidManualCharge',
};

export const CHARGING_METHOD = {
  manual: 'manual_adjustment',
  system: 'system',
};

export const CHARGE_SORT_BY = ['code', 'billing_party_name'];

export function getNewEmptyChargeRow(defaultCurrency, editableConfigs = {}) {
  return {
    id: uuidv4(),
    currency: defaultCurrency,
    missingInfo: true,
    sell: null,
    cost: null,
    chargeType: CHARGE_TYPES.newManualCharge,
    getChargeStatus,
    editableConfigs,
  };
}

function getBillingPartyIDDataMap(billingParties) {
  return billingParties?.reduce((acc, billingParty) => ({ ...acc, [billingParty.id]: billingParty.name }), {});
}

export function getOrderStepGroupIndexLegMap(charges, key = 'order_step_group_index') {
  let legNumber = 1;
  return (
    charges?.reduce((acc, leg) => {
      if (leg[key] !== null && !acc[leg[key]]) {
        acc[leg[key]] = legNumber;
        ++legNumber;
      }
      return acc;
    }, {}) ?? {}
  );
}

export function getChargeType(charge) {
  const isSystemCharge = charge.charging_method === CHARGING_METHOD.system;
  const isOldManualCharge = charge.charging_method === CHARGING_METHOD.manual;
  const isNewManualCharge = charge.chargeType === CHARGE_TYPES.newManualCharge;

  if (isSystemCharge) return CHARGE_TYPES.systemCharge;
  if (isOldManualCharge) return CHARGE_TYPES.oldManualCharge;
  if (isNewManualCharge) return CHARGE_TYPES.newManualCharge;

  return CHARGE_TYPES.v3Charge;
}

/**
 * This function used as property of charge object. We get charge by `this` keyword
 * @returns {string}
 */
function getChargeStatus() {
  const isNewManualCharge = this?.chargeType === CHARGE_TYPES.newManualCharge;

  const isNewEmptyRow = this?.newEmptyRow;
  const isDeletedCharge = this?.deleted;
  const isInvalidCharge = this?.missingInfo && !isDeletedCharge;
  const isChangedCharge = this?.dataChanged;
  const isNewValidManualCharge = isNewManualCharge && !isInvalidCharge && !isDeletedCharge && !isNewEmptyRow;
  const isEditedCharge = isChangedCharge || isDeletedCharge;

  if (isDeletedCharge) return CHARGE_STATUS.deletedCharge;
  if (isInvalidCharge) return CHARGE_STATUS.invalidCharge;
  if (isNewValidManualCharge) return CHARGE_STATUS.newValidManualCharge;
  if (isEditedCharge) return CHARGE_STATUS.editedCharge;
}

/**
 * Issue
   There is an issue in saving the edited ‘Sell’ for charges received by a downstream partner, 
   if the Charge Code does not exist at the up-stream level.

   Solution
   Two phased approach.
   Step 1 temporary: We will disallow editing the sell for charge codes that do not exist on the upstream. This is a temporary measure, 
   so as to allow us to  move forward with MT-132.

   Step 2 target: Allow a user to edit the Sell charge, upon saving display a modal that asks ‘Do you want to add this charge code’. 
   If so, the charge code is first created on the up-stream and the charge is saved.
 * @param {*} chargeCode 
 * @param {*} companySlugChargeCodes 
 * @returns {boolean}
 */
function isChargeCodeExistInCurrentCompanySlug(chargeCode, companySlugChargeCodes = []) {
  return companySlugChargeCodes.findIndex(({ code }) => code === chargeCode) > -1;
}

export function mapApiCharges({ apiCharges = [], billingParties = [], chargeCodes = [] }) {
  const billingPartyIDDataMap = getBillingPartyIDDataMap(billingParties);

  return apiCharges.map((charge) => {
    return {
      ...charge,
      billing_party_name: billingPartyIDDataMap?.[charge.billing_party_id],
      ...(isChargeCodeExistInCurrentCompanySlug(charge.charge_code, chargeCodes)
        ? {}
        : { isChargeCodeExistInCompanySlug: false }),
      getChargeStatus,
    };
  });
}

export function getDeletedRows(rows) {
  return rows?.filter((row) => !!row?.deleted) ?? [];
}

export function isManualCharge(row) {
  return [CHARGE_TYPES.newManualCharge, CHARGE_TYPES.oldManualCharge].includes(row?.chargeType);
}

export function isNewManualCharge(row) {
  return row?.chargeType === CHARGE_TYPES.newManualCharge;
}

export function isSystemCharge(row) {
  return row?.chargeType === CHARGE_TYPES.systemCharge;
}

export function isNewEmptyRow(row) {
  return row?.newEmptyRow;
}

export function isChargeFromDSPartner(charge) {
  return ['estimated_buy_from_partner_rate_card', 'actual_buy_from_partner'].includes(charge.cost_type);
}

export function canDeleteCharge(row) {
  return isManualCharge(row) && !isNewEmptyRow(row);
}

export function onValueChangeHoc(onValueChange, { addNewRow }) {
  return (value) => {
    if (isNewEmptyRow(value)) {
      onValueChange({ ...value, lastRow: false });
      addNewRow?.();
    } else {
      onValueChange(value);
    }
  };
}

export function getCommonColumnEditorProps(props) {
  const { onValueChange, addNewRow, column } = props;

  return {
    ...props,
    onValueChange: onValueChangeHoc(onValueChange, { addNewRow, column }),
  };
}

export function getWarningMessages(chargeWarnings = []) {
  const isWarningHaveChildrenMap = chargeWarnings.reduce((acc, warning) => {
    acc[warning.parent_rate_calculation_index] = true;
    return acc;
  }, {});

  return chargeWarnings
    .filter((warning) => !isWarningHaveChildrenMap[warning.rate_calculation_index])
    .map((warning) => `${warning.calculation_description}: ${warning.description}`);
}

export function isParentRow(row) {
  return Object.hasOwn(row, 'parent_id') && row.parent_id === null;
}

export function isProfitable(profit) {
  return profit >= 0;
}

export function isInvoiceFromProformaPeriodic(charges = []) {
  return charges.some((charge) => charge.invoice_infor?.type === INVOICE_TYPES.proformaPeriodic);
}

export const getProfitabilityPercentage = (sell = 0, profit = 0) => {
  const parsedFloatSell = parseFloat(sell);
  const parsedFloatProfit = parseFloat(profit);

  if (parsedFloatSell === 0) {
    if (parsedFloatProfit === 0) {
      return (0).toFixed(2);
    }

    return (parsedFloatProfit > 0 ? 100 : -100).toFixed(2);
  }

  const netProfit = Math.abs(parsedFloatProfit / parsedFloatSell);

  return ((parsedFloatProfit > 0 ? netProfit : -netProfit) * 100).toFixed(2);
};

export const getProfit = (sell, cost) => {
  const profit = (sell - cost).toFixed(2);
  const profitabilityPercentage = `${getProfitabilityPercentage(sell, profit)}`;

  return { profit, profitabilityPercentage };
};

export function isOrderInvoicedFromPeriodic(charges = []) {
  if (charges.length <= 0) return false;

  if (charges.filter(({ billing_party_id }) => !!billing_party_id).length <= 0) return false;

  return charges.every(({ invoice_number }) => invoice_number && /^PI-J/.test(invoice_number));
}

export function isChargeCodeExistInCompanySlug(row) {
  return row.isChargeCodeExistInCompanySlug !== false;
}

export function isInvoiceGenerated(charge) {
  return !!charge.invoice_number;
}

export function isOrderInvoiceGenerated(charges = []) {
  return charges.length > 0 && charges.some(isInvoiceGenerated);
}

export function isManualChargeFromPeriodiJob(charge) {
  return charge.chargeType === 'newManualCharge' && charge.type === 'sell';
}
