import { call, put, select, takeLatest } from 'redux-saga/effects';

import { wayBillService } from '../../services/wayBillService';

export default function* saga() {
  yield takeLatest('GET_WAYBILL_VERSIONS_REQUEST', requestGetWayBillVersions);
  yield takeLatest('SELECT_WAYBILL_VERSION', requestGetWayBillByVersion);
  yield takeLatest('CREATE_NEW_WAYBILL_REQUEST', createNewWayBillRequest);
  yield takeLatest('UPDATE_WAYBILL_REQUEST', updateWayBillRequest);
  yield takeLatest('GET_LABELS_LIST_REQUEST', getLabelsListRequest);
  yield takeLatest('GET_LABEL_DETAILS_REQUST', getLabelDetailsRequest);
  yield takeLatest('CREATE_LABEL_REQUEST', createLabelRequest);
  yield takeLatest('UPDATE_LABEL_REQUEST', updateLabelRequest);
  yield takeLatest('SET_DEFAULT_LABEL_REQUEST', setDefaultLabelRequest);
}

function* requestGetWayBillVersions(action) {
  try {
    yield put({ type: 'SET_WAYBILL_PROGRESS', key: 'getVersionsListRequest' });
    const labelId = action?.payload?.label_id || null;
    const data = yield call(wayBillService.getAllVersions, labelId);
    const versionList = data?.waybill_templates || [];
    yield put({ type: 'GET_WAYBILL_VERSION_SUCCESS', data: versionList });
    const activeVersion = versionList.find((i) => i.is_active || i.is_active === null);

    if (activeVersion) {
      const content = yield call(wayBillService.getWayBillContentByVersion, activeVersion.id);
      yield put({ type: 'GET_WAYBILL_CONTENT_SUCCESS', data: content, selectedVersion: activeVersion.id });
    } else {
      yield put({ type: 'GET_WAYBILL_CONTENT_SUCCESS', data: {} });
    }
    yield put({ type: 'SET_WAYBILL_PROGRESS', key: 'getVersionsListRequest', value: false });
  } catch (error) {
    yield put({ type: 'SET_WAYBILL_PROGRESS', key: 'getVersionsListRequest', value: false });
    yield put({ type: 'GET_WAYBILL_VERSION_ERROR', error });
  }
}

function* requestGetWayBillByVersion({ payload }) {
  try {
    const content = yield call(wayBillService.getWayBillContentByVersion, payload);
    yield put({ type: 'GET_WAYBILL_CONTENT_SUCCESS', data: content, selectedVersion: payload });
  } catch (error) {
    yield put({ type: 'GET_WAYBILL_VERSION_ERROR', error });
  }
}

function* createNewWayBillRequest({ payload }) {
  try {
    yield put({ type: 'SET_WAYBILL_PROGRESS', key: 'createNewWayBillRequest' });
    yield call(wayBillService.createNewWayBill, payload);
    yield put({ type: 'SET_WAYBILL_PROGRESS', key: 'createNewWayBillRequest', value: false });
    yield put({ type: 'GET_WAYBILL_VERSIONS_REQUEST', payload });
  } catch (error) {
    yield put({ type: 'SET_WAYBILL_PROGRESS', key: 'createNewWayBillRequest', value: false });
  }
}

function* updateWayBillRequest({ payload }) {
  try {
    yield put({ type: 'SET_WAYBILL_PROGRESS', key: 'updateWayBillRequest' });
    yield call(wayBillService.updateWayBill, payload.id, payload);
    yield put({ type: 'SET_WAYBILL_PROGRESS', key: 'updateWayBillRequest', value: false });
    yield put({ type: 'GET_WAYBILL_VERSIONS_REQUEST', payload });
  } catch (error) {
    yield put({ type: 'SET_WAYBILL_PROGRESS', key: 'updateWayBillRequest', value: false });
  }
}

function* getLabelsListRequest({ payload }) {
  try {
    yield put({ type: 'SET_WAYBILL_PROGRESS', key: 'getLabelsListRequest', value: true });
    const { data, pagination } = yield call(wayBillService.getAllLabels, payload);
    const formattedData = yield data.map((item) => ({
      ...item,
      active_template: item.waybill_templates?.find((template) => template.is_active)?.version || '',
      number_of_templates: item.waybill_templates.length,
      disabled: item.waybill_templates.length === 0,
    }));
    yield put({ type: 'GET_LABELS_LIST_SUCCESS', data: formattedData, pagination });

    yield put({ type: 'SET_WAYBILL_PROGRESS', key: 'getLabelsListRequest', value: false });
  } catch (error) {
    yield put({ type: 'GET_LABELS_LIST_ERROR', error });
    yield put({ type: 'SET_WAYBILL_PROGRESS', key: 'getLabelsListRequest', value: false });
  }
}

function* getLabelDetailsRequest({ payload }) {
  try {
    yield put({ type: 'SET_WAYBILL_PROGRESS', key: 'getLabelDetailsRequest', value: true });
    const data = yield call(wayBillService.getLabelDetail, payload);
    yield put({ type: 'GET_LABEL_DETAILS_SUCCESS', data: data });
    yield put({ type: 'SET_WAYBILL_PROGRESS', key: 'getLabelDetailsRequest', value: false });
  } catch (error) {
    yield put({ type: 'GET_LABEL_DETAILS_ERROR', error });
    yield put({ type: 'SET_WAYBILL_PROGRESS', key: 'getLabelDetailsRequest', value: false });
  }
}

function* createLabelRequest({ payload }) {
  try {
    yield put({ type: 'SET_WAYBILL_PROGRESS', key: 'createLabelRequest' });
    yield call(wayBillService.createLabel, payload);
    yield put({ type: 'SET_WAYBILL_PROGRESS', key: 'createLabelRequest', value: false });
    yield put({ type: 'CREATE_LABEL_SUCCESS' });
    const { current_page: page, limit_value: page_size } = yield select((state) => state.waybill.pagination);
    yield put({ type: 'GET_LABELS_LIST_REQUEST', payload: { page, page_size } });
  } catch (error) {
    yield put({ type: 'CREATE_LABEL_ERROR', error });
    yield put({ type: 'SET_WAYBILL_PROGRESS', key: 'createLabelRequest', value: false });
  }
}

function* updateLabelRequest({ payload }) {
  try {
    yield put({ type: 'SET_WAYBILL_PROGRESS', key: 'updateLabelRequest' });
    yield call(wayBillService.updateLabels, payload.id, payload);
    yield put({ type: 'SET_WAYBILL_PROGRESS', key: 'updateLabelRequest', value: false });
    yield put({ type: 'GET_LABEL_DETAILS_REQUST', payload: payload.id });
  } catch (error) {
    yield put({ type: 'UPDATE_LABEL_ERROR', error });
    yield put({ type: 'SET_WAYBILL_PROGRESS', key: 'updateLabelRequest', value: false });
  }
}

function* setDefaultLabelRequest({ payload }) {
  try {
    yield put({ type: 'SET_WAYBILL_PROGRESS', key: 'setDefaultLabelRequest' });
    yield call(wayBillService.updateLabels, payload.id, payload);
    yield put({ type: 'SET_WAYBILL_PROGRESS', key: 'setDefaultLabelRequest', value: false });
    yield put({ type: 'SET_DEFAULT_LABEL_SUCCESS' });
    const { current_page: page, limit_value: page_size } = yield select((state) => state.waybill.pagination);
    yield put({ type: 'GET_LABELS_LIST_REQUEST', payload: { page, page_size } });
  } catch (error) {
    yield put({ type: 'SET_DEFAULT_LABEL_ERROR', error });
    yield put({ type: 'SET_WAYBILL_PROGRESS', key: 'setDefaultLabelRequest', value: false });
  }
}
