import { authService } from '../authService/index';
import { BaseService } from '../baseService/index';

export class ContainerTypeApi extends BaseService {
  constructor({ authService }) {
    super();
    this.authService = authService;
  }

  getContainerTypes = (params = {}) => {
    return this.authService
      .get(this.getUmbrellaApiUrlV4(`public/container_types`), params)
      .then((response) => {
        return this.extractData(response);
      })
      .catch((error) => this.handleNewErrorFormat(error));
  };
}

export const containerTypeApi = new ContainerTypeApi({ authService });
