import { authService } from '@yojee/api/authService';
import { BaseService } from '@yojee/api/baseService';

export class TagService extends BaseService {
  constructor({ authService }) {
    super();
    this.authService = authService;
  }

  listTags = (params) => {
    return this.authService
      .get(this.getUmbrellaApiUrl('dispatcher/company/tags'), params)
      .then((response) => {
        return this.extractData(response);
      })
      .catch((error) => this.handleError(error));
  };

  createTag = (tag) => {
    return this.authService
      .post(this.getUmbrellaApiUrl('dispatcher/company/tags'), tag)
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  updateTag = (tag) => {
    return this.authService
      .put(this.getUmbrellaApiUrl(`dispatcher/company/tags/${tag.id}`), tag)
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  deleteTag = (id) => {
    return this.authService
      .delete(this.getUmbrellaApiUrl(`dispatcher/company/tags/${id}`))
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  batchUpload = ({ file, format }) => {
    const uploadJson = new FormData();
    uploadJson.set('file', file);
    uploadJson.set('format', format);

    return this.authService
      .post(this.getUmbrellaApiUrl(`dispatcher/company/tags/batches`), uploadJson, {
        'Content-Type': 'multipart/form-data',
      })
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };

  checkBatchStatus = ({ batchId }) => {
    return this.authService
      .get(this.getUmbrellaApiUrl(`dispatcher/company/tags/batches/${batchId}/status`))
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };

  downloadTagsTemplateSample = ({ format }) => {
    return this.getUmbrellaApiUrl(`public/tags/download_sample?format=${format}`);
  };
}

export const tagService = new TagService({ authService });
