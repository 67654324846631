import { authService } from '@yojee/api/authService';
import { BaseService } from '@yojee/api/baseService';

export class LoginService extends BaseService {
  constructor({ authService }) {
    super();
    this.authService = authService;
  }

  getUserInfo = () => {
    return this.authService
      .get(this.getUmbrellaApiUrl('dispatcher/info'))
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };
}

export const loginService = new LoginService({ authService });
