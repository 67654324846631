import moment from 'moment';

import {
  FORMAT_DATE_FOR_MOMENT,
  FORMAT_DATE_TIME_ISO_8601,
  FORMAT_TIME_FOR_NEW_BOOKING,
} from '@yojee/helpers/constants';

export function getStepUniqueKey({ order_step_id, step_group, step_sequence, type }) {
  return `${order_step_id}-${step_group}-${step_sequence}-${type}`;
}

export function getItemStepUniqueKey({ order_step_id, step_group, step_sequence, type, order_item_id }) {
  return `${order_step_id}-${step_group}-${step_sequence}-${type}-${order_item_id}`;
}

const formatTime = (date, time, timeFmt = FORMAT_TIME_FOR_NEW_BOOKING) => {
  // In Safari: The JS function: new Date(date) will return Invalid date if the input
  // is in the format including milliseconds 'yyyy/MM/DD HH:mm:ss.SSS'.
  // So replacing '/' with '-' to support Safari as well.
  const formattedDate = moment(date).format(FORMAT_DATE_FOR_MOMENT).replaceAll('/', '-');
  return formattedDate + ' ' + time?.format?.(timeFmt);
};

export function buildDateTimeData(model = {}, timeFmt = FORMAT_TIME_FOR_NEW_BOOKING) {
  const from_time = new Date(formatTime(model.from_time, model.from_time_time, timeFmt));
  const to_time = new Date(formatTime(model.to_time, model.to_time_time, timeFmt));

  return { from_time, to_time };
}

export function convertDateToUTC(date, timezone, fmt = FORMAT_DATE_TIME_ISO_8601) {
  if (!date) {
    return undefined;
  }

  // Use browser time if timezone is not provided.
  if (!timezone) {
    return moment(date).utc();
  }

  const dateStr = moment(date).format(fmt);
  const timeInTimezone = moment.tz(dateStr, timezone);
  return timeInTimezone.utc();
}
