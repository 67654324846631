import { authService } from './authService/index';
import { BaseService } from './baseService/index';

export class OrderService extends BaseService {
  constructor({ authService }) {
    super();
    this.authService = authService;
  }

  bulkUpdateOrderSteps = (params) => {
    const {
      order_step_ids,
      from_time,
      to_time,
      address,
      address2,
      postal_code,
      state,
      city,
      country,
      lat,
      lng,
      contact_name,
      contact_company,
      contact_phone,
      contact_email,
      address_item_id,
    } = params;
    return this.authService
      .put(this.getUmbrellaApiUrl('dispatcher/orders_bulk_update'), {
        order_step_ids,
        from_time,
        to_time,
        address,
        address2,
        postal_code,
        state,
        city,
        country,
        lat,
        lng,
        contact_name,
        contact_company,
        contact_phone,
        contact_email,
        address_item_id,
      })
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };

  getOrderStepsInfo = (order_step_ids) => {
    return this.authService
      .put(this.getUmbrellaApiUrl('dispatcher/orders_get_order_steps_info'), {
        order_step_ids,
      })
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };

  getOrderTaskPhotos = (orderNumber) => {
    return this.authService
      .get(this.getUmbrellaApiUrlV4(`company/orders/${orderNumber}/task_photos`))
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };

  acceptOrderTransfer = ({ orderNumber }) => {
    const reqPayload = { order_numbers: [orderNumber] };
    return this.authService
      .post(this.getUmbrellaApiUrl('dispatcher/partner_transfer/dispatcher/bulk_accept_order'), reqPayload)
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };

  transferOrdersToPartner = (payload) => {
    const reqPayload = { partner_cip: payload?.cip, order_numbers: payload.orderNumbers, source: 'transfer-manual' };
    return this.authService
      .post(this.getUmbrellaApiUrl('dispatcher/partner_transfer/sender/create_order'), reqPayload)
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };

  acceptOrders = (payload) => {
    const reqPayload = { order_numbers: payload.orderNumbers };

    return this.authService
      .post(this.getUmbrellaApiUrl('dispatcher/partner_transfer/dispatcher/bulk_accept_order'), reqPayload)
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };

  declineOrders = (payload) => {
    const reqPayload = {
      order_numbers: payload.orderNumbers,
      cancelled_notes: payload.declinedNotes,
      reason_code: payload.reasonCode,
    };
    return this.authService
      .post(this.getUmbrellaApiUrl('dispatcher/partner_transfer/dispatcher/bulk_reject_order'), reqPayload)
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };
}

export const orderService = new OrderService({ authService });
