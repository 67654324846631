import { t } from 'i18next';

import {
  batchUploadDefaultState,
  generateBatchUploadReducerMethods,
} from '@yojee/ui/onboarding/helpers/batchUploadHelper';

const defaultState = {
  loading: {
    listSenders: false,
    getSenders: false,
    createSender: false,
    updateSender: false,
    deleteSender: false,
    bulkDeleteSender: false,
  },
  batchUpload: batchUploadDefaultState,
  senderData: [],
  senderDetail: {},
  filter: { sort_by: 'id' },
  pagination: { current_page: 1, limit_value: 25, total_count: 0, total_pages: 0 },
  organisationData: [],
  error: {
    failed: false,
  },
};

export const senderMessagesSuccessEvents = {
  CREATE_SENDER_SUCCESSFUL: 'Sender created successfully',
  UPDATE_SENDER_SUCCESSFUL: 'Sender updated successfully',
  DELETE_SENDER_SUCCESSFUL: 'Sender deleted successfully',
  REQUEST_DELETE_SENDER_IN_PROCESS: 'Sender deleted in process',
  BULK_REQUEST_DELETE_SENDER_SUCCESSFUL: 'Sender deleted successfully',
  SENDERS_BATCH_UPLOAD_SUCCEEDED: t('Batch upload completed'),
};

export const senderMessagesErrorEvents = {
  LIST_SENDERS_FAILED: ({ error }) => error?.message ?? 'Failed to get sender info',
  GET_SENDERS_FAILED: ({ error }) => error?.message ?? 'Failed to get sender info',
  CREATE_SENDER_FAILED: ({ error }) => error?.message ?? 'Failed to create sender',
  UPDATE_SENDER_FAILED: ({ error }) => error?.message ?? 'Failed to update sender',
  DELETE_SENDER_FAILED: ({ error }) => error?.message ?? 'Error deleting sender.',
  BULK_REQUEST_DELETE_SENDER_FAIL: ({ payload }) => payload || 'Sender deleted fail!',
};

export const init = () => {
  return defaultState;
};

const ACTION_HANDLERS = {
  LIST_SENDERS_SUCCESSFUL: (state, sagaData) => {
    const data = sagaData.senderData;
    return {
      ...state,
      loading: {
        ...state.loading,
        listSenders: false,
      },
      senderData: data && data['data'],
      pagination: data && data['pagination'],
      filter: sagaData.params,
    };
  },
  LIST_SENDERS_FAILED: (state) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        listSenders: false,
      },
      error: {
        failed: true,
      },
    };
  },
  GET_SENDERS_SUCCESSFUL: (state, sagaData) => {
    const data = sagaData.senderData;
    return {
      ...state,
      loading: {
        ...state.loading,
        getSenders: false,
      },
      senderDetail: data && data['data'],
    };
  },
  GET_SENDERS_FAILED: (state) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        getSenders: false,
      },
      error: {
        failed: true,
      },
    };
  },
  SET_SENDER_LOADING: (state, sagaData) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        [sagaData.key]: true,
      },
    };
  },
  CREATE_SENDER_FAILED: (state) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        createSender: false,
      },
    };
  },
  UPDATE_SENDER_FAILED: (state) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        updateSender: false,
      },
    };
  },
  DELETE_SENDER_FAILED: (state) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        deleteSender: false,
      },
    };
  },
  CREATE_SENDER_SUCCESSFUL: (state) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        createSender: false,
      },
    };
  },
  UPDATE_SENDER_SUCCESSFUL: (state, data) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        updateSender: false,
      },
      senderDetail: data?.senderData,
    };
  },
  DELETE_SENDER_SUCCESSFUL: (state) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        deleteSender: false,
      },
    };
  },
  REQUEST_DELETE_SENDER_IN_PROCESS: (state) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        bulkDeleteSender: true,
      },
    };
  },
  BULK_REQUEST_DELETE_SENDER_FAIL: (state) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        deleteSender: false,
        bulkDeleteSender: false,
      },
    };
  },
  BULK_REQUEST_DELETE_SENDER_SUCCESSFUL: (state, { payload }) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        bulkDeleteSender: false,
      },
      senderData: state.senderData.filter((s) => !payload.includes(s.id)),
    };
  },
  ...generateBatchUploadReducerMethods({ prefix: 'SENDERS' }),
};

const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};

export default reducer;
