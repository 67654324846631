const defaultState = {
  data: [],
  pagination: {
    current_page: 1,
    limit_value: 25,
    total_count: 0,
    total_pages: 0,
  },
  manifestPDF: [],
  loadlistPDF: [],
  isLoading: false,
  isError: false,
  isSuccess: false,
  statusCode: null,
  singlePdf: null,
};

export const init = () => {
  return defaultState;
};

const ACTION_HANDLERS = {
  REQUEST_GET_MANIFEST_SUCCESSFUL: (state, { data, pagination, q }) => {
    return {
      ...state,
      data,
      pagination,
      query: q,
    };
  },
  REQUEST_GET_MANIFEST_PDF_SUCCESSFUL: (state, data) => {
    return {
      ...state,
      manifestPDF: [...data.data],
    };
  },
  REQUEST_GET_LOADLIST_PDF_SUCCESSFUL: (state, data) => {
    return {
      ...state,
      loadlistPDF: [...data.data],
    };
  },
  REQUEST_GET_MANIFEST_SECTIONS_SUCCESSFUL: (state, data) => {
    return {
      ...state,
      sectionsInfo: data.data,
    };
  },
  SET_MANIFEST_PROGRESS: (state) => {
    return {
      ...state,
      isLoading: true,
    };
  },
  SET_SINGLE_PROGRESS: (state, data) => {
    return {
      ...state,
      singlePdf: data,
    };
  },
};

const reducer = (state = defaultState, action = null) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};

export default reducer;
