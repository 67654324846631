export const listWorkers = (value) => {
  return { type: 'REQUEST_LIST_WORKERS', payload: value };
};

export const requestGetWorkers = (payload) => {
  return { type: 'REQUEST_GET_WORKERS', payload };
};

//GET WORKER
export const requestGetWorker = (payload) => {
  return { type: 'REQUEST_GET_WORKER', payload };
};
export const requestGetWorker_Success = (payload) => {
  return { type: 'REQUEST_GET_WORKER_SUCCESS', payload };
};
export const requestGetWorker_Error = (payload) => {
  return { type: 'REQUEST_GET_WORKER_ERROR', payload };
};

//CREATE WORKER
export const requestCreateWorker = (payload) => {
  return { type: 'REQUEST_CREATE_WORKER', payload };
};
export const requestCreateWorker_Success = (payload) => {
  return { type: 'REQUEST_CREATE_WORKER_SUCCESS', payload };
};
export const requestCreateWorker_Error = (payload) => {
  return { type: 'REQUEST_CREATE_WORKER_ERROR', payload };
};

//UPDATE WORKER
export const requestUpdateWorker = (payload) => {
  return { type: 'REQUEST_UPDATE_WORKER', payload };
};
export const requestUpdateWorker_Success = (payload) => {
  return { type: 'REQUEST_UPDATE_WORKER_SUCCESS', payload };
};
export const requestUpdateWorker_Error = (payload) => {
  return { type: 'REQUEST_UPDATE_WORKER_ERROR', payload };
};

//DELETE WORKER
export const requestDeleteWorker = (payload) => {
  return { type: 'REQUEST_DELETE_WORKER', payload };
};
export const requestDeleteWorker_Success = (payload) => {
  return { type: 'REQUEST_DELETE_WORKER_SUCCESS', payload };
};
export const requestDeleteWorker_Error = (payload) => {
  return { type: 'REQUEST_DELETE_WORKER_ERROR', payload };
};

// BATCH UPLOAD
export const requestWorkerBatchUpload = (uploadData) => {
  return { type: 'WORKERS_BATCH_UPLOAD', uploadData };
};
export const downloadWorkerSample = (format) => {
  return { type: 'DOWNLOAD_WORKERS_TEMPLATE_SAMPLE', format };
};
export const refreshBatchUpload = () => {
  return { type: 'REFRESH_BATCH_UPLOAD' };
};
