import { call, put, takeLatest } from 'redux-saga/effects';

import operationService from '../../services/operationService';

export default function* sagas() {
  yield takeLatest('GET_OPERATIONS', getOperations);
  yield takeLatest('CREATE_OPERATION', createOperation);
  yield takeLatest('DELETE_OPERATION', deleteOperation);
  yield takeLatest('UPDATE_OPERATION', updateOperation);
}

function* getOperations() {
  try {
    yield put({ type: 'START_LOADING', loadingAction: 'getOperations' });
    const data = yield call(operationService.getOperations);
    yield put({ type: 'GET_OPERATIONS_SUCCESSFUL', data });
  } catch (error) {
    yield put({ type: 'GET_OPERATIONS_FAILED', error });
  }
}

function* createOperation(action) {
  try {
    yield put({ type: 'START_LOADING', loadingAction: 'createOperation' });
    yield call(operationService.createOperation, action.operationData);
    yield put({ type: 'CREATE_OPERATION_SUCCESSFUL' });
    yield put({ type: 'GET_OPERATIONS' });
  } catch (error) {
    yield put({ type: 'CREATE_OPERATION_FAILED', error });
  }
}

function* deleteOperation(action) {
  try {
    yield put({ type: 'START_LOADING', loadingAction: 'deleteOperation' });
    yield call(operationService.deleteOperation, action.operationId);
    yield put({ type: 'DELETE_OPERATION_SUCCESSFUL' });
    yield put({ type: 'GET_OPERATIONS' });
  } catch (error) {
    yield put({ type: 'DELETE_OPERATION_FAILED', error });
  }
}

function* updateOperation(action) {
  try {
    yield put({ type: 'START_LOADING', loadingAction: 'updateOperation' });
    yield call(operationService.updateOperation, action.operationData);
    yield put({ type: 'UPDATE_OPERATION_SUCCESSFUL' });
    yield put({ type: 'GET_OPERATIONS' });
  } catch (error) {
    yield put({ type: 'UPDATE_OPERATION_FAILED', error });
  }
}
