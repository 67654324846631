import { ADDRESS_PICKER_ACTION_TYPES } from '../saga/actions';

const defaultState = {
  status: 'normal',
  message: undefined,
  data: null,
};

const reducer = (state = defaultState, action = null) => {
  switch (action?.type) {
    case ADDRESS_PICKER_ACTION_TYPES.SAVE_TO_ADDRESS_BOOK:
      return { ...state, status: 'processing' };
    case ADDRESS_PICKER_ACTION_TYPES.SAVE_TO_ADDRESS_BOOK_SUCCESS:
      return { ...state, status: 'success', message: action.message, data: action.data };
    case ADDRESS_PICKER_ACTION_TYPES.SAVE_TO_ADDRESS_BOOK_ERROR:
      return { ...state, status: 'error', message: action.message };
    case ADDRESS_PICKER_ACTION_TYPES.CLEAR_ADDRESS_PICKER_MESSAGE:
      return defaultState;
    default:
      return state;
  }
};

export default reducer;
