const defaultState = {
  loading: false,
  inProgress: {
    deleteDispatchRule: false,
  },
  rules: [],
  error: {
    failed: false,
  },
  dispatchRulesData: [],
  bulkDeleteSuccess: false,
  pagination: { current_page: 1, limit_value: 25, total_count: 0, total_pages: 0 },
};

export const init = () => {
  return defaultState;
};

export const dispatchRuleMessagesSuccessEvents = {
  CREATE_DISPATCH_RULE_SUCCESSFUL: 'Dispatch rule created successfully.',
  UPDATE_DISPATCH_RULE_SUCCESSFUL: 'Dispatch rule updated successfully.',
  DELETE_DISPATCH_RULE_SUCCESSFUL: 'Dispatch rule deleted successfully.',
  REQUEST_DELETE_DISPATCH_RULE_IN_PROCESS: 'Dispatch rule delete in process.',
  BULK_REQUEST_DELETE_DISPATCH_RULE_SUCCESS: 'Dispatch rule deleted successfully.',
};

export const dispatchRuleMessagesErrorEvents = {
  LIST_DISPATCH_RULES_FAILED: ({ error }) => error?.message ?? 'Failed to get dispatch rule info',
  CREATE_DISPATCH_RULE_FAILED: 'Error creating dispatch rule.',
  UPDATE_DISPATCH_RULE_FAILED: 'Error updating dispatch rule.',
  DELETE_DISPATCH_RULE_FAILED: 'Error deleting dispatch rule.',
};

const ACTION_HANDLERS = {
  LIST_DISPATCH_RULES_SUCCESSFUL: (state, sagaData) => {
    return {
      ...state,
      loading: false,
      dispatchRulesData: sagaData.dispatchRulesData.data,
      pagination: sagaData.dispatchRulesData.pagination || {},
    };
  },
  LIST_DISPATCH_RULES_FAILED: (state) => {
    return {
      ...state,
      loading: false,
      error: {
        failed: true,
      },
    };
  },
  SET_DISPATCH_RULE_LOADING: (state) => {
    return {
      ...state,
      loading: true,
    };
  },
  REQUEST_DELETE_DISPATCH_RULE: (state) => {
    return {
      ...state,
      inProgress: {
        deleteDispatchRule: true,
      },
    };
  },
  DELETE_DISPATCH_RULE_SUCCESSFUL: (state) => {
    return {
      ...state,
      inProgress: {
        deleteDispatchRule: false,
      },
    };
  },
  DELETE_DISPATCH_RULE_FAILED: (state) => {
    return {
      ...state,
      inProgress: {
        deleteDispatchRule: false,
      },
    };
  },
  REQUEST_DELETE_DISPATCH_RULE_IN_PROCESS: (state) => {
    return {
      ...state,
      loading: true,
      bulkDeleteSuccess: false,
    };
  },
  BULK_REQUEST_DELETE_DISPATCH_RULE_SUCCESS: (state) => {
    return {
      ...state,
      loading: false,
      bulkDeleteSuccess: true,
    };
  },
  BULK_REQUEST_DELETE_DISPATCH_RULE_FAIL: (state) => {
    return {
      ...state,
      loading: false,
      bulkDeleteSuccess: false,
    };
  },
};

const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};

export default reducer;
