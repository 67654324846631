import React from 'react';
import { components } from 'react-select';

import Checkbox from '../Checkbox';

const MultiCheckboxOption =
  ({ optionDataCy }) =>
  (props) => {
    return (
      <div data-cy={optionDataCy}>
        <components.Option {...props}>
          <Checkbox checked={props.isSelected} onChange={() => null} />
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };

export default MultiCheckboxOption;
