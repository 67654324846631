import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import React from 'react';

import Lock from './lock.png';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    flexDirection: 'column',
  },
  image: {
    width: '90px',
    height: '130px',
  },
  text: {
    paddingTop: theme.spacing(2),
    color: theme.palette?.warning?.main,
    textAlign: 'center',
    whiteSpace: 'pre-line',
  },
}));

const NoPermission = ({ className = '', message = '', ...other }) => {
  const classes = useStyles();
  return (
    <div className={clsx(classes.root, className)} {...other}>
      <img className={classes.image} src={Lock} alt="no-permission" />
      <span className={classes.text}>{message}</span>
    </div>
  );
};

NoPermission.defaultProps = {
  message: 'You cannot access to this page \n Please contact your admin for more details',
};
export default NoPermission;
