import Grid from '@material-ui/core/Grid';
import { Trans } from 'react-i18next';

import { Input as TextField } from '@yojee/ui/base';
import Title from '@yojee/ui/base/furnitures/Typography/Title';

const CompanyLinks = ({
  readOnly,
  link1Label,
  setLink1Label,
  link1Url,
  setLink1Url,
  link2Label,
  setLink2Label,
  link2Url,
  setLink2Url,
  link1LabelError,
  link1UrlError,
  link2LabelError,
  link2UrlError,
}) => {
  return (
    <Grid spacing={2} container>
      <Grid item xs={12}>
        <Title variant="h4">
          <Trans>Driver app terms and conditions links</Trans>
        </Title>
      </Grid>
      <Grid item xs={6}>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <div className="input-label">
              <Trans>Link 1 label</Trans>
            </div>
            <TextField
              disabled={readOnly}
              variant="outlined"
              fullWidth
              margin="none"
              type="text"
              onChange={({ target }) => {
                setLink1Label(target.value);
              }}
              value={link1Label}
              error={!!link1LabelError}
              helperText={link1LabelError || ' '}
            />
          </Grid>
          <Grid item xs={8}>
            <div className="input-label">
              <Trans>Link 1 URL</Trans>
            </div>
            <TextField
              disabled={readOnly}
              variant="outlined"
              fullWidth
              margin="none"
              type="text"
              onChange={({ target }) => {
                setLink1Url(target.value);
              }}
              value={link1Url}
              error={!!link1UrlError}
              helperText={link1UrlError || ''}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <div className="input-label">
              <Trans>Link 2 label</Trans>
            </div>
            <TextField
              disabled={readOnly}
              variant="outlined"
              fullWidth
              margin="none"
              type="text"
              onChange={({ target }) => {
                setLink2Label(target.value);
              }}
              value={link2Label}
              error={!!link2LabelError}
              helperText={link2LabelError || ''}
            />
          </Grid>
          <Grid item xs={8}>
            <div className="input-label">
              <Trans>Link 2 URL</Trans>
            </div>
            <TextField
              disabled={readOnly}
              variant="outlined"
              fullWidth
              margin="none"
              type="text"
              onChange={({ target }) => {
                setLink2Url(target.value);
              }}
              value={link2Url}
              error={!!link2UrlError}
              helperText={link2UrlError || ''}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CompanyLinks;
