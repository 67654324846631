import { makeStyles } from '@material-ui/core/styles';
import { Trans } from 'react-i18next';

import { isValidUrl } from '@yojee/helpers/validators';

export const useCompanySettingsStyles = makeStyles((theme) => ({
  pageTitle: {
    marginBottom: theme.spacing(4),
  },
  section: {
    marginBottom: theme.spacing(2),
  },
  inputLabel: {
    color: 'rgba(4, 9, 33, 0.6)',
    display: 'block',
    fontSize: '0.75rem',
    wordWrap: 'break-word',
    fontWeight: '600',
    lineHeight: '12px',
    marginBottom: '8px',
  },
}));

export const getLabelError = (label, url) => {
  if (!label && url) {
    return <Trans>Please fill in label</Trans>;
  }
  return null;
};

export const getUrlError = (label, url) => {
  if (label) {
    if (!url) {
      return <Trans>Please fill in URL</Trans>;
    } else if (!isValidUrl(url)) {
      return <Trans>Please enter a valid URL</Trans>;
    }
  }
  return null;
};

export const prefixProtocol = (url) => {
  if (url && !url.startsWith('http')) {
    return `http://${url}`;
  }

  return url;
};
