import { makeStyles } from '@material-ui/core/styles';
import MuiSkeleton from '@material-ui/lab/Skeleton';
import React from 'react';

const useStyles = makeStyles({
  skeletonRoot: {
    backgroundColor: '#0409210a',
  },
});

const Skeleton = (props) => {
  const classes = useStyles();

  return <MuiSkeleton className={classes.skeletonRoot} {...props} />;
};

Skeleton.defaultProps = {
  height: 50,
};

export default React.memo(Skeleton);
