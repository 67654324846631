import ButtonBase from '@material-ui/core/ButtonBase';
import clsx from 'clsx';

import useMenuItemLogic from '../hooks/useMenuItemLogic';
import useTopMenuStyles from './topMenuStyles';

const ThirdLevelMenuItem = ({ menuItem }) => {
  const classes = useTopMenuStyles();
  const { isSelected, onClickLeafMenu, link } = useMenuItemLogic(menuItem);

  return (
    <li
      data-cy={`menu-item-${menuItem.key}`}
      className={clsx(classes.thirdLevelMenuItem, isSelected && classes.thirdLevelMenuItemActive)}
    >
      <ButtonBase
        component="a"
        href={link}
        onClick={(event) => {
          event.preventDefault();
          onClickLeafMenu();
        }}
      >
        <span>{menuItem.title}</span>
      </ButtonBase>
    </li>
  );
};

export default ThirdLevelMenuItem;
