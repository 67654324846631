// before-index.js - makes sure that we start out the Manage Iframe with a
// clean state, in particular, old and outdated JWT data SHOULD BE CLEANED AWAY.
//
// New and fresh data will be passed from Explore to Manage by means of
// communicating back and forth between `App.jsx(manage)` and `ManageIframe.jsx`
// (explore) anyway. (See [1], [2], and [3]).
//
// IMPORTANT:
//
//   - This file is supposed to be imported JUST ONCE by the `index.jsx`.
//
//   - Please DO NOT add any heavy computation in here!
//
//   - Why don't we just do this inside the `index.jsx`? It's because
//     `apps/manage/src/reducers/login.js:32` has already been executed
//     when importing into `index.jsx`. We want a clean JWT state before
//     anyone using it.
//
//
// NOTES:
//
//   [1] - `apps/manage/src/App.jsx`: after rendering its children, App
//     (now lives under the origin `host:3001`) will send a message
//     `MANAGE_FRAME_LISTENING` back to its parent - the Explore who lives
//     in a different origin (`host:3000`) - to tell its parent that it's
//     ready to receive data.
//
//   [2] - `apps/explore/src/components/ManageIframe/ManageIframe.jsx` -
//     (lives under Explore with `host:3000`) receives the above message,
//     and respond by sending back a message `PASS_DATA_TO_MANAGE` along
//     with fresh data.
//
//   [3] - When `App.jsx` receives the `PASS_DATA_TO_MANAGE` message, it
//     goes and updates the store as well as the localStorage using the
//     data sent along. Note that all of these happen under `host:3001`.

import { clearJwtData } from '@yojee/auth/jwt-storage';
import { LocalStorage } from '@yojee/local-storage/local-storage';

const refreshTokenExpiry = LocalStorage.getItem('refresh_token_expiry');
const timeNow = new Date().getTime() / 1000;

// See `libs/api/src/lib/authService/dispatcherAuthService.js`.
// IMPORTANT: only clear data if it's truly outdated!
if (refreshTokenExpiry && refreshTokenExpiry - 2 * 60 < timeNow) {
  clearJwtData();
}
