import { generateBatchUploadReducerMethods } from '@yojee/ui/onboarding/helpers/batchUploadHelper';

const defaultState = {
  createOrUpdate: false,
  isLoading: false,
  isCreateUpdateLoading: false,
  schedules: [],
  loadOptions: undefined,
  batchUpload: {
    state: 'not_submitted',
    errors: {},
    file: null,
    batchId: null,
  },
};

export const scheduleMessagesSuccessEvents = {
  REQUEST_ADD_SCHEDULE_SUCCESS: 'Schedule was added',
  REQUEST_DELETE_SCHEDULE_SUCCESS: 'Schedule deleted successfully',
  BULK_REQUEST_DELETE_SCHEDULE_SUCCESS: 'Schedule deleted successfully',
  REQUEST_UPDATE_SCHEDULE_SUCCESS: 'Schedule was updated',
  CLEAR_SCHEDULES: undefined,
};

export const scheduleMessagesErrorEvents = {
  REQUEST_ADD_SCHEDULE_ERROR: (data) => `Failed to add schedule: ${data.error.message}`,
  REQUEST_SCHEDULES_LIST_ERROR: (data) => `Failed to get schedules list: ${data.error.message}`,
  REQUEST_GET_SCHEDULE_ERROR: (data) => `Failed to get schedule: ${data.error.message}`,
  REQUEST_DELETE_SCHEDULE_ERROR: (data) => `Failed to delete schedule: ${data.error.message}`,
  BULK_REQUEST_DELETE_SCHEDULE_ERROR: (data) => data.payload || `Failed to delete schedule!`,
  REQUEST_UPDATE_SCHEDULE_ERROR: (data) => `Failed to update schedule: ${data.error.message}`,
  CLEAR_SCHEDULES: undefined,
};

export const init = () => {
  return defaultState;
};

const ACTION_HANDLERS = {
  SET_SCHEDULES_LOADING: (state) => {
    return {
      ...state,
      isLoading: true,
    };
  },
  SET_CREATE_UPDATE_LOADING: (state) => {
    return {
      ...state,
      isCreateUpdateLoading: true,
    };
  },
  REQUEST_ADD_SCHEDULE_SUCCESS: (state) => {
    return {
      ...state,
      isCreateUpdateLoading: false,
    };
  },
  REQUEST_ADD_SCHEDULE_ERROR: (state) => {
    return {
      ...state,
      isCreateUpdateLoading: false,
    };
  },
  REQUEST_SCHEDULES_LIST_SUCCESS: (state, data) => {
    return {
      ...state,
      isLoading: false,
      schedules: data.schedule,
      loadOptions: data.options,
    };
  },
  REQUEST_SCHEDULES_LIST_ERROR: (state) => {
    return {
      ...state,
      isLoading: false,
      schedules: [],
    };
  },
  REQUEST_GET_SCHEDULE_SUCCESS: (state) => {
    return {
      ...state,
      isLoading: false,
    };
  },
  REQUEST_GET_SCHEDULE_ERROR: (state) => {
    return {
      ...state,
      isLoading: false,
    };
  },
  REQUEST_DELETE_SCHEDULE_SUCCESS: (state) => {
    return {
      ...state,
      isLoading: false,
    };
  },
  BULK_REQUEST_DELETE_SCHEDULE_SUCCESS: (state, { payload }) => {
    return {
      ...state,
      isLoading: false,
      schedules: state.schedules.filter((s) => !payload.includes(s.id)),
    };
  },
  REQUEST_DELETE_SCHEDULE_IN_PROCESS: (state) => {
    return {
      ...state,
      isLoading: false,
    };
  },
  REQUEST_DELETE_SCHEDULE_ERROR: (state) => {
    return {
      ...state,
      isLoading: false,
    };
  },
  BULK_REQUEST_DELETE_SCHEDULE_ERROR: (state) => {
    return {
      ...state,
      isLoading: false,
    };
  },
  REQUEST_UPDATE_SCHEDULE_SUCCESS: (state) => {
    return {
      ...state,
      isCreateUpdateLoading: false,
    };
  },
  REQUEST_UPDATE_SCHEDULE_ERROR: (state) => {
    return {
      ...state,
      isCreateUpdateLoading: false,
    };
  },
  CLEAR_SCHEDULES: (state) => {
    return {
      ...state,
      schedules: [],
    };
  },
  ...generateBatchUploadReducerMethods({ prefix: 'SCHEDULE' }),
};

const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};

export default reducer;
