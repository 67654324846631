import { getSlugFromUrl } from '@yojee/auth/utils/AuthUtils';
import { getValue } from '@yojee/helpers/access-helper';

import { initAuthenticationState } from '../helpers';

export let defaultState = {
  token: null,
  error: null,
  dispatcher_companies: [],
  jwtData: null,
  jwt: null,
  partnerJwt: null,
  dispatcher_info: {
    data: {
      company: {
        country: 'Singapore',
        currency: 'SGD',
        id: 1,
        name: 'yojee',
        language: null,
        service_types: {},
        settings: {
          features: {
            task_assignment_with_vehicle: false,
            worker_sequence_rearrangement: false,
          },
          company: {
            support_dispatcher_languages: null,
            timezone: '',
          },
          branding: {
            admin_primary_color: '',
            background_color: '',
            banner: '',
            banner_header: '',
            banner_text_color: '',
            banner_text_line1: '',
            banner_text_line2: '',
            banner_text_line3: '',
            favicon: '',
            header_cta_color: '',
            header_cta_text_color: '',
            logo: '',
            pricing_url: '',
            social_info: [],
            t_and_c_url: '',
            text_color: '',
            text_color_highlighted: '',
          },
        },
        slug: 'yojee',
        display_settings: {},
        beta_enabled: false,
      },
      id: null,
      permissions: {},
      // To suppress Typescript's warning when using selector
      // `state.auth?.dispatcher_info?.data?.user_profile_id`.
      user_profile_id: null,
      role: {
        organisational_units: [],
        assigned_to_all_ous: true,
      },
    },
  },
  loading: {
    login: false,
    requestNewPassword: false,
    editPassword: false,
    singUp: false,
    companySettings: false,
    getOrgCompanies: false,
    getDispatcherInfo: false,
  },
  canClearIframeData: false,
  requestNewPasswordSuccess: null,
  editPasswordSuccess: null,
  message: null,
  failed: false,
  failReason: null,
  relatedCompanies: [],
};

defaultState = initAuthenticationState(defaultState);

export const init = () => {
  return defaultState;
};

const ACTION_HANDLERS = {
  RESET_ALL_STORE: () => {
    const params = new URLSearchParams(window.location.search);
    const partnerJWT = params && (params.get('partner_jwt') || params.get('tracking_jwt'));
    const urlSlug = getSlugFromUrl();
    if (partnerJWT) {
      const newState = { ...defaultState, partnerJwt: partnerJWT };
      if (
        urlSlug &&
        newState.dispatcher_info &&
        newState.dispatcher_info.data &&
        newState.dispatcher_info.data.company
      ) {
        newState.dispatcher_info.data.company.slug = urlSlug;
      }
      return newState;
    }
    return defaultState;
  },
  UI_AUTH_LOGIN_SUCCESSFUL: (state, { jwtData, dispatcher_companies }) => {
    return {
      ...state,
      jwtData,
      dispatcher_companies,
      token: jwtData ? jwtData['access_token'] : state.token,
    };
  },
  UI_AUTH_LOGIN_FAILED: (state, { error, preventErrorDisplay }) => {
    const _statusCode = getValue(error, 'statusCode', false);
    const _message = getValue(error, 'message', false);
    let failReason = '';
    if (_statusCode === 401) {
      if (!preventErrorDisplay) {
        failReason =
          'The Email Address or Password/Code entered is incorrect. Please enter valid email and password/code';
      }
    } else if (_statusCode === 429) {
      failReason = 'You have exceeded the maximum number of attempts. Please try after some time';
    } else if (_message) {
      failReason = _message;
    } else {
      failReason = 'Internal server error';
    }

    return {
      ...state,
      failed: true,
      failReason,
      loading: {
        ...state.loading,
        login: false,
        getDispatcherInfo: false,
      },
    };
  },
  UI_AUTH_SSO_LOGIN_FAILED: (state, { payload: { ssoError } }) => {
    let failReason = 'Internal server error';
    if (ssoError === 'company_not_found') {
      failReason = 'Company not found';
    } else if (ssoError === 'account_not_found') {
      failReason = 'Account doesnt exist';
    }

    return {
      ...state,
      failed: true,
      failReason,
      loading: {
        ...state.loading,
        login: false,
        getDispatcherInfo: false,
      },
    };
  },
  UI_AUTH_GET_DISPATCHER_INFO_SUCCESSFUL: (state, { dispatcher_info }) => {
    return {
      ...state,
      dispatcher_info,
      loading: {
        ...state.loading,
        login: false,
        getDispatcherInfo: false,
      },
    };
  },
  UI_AUTH_SET_DISPATCHER_INFO: (state, { payload }) => {
    return {
      ...state,
      dispatcher_info: {
        data: payload,
      },
    };
  },
  UI_AUTH_REQUEST_RELATED_COMPANIES_SUCCESSFUL: (state, { data }) => {
    return {
      ...state,
      relatedCompanies: Object.keys(data).map((company_slug) => {
        return {
          company_slug,
          ...data[company_slug],
        };
      }),
      loading: {
        ...state.loading,
        getRelatedCompanies: false,
      },
    };
  },
  UI_AUTH_REQUEST_NEW_PASSWORD_SUCCESS: (state, { message }) => {
    return {
      ...state,
      requestNewPasswordSuccess: true,
      message,
      loading: {
        ...state.loading,
        requestNewPassword: false,
      },
    };
  },
  UI_AUTH_REQUEST_NEW_PASSWORD_FAILED: (state, err) => {
    const {
      error: { statusCode },
    } = err;
    let msg = '';
    if (statusCode === 429) {
      msg = 'You have exceeded the maximum number of attempts. Please try after some time';
    }
    return {
      ...state,
      failed: true,
      requestNewPasswordSuccess: false,
      message: msg || 'The email address entered is incorrect',
      loading: {
        ...state.loading,
        requestNewPassword: false,
      },
    };
  },
  UI_AUTH_EDIT_PASSWORD_SUCCESS: (state, { message }) => {
    return {
      ...state,
      editPasswordSuccess: true,
      message,
      loading: {
        ...state.loading,
        editPassword: false,
      },
    };
  },
  UI_AUTH_EDIT_PASSWORD_FAILED: (state, { message }) => {
    return {
      ...state,
      failed: true,
      editPasswordSuccess: false,
      message,
      loading: {
        ...state.loading,
        editPassword: false,
      },
    };
  },
  UI_AUTH_REQUEST_SIGN_UP_SUCCESS: (state, { message }) => {
    return {
      ...state,
      signUpSuccess: true,
      message,
      loading: {
        ...state.loading,
        signUp: false,
      },
    };
  },
  UI_AUTH_REQUEST_SIGN_UP_FAILED: (state, { message }) => {
    return {
      ...state,
      failed: true,
      signUpSuccess: false,
      message: message,
      loading: {
        ...state.loading,
        signUp: false,
      },
    };
  },
  UI_AUTH_SEND_SSO_LINK_SUCCESS: (state, { message }) => {
    return {
      ...state,
      message,
      loading: {
        ...state.loading,
        sendSSOLink: false,
      },
    };
  },
  UI_AUTH_SEND_SSO_LINK_FAILED: (state, { message }) => {
    return {
      ...state,
      failed: true,
      message,
      loading: {
        ...state.loading,
        sendSSOLink: false,
      },
    };
  },
  UI_AUTH_LOADING: (state, { key }) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        [key]: true,
      },
    };
  },
  UI_AUTH_REQUEST_TOKEN_FOR_RELATED_COMPANY_SUCCESSFUL: (state, { data }) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        getTokenForRelatedCompany: false,
      },
    };
  },
  UI_AUTH_RESET_NEW_PASSWORD_SETTINGS_SUCCESS: (state) => {
    return { ...state, ...defaultState };
  },
  UI_AUTH_FETCH_JWT_SUCCEEDED: (state, { data: jwt }) => {
    return { ...state, jwt: jwt };
  },
  UI_AUTH_UPDATE_COMPANY_SETTINGS: (state, { companyInfo }) => {
    const { data: company } = companyInfo;
    return {
      ...state,
      dispatcher_info: {
        ...state.dispatcher_info,
        data: { ...state.dispatcher_info.data, company },
      },
      loading: {
        ...state.loading,
        companySettings: false,
      },
    };
  },
  UI_AUTH_SET_CLEAR_IFRAME_DATA: (state, { value }) => {
    return {
      ...state,
      canClearIframeData: value,
    };
  },
  UI_AUTH_CLEAR_MESSAGE: (state) => {
    return {
      ...state,
      failed: false,
      message: null,
    };
  },
};

export const UIAuthReducer = (state = defaultState, action) => {
  if (!action || !action.type) {
    return state;
  }

  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};
