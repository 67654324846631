import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';

import { companyService } from '../../services/companyService';
import { driverProcessService } from '../../services/driverProcessService';

export default function* sagas() {
  yield takeEvery('REQUEST_GET_SUB_TASK_RULES', getSubTaskRules);
  yield takeLatest('REQUEST_CREATE_SUB_TASK_RULE', createSubTaskRule);
  yield takeLatest('REQUEST_DELETE_SUB_TASK_RULE', deleteSubTaskRule);
  yield takeLatest('REQUEST_UPDATE_SUB_TASK_RULE', updateSubTaskRule);
  yield takeLatest('REQUEST_UPDATE_WORKER_SETTINGS', updateWorkerSettings);
}

function* deleteSubTaskRule(action) {
  try {
    yield put({ type: 'CLEAR_MESSAGE' });
    yield call(driverProcessService.deleteSubTaskRule, action.payload);
    yield put({ type: 'REQUEST_DELETE_SUB_TASK_RULE_SUCCESS' });
    yield put({ type: 'REQUEST_GET_SUB_TASK_RULES' });
  } catch (error) {
    yield put({ type: 'REQUEST_DELETE_SUB_TASK_RULE_ERROR', error });
  }
}

function* updateSubTaskRule(action) {
  try {
    yield put({ type: 'CLEAR_MESSAGE' });
    yield call(driverProcessService.updateSubTaskRule, action.payload);
    yield put({ type: 'REQUEST_UPDATE_SUB_TASK_RULE_SUCCESS' });
    yield put({ type: 'REQUEST_GET_SUB_TASK_RULES' });
  } catch (error) {
    yield put({ type: 'REQUEST_UPDATE_SUB_TASK_RULE_ERROR', error });
  }
}

function* createSubTaskRule(action) {
  try {
    yield put({ type: 'CLEAR_MESSAGE' });
    yield call(driverProcessService.createSubTaskRule, action.payload);
    yield put({ type: 'REQUEST_CREATE_SUB_TASK_RULE_SUCCESS' });
    yield put({ type: 'REQUEST_GET_SUB_TASK_RULES' });
  } catch (error) {
    yield put({ type: 'REQUEST_CREATE_SUB_TASK_RULE_ERROR', error });
  }
}

function* getSubTaskRules() {
  try {
    const subTaskRules = yield call(driverProcessService.getSubTaskRules);
    yield put({
      type: 'REQUEST_GET_SUB_TASK_RULE_SUCCESS',
      result: subTaskRules,
    });
  } catch (error) {
    yield put({ type: 'REQUEST_GET_SUB_TASK_RULE_ERROR', error });
  }
}

function* updateWorkerSettings(action) {
  try {
    yield put({ type: 'CLEAR_MESSAGE' });
    const result = yield call(companyService.updateWorkerSettings, action.payload);
    yield put({ type: 'UPDATE_COMPANY_SETTINGS_SUCCESS', result });
    yield put({ type: 'UPDATE_COMPANY', data: result.data });
  } catch (error) {
    yield put({ type: 'UPDATE_COMPANY_SETTINGS_FAILED', error });
  }
}
