import { makeStyles } from '@material-ui/core/styles';
import { useState } from 'react';
import { Builder, Query, Utils } from 'react-awesome-query-builder';
import MaterialConfig from 'react-awesome-query-builder/lib/config/material';
import { useTranslation } from 'react-i18next';

import { Button, Dialog } from '@yojee/ui/base';
import { dimensions, getDefaultConfig, getFields } from '@yojee/ui/condition/helper';
import { getMappedLabelByKey } from '@yojee/ui/condition/mappedOperatorsHelper';

const useStyles = makeStyles((theme) => ({
  popupContent: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    fontSize: '0.875rem',
  },
  queryContainer: {
    '& span::after': {
      content: '" "',
    },
    '& .rule--operator, & .widget--widget': {
      marginLeft: 3,
    },
    '& .group': {
      background: 'initial',
      border: 'none',
    },
    '& .group--header': {
      margin: 0,
      padding: 0,
    },
    '& .mui > .group-or-rule-container': {
      // Remove list item of the first parent
      display: 'block',
    },
    '& .rule--body--wrapper': {
      display: 'inline-block',
    },
    '& .rule': {
      padding: 0,
    },
    '& .group-or-rule-container': {
      marginBottom: theme.spacing(1),
      display: 'list-item',
    },
    '& .group-or-rule::before, & .group-or-rule::after': {
      display: 'none !important',
    },
  },
}));

function renderValue(value) {
  if (typeof value === 'string') {
    return value;
  }

  if (Array.isArray(value)) {
    return value.map((el) => el).join(', ');
  }
}

const customWidgets = Object.keys(MaterialConfig.widgets).reduce(
  (currentValue, key) => ({
    ...currentValue,
    [key]: {
      ...MaterialConfig.widgets[key],
      factory: ({ value, customProps, ...others }) => (
        <span>
          {renderValue(value)}
          {customProps?.si_unit}
        </span>
      ),
    },
  }),
  {}
);

const defaultQuery = { id: Utils.uuid(), type: 'group' };
const fields = getFields(dimensions);
const defaultConfigs = getDefaultConfig(fields);

function useConfig() {
  const { t } = useTranslation();
  const config = {
    ...defaultConfigs,
    settings: {
      ...defaultConfigs.settings,
      immutableGroupsMode: true,
      immutableFieldsMode: true,
      immutableOpsMode: true,
      immutableValuesMode: true,
      canReorder: false,
      canRegroup: false,
      renderField: ({ selectedLabel }) => <span>{selectedLabel}</span>,
      renderOperator: ({ selectedKey, selectedLabel }) => (
        <span>{(getMappedLabelByKey(selectedKey, selectedLabel) + '').toLowerCase()}</span>
      ),
      renderConjs: ({ conjunctionOptions, selectedConjunction }) => (
        <span>{conjunctionOptions[selectedConjunction].label}</span>
      ),
    },
    widgets: {
      ...defaultConfigs.widgets,
      ...customWidgets,
    },
    conjunctions: {
      ...defaultConfigs.conjunctions,
      AND: {
        ...defaultConfigs.conjunctions.AND,
        label: t('All conditions need to meet:'),
      },
      OR: {
        ...defaultConfigs.conjunctions.AND,
        label: t('One of conditions need to meet:'),
      },
    },
  };
  return config;
}

const ReadOnlyConditions = ({ condition }) => {
  const classes = useStyles();
  const config = useConfig();

  const [conditionModalVisibility, setConditionPopupVisibility] = useState(false);
  const { t } = useTranslation();

  const tree = Utils.checkTree(Utils.loadFromJsonLogic(condition, config), config);

  return (
    <div>
      <Button
        size="small"
        color="primary"
        onClick={() => setConditionPopupVisibility(true)}
        data-cy="partner-buy-rates-view-conditions-button"
      >
        {t('View')}
      </Button>
      <Dialog
        open={conditionModalVisibility}
        onClose={() => setConditionPopupVisibility(false)}
        maxWidth="sm"
        dividers="paper"
        title={t('Conditions')}
        secondaryBtnText={t('Close')}
      >
        <div className={classes.popupContent} data-cy="partner-buy-rates-conditions-popup-content">
          <div className={classes.queryContainer}>
            <Query
              {...config}
              value={tree}
              defaultQuery={defaultQuery}
              renderBuilder={(props) => <Builder {...props} />}
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default ReadOnlyConditions;
