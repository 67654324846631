import Box from '@material-ui/core/Box';
// Library components
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import ChevronLeftRoundedIcon from '@material-ui/icons/ChevronLeftRounded';
import EditIcon from '@material-ui/icons/Edit';
import PropTypes from 'prop-types';
import React from 'react';
import { Trans } from 'react-i18next';

import Title from '../../furnitures/Typography/Title';
// Custom components
import ManageButtonGroup from '../ManageButtonGroup';

const MAIN_VARIANT = 'main';
const SUB_VARIANT = 'sub';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '44px',
  },
  titleBtn: (props) => ({
    marginLeft: props.titleVariant === MAIN_VARIANT ? '30px' : '10px',
  }),
  truncateText: {
    maxWidth: '50vw',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

const titleSizeConfigs = {
  [MAIN_VARIANT]: 'h3',
  [SUB_VARIANT]: 'h4',
};

const ManageHeader = ({
  title,
  titleVariant,
  titleBtnShow,
  titleBtnOnClick,
  component,
  backBtnShow,
  backBtnOnClick,
  primaryBtns,
  primaryBtnsDisable,
  containerStyle,
}) => {
  const classes = useStyles({ titleVariant });

  return (
    <Grid container alignItems="center" className={classes.container} style={containerStyle}>
      {/* Title */}
      <Grid item style={{ marginRight: 'auto' }}>
        <Box display="flex" alignItems="center">
          {backBtnShow && (
            <Box display="flex" alignSelf="center">
              <IconButton aria-label="GoBack" size="small" onClick={() => backBtnOnClick && backBtnOnClick()}>
                <ChevronLeftRoundedIcon fontSize="large" />
              </IconButton>
            </Box>
          )}
          <Box display="flex">
            <Title variant={titleSizeConfigs[titleVariant]} className={classes.truncateText}>
              {title}
            </Title>
            {titleBtnShow && (
              <Box display="flex" alignSelf="center">
                {component?.titleBtn ? (
                  component?.titleBtn
                ) : (
                  <IconButton className={classes.titleBtn} color="primary" size="small" onClick={titleBtnOnClick}>
                    <EditIcon fontSize="small" />
                  </IconButton>
                )}
              </Box>
            )}
          </Box>
        </Box>
      </Grid>
      <Grid item>
        {/* Primary button(s) */}
        <Grid container justifyContent="flex-end">
          {primaryBtns && !!component?.primaryBtns === false ? (
            <ManageButtonGroup
              btnGroup={
                // use medium size btns
                primaryBtns.map(({ size = 'medium', ...restPrimaryProps }) => ({ size, ...restPrimaryProps }))
              }
              disabledAll={primaryBtnsDisable}
            />
          ) : null}
          {component?.primaryBtns && component.primaryBtns}
        </Grid>
      </Grid>
    </Grid>
  );
};

ManageHeader.defaultProps = {
  backBtnShow: false,
  titleVariant: 'main',
  backBtnOnClick: () => {},
  primaryBtns: [
    {
      label: <Trans>Add</Trans>,
      color: 'primary',
      onClick: () => {},
      disabled: false,
    },
  ],
  primaryBtnsDisable: false,
  titleBtnShow: false,
};

ManageHeader.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  backBtnShow: PropTypes.bool,
  backBtnOnClick: PropTypes.func,
  primaryBtns: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
  primaryBtnsDisable: PropTypes.bool,
  containerStyle: PropTypes.object,
  titleBtnShow: PropTypes.bool,
  titleBtnOnClick: PropTypes.func,
  component: PropTypes.shape({
    titleBtn: PropTypes.node,
    primaryBtns: PropTypes.node,
  }),
};

export default ManageHeader;
