import { authService } from '@yojee/api/authService';
import { BaseService } from '@yojee/api/baseService';

export class ReasonCodeService extends BaseService {
  constructor({ authService: constructorAuthService }) {
    super();
    this.authService = constructorAuthService;
  }

  bulkDeleteReasonCodes = (data) => {
    return this.authService
      .post(this.getUmbrellaApiUrlV4(`company/reason_codes/bulk_delete`), data)
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };

  createReasonCode = (data) => {
    return this.authService
      .post(this.getUmbrellaApiUrlV4(`company/reason_codes/create`), data)
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error.response));
  };

  editReasonCode = (data) => {
    const {
      data: { id },
    } = data;
    return this.authService
      .put(this.getUmbrellaApiUrlV4(`company/reason_codes/${id}`), data)
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error.response));
  };

  batchUpload = ({ file, format }) => {
    const uploadJson = new FormData();
    uploadJson.set('file', file);
    uploadJson.set('format', format);

    return this.authService
      .post(this.getUmbrellaApiUrlV4(`company/reason_codes/batch_create`), uploadJson, {
        'Content-Type': 'multipart/form-data',
      })
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };

  checkBatchStatus = ({ batchId }) => {
    return this.authService
      .get(this.getUmbrellaApiUrlV4(`company/reason_codes/batch_create/${batchId}/status`))
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };

  downloadTemplateSample = (format) => {
    return this.getUmbrellaApiUrlV4(`public/reason_codes/download_sample?format=${format}`);
  };
}

export const reasonCodeService = new ReasonCodeService({ authService });
