export const localEnvsAtRuntimeLocalStorageKey = 'local-env-at-run-time';

function getEnvFromStaticFile(url) {
  return fetch(url)
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.log('[Override env] Error: ' + error.toString());
      return {};
    });
}

export async function updateEnvsFromStaticFile() {
  const envsFromStaticFile = await getEnvFromStaticFile('/env.json');
  const currentEnvsInLocalStorage = JSON.parse(localStorage.getItem(localEnvsAtRuntimeLocalStorageKey) || '{}');
  let isNeedToReloadPage = false;

  for (const key in envsFromStaticFile) {
    if (envsFromStaticFile[key] !== currentEnvsInLocalStorage[key]) {
      isNeedToReloadPage = true;
    }
  }

  localStorage.setItem(localEnvsAtRuntimeLocalStorageKey, JSON.stringify(envsFromStaticFile));

  if (isNeedToReloadPage) {
    window.location.reload();
  }
}
