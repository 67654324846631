import { FormControl } from '@material-ui/core';
import React, { useMemo } from 'react';

import { ReactSelect } from '../base';

const QbSelectWidget = ({ qbProps, ...otherProps }) => {
  const { listValues, setValue, value, type } = qbProps;

  const options = useMemo(() => {
    return listValues.map(({ value: optValue, title }) => ({
      label: title,
      value: optValue,
    }));
  }, [listValues]);

  const selectValue = useMemo(() => {
    if (!value) return null;

    if (type === 'select') {
      return options.find((opt) => opt.value === value);
    }

    if (type === 'multiselect') {
      return options.filter((opt) => value.includes(opt.value));
    }

    return null;
  }, [value, type, options]);

  function onChange(event) {
    if (typeof setValue === 'function') {
      if (type === 'select') {
        setValue(event.value);
      } else if (type === 'multiselect') {
        setValue(event.map((opt) => opt.value));
      }
    }
  }

  return (
    <FormControl>
      <ReactSelect
        {...otherProps}
        options={options}
        value={selectValue}
        onChange={onChange}
        closeMenuOnSelect={type !== 'multiselect'}
        hideSelectedOptions={false}
        className="condition-select-container"
        dataCy="selected-field"
      />
    </FormControl>
  );
};

export default QbSelectWidget;
