import { get as lodashGet, set as lodashSet, unset as lodashUnset } from 'lodash-es';
import moment from 'moment';

import { uuidv4 } from '@yojee/helpers/uuidv4';
import { getFormKeyPath } from '@yojee/ui/new-order-booking/components/helpers/formHelpers';
import { fromListErrorObjToErrorStr, updateTimeOfStepBaseOnTemplate } from '@yojee/ui/new-order-booking/saga/helpers';
import { CHARGING_METHOD, isChargeFromDSPartner } from '@yojee/ui/rating/Charges/helpers';

import actionTypes from '../saga/actionTypes';
import {
  copyValueOfNewModelToOldModel,
  getNewFormKeysMetaDataWhenDelete,
  getNewFormKeysMetaDataWhenUpdate,
  getNewFormKeysMetaDataWhenUpdateFormModel,
  handleOrderFieldData,
} from './helpers';

const defaultAddressSearchState = {
  addressBookItems: [],
  googleAddressItems: [],
  searchKey: '',
};
/** @type {import('../types').OrderFormModelsState} */
const defaultOrderFormModels = {
  bookingInfoSections: undefined,
  orderItemSteps: undefined,
  order: undefined,
  sender: undefined,
};

export const defaultState = {
  addressSearch: defaultAddressSearchState,
  organisations: {},
  taskTypes: {},
  itemTypes: {},
  nonOperationalZones: {},
  containerTypes: {},
  companyInfo: {},
  templateDetail: {},
  tasks: {},
  orderDetail: {},
  // This is mutable data,
  // Use to kee form refs (sender, order, step, item detail, ...)
  formRefs: {},
  // This is mutable data,
  // Use to keep sync with data user edit in order form
  // When user do create/edit will get data from here

  orderFormModels: defaultOrderFormModels,
  // Contain meta data of form, ex: changed, isHaveError, submitted, ...
  formKeysMetaDataMap: {},
  charges: {},
  orderFieldTemplate: {},
  selectedServiceType: null,
  selectedOrganisationalUnit: null,
  orderFieldTemplateSender: undefined,
  errorMessage: undefined,
  successMessage: undefined,
};

const ACTION_HANDLERS = {
  [actionTypes.reset()]: () => {
    return {
      ...defaultState,
      // Explicit reset above data because they are mutable data
      orderFormModels: defaultOrderFormModels,
      formRefs: {},
    };
  },
  [actionTypes.addSuccessMessage()]: (state, { successMessage }) => ({
    ...state,
    successMessage,
  }),
  [actionTypes.addErrorMessage()]: (state, { errorMessage }) => ({
    ...state,
    errorMessage,
  }),
  [actionTypes.clearMessage()]: (state) => ({
    ...state,
    errorMessage: undefined,
    successMessage: undefined,
  }),
  [actionTypes.startProgress()]: (state, { key }) => ({
    ...state,
    inProgress: {
      ...state.inProgress,
      [key]: true,
    },
  }),
  [actionTypes.endProgress()]: (state, { key }) => ({
    ...state,
    inProgress: {
      ...state.inProgress,
      [key]: false,
    },
  }),
  [actionTypes.updateState()]: (state, { newState }) => {
    return {
      ...state,
      ...newState,
    };
  },
  [actionTypes.setError()]: (state, { message }) => ({
    ...state,
    errorMessage: message,
  }),
  [actionTypes.resetState()]: () => defaultState,
  [actionTypes.registerForm()]: (state, { formKeyPath, formRef }) => {
    // IMPORTANT: this action mutate value of formRef, not return new state
    // Why: because we don't want to rerender forms when register formRef.
    // formRefs just where we want to get formRef and call some methods on it
    if (formRef) {
      state.formRefs = lodashSet(state.formRefs, formKeyPath, formRef);
    } else {
      lodashUnset(state.formRefs, formKeyPath);
    }

    return state;
  },
  [actionTypes.syncFormModel()]: (state, { formKeyPath, formModel }) => {
    const orderFormKeyPath = getFormKeyPath({ type: 'order' });
    let newFormKeysMetaDataMap = getNewFormKeysMetaDataWhenUpdateFormModel(state, formKeyPath, formModel);

    // IMPORTANT: this action mutate value of orderFormModels, not return new state
    // Why: because we don't want to rerender forms.

    // Remove empty leg. e.g [empty, Arrays(2), Array(2)]
    // -> [Arrays(2), Array(2)]
    const bookingInfoSections = lodashGet(state.orderFormModels, 'bookingInfoSections', []);
    bookingInfoSections.forEach((bookingInfoSection) => {
      for (let i = 0; i < bookingInfoSection.legs.length; i++) {
        if (bookingInfoSection.legs[i] === undefined) {
          bookingInfoSection.legs.splice(i, 1);
          i--;
        }
      }
    });

    const oldFormModel = lodashGet(state.orderFormModels, formKeyPath);

    state.orderFormModels = lodashSet(
      state.orderFormModels,
      formKeyPath,
      copyValueOfNewModelToOldModel(oldFormModel, formModel)
    );

    if (formModel?.organisational_unit_id && state?.selectedOrganisationalUnit) {
      const oldOrderInfo = lodashGet(state.orderFormModels, orderFormKeyPath);
      const oldOrganisationalUnitId = oldOrderInfo?.organisational_unit_id;
      const newOrganisationalUnitId = state.selectedOrganisationalUnit.id;

      if (oldOrganisationalUnitId !== newOrganisationalUnitId) {
        state.orderFormModels = lodashSet(state.orderFormModels, orderFormKeyPath, {
          ...oldOrderInfo,
          organisational_unit_id: newOrganisationalUnitId,
        });

        newFormKeysMetaDataMap = {
          ...newFormKeysMetaDataMap,
          [orderFormKeyPath]: {
            ...newFormKeysMetaDataMap[orderFormKeyPath],
            changed: true,
          },
          root: {
            ...newFormKeysMetaDataMap.root,
            changed: true,
          },
        };
      }
    }

    return {
      ...state,
      formKeysMetaDataMap: newFormKeysMetaDataMap,
    };
  },
  [actionTypes.deleteFormModels()]: (state, { formKeyPath }) => {
    // Mutate orderFormModels
    lodashUnset(state.orderFormModels, formKeyPath);
    const newFormKeysMetaDataMap = getNewFormKeysMetaDataWhenDelete(state.formKeysMetaDataMap, formKeyPath);

    return {
      ...state,
      formKeysMetaDataMap: newFormKeysMetaDataMap,
    };
  },
  [actionTypes.deleteContainerData()]: (state, { formKeyPath }) => {
    // Mutate orderFormModels
    lodashSet(state.orderFormModels, formKeyPath, null);
    const newFormKeysMetaDataMap = getNewFormKeysMetaDataWhenDelete(state.formKeysMetaDataMap, formKeyPath);

    return {
      ...state,
      formKeysMetaDataMap: newFormKeysMetaDataMap,
    };
  },
  [actionTypes.updateFormMetaData()]: (state, { formKeyPath, formMetaData }) => {
    return {
      ...state,
      formKeysMetaDataMap: getNewFormKeysMetaDataWhenUpdate(state.formKeysMetaDataMap, formKeyPath, formMetaData),
    };
  },
  [actionTypes.clearAddressOptions()]: (state) => {
    return {
      ...state,
      addressSearch: defaultAddressSearchState,
    };
  },
  [actionTypes.searchAddressBook().success()]: (state, { items }) => {
    const ORDERS = ['specific', 'all'];

    return {
      ...state,
      inProgress: {
        ...state.inProgress,
        searchAddressBook: false,
      },
      addressSearch: {
        ...state.addressSearch,
        addressBookItems: items.sort(
          (a, b) => ORDERS.indexOf(a.value.sender_link_type) - ORDERS.indexOf(b.value.sender_link_type)
        ),
      },
    };
  },
  [actionTypes.searchGoogleAddress().success()]: (state, { items }) => {
    return {
      ...state,
      inProgress: {
        ...state.inProgress,
        searchAddress: false,
      },
      addressSearch: {
        ...state.addressSearch,
        googleAddressItems: items,
      },
    };
  },
  [actionTypes.createOrderFieldTemplate().success()]: (state, { data }) => {
    return {
      ...state,
      inProgress: {
        ...state.inProgress,
        createOrderFieldTemplate: false,
      },
      successMessage: 'Template created',
      ...handleOrderFieldData(state, data),
    };
  },
  [actionTypes.updateOrderFieldTemplate().success()]: (state, { data }) => {
    return {
      ...state,
      inProgress: {
        ...state.inProgress,
        updateOrderFieldTemplate: false,
      },
      successMessage: 'Template updated',
      ...handleOrderFieldData(state, data),
    };
  },
  [actionTypes.getOrderFieldTemplate().success()]: (state, { data }) => {
    return {
      ...state,
      inProgress: {
        ...state.inProgress,
        getOrderFieldTemplate: false,
      },
      ...handleOrderFieldData(state, data),
    };
  },
  [actionTypes.deleteOrderFieldTemplate().success()]: (state) => {
    return {
      ...state,
      inProgress: {
        ...state.inProgress,
        deleteOrderFieldTemplate: false,
      },
      successMessage: 'Template deleted',
      orderDetail: {
        ...state.orderDetail,
        data: {},
      },
      orderFieldTemplate: {},
    };
  },
  [actionTypes.getOrderFieldTemplate().failed()]: (state) => {
    return {
      ...state,
      inProgress: {
        ...state.inProgress,
        getOrderFieldTemplate: false,
      },
      orderDetail: {
        ...state.orderDetail,
        data: {},
      },
      orderFieldTemplate: {},
    };
  },
  [actionTypes.recalculateStepsTimeBaseOnTemplate()]: (state) => {
    const newOrderFormModel = updateTimeOfStepBaseOnTemplate({
      originalOrderDetailData: state.orderDetail.data,
      orderFormModel: state.orderFormModels,
      templateDetailData: state.templateDetail.data,
    });

    return {
      ...state,
      orderFormModels: newOrderFormModel,
    };
  },
  [actionTypes.reCalculateCharge()]: (state, { chargeData }) => {
    let newCharges = state.charges?.data?.charges;

    if (chargeData.status === 'success') {
      let { charges: newRateTableCharges = [] } = chargeData?.data ?? {};
      newRateTableCharges = newRateTableCharges?.map((charge) => ({
        ...charge,
        charging_method: CHARGING_METHOD.system,
        reCalculated: true,
      }));

      const isManualChargesFromUS = (charge) =>
        !isChargeFromDSPartner(charge) && charge.charging_method === CHARGING_METHOD.manual;

      const currentCharges = state.charges.data?.charges || [];
      const oldManualCharges = currentCharges.filter(isManualChargesFromUS) ?? [];
      newCharges = [...newRateTableCharges, ...oldManualCharges];
    }

    return {
      ...state,
      charges: {
        ...state.charges,
        ...chargeData,
        data: {
          ...chargeData?.data,
          charges: newCharges,
        },
      },
    };
  },
  [actionTypes.setOrderFieldTemplateSender()]: (state, { sender }) => {
    return {
      ...state,
      orderFieldTemplateSender: sender,
    };
  },
  [actionTypes.selectServiceType()]: (state, { serviceType }) => {
    return {
      ...state,
      selectedServiceType: serviceType,
    };
  },
  [actionTypes.selectOrganisationalUnit()]: (state, { organisationalUnit }) => {
    return {
      ...state,
      selectedOrganisationalUnit: organisationalUnit,
    };
  },
  [actionTypes.updateStateInCaseCreateOrderFailed()]: (state, { createOrderData }) => {
    const templateFieldsSchema = state.templateDetail.data?.fields_schema;
    const errorMessage = fromListErrorObjToErrorStr(createOrderData.error?.errors, templateFieldsSchema);

    return {
      ...state,
      createOrder: createOrderData,
      errorMessage,
    };
  },
  [actionTypes.updateStateInCaseUpdateOrderFailed()]: (state, { editOrderData }) => {
    const templateFieldsSchema = state.templateDetail.data?.fields_schema;
    const errorMessage = fromListErrorObjToErrorStr(editOrderData.error?.errors, templateFieldsSchema);

    return {
      ...state,
      editOrder: editOrderData,
      errorMessage,
    };
  },
  [actionTypes.updateStateInCaseBulkReapplyOperationsForOrderFailed()]: (state, { bulkReapplyOperationsResp }) => {
    const { error: errorMessage } = bulkReapplyOperationsResp;
    return {
      ...state,
      errorMessage,
    };
  },
  [actionTypes.duplicateOrder()]: (state, { orderFormModels, template, duplicationSpec }) => {
    const { fields_schema } = template;
    const schema = Object.entries(fields_schema).reduce((acc, [key, value]) => {
      return { ...acc, [key]: value.ui_visible };
    }, {});

    const copyFieldsByTemplate = (entity, { prefix = '' } = {}) => {
      return Object.keys(entity).reduce((acc, key) => {
        const schemaKey = `${prefix}_${key}`;

        return schema[schemaKey] ? { ...acc, [key]: entity[key] } : acc;
      }, {});
    };

    const duplicateItems = (items) => {
      return items.map(({ item, item_container }) => ({
        item: copyFieldsByTemplate(item, { prefix: 'order_item' }),
        ...(item_container
          ? {
              item_container: copyFieldsByTemplate(item_container, { prefix: 'item_container' }),
            }
          : {}),
      }));
    };

    const duplicateLegs = (legs) => {
      const { legStep: duplicationLegStepSpec, reverseLegs } = duplicationSpec;

      const duplicateStep = (step) => {
        const duplicatedStep = copyFieldsByTemplate(
          Object.entries(step).reduce(
            (acc, [key, value]) => (duplicationLegStepSpec[key] ? { ...acc, [key]: value } : acc),
            {}
          ),
          { prefix: 'order_step' }
        );

        return {
          ...duplicatedStep,
          uniqueID: uuidv4(),
          is_empty: step.is_empty,
          address_external_id: step.address_external_id,
          address_id: duplicationLegStepSpec.address_id ? step.address_id : null,
          address_item_id: duplicationLegStepSpec.address_item_id ? step.address_item_id : null,
          location: step.location,
          mapPosition: step.mapPosition,
          from_time_time: duplicatedStep.from_time ? moment(duplicatedStep.from_time) : null,
          to_time_time: duplicatedStep.to_time ? moment(duplicatedStep.to_time) : null,
        };
      };

      const transformedLegs = legs.map(([pickupStep, dropoffStep]) => [
        {
          type: 'pickup',
          isNewStep: true,
          ...duplicateStep(reverseLegs ? dropoffStep : pickupStep),
        },
        {
          type: 'dropoff',
          isNewStep: true,
          ...duplicateStep(reverseLegs ? pickupStep : dropoffStep),
        },
      ]);

      return reverseLegs ? transformedLegs.reverse() : transformedLegs;
    };

    const duplicateEmptyLegs = (legs) => {
      const emptyLeg = {
        address: null,
        address2: null,
        city: null,
        contact_company: null,
        contact_email: null,
        contact_name: null,
        contact_phone: null,
        country: null,
        postal_code: null,
        state: null,
        note: null,
        is_empty: false,
        address_external_id: null,
        location: null,
        mapPosition: null,
        from_time: null,
        from_time_time: null,
        to_item: null,
        to_time_time: null,
        time_zone: null,
      };

      return legs.map((_) => [
        {
          type: 'pickup',
          isNewStep: true,
          uniqueID: uuidv4(),
          ...emptyLeg,
        },
        {
          type: 'dropoff',
          isNewStep: true,
          uniqueID: uuidv4(),
          ...emptyLeg,
        },
      ]);
    };

    const duplicateVoyageInfo = (voyage) => {
      const duplicatedVoyage = copyFieldsByTemplate(voyage, { prefix: 'order_voyage' });

      return {
        ...duplicatedVoyage,
        arrival_time: duplicatedVoyage.arrival_datetime ? moment(duplicatedVoyage.arrival_datetime) : null,
        departure_time: duplicatedVoyage.departure_datetime ? moment(duplicatedVoyage.departure_datetime) : null,
        fcl_storage_time: duplicatedVoyage.fcl_storage_datetime ? moment(duplicatedVoyage.fcl_storage_datetime) : null,
        fcl_available_time: duplicatedVoyage.fcl_available_datetime
          ? moment(duplicatedVoyage.fcl_available_datetime)
          : null,
        receiving_start_time: duplicatedVoyage.receiving_start_datetime
          ? moment(duplicatedVoyage.receiving_start_datetime)
          : null,
        receiving_end_time: duplicatedVoyage.receiving_end_datetime
          ? moment(duplicatedVoyage.receiving_end_datetime)
          : null,
      };
    };

    const { bookingInfoSections, order, order_voyage_info, sender } = orderFormModels;

    const newState = {
      ...state,
      orderFormModels: {
        ...state.orderFormModels,
        bookingInfoSections:
          duplicationSpec.legStep || duplicationSpec.items
            ? bookingInfoSections.map(({ itemDetails, legs }) => ({
                ...(duplicationSpec.items
                  ? {
                      itemDetails: duplicateItems(itemDetails),
                    }
                  : {}),
                ...(duplicationSpec.legStep ? { legs: duplicateLegs(legs) } : { legs: duplicateEmptyLegs(legs) }),
              }))
            : state.orderFormModels.bookingInfoSections,
        order: {
          ...state.orderFormModels.order,
          template_id: template.id,
          ...copyFieldsByTemplate(
            {
              packing_mode: order.packing_mode,
              ...(duplicationSpec.externalId ? { external_id: order.external_id } : {}),
              ...(duplicationSpec.customFieldOne ? { custom_field_1: order.custom_field_1 } : {}),
              ...(duplicationSpec.customFieldTwo ? { custom_field_2: order.custom_field_2 } : {}),
              ...(duplicationSpec.customFieldThree ? { custom_field_3: order.custom_field_3 } : {}),
              ...(duplicationSpec.customFieldFour ? { custom_field_4: order.custom_field_4 } : {}),
              ...(duplicationSpec.serviceType ? { service_type_name: order.service_type_name } : {}),
              ...(duplicationSpec.organisationalUnit ? { organisational_unit_id: order.organisational_unit_id } : {}),
            },
            { prefix: 'order' }
          ),
        },
        sender: duplicationSpec.sender
          ? {
              id: sender.id,
              name: sender.name,
              corporate: sender.corporate,
              organisation_name: sender.organisation_name,
              ...(duplicationSpec.senderUser ? { user_profile_id: sender.user_profile_id } : {}),
              ...(duplicationSpec.organisationalUnit ? { organisational_unit_id: sender.organisational_unit_id } : {}),
            }
          : state.orderFormModels.sender,
        order_voyage_info:
          duplicationSpec.voyage && order_voyage_info
            ? duplicateVoyageInfo(order_voyage_info)
            : state.orderFormModels.order_voyage_info,
      },
    };

    return newState;
  },
};

export const OrderBookingReducer = {
  orderBooking: (state = defaultState, action) => {
    const handler = ACTION_HANDLERS[action.type];
    const newState = handler ? handler(state, action) : state;
    // For debug
    window.orderBooking = newState;
    return newState;
  },
};
