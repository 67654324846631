import { authService } from '@yojee/api/authService';
import { BaseService } from '@yojee/api/baseService';

export class DriverRouteService extends BaseService {
  constructor({ authService }) {
    super();
    this.authService = authService;
  }

  listDriverRoutesReq = () => {
    return this.authService
      .get(this.getUmbrellaApiUrl('dispatcher/route_schedules'))
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  createNewRouteReq = (body) => {
    return this.authService
      .post(this.getUmbrellaApiUrl('dispatcher/route_schedules'), body)
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  updateRouteReq = (body) => {
    return this.authService
      .put(this.getUmbrellaApiUrl(`dispatcher/route_schedules/${body.id}`), body)
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  deleteRouteReq = (body) => {
    return this.authService
      .delete(this.getUmbrellaApiUrl(`dispatcher/route_schedules/${body.id}`))
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };
}

export const driverRouteService = new DriverRouteService({ authService });
