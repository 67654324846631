import { Checkbox, Collapse, IconButton, TableCell, TableRow, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { styledBy } from '../../../utils';

const getStyleBy = (prop, theme) =>
  styledBy(prop, {
    default: theme.palette.primary.tint,
    error: theme.palette.error.tint,
    warning: theme.palette.warning.tint,
    success: theme.palette.success.tint,
    info: theme.palette.info.tint,
    fileHover: 'rgba(0,0,0,0.1)',
  });

const useStyles = makeStyles((theme) => ({
  hover: {
    '&$hover': {
      '&:hover': {
        '& td': {
          backgroundColor: getStyleBy('activecolor', theme),
        },
        '&$clickable': {
          '& td': {
            cursor: 'pointer',
          },
        },
      },
    },
  },
  selected: {
    '&$selected': {
      '& td': {
        backgroundColor: getStyleBy('activecolor', theme),
      },
    },
  },
  checked: {
    '&$checked': {
      color: styledBy('activecolor', {
        default: theme.palette.primary.main,
        error: theme.palette.error.main,
        warning: theme.palette.warning.main,
        success: theme.palette.success.main,
        info: theme.palette.info.main,
      }),
    },
  },
  checkbox: {
    '& input[type="checkbox"]': {
      height: '100%',
      width: '100%',
    },
  },
  tableRow: {
    height: theme.spacing(6),
    '& td': {
      padding: `0 ${theme.spacing(2)}px`,
      borderBottomColor: theme.palette.black?.B4,
      backgroundColor: styledBy('activecolor', {
        default: theme.palette.common.white,
        error: theme.palette.common.white,
        warning: theme.palette.common.white,
        success: theme.palette.common.white,
        info: theme.palette.common.white,
        fileHover: 'rgba(0,0,0,0.1)',
      }),
    },
  },
  childTableRow: {
    '& td': {
      borderBottomColor: theme.palette.black?.B4,
      backgroundColor: theme.palette.common.white,
      paddingBottom: 0,
      paddingTop: 0,
    },
  },
  cellCheckbox: {
    width: theme.spacing(5),
    padding: `0 ${theme.spacing(1)}px`,
  },
  clickable: {},
}));

const renderCellContent = ({ row, col, ...restArgs }) => {
  if (col.format) return col.format(row[col.id], row);

  if (typeof row[col.id] === 'function') {
    const { open, setOpen } = restArgs;
    return row[col.id](open, setOpen);
  }

  if (row.hideColumns?.includes(col.id)) return null;

  return row[col.id];
};

const YoJeeTableRow = ({
  columns,
  colWidths,
  row,
  index,
  showCheckBox,
  disabledCheckBox,
  onRowClick,
  alwaysClickable,
  isSelected,
  rowActions,
  rowChildComponent,
  dataCyRow,
  dataCyCheckbox,
  id,
  rowClassName,
  ...restProps
}) => {
  const classes = useStyles(restProps);
  const isItemSelected = isSelected(row.id ?? index);
  const labelId = `enhanced-table-checkbox-${index}`;

  const [open, setOpen] = useState(false);

  const handleRowClick = () => onRowClick?.(row.id ?? index);

  let rowSelectedProps = showCheckBox
    ? {
        onClick: handleRowClick,
        role: 'checkbox',
        'aria-checked': isItemSelected,
        selected: isItemSelected,
        ...restProps,
      }
    : { ...restProps };

  // If `alwaysClickable` is set to `true` then the Row will accept
  // `onRowClick` props no matter what the value of `showCheckBox` is.
  // This allows users to click on normal rows (those do not have checkboxes)
  // in order to, say, navigate to the detail page for example.
  rowSelectedProps = alwaysClickable
    ? {
        ...rowSelectedProps,
        onClick: handleRowClick,
      }
    : rowSelectedProps;

  const getRowClassName = () => {
    return typeof rowClassName === 'function' ? rowClassName(row) : rowClassName;
  };

  const ExpandRows = ({ listChildren }) => {
    return (
      open &&
      listChildren.map((listRow, indexRow) => (
        <TableRow key={'children-row-' + indexRow} className={open ? classes.tableRow : classes.childTableRow}>
          {listRow.map((children, indexCell) => (
            <TableCell
              key={'children-cell-' + indexCell}
              component="td"
              scope="row"
              colSpan={columns.length / listRow.length}
            >
              <Collapse in={open} timeout="auto" unmountOnExit>
                {children}
              </Collapse>
            </TableCell>
          ))}
        </TableRow>
      ))
    );
  };

  return (
    <React.Fragment>
      <TableRow
        hover
        classes={{ hover: classes.hover, selected: classes.selected }}
        className={classNames(classes.tableRow, 'row', getRowClassName(), {
          [classes.clickable]: alwaysClickable,
        })}
        tabIndex={-1}
        data-cy={dataCyRow}
        {...rowSelectedProps}
      >
        {showCheckBox && (
          <TableCell
            className={`word-break ${classes.cellCheckbox} left-sticky-` + id}
            component="td"
            scope="row"
            data-cy={dataCyCheckbox}
          >
            <Checkbox
              size="small"
              checked={isItemSelected}
              disabled={disabledCheckBox}
              classes={{ checked: classes.checked }}
              inputProps={{ 'aria-labelledby': labelId }}
              style={{
                ...(colWidths ? { width: colWidths[0] } : {}),
              }}
            />
          </TableCell>
        )}
        {columns.map((col, index) => {
          const { id: colId, width, ...restColProps } = col;

          return (
            <TableCell
              width={width ?? undefined}
              className={`word-break ${
                index === columns.length - 1 && rowActions?.length > 0 ? 'right-sticky-' + id : ''
              }`}
              key={colId ?? index}
              component="td"
              scope="row"
              style={{
                ...(colWidths ? { width: colWidths[index + 1] } : {}),
                ...col.cellStyle,
              }}
              {...restColProps}
            >
              {renderCellContent({ row, col, open, setOpen })}
            </TableCell>
          );
        })}
        {Array.isArray(rowActions) && rowActions.length > 0 ? (
          <TableCell className={'actions actions-' + id}>
            {rowActions.map(
              ({ conditions = () => true, ...action }) =>
                conditions(row) && (
                  <Tooltip key={action.type} title={action.title}>
                    <IconButton
                      aria-label={action.type}
                      onClick={(e) => {
                        e.stopPropagation();
                        action.onClick(row);
                      }}
                      data-cy={action?.['data-cy']}
                    >
                      {action.icon}
                    </IconButton>
                  </Tooltip>
                )
            )}
          </TableCell>
        ) : undefined}
      </TableRow>
      {row.children && (
        <TableRow className={classes.childTableRow}>
          <TableCell component="td" scope="row" colSpan={columns.length}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              {row.children}
            </Collapse>
          </TableCell>
        </TableRow>
      )}
      {row.listChildren && <ExpandRows listChildren={row.listChildren} />}
    </React.Fragment>
  );
};

YoJeeTableRow.propTypes = {
  columns: PropTypes.array,
  colWidths: PropTypes.array,
  row: PropTypes.object,
  showCheckBox: PropTypes.bool,
  disabledCheckBox: PropTypes.bool,
  index: PropTypes.number,
  onRowClick: PropTypes.func,
  alwaysClickable: PropTypes.bool,
  isSelected: PropTypes.func,
  rowActions: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      icon: PropTypes.node,
      onClick: PropTypes.func,
    })
  ),
};

YoJeeTableRow.defaultProps = {
  columns: [],
  row: {},
  showCheckBox: false,
  disabledCheckBox: false,
  index: 0,
  onRowClick: () => {},
  alwaysClickable: false,
  isSelected: () => {},
  rowActions: [],
};

export default YoJeeTableRow;
