import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { Trans } from 'react-i18next';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '8px',
    height: '100%',
    justifyContent: 'center',
  },
}));

const FallbackWithoutRetryAbility = () => {
  const classes = useStyles();

  return (
    <div role="alert" className={classes.container}>
      <Typography color="textSecondary" gutterBottom>
        <Trans>Something went wrong</Trans>
      </Typography>
    </div>
  );
};

export default FallbackWithoutRetryAbility;
