import FormControl from '@material-ui/core/FormControl';
import React, { useMemo } from 'react';

import ReactSelect from '@yojee/ui/base/furnitures/Select/ReactSelect';

const MaterialFieldSelect = ({ items, setField, selectedKey, placeholder }) => {
  function renderOptions(fields, level = 0) {
    return Object.keys(fields).map((fieldKey) => {
      const field = fields[fieldKey];
      const { items: fieldItems, path, label } = field;
      const prefix = '\u00A0\u00A0'.repeat(level);
      if (fieldItems) {
        return {
          label,
          options: renderOptions(fieldItems, level + 1),
        };
      }

      return {
        label: prefix + label,
        value: path,
      };
    });
  }

  const options = useMemo(() => renderOptions(items), [items]);

  const onChange = (e) => {
    setField(e.value);
  };

  function getValueFromProps() {
    // Convert all options & nested options of select into simple array
    const allOptions = options.map((opt) => opt.options || opt).flat(1);
    return allOptions.find((opt) => opt.value === selectedKey);
  }

  return (
    <FormControl>
      <ReactSelect
        dataCy="selected-field"
        options={options}
        onChange={onChange}
        value={getValueFromProps()}
        hideSelectedOptions={false}
        placeholder={placeholder}
        className="condition-select-container"
      />
    </FormControl>
  );
};

export default MaterialFieldSelect;
