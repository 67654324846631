import { Divider, Grid } from '@material-ui/core';
import MuiDialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import React from 'react';
import { Trans } from 'react-i18next';

import LinearLoading from '../../furnitures/LinearLoading';
import { Text } from '../../furnitures/Typography';
import ManageButtonGroup from '../ManageButtonGroup';

const defaultFunc = () => {
  return;
};

const styles = (theme) => ({
  tabPanel: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1.25),
  },
  paper: {
    margin: 0,
    borderRadius: theme.spacing(1),
  },
  header: {
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingBottom: 0,
  },
  closeButton: {
    color: theme.palette.grey[500],
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  topRightCloseButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: theme.palette.grey[500],
  },
  title: {
    fontWeight: 'bold',
  },
  paperWidthXs: {
    maxWidth: '560px',
  },
  paperWidthMd: {
    maxWidth: '792px',
  },
  staticDialogContent: {
    position: 'static',
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, isHideSecondaryBtn, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.header} {...other}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item xs={12}>
          {' '}
          <Text variant="h6" className={classes.title}>
            {children}
          </Text>
        </Grid>
        {onClose && !!isHideSecondaryBtn ? (
          <IconButton aria-label="close" className={classes.topRightCloseButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </Grid>
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: `0px ${theme.spacing(4)}px 0px ${theme.spacing(4)}px`,
    height: '100%',
    position: 'relative',
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: `${theme.spacing(3)}px ${theme.spacing(4)}px`,
  },
}))(MuiDialogActions);

const StyledLoading = withStyles(() => ({
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '6px',
  },
}))(LinearLoading);

const DialogContainer = ({ open, isLoading, children, ...restProps }) => (
  <MuiDialog open={open || false} {...restProps}>
    {isLoading ? <StyledLoading /> : null}
    {children}
  </MuiDialog>
);

const Dialog = ({
  children,
  classes,
  dividers = 'body',
  haveTopDivider = false,
  maxWidth = 'md',
  open,
  onTertiaryBtnClick,
  onClose,
  onSave,
  title,
  dataCy,
  disabled,
  dialogContainerCustomStyle,
  primaryBtnText,
  primaryBtnColor,
  primaryBtnCustomStyle,
  primaryBtnDisabled,
  primaryBtnOrder,
  primaryBtnTooltip,
  isHideSecondaryBtn,
  secondaryBtnText,
  secondaryBtnColor,
  secondaryBtnOrder,
  tertiaryBtnText,
  tertiaryBtnColor,
  tertiaryBtnOrder,
  isLoading,
  isPaddingY,
  hasStaticDialogContent = false,
  ...restProps
}) => {
  const btnGroup = [];

  if (onTertiaryBtnClick) {
    btnGroup.push({
      label: tertiaryBtnText,
      variant: 'outlined',
      color: tertiaryBtnColor,
      onClick: onTertiaryBtnClick,
      disabled: false,
      size: 'medium',
      'data-cy': dataCy?.tertiaryBtnClick || 'tertiary-btn-click',
      order: tertiaryBtnOrder,
    });
  }

  if (onClose && !isHideSecondaryBtn) {
    btnGroup.push({
      label: secondaryBtnText,
      variant: 'outlined',
      color: secondaryBtnColor,
      onClick: onClose,
      disabled: false,
      size: 'medium',
      'data-cy': dataCy?.close || 'cancel',
      order: secondaryBtnOrder,
    });
  }

  if (onSave) {
    btnGroup.push({
      label: primaryBtnText,
      variant: 'contained',
      color: primaryBtnColor,
      onClick: onSave,
      disabled: primaryBtnDisabled || isLoading,
      size: 'medium',
      'data-cy': dataCy?.save || 'save-button',
      order: primaryBtnOrder,
      tooltip: primaryBtnTooltip,
      style: primaryBtnCustomStyle,
    });
  }

  const sortedBtnGroup = btnGroup.sort((a, b) => a.order - b.order);
  const onCloseHandler = isLoading ? defaultFunc : onClose;

  return (
    <DialogContainer
      open={open}
      isLoading={isLoading}
      onClose={onCloseHandler}
      scroll={dividers}
      maxWidth={maxWidth}
      fullWidth
      classes={{
        paperWidthXs: classes.paperWidthXs,
        paperWidthMd: classes.paperWidthMd,
        paper: classes.paper,
      }}
      style={dialogContainerCustomStyle}
      {...restProps}
    >
      <DialogTitle isHideSecondaryBtn={isHideSecondaryBtn} onClose={onClose}>
        {title}
      </DialogTitle>
      <DialogContent
        className={clsx({
          [classes.staticDialogContent]: hasStaticDialogContent,
          [classes.tabPanel]: isPaddingY,
        })}
        dividers={dividers === 'paper' && haveTopDivider}
      >
        {children}
      </DialogContent>
      {dividers === 'paper' && !!haveTopDivider === false && <Divider />}
      <DialogActions>
        <ManageButtonGroup btnGroup={sortedBtnGroup} disabledAll={isLoading} noMargin />
      </DialogActions>
    </DialogContainer>
  );
};

Dialog.defaultProps = {
  dialogContainerCustomStyle: {},
  primaryBtnTooltip: '',
  primaryBtnDisabled: false,
  isLoading: false,
  primaryBtnText: <Trans>Save</Trans>,
  primaryBtnColor: 'primary',
  primaryBtnCustomStyle: {},
  secondaryBtnColor: 'default',
  secondaryBtnText: <Trans>Cancel</Trans>,
  tertiaryBtnText: <Trans>Discard</Trans>,
  tertiaryBtnColor: 'default',
  primaryBtnOrder: 3,
  secondaryBtnOrder: 1,
  isHideSecondaryBtn: false,
  tertiaryBtnOrder: 2,
  isPaddingY: false,
};

export default withStyles(styles)(Dialog);
