import { authService } from '@yojee/api/authService';
import { BaseService } from '@yojee/api/baseService';

export class WorkerService extends BaseService {
  constructor({ authService }) {
    super();
    this.authService = authService;
  }

  listWorkers = (q, pageSize) => {
    const apiUrl = q
      ? `dispatcher/search/worker?page=${1}&page_size=${5}&q=${q}`
      : `dispatcher/workers?page=1&page_size=${pageSize || 10000}`;
    return this.authService
      .get(this.getUmbrellaApiUrl(apiUrl))
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  getWorkers = ({ q, page, page_size }) => {
    return this.authService
      .get(this.getUmbrellaApiUrl(q ? `dispatcher/search/worker` : 'dispatcher/workers'), {
        page,
        page_size,
        ...(q ? { q } : {}),
      })
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };

  getWorker = (workerId) => {
    return this.authService
      .get(this.getUmbrellaApiUrl(`dispatcher/workers/${workerId}`))
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  updateWorker = (worker) => {
    const formData = parseWorkerParamsIntoFormData(worker);

    return this.authService
      .put(this.getUmbrellaApiUrl(`dispatcher/workers/${worker.id}`), formData, {
        'Content-Type': 'multipart/form-data',
      })
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  createWorker = (worker) => {
    const formData = parseWorkerParamsIntoFormData(worker);

    return this.authService
      .post(this.getUmbrellaApiUrl(`dispatcher/workers`), formData, { 'Content-Type': 'multipart/form-data' })
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  deleteWorker = (workerId) => {
    return this.authService
      .delete(this.getUmbrellaApiUrl(`dispatcher/workers/${workerId}`))
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  batchUpload = ({ file, format }) => {
    const uploadJson = new FormData();
    uploadJson.set('file', file);
    uploadJson.set('format', format);

    return this.authService
      .post(this.getUmbrellaApiUrl(`dispatcher/workers/batches`), uploadJson, {
        'Content-Type': 'multipart/form-data',
      })
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };

  checkBatchStatus = ({ batchId }) => {
    return this.authService
      .get(this.getUmbrellaApiUrl(`dispatcher/workers/batches/${batchId}/status`))
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };

  downloadWorkersTemplateSample = ({ format }) => {
    return this.getUmbrellaApiUrl(`public/workers/download_sample?format=${format}`);
  };
}

function parseWorkerParamsIntoFormData(workerParams) {
  const formData = new FormData();
  const {
    name,
    phone,
    email,
    vehicle_type_ids = [],
    driver_license,
    national_id,
    tester,
    static_otp_token,
    start_address,
    start_location,
    end_address,
    end_location,
    external_id,
    tags = [],
  } = workerParams;

  // Mandatory fields
  formData.set('name', name);
  formData.set('phone', phone);
  formData.set('email', email ? email : null);
  formData.set('tester', tester);
  formData.set('start_address', start_address || '');
  formData.set('start_location[lat]', start_location ? Number(start_location.lat) : '');
  formData.set('start_location[lng]', start_location ? Number(start_location.lng) : '');
  formData.set('end_address', end_address || '');
  formData.set('end_location[lat]', end_location ? Number(end_location.lat) : '');
  formData.set('end_location[lng]', end_location ? Number(end_location.lng) : '');
  formData.set('external_id', external_id || '');

  if (vehicle_type_ids?.length > 0) {
    vehicle_type_ids.forEach((vehicleTypeId) => {
      formData.append('vehicle_type_ids[]', vehicleTypeId);
    });
  } else {
    formData.append('vehicle_type_ids[]', '');
  }

  if (tags?.length > 0) {
    tags.forEach(function (tag) {
      formData.append('tags[]', tag);
    });
  } else {
    formData.append('tags[]', '');
  }

  // Optional fields
  if (static_otp_token) {
    formData.set('static_otp_token', static_otp_token);
  }

  if (driver_license) {
    formData.set('driver_license', driver_license);
  }

  if (national_id) {
    formData.set('national_id', national_id);
  }

  formData.set('driver_license_photo_front', workerParams['driver_license_photo_front']);

  formData.set('driver_license_photo_back', workerParams['driver_license_photo_back']);

  formData.set('national_id_photo_front', workerParams['national_id_photo_front']);

  formData.set('national_id_photo_back', workerParams['national_id_photo_back']);

  return formData;
}

export const workerService = new WorkerService({ authService });
