import BaseActionTypes from '@yojee/helpers/BaseActionTypes';

export default class ActionTypes extends BaseActionTypes {
  constructor({ resource }) {
    if (!resource) throw new Error('resource is not passed');
    super({ prefix: `${resource.toUpperCase()}` });
  }

  getActionType(type) {
    switch (type) {
      case 'LIST':
      case 'ADD':
      case 'CREATE':
      case 'EDIT':
      case 'UPDATE':
      case 'GET':
      case 'DELETE':
      case 'CLEAR_MESSAGE':
        return this.customWithSubType(type);
      default:
        throw new Error(`${type} is not supported`);
    }
  }
}
