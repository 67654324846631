import RootMenuItem from './RootMenuItem';
import SubMenuItem from './SubMenuItem';
import ThirdLevelMenuItem from './ThirdLevelMenuItem';
import useTopMenuStyles from './topMenuStyles';

const isVisibleMenuItem = (menuItem) => {
  const shouldShow = menuItem.shouldShow;
  const hasVisibleChildren = !menuItem.children || menuItem.children.some(isVisibleMenuItem);

  return shouldShow && hasVisibleChildren;
};

const MenuGroup = ({ childItems, menuLevel = 0 }) => {
  const classes = useTopMenuStyles();
  const menuStyleMap = [classes.menuGroup, classes.subMenuGroup, classes.thirdLevelMenuGroup];
  const menuItemMap = [RootMenuItem, SubMenuItem, ThirdLevelMenuItem];

  return (
    <ul className={menuStyleMap[menuLevel]}>
      {childItems.filter(isVisibleMenuItem).map((menuItem) => {
        const subMenu = menuItem.children && <MenuGroup childItems={menuItem.children} menuLevel={menuLevel + 1} />;
        const MenuItemComponent = menuItemMap[menuLevel];

        return <MenuItemComponent key={menuItem.key} menuItem={menuItem} children={subMenu} />;
      })}
    </ul>
  );
};

export default MenuGroup;
