import { endOfDate, startOfDate } from '@yojee/helpers/rating/utilities';

import { ALL_OPTION } from './filtersConfig';

export const filterUpdatedFilters = (filters, config) => {
  return Object.values(config).reduce((acc, { filterKey, defaultValue, transformFn }) => {
    if (filters[filterKey] !== undefined && filters[filterKey] !== defaultValue) {
      if (filters[filterKey].value === ALL_OPTION.value) {
        return acc;
      }

      if (transformFn) {
        const json = transformFn(filters[filterKey]);
        return { ...acc, ...json };
      }

      if (filterKey === 'start_date') {
        return { ...acc, [filterKey]: startOfDate(new Date(filters[filterKey])) };
      }

      if (filterKey === 'end_date') {
        return { ...acc, [filterKey]: endOfDate(new Date(filters[filterKey])) };
      }

      return { ...acc, [filterKey]: filters[filterKey] };
    }

    return acc;
  }, {});
};
