import { call, put, select, takeLatest } from 'redux-saga/effects';

import { clearJwtData, saveJwtData } from '@yojee/auth/jwt-storage';
import {
  getDispatcherSupportedLanguages,
  getSystemLanguageForUser,
  isSupportedLanguage,
  languageStorage,
} from '@yojee/helpers/languages';
import { LocalStorage } from '@yojee/local-storage/local-storage';
import { UIAuthActions } from '@yojee/ui/onboarding/sagas/auth/actions';

import { loginService } from '../../services/loginService';

export default function* sagas() {
  yield takeLatest('REQUEST_GET_USER_INFO', getUserInfo);
  yield takeLatest('SIGN_OUT', signOut);
  yield takeLatest('REQUEST_SET_DATA_FROM_EXPLORE', setDataFromExplore);
  yield takeLatest('REQUEST_SET_SLUG_AND_TOKEN', setSlugAndToken);
}

function* setDataFromExplore(action) {
  try {
    if (action.payload && action.payload) {
      yield put({ type: 'START_LOADING', loadingAction: 'setDataFromExplore' });
      const {
        payload: { accessToken, currentUser, dispatcherCompanies, jwtData, companySettings, localLanguage },
      } = action;
      const slug = currentUser && currentUser.company && currentUser.company.slug;

      saveJwtData(jwtData);
      LocalStorage.setItem('dispatcher_companies', dispatcherCompanies);
      LocalStorage.setItem('slug', slug);

      if (localLanguage !== undefined && localLanguage !== languageStorage.getSavedLanguage()) {
        languageStorage.setSavedLanguage(localLanguage);
        window.location.reload();
      }

      yield put({
        type: 'LOGIN_SUCCESSFUL',
        data: {
          jwtData,
          token: accessToken,
          dispatcher_companies: dispatcherCompanies,
          slug: slug,
        },
        isAppInjected: action?.payload?.isAppInjected,
      });

      if (currentUser) {
        yield put(UIAuthActions.setDispatcherInfo(currentUser));
      }

      if (companySettings) {
        yield put({ type: 'SET_COMPANY_INFO', payload: { companyData: companySettings } });
      } else {
        yield put({ type: 'REQUEST_GET_USER_INFO' });
      }
    }
  } catch (error) {
    yield put({ type: 'SET_DATA_FROM_EXPLORE_FAILED', error });
  }
}

function* setSlugAndToken(action) {
  try {
    const foundSlug = LocalStorage.getItem('slug');
    const foundToken = LocalStorage.getItem('access_token');
    const {
      payload: { slug, token, jwtData },
    } = action;
    saveJwtData(jwtData);
    if (slug && token && (!foundSlug || !foundToken || foundSlug !== slug || foundToken !== token)) {
      LocalStorage.setItem('slug', slug);
      yield put({ type: 'SET_SLUG_AND_TOKEN_SUCCESSFUL', token, slug, jwtData });
    }
    yield put({ type: 'LOGIN_SUCCESSFUL', data: { jwtData, token, slug } });
    yield put({ type: 'REQUEST_GET_USER_INFO' });
  } catch (error) {
    yield put({ type: 'SET_SLUG_AND_TOKEN_FAILED', error });
  }
}

function* getUserInfo() {
  try {
    yield put({ type: 'START_LOADING', loadingAction: 'getUserInfo' });
    const userInfo = yield call(loginService.getUserInfo);
    const supportedLanguages = yield select(getDispatcherSupportedLanguages);
    const { language: savedLanguage } = userInfo.data;

    LocalStorage.setItem('current_user', userInfo.data);

    yield put({ type: 'GET_USER_INFO_SUCCESSFUL', data: { ...userInfo.data } });
    yield put({ type: 'REQUEST_GET_COMPANY_INFO' });

    const pickedLanguage = getSystemLanguageForUser(savedLanguage, supportedLanguages);

    if (savedLanguage && savedLanguage !== pickedLanguage) {
      if (isSupportedLanguage(savedLanguage, supportedLanguages)) {
        languageStorage.setSavedLanguage(savedLanguage);
        window.location.reload();
      }
    }
  } catch (error) {
    yield put({ type: 'GET_USER_INFO_FAILED', error });
  }
}

function* signOut() {
  clearJwtData();
  LocalStorage.removeItem('access_token');
  LocalStorage.removeItem('current_user');
  LocalStorage.removeItem('dispatcher_companies');
  LocalStorage.removeItem('slug');
  LocalStorage.removeItem('permissions_updated_at');
  yield put({ type: 'SIGN_OUT_SUCCESSFUL' });
}
