import { Grid, IconButton } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { cloneDeep } from 'lodash-es';
import React from 'react';
import { Trans } from 'react-i18next';

import { isValidEmail } from '@yojee/auth/utils/AuthUtils';
import { currencies } from '@yojee/helpers/currencySourceHelper';
import { capitalize, join, pipe, split } from '@yojee/helpers/rating/utilities';
import { capitalizeFirstLetter } from '@yojee/helpers/string-helper';
import Table from '@yojee/ui/base/corners/Table/Table';

import ReadOnlyConditions from './ReadOnlyConditions';

const columnIndexWidth = 7;

export const APPLIED_TO_OPTIONS = [
  { value: 'allChargeCodes', label: <Trans>All charge codes</Trans> },
  { value: 'specificChargeCodes', label: <Trans>Specific charge codes</Trans> },
];

export const CALCULATION_TYPES = {
  FLAT: 'flat',
  PERCENTAGE: 'percentage',
  RATE_TABLE: 'rate_table',
  SUM: 'sum',
  UNIT: 'unit',
  CLIPPING_TABLE: 'clipping_table',
};

export const FIELD_KEY = {
  calculation_type: 'calculation_type',
  rate_charge_type_id: 'rate_charge_type_id',
  description: 'description',
  base: 'base',
  currency: 'currency',
  relative_rate_calculation_index: 'relative_rate_calculation_index',
  relative_percentage: 'relative_percentage',
  unit_table_dimension: 'unit_table.dimension',
  unit_table_unit: 'unit_table.unit',
  unit_table_amount: 'unit_table.amount',
  unit_table: 'unit_table',
  min: 'min',
  max: 'max',
  rate_tables: 'rate_tables',
  condition: 'condition',
  parent_rate_calculation_index: 'parent_rate_calculation_index',
  rate_calculation_index: 'rate_calculation_index',
  relative_calculation_type: 'relative_calculation_type',
  id: 'id',
  charge_code: 'charge_code',
  relative_charge_type_ids: 'relative_charge_type_ids',
};

export const RATE_CALCULATOR_FORM = {
  [FIELD_KEY.calculation_type]: {
    label: <Trans>Calculation Type</Trans>,
    key: FIELD_KEY.calculation_type,
    value: 'flat',
    error: '',
    isValid: true,
    validators: [],
    touched: false,
    type: 'select',
    requiredFields: {
      flat: [FIELD_KEY.rate_charge_type_id, FIELD_KEY.base, FIELD_KEY.description, FIELD_KEY.currency],
      percentage: [
        FIELD_KEY.rate_charge_type_id,
        FIELD_KEY.relative_rate_calculation_index,
        FIELD_KEY.relative_percentage,
        FIELD_KEY.description,
        FIELD_KEY.currency,
        FIELD_KEY.relative_charge_type_ids,
      ],
      unit: [
        FIELD_KEY.rate_charge_type_id,
        FIELD_KEY.unit_table_dimension,
        FIELD_KEY.unit_table_unit,
        FIELD_KEY.unit_table_amount,
        FIELD_KEY.description,
        FIELD_KEY.currency,
        FIELD_KEY.min,
      ],
      rate_table: [FIELD_KEY.rate_charge_type_id, FIELD_KEY.description, FIELD_KEY.currency, FIELD_KEY.min],
      sum: [FIELD_KEY.rate_charge_type_id, FIELD_KEY.description, FIELD_KEY.currency],
    },
    display: true,
    transformValue: (value) => ({
      value,
      label: pipe(value, split, capitalize, join),
    }),
    options: [],
    size: {
      xs: 12,
      md: 4,
    },
    ordinalnumbers: 1,
    dataCy: 'calculation-type',
    optionDataCy: 'calculation-type-item',
  },
  [FIELD_KEY.rate_charge_type_id]: {
    label: <Trans>Charge Code</Trans>,
    key: FIELD_KEY.rate_charge_type_id,
    value: '',
    error: '',
    placeholder: 'Select charge code',
    isValid: false,
    validators: ['required'],
    touched: false,
    type: 'select',
    display: false,
    transformValue: (value, options) => {
      if (value && typeof value === 'object') {
        return {
          value: value.id,
          label: value.code,
        };
      }
      if (value && typeof value === 'number') {
        return {
          value,
          label: options?.find((o) => o.value === value)?.label ?? '',
        };
      }
      return value;
    },
    options: [],
    size: {
      xs: 12,
      md: 4,
    },
    tabIndx: 0,
    ordinalnumbers: 2,
    dataCy: 'charge-code',
    optionDataCy: 'charge-code-item',
  },
  [FIELD_KEY.description]: {
    label: <Trans>Description</Trans>,
    key: FIELD_KEY.description,
    value: '',
    error: '',
    placeholder: 'Add description',
    isValid: true,
    validators: [],
    touched: false,
    type: 'text',
    display: false,
    disabled: true,
    options: [],
    size: {
      xs: 12,
      md: 4,
    },
    tabIndx: 0,
    ordinalnumbers: 3,
    isOptional: true,
    dataCy: 'description',
  },
  [FIELD_KEY.charge_code]: {
    ordinalnumbers: 2,
  },
  [FIELD_KEY.currency]: {
    label: <Trans>Currency</Trans>,
    key: FIELD_KEY.currency,
    value: '',
    error: '',
    transformValue: (value, options) => {
      if (value && typeof value === 'object') {
        return {
          value: value.code,
          label: value.code,
        };
      }
      if (value && typeof value === 'string') {
        return {
          value,
          label: value,
        };
      }
      return value;
    },
    isValid: true,
    validators: [],
    touched: false,
    type: 'select',
    display: false,
    options: [],
    size: {
      xs: 12,
      md: 4,
    },
    tabIndx: 0,
    ordinalnumbers: 4,
    isOptional: true,
    dataCy: 'currency',
    optionDataCy: 'currency-item',
  },
  [FIELD_KEY.charge_code]: {
    ordinalnumbers: 2,
  },
  [FIELD_KEY.relative_percentage]: {
    label: <Trans>Percentage</Trans>,
    key: FIELD_KEY.relative_percentage,
    value: '',
    error: '',
    placeholder: 'e.g. 7.5',
    isValid: true,
    validators: ['required'],
    touched: false,
    display: false,
    type: 'number',
    options: [],
    size: {
      xs: 12,
      md: 4,
    },
    tabIndx: 0,
    ordinalnumbers: 7,
    dataCy: 'relative-percentage',
  },
  [FIELD_KEY.relative_rate_calculation_index]: {
    label: <Trans>Applied To</Trans>,
    key: FIELD_KEY.relative_rate_calculation_index,
    value: APPLIED_TO_OPTIONS[0].value,
    error: '',
    isValid: true,
    validators: [],
    transformValue: (value, options) => {
      if (value && typeof value === 'object') {
        return {
          value: value.id,
          label: value.name,
        };
      }
      if (value && typeof value === 'string') {
        return APPLIED_TO_OPTIONS.find((o) => o.value === value);
      }
      return value;
    },
    touched: false,
    type: 'select',
    display: false,
    options: APPLIED_TO_OPTIONS,
    size: { xs: 12, md: 4 },
    tabIndx: 0,
    ordinalnumbers: 6,
    isOptional: true,
    dataCy: 'relative-to',
    optionDataCy: 'relative-to-item',
  },
  [FIELD_KEY.base]: {
    label: <Trans>Base Amount</Trans>,
    key: FIELD_KEY.base,
    value: '',
    error: '',
    isValid: true,
    placeholder: 'Add base amount',
    validators: ['required'],
    touched: false,
    type: 'number',
    display: false,
    options: [],
    size: {
      xs: 12,
      md: 4,
    },
    tabIndx: 0,
    ordinalnumbers: 5,
    props: {
      InputProps: {
        inputProps: {
          min: 0,
        },
      },
    },
    dataCy: 'base-amount',
  },
  [FIELD_KEY.unit_table_dimension]: {
    label: <Trans>Unit Type</Trans>,
    key: FIELD_KEY.unit_table_dimension,
    value: '',
    error: '',
    placeholder: 'Select unit type',
    isValid: true,
    validators: [],
    touched: false,
    display: false,
    type: 'select',
    transformValue: (key) => ({ value: key, label: pipe(key, split, capitalize, join) }),
    options: [],
    size: {
      xs: 12,
      md: 4,
    },
    tabIndx: 0,
    ordinalnumbers: 8,
    dataCy: 'unit-type',
    optionDataCy: 'unit-type-item',
  },
  [FIELD_KEY.unit_table_unit]: {
    label: <Trans>Unit</Trans>,
    key: FIELD_KEY.unit_table_unit,
    value: '',
    error: '',
    isValid: true,
    display: false,
    validators: [],
    touched: false,
    type: 'select',
    transformValue: (unit) => ({ value: unit, label: pipe(unit, split, capitalize, join) }),
    options: [],
    size: {
      xs: 12,
      md: 4,
    },
    tabIndx: 0,
    ordinalnumbers: 9,
    dataCy: 'unit',
    optionDataCy: 'unit-item',
  },
  [FIELD_KEY.unit_table_amount]: {
    label: <Trans>Unit Price</Trans>,
    key: FIELD_KEY.unit_table_amount,
    value: '',
    error: '',
    placeholder: 'Add unit price',
    isValid: true,
    validators: [],
    display: false,
    touched: false,
    type: 'number',
    options: [],
    size: {
      xs: 12,
      md: 4,
    },
    tabIndx: 0,
    ordinalnumbers: 10,
    props: {
      InputProps: {
        inputProps: {
          min: 0,
        },
      },
    },
    dataCy: 'unit-price',
  },
  [FIELD_KEY.min]: {
    label: <Trans>Minimum charge</Trans>,
    placeholder: 'Optional miminum charge',
    key: FIELD_KEY.min,
    value: '',
    error: null,
    isValid: true,
    display: false,
    validators: [],
    touched: false,
    type: 'number',
    isOptional: true,
    options: [],
    size: {
      xs: 12,
      md: 4,
    },
    tabIndx: 0,
    ordinalnumbers: 11,
    props: {
      InputProps: {
        inputProps: {
          min: 0,
        },
      },
    },
    dataCy: 'min-charge',
  },
  [FIELD_KEY.max]: {
    label: <Trans>Max Charge</Trans>,
    key: FIELD_KEY.max,
    value: '',
    error: '',
    isValid: true,
    display: false,
    validators: [],
    touched: false,
    isOptional: true,
    type: 'number',
    options: [],
    size: {
      xs: 12,
      md: 2,
    },
    tabIndx: 0,
    ordinalnumbers: 12,
    props: {
      InputProps: {
        inputProps: {
          min: 0,
        },
      },
    },
    dataCy: 'max-charge',
  },
  [FIELD_KEY.condition]: {
    label: '',
    key: FIELD_KEY.condition,
    value: null,
    error: '',
    isValid: true,
    validators: [],
    touched: false,
    type: undefined,
    tabIndx: 1,
    display: false,
  },
  [FIELD_KEY.rate_tables]: {
    label: '',
    key: FIELD_KEY.rate_tables,
    value: [],
    error: '',
    isValid: true,
    touched: false,
    type: undefined,
    display: false,
  },
  [FIELD_KEY.rate_calculation_index]: {
    label: '',
    key: FIELD_KEY.rate_calculation_index,
    value: undefined,
    error: '',
    isValid: true,
    validators: [],
    touched: false,
    type: undefined,
    display: false,
  },
  [FIELD_KEY.parent_rate_calculation_index]: {
    label: '',
    key: FIELD_KEY.parent_rate_calculation_index,
    value: -1,
    error: '',
    isValid: true,
    validators: [],
    touched: false,
    type: undefined,
    display: false,
  },
  [FIELD_KEY.id]: {
    label: '',
    key: FIELD_KEY.id,
    value: undefined,
    error: '',
    isValid: true,
    validators: [],
    touched: false,
    type: undefined,
    display: false,
  },
  [FIELD_KEY.relative_calculation_type]: {
    label: '',
    key: FIELD_KEY.relative_calculation_type,
    value: null,
    error: '',
    isValid: true,
    validators: [],
    touched: false,
    type: undefined,
    display: false,
  },
  [FIELD_KEY.relative_charge_type_ids]: {
    label: '',
    key: FIELD_KEY.relative_charge_type_ids,
    value: null,
    error: '',
    isValid: true,
    validators: ['array'],
    touched: false,
    type: undefined,
    isOptional: true,
    display: false,
    tabIndx: 0,
  },
};

const MAPPED_COL_ID_BY_KEY = {
  [FIELD_KEY.rate_charge_type_id]: 'charge_code',
  [FIELD_KEY.condition]: 'temp_condition',
  [FIELD_KEY.calculation_type]: 'calculation_type_name',
};

const COLUMN_LABEL_MAPPING = {
  [FIELD_KEY.base]: <Trans>Base Amount</Trans>,
  [FIELD_KEY.condition]: <Trans>Condition</Trans>,
  [FIELD_KEY.calculation_type]: <Trans>Calculation Type</Trans>,
  [FIELD_KEY.currency]: <Trans>Currency</Trans>,
  [FIELD_KEY.description]: <Trans>Description</Trans>,
  [FIELD_KEY.unit_table_dimension]: <Trans>Unit Type</Trans>,
  [FIELD_KEY.unit_table_unit]: <Trans>Unit</Trans>,
  [FIELD_KEY.unit_table_amount]: <Trans>Unit Price</Trans>,
  [FIELD_KEY.rate_charge_type_id]: <Trans>Charge Code</Trans>,
  [FIELD_KEY.relative_charge_type_ids]: <Trans>Applied To</Trans>,
  [FIELD_KEY.relative_percentage]: <Trans>Percentage</Trans>,
  [FIELD_KEY.rate_calculation_index]: <Trans>Line No.</Trans>,
};

export function validateField(validator, value) {
  let error;
  if (validator === 'required' && !`${value ?? ''}`.trim()) {
    error = 'Field is required';
  } else if (validator === 'email' && !isValidEmail(value)) {
    error = 'Email is invalid';
  }

  return error;
}

export const financial = (x, fixedPoint = 2) => Number.parseFloat(x).toFixed(fixedPoint);

export const customEditCalcProps = (editedCalc, key) => {
  if (key === FIELD_KEY.description) {
    const { rate_charge_type_id } = editedCalc;
    const isRateChargeTypeExisted = typeof rate_charge_type_id === 'number' && rate_charge_type_id > 0;
    return { disabled: !isRateChargeTypeExisted };
  }

  return {};
};

export const handleChargeCodeChange = ({
  form,
  chargeCodeId,
  rateChargeTypes,
  tempCalculations,
  handleUpdateSumCalcs,
}) => {
  const currentRateCalcType = form[FIELD_KEY.calculation_type]?.value;
  const rateChargeTypeIdx = rateChargeTypes.findIndex((r) => r.id === chargeCodeId);

  if (rateChargeTypeIdx > -1 && rateChargeTypes[rateChargeTypeIdx].description) {
    const value = rateChargeTypes[rateChargeTypeIdx].description;
    updateDescriptionField({ form, chargeCodeId, value });
  }

  if (currentRateCalcType === CALCULATION_TYPES.SUM) {
    updateSumCalcs({ form, calcs: tempCalculations, chargeCodeId, updatedHandler: handleUpdateSumCalcs });
  }
};

const updateDescriptionField = ({ form, chargeCodeId, ...restParams }) => {
  form[FIELD_KEY.description] = {
    ...form[FIELD_KEY.description],
    disabled: !chargeCodeId,
    ...restParams,
  };
};

const updateSumCalcs = ({ form, calcs, chargeCodeId, updatedHandler }) => {
  const checkedCalcs = calcs.filter(
    (c) => c[FIELD_KEY.parent_rate_calculation_index] === form[FIELD_KEY.rate_calculation_index]?.value
  );

  const checkedPercentageCalcs = checkedCalcs.filter(isPercentageCalc);
  if (checkedPercentageCalcs.length > 0) {
    const isInValidCheckedPercentageCalc = (calc) => calc[FIELD_KEY.relative_rate_calculation_index] === chargeCodeId;

    const invalidCheckedPercentageCalcIndexes = checkedPercentageCalcs
      .filter(isInValidCheckedPercentageCalc)
      .map((c) => c[FIELD_KEY.rate_calculation_index]);

    if (invalidCheckedPercentageCalcIndexes.length > 0) {
      updatedHandler(
        checkedCalcs.filter((c) => !invalidCheckedPercentageCalcIndexes.includes(c[FIELD_KEY.rate_calculation_index]))
      );
    }
  }
};

const initializeMetadata = ({ form, rateChargeTypes, calculationTypes, variables, bases }) => {
  form[FIELD_KEY.calculation_type] = {
    ...form[FIELD_KEY.calculation_type],
    options: calculationTypes
      .filter((c) =>
        form[FIELD_KEY.parent_rate_calculation_index].value > -1
          ? c !== CALCULATION_TYPES.CLIPPING_TABLE && c !== CALCULATION_TYPES.SUM
          : c !== CALCULATION_TYPES.CLIPPING_TABLE
      )
      .map(form[FIELD_KEY.calculation_type].transformValue),
  };
  form[FIELD_KEY.rate_charge_type_id] = {
    ...form[FIELD_KEY.rate_charge_type_id],
    options: getChargeCodes([], rateChargeTypes),
  };
  form[FIELD_KEY.currency] = {
    ...form[FIELD_KEY.currency],
    options: Object.values(currencies).map(form[FIELD_KEY.currency].transformValue),
  };
  form[FIELD_KEY.unit_table_dimension] = {
    ...form[FIELD_KEY.unit_table_dimension],
    options: Object.keys(variables)
      .filter((key) => ['decimal', 'integer'].includes(variables[key]['data_type']))
      .map(form[FIELD_KEY.unit_table_dimension].transformValue),
  };
  form[FIELD_KEY.unit_table_unit] = {
    ...form[FIELD_KEY.unit_table_unit],
    options: form[FIELD_KEY.unit_table_dimension]?.value
      ? (bases[variables[form[FIELD_KEY.unit_table_dimension].value].base]?.units || []).map(
          form[FIELD_KEY.unit_table_unit].transformValue
        )
      : [],
  };
};

const updateRequiredField = ({ form, requiredField, bases, variables }) => {
  if (requiredField === FIELD_KEY.unit_table_unit) {
    const hasUnits = bases?.[variables[form[FIELD_KEY.unit_table_dimension].value]?.base]?.units;
    const hasUnitValue = hasUnits && form[FIELD_KEY.unit_table_unit]?.value;
    return {
      isDisabled: !hasUnits,
      validators: hasUnits ? ['required'] : [],
      isValid: !hasUnits || hasUnitValue,
    };
  }

  return {
    validators: ['required'],
    isValid: !validateField('required', form[requiredField].value),
  };
};

export const handleCalcTypeChange = ({
  form,
  calculationValue,
  rateChargeTypes,
  shouldResetValue = true,
  calculations,
  calculationTypes,
  variables,
  bases,
  setSelectedChargeCodeIds,
}) => {
  const { parent_rate_calculation_index, calculation_type: calcType, rate_calculation_index, id, ...restForm } = form;

  const resetBaseProps = {
    validators: [],
    isValid: true,
    touched: false,
    error: '',
  };

  Object.keys(restForm).forEach((key) => {
    const customValueObj = {};
    if (shouldResetValue && key !== FIELD_KEY.currency) {
      if (key === FIELD_KEY.rate_tables) {
        customValueObj.value = [];
      } else {
        customValueObj.value = '';
      }
    }
    form[key] = {
      ...form[key],
      ...resetBaseProps,
      ...customValueObj,
      display: false,
    };
  });

  updateDescriptionField({ form, chargeCodeId: form[FIELD_KEY.rate_charge_type_id].value });
  initializeMetadata({ form, rateChargeTypes, calculationTypes, variables, bases });

  // Check required fields and update validation correspondingly
  if (calcType.requiredFields[calculationValue]) {
    calcType.requiredFields[calculationValue].forEach((requiredKey) => {
      const customProps = form[requiredKey]?.['isOptional']
        ? {}
        : updateRequiredField({ form, requiredField: requiredKey, bases, variables });

      form[requiredKey] = {
        ...form[requiredKey],
        display: true,
        ...customProps,
      };
    });
  }

  if (calculationValue === CALCULATION_TYPES.SUM) {
    const updateListChargeCode = () => {
      const isAChildOfThisSum = (calc) =>
        calc[FIELD_KEY.parent_rate_calculation_index] === form[FIELD_KEY.rate_calculation_index]?.value;
      const percentageCalcsChildren = calculations.filter((c) => isPercentageCalc(c) && isAChildOfThisSum(c));
      const relativeChargCodeIds = percentageCalcsChildren.map((c) => c[FIELD_KEY.relative_rate_calculation_index]);
      form[FIELD_KEY.rate_charge_type_id] = {
        ...form[FIELD_KEY.rate_charge_type_id],
        options: getChargeCodes([...new Set(relativeChargCodeIds)], rateChargeTypes),
      };
    };
    updateListChargeCode();
  }

  if (calculationValue === CALCULATION_TYPES.PERCENTAGE) {
    if (form[FIELD_KEY.relative_charge_type_ids].value.length > 0) {
      form[FIELD_KEY.relative_rate_calculation_index].value = APPLIED_TO_OPTIONS[1].value;
      form[FIELD_KEY.relative_charge_type_ids].validators = ['array'];
      setSelectedChargeCodeIds(form[FIELD_KEY.relative_charge_type_ids].value);
    } else {
      form[FIELD_KEY.relative_rate_calculation_index].value = APPLIED_TO_OPTIONS[0].value;
      form[FIELD_KEY.relative_charge_type_ids].value = [];
      form[FIELD_KEY.relative_calculation_type].value = 'charge_code';
    }
  }
};

export const handleUnitTypeChange = ({ form, value, variables, bases }) => {
  const base = variables[value]?.base;
  form[FIELD_KEY.unit_table_unit] = {
    ...form[FIELD_KEY.unit_table_unit],
    value: '',
    validators: bases[base]?.units ? ['required'] : [],
    isDisabled: !bases[base]?.units,
    isValid: !bases[base]?.units,
    options: (bases[base]?.units ?? []).map(form[FIELD_KEY.unit_table_unit].transformValue),
  };
};

export const getFlatCalculator = (data) => {
  return Object.keys(data).reduce((mappedData, key) => {
    if (
      ![FIELD_KEY.condition, FIELD_KEY.rate_tables, FIELD_KEY.relative_charge_type_ids].includes(key) &&
      data?.[key] &&
      typeof data?.[key] === 'object'
    ) {
      const { currency, ...restData } = data[key];
      Object.keys(restData).forEach((subKey) => {
        switch (key) {
          case FIELD_KEY.base:
          case FIELD_KEY.min:
          case FIELD_KEY.max:
            mappedData[key] = restData[subKey];
            break;

          case FIELD_KEY.unit_table:
            mappedData[`${key}.${subKey}`] = restData[subKey];
            break;

          default:
            mappedData[subKey] = data?.[key][subKey] ?? undefined;
            break;
        }
      });
    } else {
      mappedData[key] = data[key];
    }
    return mappedData;
  }, {});
};

export const getChargeCodes = (excludedChargeCodeIds = [], chargeTypes = []) => {
  const excludedChargeCodeIdsSet = new Set(excludedChargeCodeIds);
  return chargeTypes.filter((t) => !excludedChargeCodeIdsSet.has(t.id)).map((t) => ({ value: t.id, label: t.code }));
};

export const getDataFromCalc = ({
  calc,
  rateChargeTypes = [],
  calculations,
  isReadOnly = false,
  calcActions,
  readOnlyActions,
  ...restArgs
}) => {
  const clonedCalc = cloneDeep(calc);

  if (Object.keys(calc[FIELD_KEY.condition] ?? {}).length > 0) {
    clonedCalc['temp_condition'] = isReadOnly ? (
      <ReadOnlyConditions condition={clonedCalc.condition} />
    ) : (
      <Checkbox size="small" color="primary" checked={true} disableRipple />
    );
  }

  if (isSumCalc(calc)) {
    const childrenCalcs = (calculations ?? []).filter(
      (c) => c[FIELD_KEY.parent_rate_calculation_index] === calc[FIELD_KEY.rate_calculation_index]
    );

    if (childrenCalcs.length > 0) {
      const { classes } = restArgs;

      clonedCalc['children'] = childrenCalcs.map((cCalc) => (
        <Grid key={cCalc?.[FIELD_KEY.rate_calculation_index]} item xs={12} className={classes.calculationTable}>
          <Table
            id={`${cCalc?.[FIELD_KEY.calculation_type]}_${cCalc?.[FIELD_KEY.rate_calculation_index]}`}
            columns={getColumnsFromCalc(cCalc)}
            data={getDataFromCalc({ calc: cCalc, rateChargeTypes, calculations })}
            rowActions={!isReadOnly ? calcActions(cCalc) : readOnlyActions(cCalc)}
          />
        </Grid>
      ));
    }

    clonedCalc['sum_calculation_type_name'] = (open, setOpen) => (
      <React.Fragment>
        <IconButton data-cy="expanding-sum-btn" aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
          {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
        {capitalizeFirstLetter(calc[FIELD_KEY.calculation_type])}
      </React.Fragment>
    );
  }

  return [
    {
      ...clonedCalc,
      charge_code: rateChargeTypes.find((r) => r.id === clonedCalc[FIELD_KEY.rate_charge_type_id])?.code,
      calculation_type_name: pipe(calc[FIELD_KEY.calculation_type], split, capitalize, join),
    },
  ];
};

export const handleAppliedToFieldChange = ({ appliedToValue, selectedChargeCodeIds, form }) => {
  // Updates `Selected charge codes` Validator
  // All charge codes
  if (appliedToValue === APPLIED_TO_OPTIONS[0].value) {
    form[FIELD_KEY.relative_charge_type_ids].value = [];
    form[FIELD_KEY.relative_charge_type_ids].validators = [];
    form[FIELD_KEY.relative_charge_type_ids].isValid = true;
  } else {
    // Specific charge codes
    form[FIELD_KEY.relative_charge_type_ids].isValid = selectedChargeCodeIds.length > 0;
    form[FIELD_KEY.relative_charge_type_ids].validators = ['array'];
  }
};

const isChargeCodeExisted = (form) => !!form[FIELD_KEY.rate_charge_type_id]?.value;

export const isRateTableCalc = (calc) => calc?.[FIELD_KEY.calculation_type] === CALCULATION_TYPES.RATE_TABLE;
export const isPercentageCalc = (calc) => calc?.[FIELD_KEY.calculation_type] === CALCULATION_TYPES.PERCENTAGE;
export const isSumCalc = (calc) => {
  if (typeof calc?.[FIELD_KEY.calculation_type] === 'object') {
    return calc?.[FIELD_KEY.calculation_type]?.value === CALCULATION_TYPES.SUM;
  }
  return calc?.[FIELD_KEY.calculation_type] === CALCULATION_TYPES.SUM;
};
export const isAChildCalc = (calc) => {
  if (typeof calc?.[FIELD_KEY.parent_rate_calculation_index] === 'object') {
    return calc?.[FIELD_KEY.parent_rate_calculation_index]?.value > -1;
  }
  return calc?.[FIELD_KEY.parent_rate_calculation_index] > -1;
};
export const isSumCalcItself = (calc, selectedCalcIndex) =>
  calc[FIELD_KEY.rate_calculation_index] === selectedCalcIndex;
export const isNotAChildOfSumCalc = (calc, selectedCalcIndex) =>
  isAChildCalc(calc) && selectedCalcIndex !== calc[FIELD_KEY.parent_rate_calculation_index];
export const isShowSumTab = (form) => isSumCalc(form) && isChargeCodeExisted(form);

const columnFormat = (key) => (cellVal) => {
  if (key === FIELD_KEY.relative_charge_type_ids) {
    if (Array.isArray(cellVal)) {
      if (cellVal.length <= 0) return 'All charge codes';

      return 'Specific charge codes';
    }

    return null;
  }

  return cellVal;
};

export const getColumnsFromCalc = ({
  rate_tables,
  parent_rate_calculation_index,
  relative_calculation_type,
  relative_rate_calculation_index,
  id,
  ...restProps
}) => {
  const columns = Object.keys(restProps)
    .reduce((columns, key) => {
      if (key === FIELD_KEY.condition && Object.keys(restProps[key] ?? {}).length <= 0) {
        restProps[key] = undefined;
      }

      if (restProps[key] && COLUMN_LABEL_MAPPING[key]) {
        columns.push({
          id: restProps[key] === 'sum' ? 'sum_calculation_type_name' : MAPPED_COL_ID_BY_KEY[key] || key,
          label: COLUMN_LABEL_MAPPING[key],
          ordinalnumbers:
            key === FIELD_KEY.rate_calculation_index ? 0 : RATE_CALCULATOR_FORM[key]?.['ordinalnumbers'] ?? 999,
          style: { whiteSpace: 'nowrap' },
          ...(restProps[key] === 'sum' ? {} : { format: columnFormat(key) }),
        });
      }

      columns = columns.map((c) => ({
        ...c,
        width:
          c.id === FIELD_KEY.rate_calculation_index
            ? `${columnIndexWidth}%`
            : `${(100 - columnIndexWidth) / (columns.length - 1)}%`,
      }));

      return columns;
    }, [])
    .sort((c1, c2) => c1.ordinalnumbers - c2.ordinalnumbers);

  return columns;
};
