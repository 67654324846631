import moment from 'moment-timezone';
import React from 'react';
import { Trans } from 'react-i18next';
import RRule from 'rrule';

export const repeatTypes = [
  { id: 999, name: <Trans>None</Trans> },
  { id: 3, name: <Trans>Daily</Trans> },
  { id: 2, name: <Trans>Weekly</Trans> },
];

export const repeatWeekly = [
  { name: 'Mon', value: RRule.MO },
  { name: 'Tue', value: RRule.TU },
  { name: 'Wed', value: RRule.WE },
  { name: 'Thu', value: RRule.TH },
  { name: 'Fri', value: RRule.FR },
  { name: 'Sat', value: RRule.SA },
  { name: 'Sun', value: RRule.SU },
];

export const scheduleTypes = [
  { id: 1, name: <Trans>Available</Trans> },
  { id: 2, name: <Trans>Unavailable</Trans> },
];

export const roles = [
  { id: 'admin', name: <Trans>Admin</Trans> },
  { id: 'user', name: <Trans>User</Trans> },
];

export const getScheduleTypes = (type) => {
  const AVALAIBLE = 0;
  const UNAVAILABLE = 1;

  switch (type) {
    case 'worker_on':
      return scheduleTypes[AVALAIBLE].name;
    case 'worker_off':
      return scheduleTypes[UNAVAILABLE].name;
    default:
      console.error('[WorkerSchedule]: Schedule types not found!');
      return;
  }
};

export const getFormatDate = (date, duration) => {
  if (duration) {
    const dsms = new Date(date).getTime();
    const dtEnd = new Date(dsms + duration * 1000);
    return moment(dtEnd).format('MMM-DD-YYYY - hh:mm A');
  } else {
    return moment(date).format('MMM-DD-YYYY - hh:mm A');
  }
};

export const getFormatOnlyDate = (date, duration) => {
  return moment(date).format('MMM-DD-YYYY');
};

export const getTimezoneOffsetStr = (timezone) => {
  if (!timezone) return '';
  const _moment = moment().tz(timezone);
  const _offsetInMinutes = _moment && _moment.utcOffset();
  if (!_offsetInMinutes) {
    return '';
  }
  const _absoluteOffset = Math.abs(_offsetInMinutes);
  let _offsetHour = _absoluteOffset / 60;
  if (_offsetHour < 10) {
    _offsetHour = `0${_offsetHour}`;
  }
  let _offsetMinute = _absoluteOffset % 60;
  if (_offsetMinute < 10) {
    _offsetMinute = `0${_offsetMinute}`;
  }

  return _offsetInMinutes < 0 ? `-${_offsetHour}:${_offsetMinute}` : `+${_offsetHour}:${_offsetMinute}`;
};

export const getRepeatValues = (recurrence_rule) => {
  const rule = RRule.fromString(recurrence_rule);
  return rule.origOptions.byweekday || [];
};

export const transFormedScheduleForOptimisation = (schedule) => {
  const scheduleAferTransform = transformSchedule(schedule);
  const repeatValues = getRepeatValues(schedule.recurrence_rule);
  const daysOfTheWeek = [];

  repeatWeekly.forEach((repeatValue) => {
    if (repeatValues.includes(repeatValue.value)) {
      daysOfTheWeek.push(repeatValue.name);
    }
  });

  return {
    repeat: daysOfTheWeek,
    start: scheduleAferTransform.start_repeat_datetime,
    from: scheduleAferTransform.start_event_datetime,
    to: scheduleAferTransform.end_event_datetime,
    until: scheduleAferTransform.end_repeat_datetime,
  };
};

export const transformSchedule = (schedule) => {
  const startRepeatdatetime = new Date(schedule.start_event_datetime);
  startRepeatdatetime.setHours(0, 0, 0, 0);
  const startEventDatetime = new Date(schedule.start_event_datetime);
  const endEventDatetime = new Date(schedule.end_event_datetime);
  const endRepeatDatetime = new Date(schedule.end_repeat_datetime);
  endRepeatDatetime.setHours(0, 0, 0, 0);

  let startEventDatetimeString = startEventDatetime.toLocaleTimeString('en-US', { hour12: false });
  if (startEventDatetimeString >= '24:00:00') {
    startEventDatetimeString = '00' + startEventDatetimeString.substring(2);
  }
  let endEventDatetimeString = endEventDatetime.toLocaleTimeString('en-US', { hour12: false });
  if (endEventDatetimeString >= '24:00:00') {
    endEventDatetimeString = '00' + endEventDatetimeString.substring(2);
  }

  return {
    recurrence_rule: schedule.recurrence_rule,
    start_repeat_datetime: startRepeatdatetime,
    start_event_datetime: startEventDatetimeString,
    end_event_datetime: endEventDatetimeString,
    end_repeat_datetime: endRepeatDatetime,
  };
};

export const isDateTimeRangeOverlapping = ({ dateStart1, dateEnd1, dateStart2, dateEnd2 }) => {
  return (dateStart1 >= dateStart2 && dateStart1 <= dateEnd2) || (dateStart2 >= dateStart1 && dateStart2 <= dateEnd1);
};

export const isTwoScheduleOverlapping = (schedule1, schedule2) => {
  const schedule1RRuleString = RRule.fromString(schedule1.recurrence_rule);
  const schedule2RRuleString = RRule.fromString(schedule2.recurrence_rule);

  const isDaysOfWeekOverlapping =
    schedule1RRuleString.options.byweekday?.filter((weekday) =>
      schedule2RRuleString.options.byweekday?.includes(weekday)
    ).length > 0;
  if (!isDaysOfWeekOverlapping) return false;

  const isRepeatDateRangeOverlapping = isDateTimeRangeOverlapping({
    dateStart1: schedule1.start_repeat_datetime,
    dateEnd1: schedule1.end_repeat_datetime,
    dateStart2: schedule2.start_repeat_datetime,
    dateEnd2: schedule2.end_repeat_datetime,
  });
  if (!isRepeatDateRangeOverlapping) return false;

  const isTimeRangeOverLapping = isDateTimeRangeOverlapping({
    dateStart1: schedule1.start_event_datetime,
    dateEnd1: schedule1.end_event_datetime,
    dateStart2: schedule2.start_event_datetime,
    dateEnd2: schedule2.end_event_datetime,
  });
  if (!isTimeRangeOverLapping) return false;

  return true;
};

export const getScheduleIsOverlappingWithNewSchedule = (newSchedule, schedules) => {
  const listDuplicateSchedule = [];
  for (const schedule of schedules) {
    if (
      newSchedule.id !== schedule.id &&
      (newSchedule.id > schedule.id || !newSchedule.id) &&
      isTwoScheduleOverlapping(transformSchedule(newSchedule), transformSchedule(schedule))
    ) {
      listDuplicateSchedule.push(schedule);
    }
  }

  return listDuplicateSchedule;
};
