import { put, takeLatest } from 'redux-saga/effects';

import * as workerActions from '../worker/workerActions';

export default function* sagas() {
  yield takeLatest(['LOGIN_SUCCESSFUL'], startUP);
}

function* startUP(action) {
  try {
    yield put(workerActions.listWorkers());
  } catch (error) {
    console.error(error);
  }
}
