import { clearJwtData } from '@yojee/auth/jwt-storage';
import { LocalStorage } from '@yojee/local-storage/local-storage';

const defaultState = {
  accountInfo: {
    dispatcher_companies: undefined,
    dispatcher_info: {
      company: {
        settings: null,
      },
    },
    token: undefined,
    slug: undefined,
    jwtData: undefined,
  },
  loading: {
    login: false,
    getUserInfo: false,
    setDataFromExplore: false,
  },
  isAppInjected: false,
};

const {
  location: { search },
} = window;
let isEmbedded = false;
if (search && search.indexOf('embed=true') > -1) {
  isEmbedded = true;
}

if (LocalStorage.hasItem('access_token') && !isEmbedded) {
  try {
    const loginData = {};
    loginData.token = LocalStorage.getItem('access_token');
    loginData.dispatcher_info = LocalStorage.getItem('current_user');
    loginData.dispatcher_companies = LocalStorage.getItem('dispatcher_companies');
    defaultState.accountInfo = loginData;
  } catch (e) {
    LocalStorage.removeItem('access_token');
  }
}

export const loginMessagesErrorEvents = {
  SET_DATA_FROM_EXPLORE_FAILED: ({ error }) => error?.message ?? 'Failed to set data from Explore',
  GET_USER_INFO_FAILED: ({ error }) => error?.message ?? 'Failed to get sender info',
};

export const init = () => {
  return defaultState;
};

const ACTION_HANDLERS = {
  LOGIN_SUCCESSFUL: (state, sagaData) => {
    const data = sagaData.data;
    return {
      ...state,
      accountInfo: { ...state.accountInfo, ...data },
      loading: { ...state.loading, login: false, setDataFromExplore: false },
      isAppInjected: sagaData?.isAppInjected ?? false,
    };
  },
  LOGIN_FAILED: (state, data) => {
    const { error } = data;
    return {
      ...state,
      loading: { ...state.loading, login: false },
      errorMessage: error.message,
    };
  },
  SET_DATA_FROM_EXPLORE_FAILED: (state) => {
    return {
      ...state,
      loading: { ...state.loading, setDataFromExplore: false },
      error: {
        failed: true,
      },
    };
  },
  GET_USER_INFO_SUCCESSFUL: (state, sagaData) => {
    const data = sagaData.data || null;
    return {
      ...state,
      accountInfo: { ...state.accountInfo, dispatcher_info: data },
      loading: { ...state.loading, getUserInfo: false },
    };
  },
  GET_USER_INFO_FAILED: (state) => {
    return {
      ...state,
      loading: { ...state.loading, getUserInfo: false },
      error: {
        failed: true,
      },
    };
  },
  START_LOADING: (state, sagaData) => {
    return {
      ...state,
      loading: { ...state.loading, [sagaData.loadingAction]: true },
    };
  },
  SIGN_OUT_SUCCESSFUL: () => {
    clearJwtData();
    return defaultState;
  },
  SET_SLUG_AND_TOKEN_SUCCESSFUL: (state, sagaData) => {
    const { token, slug, jwtData } = sagaData;
    return {
      ...state,
      accountInfo: {
        ...state.accountInfo,
        token,
        slug,
        jwtData,
      },
    };
  },
  SET_SLUG_AND_TOKEN_FAILED: (state, sagaData) => {
    const { error } = sagaData;
    console.log(`failed to set slug and token ${error}`);
  },
};

const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};

export default reducer;
