import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * @param {Partial<React.ComponentProps<typeof Typography>> & {children?: import('react').ReactNode}} props
 */
const Text = (props) => {
  const { variant = 'caption' } = props;
  return <Typography {...props} variant={variant} />;
};
Text.propTypes = {
  variant: PropTypes.oneOf(['caption', 'overline', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
};

export default Text;
