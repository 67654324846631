import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { ratingsService } from '@yojee/api/ratingsService';
import { MAX_INPUT_LENGTH } from '@yojee/helpers/constants';
import {
  endOfDate,
  isAfterToday,
  isBeforeToday,
  repeatFnCall,
  startOfDate,
  today,
} from '@yojee/helpers/rating/utilities';

import { RATINGS_FILTERS } from '../../utils/filtersConfig';
import { filterUpdatedFilters } from '../../utils/filtersHelper';
import { financial } from '../../utils/NewCalculatorHelper';
import { removeDeepByKey } from '../../utils/objectHelper';
import { clearRaingEntryIssues } from './ratingsActions';

export default function* sagas() {
  yield takeLatest('REQUEST_LIST_RATING_ENTRIES', listRatingEntries);
  yield takeLatest('REQUEST_RATING_METADATA', requestRatingMetadata);
  yield takeLatest('REQUEST_LIST_RATE_CHARGE_TYPES', listRateChargeTypes);
  yield takeLatest('REQUEST_FETCH_RATING_ENTRY', fetchRatingEntry);
  yield takeLatest('REQUEST_DELETE_RATING_ENTRY', deleteRatingEntry);
  yield takeLatest('REQUEST_UPDATE_RATING_ENTRY', updateRatingEntry);
  yield takeLatest('REQUEST_CREATE_RATING_ENTRY', createRatingEntry);
  yield takeLatest('REQUEST_COPY_RATING_ENTRY', copyRatingEntry);
}

export const getFilter = (state) => {
  return state.ratings.filter;
};

const getRatingEntry = (state) => {
  return state.ratings.ratingEntryData;
};

export const transformRateEntryResp = (rateCardData) => ({
  ...rateCardData,
  rate_calculators: rateCardData.rate_calculators.map((c) => {
    return {
      ...c,
      condition: c['condition']
        ? JSON.parse(
            JSON.stringify(c['condition'])
              .replace('origin_', 'origin.origin_')
              .replace('destination_', 'destination.destination_')
          )
        : null,
      ...['base', 'min', 'max'].reduce(
        (acc, key) => ({ ...acc, [key]: c[key] ? { ...c[key], amount: financial(c[key].amount, 2) } : null }),
        {}
      ),
    };
  }),
});

const transformCopyRatingEntry = (ratingEntryData, isSetExpiryDate) => {
  const { rate_calculators, partners, senders, ...baseRatingEntryData } = {
    ...ratingEntryData,
    rate_calculations: ratingEntryData['rate_calculators'],
    sender_ids: ratingEntryData['senders'].map((sender) => sender['sender_id']),
    partner_ids: ratingEntryData['partners'].map((partner) => partner['partner_id']),
  };
  let shouldUpdateOldRatingEntry = false;
  const oldRatingEntry = cloneDeep(baseRatingEntryData);
  const newRatingEntry = removeDeepByKey(cloneDeep(baseRatingEntryData), 'id');
  const oldEndDate = oldRatingEntry['end_date'] ? new Date(oldRatingEntry['end_date']) : null;

  if (oldEndDate) {
    if (isBeforeToday(oldEndDate)) {
      newRatingEntry['start_date'] = startOfDate(today());
    } else {
      newRatingEntry['start_date'] = startOfDate(moment(oldEndDate).add(1, 'days'));
    }
  } else {
    let updatedOldEndDate = today();
    const oldStartDate = new Date(oldRatingEntry['start_date']);
    if (isAfterToday(oldStartDate)) {
      updatedOldEndDate = oldStartDate;
    }
    if (isSetExpiryDate) {
      oldRatingEntry['end_date'] = endOfDate(updatedOldEndDate);
      shouldUpdateOldRatingEntry = true;
      newRatingEntry['start_date'] = startOfDate(moment(updatedOldEndDate).add(1, 'days'));
    } else {
      newRatingEntry['start_date'] = startOfDate(updatedOldEndDate);
    }
  }

  const copySuffix = ' - Copy';
  const newName = (oldRatingEntry.name + '').substring(0, MAX_INPUT_LENGTH - copySuffix.length) + copySuffix;

  return {
    oldRatingEntry,
    shouldUpdateOldRatingEntry,
    newRatingEntry: { ...newRatingEntry, end_date: null, name: newName },
  };
};

const convertAmounts = (value, currency = 'SGD') => {
  if (value && typeof value === 'object') {
    return value;
  }
  return value ? { amount: value, currency } : null;
};

const formatCondition = (condition) => {
  const [conj] = Object.keys(condition);

  // Switch to 'and' conjuntion when the condition contains only one rule.
  if (conj === 'or') {
    const [rules] = Object.values(condition);
    const hasOnlyOneRule = rules.length === 1;

    if (hasOnlyOneRule) {
      condition['and'] = rules;
      delete condition['or'];
    }
  }

  const replaceRegex = /origin\.|destination\./g;
  return JSON.parse(JSON.stringify(condition).replace(replaceRegex, ''));
};

const transformRequestEntry = (ratingEntry) => {
  return {
    ...ratingEntry,
    rate_calculations: ratingEntry['rate_calculations'].map((c) => ({
      ...c,
      condition: c['condition'] ? formatCondition(c['condition']) : null,
      rate_tables: Array.isArray(c['rate_tables'])
        ? c['rate_tables'].map((t) => {
            return {
              ...t,
              rate_dimensions: t['rate_dimensions'].map((d) => ({
                ...d,
                breakpoints: d.breakpoints.map((b) => (b === null ? '' : `${b}`)),
              })),
              breakpoint_type: t['relative_variable'] ? 'relative' : 'absolute',
              matrix: {
                data: (t['matrix']?.data ?? []).filter((m) => m.value),
              },
            };
          })
        : c['rate_tables'],
      base: convertAmounts(c.base, c['currency']),
      min: convertAmounts(c.min, c['currency']),
      max: convertAmounts(c.max, c['currency']),
      rate_charge_type_id: c['rate_charge_type_id'] === -1 ? null : c['rate_charge_type_id'],
      relative_rate_calculation_index: null,
    })),
  };
};
function* requestRatingMetadata() {
  try {
    yield put({ type: 'SET_RATING_ENTRY_LOADING', key: 'fetchRatingMetadata' });
    const metadata = yield call(ratingsService.fetchRatingMetadata);
    yield put({ type: 'FETCH_RATING_METADATA_SUCCESSFULLY', ratingMetadata: metadata });
  } catch (error) {
    yield put({ type: 'FETCH_RATING_METADATA_FAILED', error });
  }
}

function* fetchRatingEntry({ id }) {
  try {
    yield put({ type: 'SET_RATING_ENTRY_LOADING', key: 'fetchRatingEntry' });
    const rateCardData = yield call(ratingsService.fetchRating, { id });
    yield put({
      type: 'FETCH_RATING_ENTRY_SUCCESSFUL',
      ratingEntryData: transformRateEntryResp(rateCardData),
      params: { id },
    });
  } catch (error) {
    yield put({ type: 'FETCH_RATING_ENTRY_FAILED', error });
  }
}

function* listRatingEntries(action) {
  try {
    const { page, page_size, key_search, sort_name_by } = action.params;
    yield put({ type: 'SET_RATING_ENTRY_LOADING', key: 'listRatingEntries' });
    const response = yield call(ratingsService.listRateCards, {
      ...filterUpdatedFilters(action.params, RATINGS_FILTERS),
      page,
      page_size,
      key_search,
      sort_name_by,
    });
    yield put({ type: 'LIST_RATING_ENTRIES_SUCCESSFUL', ratingEntriesData: response, params: action.params });
  } catch (error) {
    yield put({ type: 'LIST_RATING_ENTRIES_FAILED', error, params: action.params });
  }
}

function* listRateChargeTypes() {
  try {
    yield put({ type: 'SET_RATING_ENTRY_LOADING', key: 'listRateChargeTypes' });
    const { data: rateEntryChargeTypesData } = yield call(ratingsService.listRateChargeTypes);
    yield put({ type: 'LIST_RATE_CHARGES_TYPES_SUCCESSFUL', rateEntryChargeTypesData });
  } catch (error) {
    yield put({ type: 'LIST_RATE_CHARGES_TYPES_FAILED', error });
  }
}

function* deleteRatingEntry(action) {
  try {
    yield put({ type: 'SET_RATING_ENTRY_LOADING', key: 'deleteRatingEntry' });
    yield call(ratingsService.delete, action.ratingEntry.id);
    yield put({ type: 'DELETE_RATING_ENTRY_SUCCESSFUL' });
    yield put({ type: 'REQUEST_LIST_RATING_ENTRIES', params: yield select(getFilter) });
  } catch (error) {
    yield put({ type: 'DELETE_RATING_ENTRY_FAILED', error });
  }
}

function* updateRatingEntry({ ratingEntry, isShowSuccessfulMesg = true }) {
  try {
    yield put({ type: 'SET_RATING_ENTRY_LOADING', key: 'updateRatingEntry' });
    const rateCardData = yield call(ratingsService.update, transformRequestEntry(ratingEntry));
    repeatFnCall(() => window.history.pushState(null, null, window.location.pathname), 2);
    yield put({
      type: 'UPDATE_RATING_ENTRY_SUCCESSFUL',
      ratingEntryData: transformRateEntryResp(rateCardData),
      isShowSuccessfulMesg,
    });
    yield put(clearRaingEntryIssues());
  } catch (error) {
    if (error.statusCode === 422) {
      yield put({
        type: 'RATING_ENTRY_ISSUES',
        issues: Object.values(error.data).flat(),
        keyLoading: 'updateRatingEntry',
      });
    } else {
      yield put({ type: 'UPDATE_RATING_ENTRY_FAILED', error });
    }
  }
}

function* createRatingEntry({ ratingEntry, isShowSuccessfulMesg = true }) {
  try {
    yield put({ type: 'SET_RATING_ENTRY_LOADING', key: 'createRatingEntry' });
    const rateCardData = yield call(ratingsService.create, transformRequestEntry(ratingEntry));
    repeatFnCall(() => window.history.pushState(null, null, window.location.pathname), 2);
    yield put({
      type: 'CREATE_RATING_ENTRY_SUCCESSFUL',
      ratingEntryData: transformRateEntryResp(rateCardData),
      isShowSuccessfulMesg,
    });
    yield put(clearRaingEntryIssues());
  } catch (error) {
    if (error.statusCode === 422) {
      yield put({
        type: 'RATING_ENTRY_ISSUES',
        issues: Object.values(error.data).flat(),
        keyLoading: 'createRatingEntry',
      });
    } else {
      yield put({ type: 'CREATE_RATING_ENTRY_FAILED', error });
    }
  }
}

function* copyRatingEntry({ id, isSetExpiryDate }) {
  try {
    yield put({ type: 'SET_RATING_ENTRY_LOADING', key: 'copyRatingEntry' });
    yield call(fetchRatingEntry, { id });
    const ratingEntryData = yield select(getRatingEntry);
    const { shouldUpdateOldRatingEntry, oldRatingEntry, newRatingEntry } = transformCopyRatingEntry(
      ratingEntryData,
      isSetExpiryDate
    );
    yield call(createRatingEntry, { ratingEntry: newRatingEntry, isShowSuccessfulMesg: false });
    if (shouldUpdateOldRatingEntry) {
      yield call(updateRatingEntry, { ratingEntry: oldRatingEntry, isShowSuccessfulMesg: false });
    }
    yield put({ type: 'COPY_RATING_ENTRY_SUCCESSFUL' });
    yield put({ type: 'REQUEST_LIST_RATING_ENTRIES', params: yield select(getFilter) });
  } catch (error) {
    yield put({ type: 'COPY_RATING_ENTRY_FAILED' });
  }
}
