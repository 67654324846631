import { call, put, takeLatest } from 'redux-saga/effects';

import { connoteService } from '../../services/connoteService';

export default function* connoteSaga() {
  yield takeLatest('REQUEST_GET_CONNOTE', getConnote);
}

function* getConnote() {
  try {
    const { data } = yield call(connoteService.getConnote);
    yield put({ type: 'REQUEST_GET_CONNOTE_SUCCESSFUL', data });
    const sections = yield call(connoteService.getConnoteSections);
    yield put({ type: 'REQUEST_GET_CONNOTE_SECTIONS_SUCCESSFUL', data: sections.data });
  } catch (error) {
    console.error(error);
    yield put({ type: 'REQUEST_GET_WORKERS_FAILED', error });
  }
}
