import { call, put, takeLatest } from 'redux-saga/effects';

import { partnerService } from '../../services/partnerService';

export default function* sagas() {
  yield takeLatest('REQUEST_LIST_PARTNERS', listPartners);
}

function* listPartners() {
  try {
    yield put({ type: 'SET_PARTNERS_LOADING', loading: true });
    const data = yield call(partnerService.listPartners);
    yield put({ type: 'LIST_PARTNERS_SUCCESSFUL', partners: data });
  } catch (error) {
    yield put({ type: 'LIST_PARTNERS_FAILED', error });
  }
}
