import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { clearMessages } from '../../../sagas/messages/actions';

const Messages = () => {
  const dispatch = useDispatch();
  const successMessage = useSelector((state) => state.messages.success);
  const errorMessage = useSelector((state) => state.messages.error);
  const warningMessage = useSelector((state) => state.messages.warning);
  const { enqueueSnackbar } = useSnackbar();

  const onCloseMessage = () => {
    dispatch(clearMessages());
  };

  useEffect(() => {
    if (errorMessage) {
      enqueueSnackbar(errorMessage, {
        variant: 'error',
        onClose: onCloseMessage,
      });
    }

    if (warningMessage) {
      enqueueSnackbar(warningMessage, {
        variant: 'warning',
        onClose: onCloseMessage,
      });
    }

    if (successMessage) {
      enqueueSnackbar(successMessage, {
        variant: 'success',
        onClose: onCloseMessage,
      });
    }
  }, [errorMessage, warningMessage, successMessage]);

  return null;
};

export default Messages;
