import { authService } from '@yojee/api/authService';
import { BaseService } from '@yojee/api/baseService';

export class ScheduleService extends BaseService {
  constructor({ authService }) {
    super();
    this.authService = authService;
  }

  getSchedulesList = (options) => {
    const apiUrl = `dispatcher/company/schedules`;

    return this.authService
      .get(this.getUmbrellaApiUrl(apiUrl), options)
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  getSchedule = (scheduleId) => {
    const apiUrl = `dispatcher/company/schedules`;

    return this.authService
      .get(`${this.getUmbrellaApiUrl(apiUrl)}/${scheduleId}`)
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  addSchedule = (schedule) => {
    const apiUrl = `dispatcher/company/schedules`;
    const body = JSON.stringify(schedule);

    return this.authService
      .post(this.getUmbrellaApiUrl(apiUrl), body)
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => {
        this.handleError(error);
      });
  };

  updateSchedule = (schedule) => {
    const apiUrl = `dispatcher/company/schedules`;
    const body = JSON.stringify(schedule);

    return this.authService
      .put(`${this.getUmbrellaApiUrl(apiUrl)}/${schedule.id}`, body)
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  deleteSchedule = (scheduleId) => {
    const apiUrl = `dispatcher/company/schedules`;

    return this.authService
      .delete(`${this.getUmbrellaApiUrl(apiUrl)}/${scheduleId}`)
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  batchUpload = ({ file, format, type }) => {
    const uploadJson = new FormData();
    uploadJson.set('file', file);
    uploadJson.set('format', format);
    uploadJson.set('type', type);

    return this.authService
      .post(this.getUmbrellaApiUrl(`dispatcher/company/schedules/batches`), uploadJson, {
        'Content-Type': 'multipart/form-data',
      })
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };

  checkBatchStatus = ({ batchId }) => {
    return this.authService
      .get(this.getUmbrellaApiUrl(`dispatcher/company/schedules/batches/${batchId}/status`))
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };

  downloadTemplateSample = ({ format, type }) => {
    return this.getUmbrellaApiUrl(`public/schedules/download_sample?format=${format}&type=${type}`);
  };
}

export const scheduleService = new ScheduleService({ authService });
