import moment from 'moment';

import { getSlugFromUrl } from '@yojee/auth/utils/AuthUtils';

import { authService } from './authService/index';
import { BaseService } from './baseService/index';
import { senderOrderService } from './senderOrderService';

export class ServiceTypesApi extends BaseService {
  constructor({ authService }) {
    super();
    this.authService = authService;
  }

  getServiceTypes = () => {
    return this.authService
      .get(this.getUmbrellaApiUrl('dispatcher/service_types'))
      .catch((error) => this.handleError(error));
  };

  getPartnerServiceType = ({ partnerCIP }) => {
    return this.authService
      .get(this.getUmbrellaApiUrl(`dispatcher/partners/${partnerCIP}/service_types`))
      .catch((error) => this.handleError(error));
  };

  getPublicOrderServiceTypes = ({ axiosCancelToken } = {}) => {
    const requestPayload = { company_slug: getSlugFromUrl() };

    return this.authService
      .get(this.getUmbrellaApiUrl('public/orders/service_types'), requestPayload, { axiosCancelToken })
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  // We use this function as a delegator
  // because the getPublicOrderServiceTypes return both non_operating_dates and service_types
  getPublicServiceTypes = ({ axiosCancelToken } = {}) => {
    return this.getPublicOrderServiceTypes({ axiosCancelToken }).then(({ service_types = [] }) => {
      return { data: { data: service_types } };
    });
  };

  _transformGetSlotsRequestData = (data) => {
    if (!data || !data.pickUpDate) {
      return null;
    }
    const { pickUpDate, timezone, serviceType, type } = data;
    const _today = timezone ? moment().startOf('day').tz(timezone) : moment();
    const _pickUpDateMoment = timezone ? moment(pickUpDate).tz(timezone) : moment(pickUpDate);
    let datetime;
    if (_pickUpDateMoment.isSame(_today, 'days')) {
      datetime = timezone ? moment().tz(timezone).toISOString(true) : moment().toISOString(true);
    } else {
      datetime = _pickUpDateMoment.startOf('day').toISOString(true);
    }

    return {
      datetime,
      service_type: serviceType ?? '',
      slug: getSlugFromUrl() ?? '',
      type: type ?? '',
    };
  };

  _transformGetSlotsResponseData = ({ data, pickUpDate, timezone }) => {
    if (!data || !pickUpDate) {
      return null;
    }
    const _datetimeObj = timezone ? moment(pickUpDate).tz(timezone) : moment(pickUpDate);
    const keys = [];

    const validTimeSlots = Object.keys(data).reduce((slots, key) => {
      const _slotGroup = data[key];

      if (_slotGroup && _slotGroup.length > 0) {
        slots[key] = _slotGroup.reduce((arr, currentSlot) => {
          //  filter out slots whose pickup date is not the same as selected date for pickup
          const _pickUpFromObj = currentSlot['pickup_from'] ? moment(currentSlot['pickup_from']) : null;
          if (timezone) {
            _pickUpFromObj.tz(timezone);
          }
          const _key = `${currentSlot['dropoff_from']}${currentSlot['dropoff_to']}${currentSlot['pickup_from']}${currentSlot['pickup_to']}`;

          if (_pickUpFromObj && _datetimeObj.isSame(_pickUpFromObj, 'day') && !keys.includes(_key)) {
            keys.push(_key);
            return [
              ...arr,
              {
                dropOffFrom: currentSlot?.['dropoff_from'] ?? '',
                dropOffTo: currentSlot?.['dropoff_to'] ?? '',
                dropOffCutoffTime: currentSlot?.['dropoff_cutoff_time'] ?? '',
                pickUpFrom: currentSlot?.['pickup_from'] ?? '',
                pickUpTo: currentSlot?.['pickup_to'] ?? '',
                pickUpCutoffTime: currentSlot?.['pickup_cutoff_time'] ?? '',
              },
            ];
          }
          return arr;
        }, []);
      }

      return slots;
    }, {});

    const sortedTimeSlots = senderOrderService.sortSlots(validTimeSlots);

    return sortedTimeSlots;
  };

  getSlots = (payload, options) => {
    const { pickUpDate, timezone } = payload;
    const data = this._transformGetSlotsRequestData(payload);
    return this.authService
      .get(this.getUmbrellaApiUrl('public/orders/get_slots'), data, options)
      .then((response) => {
        const result = this.extractData(response);
        return result ? this._transformGetSlotsResponseData({ data: result, pickUpDate, timezone }) : null;
      })
      .catch((error) => this.handleError(error));
  };
}

export const serviceTypesApi = new ServiceTypesApi({ authService });
