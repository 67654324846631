import { call, put, takeLatest } from 'redux-saga/effects';

import { integrationService } from '../../services/integrationService';
import { updateIntegrationData } from './integrationsActions';

export default function* sagas() {
  yield takeLatest('GET_INTEGRATION_TYPES', getIntegrationTypes);
  yield takeLatest('GET_PROFILE_DETAILS', getProfileDetails);
  yield takeLatest('UPDATE_INTEGRATION_PROFILE', updateIntegrationProfile);
  yield takeLatest('CREATE_INTEGRATION_PROFILE', createIntegrationProfile);
}

function* getIntegrationTypes() {
  try {
    yield put({ type: 'GET_INTEGRATION_TYPES_LOADING', key: 'getIntegrationTypes' });

    const integrationTypes = yield call(integrationService.getIntegrationTypes);

    yield put(updateIntegrationData({ integrationTypes }));

    yield put({ type: 'GET_INTEGRATION_TYPES_SUCCESSFULLY', key: 'getIntegrationTypes' });
  } catch (error) {
    yield put({ type: 'GET_INTEGRATION_TYPES_FAILED', error, key: 'getIntegrationTypes' });
  }
}

function* getProfileDetails() {
  try {
    yield put({ type: 'GET_PROFILE_DETAILS_LOADING', key: 'getProfileDetails' });

    const [profileDetails = {}] = yield call(integrationService.getAllIntegrationProfiles);

    yield put(updateIntegrationData({ profileDetails }));

    yield put({ type: 'GET_PROFILE_DETAILS_SUCCESSFULLY', key: 'getProfileDetails' });
  } catch (error) {
    yield put({ type: 'GET_PROFILE_DETAILS_FAILED', error, key: 'getProfileDetails' });
  }
}

function* createIntegrationProfile({ data }) {
  try {
    yield put({ type: 'CREATE_INTEGRATION_PROFILE_LOADING', key: 'createIntegrationProfile' });

    yield call(integrationService.createIntegrationProfiles, data);

    yield put(updateIntegrationData({ profileDetails: null }));

    yield call(getProfileDetails, { integrationType: data.type });

    yield put({ type: 'CREATE_INTEGRATION_PROFILE_SUCCESSFULLY', key: 'createIntegrationProfile' });
  } catch (error) {
    yield put({ type: 'CREATE_INTEGRATION_PROFILE_FAILED', error, key: 'createIntegrationProfile' });
  }
}

function* updateIntegrationProfile({ data }) {
  try {
    yield put({ type: 'UPDATE_INTEGRATION_PROFILE_LOADING', key: 'updateIntegrationProfile' });

    const { id, company_id, ...restData } = data;

    yield call(integrationService.updateIntegrationProfiles, { id, payload: restData });

    yield put(updateIntegrationData({ profileDetails: null }));

    yield call(getProfileDetails, { integrationType: data.type });

    yield put({ type: 'UPDATE_INTEGRATION_PROFILE_SUCCESSFULLY', key: 'updateIntegrationProfile' });
  } catch (error) {
    yield put({ type: 'UPDATE_INTEGRATION_PROFILE_FAILED', error, key: 'updateIntegrationProfile' });
  }
}
