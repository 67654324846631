import { call, put, select, takeLatest } from 'redux-saga/effects';

import { batchUpload } from '@yojee/ui/onboarding/helpers/batchUploadHelper';

import { tagService } from '../../services/tagService';

export default function* sagas() {
  yield takeLatest('REQUEST_LIST_TAGS', listTags);
  yield takeLatest('REQUEST_CREATE_TAG', createTag);
  yield takeLatest('REQUEST_UPDATE_TAG', updateTag);
  yield takeLatest('REQUEST_DELETE_TAG', deleteTag);
  yield takeLatest('TAG_BATCH_UPLOAD', tagsBatchUpload);
  yield takeLatest('DOWNLOAD_TAGS_TEMPLATE_SAMPLE', downloadTagsTemplateSample);
}

export const getFilter = (state) => {
  return state.tag.filter;
};

function* listTags(action) {
  try {
    yield put({ type: 'SET_TAG_LOADING', key: 'listTags' });
    const response = yield call(tagService.listTags, action.params);
    yield put({
      type: 'LIST_TAGS_SUCCESSFUL',
      tagData: response,
      params: action.params,
    });
  } catch (error) {
    yield put({ type: 'LIST_TAGS_FAILED', error });
  }
}

function* createTag(action) {
  try {
    yield put({ type: 'SET_TAG_LOADING', key: 'createTag' });
    const tagData = yield call(tagService.createTag, action.tag);
    yield put({ type: 'CREATE_TAG_SUCCESSFUL', tagData });
    yield put({
      type: 'REQUEST_LIST_TAGS',
      params: yield select(getFilter),
    });
  } catch (error) {
    yield put({ type: 'CREATE_TAG_FAILED', error });
  }
}

function* updateTag(action) {
  try {
    yield put({ type: 'SET_TAG_LOADING', key: 'updateTag' });
    const tagData = yield call(tagService.updateTag, action.tag);
    yield put({ type: 'UPDATE_TAG_SUCCESSFUL', tagData });
    yield put({
      type: 'REQUEST_LIST_TAGS',
      params: yield select(getFilter),
    });
  } catch (error) {
    yield put({ type: 'UPDATE_TAG_FAILED', error });
  }
}

function* deleteTag(action) {
  try {
    yield put({ type: 'SET_TAG_LOADING', key: 'deleteTag' });
    const tagData = yield call(tagService.deleteTag, action.tag.id);
    yield put({ type: 'DELETE_TAG_SUCCESSFUL', tagData });
    yield put({
      type: 'REQUEST_LIST_TAGS',
      params: yield select(getFilter),
    });
  } catch (error) {
    yield put({ type: 'DELETE_TAG_FAILED', error });
  }
}

function* tagsBatchUpload(info) {
  const { file, format } = info.uploadData;
  yield batchUpload(
    { file, format },
    {
      uploadApiMethod: tagService.batchUpload,
      statusCheckApiMethod: tagService.checkBatchStatus,
      events: {
        started: 'TAG_BATCH_UPLOAD_STARTED',
        processing: 'TAG_BATCH_UPLOAD_PROCESSING',
        succeeded: 'TAG_BATCH_UPLOAD_SUCCEEDED',
        failed: 'TAG_BATCH_UPLOAD_FAILED',
      },
    }
  );
}

function downloadTagsTemplateSample(format) {
  window.open(tagService.downloadTagsTemplateSample(format), '_self');
}
