import axios from 'axios';

import { authService } from '../authService/index';
import { BaseService } from '../baseService/index';

export class InvoiceApi extends BaseService {
  constructor({ authService }) {
    super();
    this.authService = authService;
  }

  getOrderInvoices = (orderId) => {
    return this.authService
      .get(this.getUmbrellaApiUrlV4(`company/order/${orderId}/invoices`))
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleNewErrorFormat(error));
  };

  finalizeInvoice = (invoiceId) => {
    return this.authService
      .put(this.getUmbrellaApiUrlV4(`company/invoice/${invoiceId}/finalize`))
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleNewErrorFormat(error));
  };

  invalidateInvoice = (invoiceId, reqPayload) => {
    return this.authService
      .put(this.getUmbrellaApiUrlV4(`company/invoice/${invoiceId}/invalidate`), reqPayload)
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleNewErrorFormat(error));
  };

  redoInvoice = (invoiceId) => {
    return this.authService
      .post(this.getUmbrellaApiUrlV4(`company/invoice/${invoiceId}/redo`))
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleNewErrorFormat(error));
  };

  getPDFInvoice = (invoiceNumber, params = {}) => {
    return axios
      .get(this.getUmbrellaApiUrlV4(`company/invoice/${invoiceNumber}`), {
        params,
        responseType: 'blob',
        'Content-Type': 'application/pdf',
      })
      .then((res) => (res.status === 202 ? Promise.reject(res) : Promise.resolve(res)));
  };

  getInvoiceLogs = (orderNumber) => {
    return this.authService
      .get(this.getUmbrellaApiUrlV4(`company/order/${orderNumber}/invoice_logs`))
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleNewErrorFormat(error));
  };

  generateOrderInvoices = (orderId) => {
    return this.authService
      .post(this.getUmbrellaApiUrlV4(`company/order/${orderId}/generate_invoices`))
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleNewErrorFormat(error));
  };
}

export const invoiceApi = new InvoiceApi({ authService });
