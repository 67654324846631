export const listRatingEntries = (params) => {
  return { type: 'REQUEST_LIST_RATING_ENTRIES', params };
};

export const listRateChargeTypes = () => {
  return { type: 'REQUEST_LIST_RATE_CHARGE_TYPES' };
};

export const fetchRatingEntry = (id) => {
  return { type: 'REQUEST_FETCH_RATING_ENTRY', id };
};

export const createRatingEntry = (ratingEntry) => {
  return { type: 'REQUEST_CREATE_RATING_ENTRY', ratingEntry };
};

export const updateRatingEntry = (ratingEntry) => {
  return { type: 'REQUEST_UPDATE_RATING_ENTRY', ratingEntry };
};

export const deleteRatingEntry = (ratingEntry) => {
  return { type: 'REQUEST_DELETE_RATING_ENTRY', ratingEntry };
};

export const fetchRatingMetadata = () => {
  return { type: 'REQUEST_RATING_METADATA' };
};

export const clearRaingEntries = () => {
  return { type: 'CLEAR_RATING_ENTRIES' };
};

export const clearRaingEntryIssues = () => {
  return { type: 'CLEAR_RATING_ENTRY_ISSUES' };
};

export const copyRatingEntry = (id, isSetExpiryDate) => {
  return { type: 'REQUEST_COPY_RATING_ENTRY', id, isSetExpiryDate };
};
