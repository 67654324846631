const getMappedLabelByKey = (key, label) => {
  switch (key) {
    case 'select_equals':
    case 'equal':
      return 'Is';

    case 'select_not_equals':
    case 'not_equal':
      return 'Is Not';

    case 'less':
      return 'Less Than';

    case 'less_or_equal':
      return 'Smaller Or Equal To';

    case 'greater':
      return 'Greater Than';

    case 'greater_or_equal':
      return 'Greater Or Equal To';

    default:
      return label;
  }
};
const getMappedOperators = (items, showEmptyOperators = false) => {
  return items.reduce((mappedItems, { key, label }) => {
    if (!showEmptyOperators && (key === 'is_empty' || key === 'is_not_empty')) return mappedItems;

    return [
      ...mappedItems,
      {
        value: key,
        label: getMappedLabelByKey(key, label),
      },
    ];
  }, []);
};
export { getMappedLabelByKey, getMappedOperators };
