import { authService } from '@yojee/api/authService';
import { BaseService } from '@yojee/api/baseService';

export class PartnerService extends BaseService {
  constructor({ authService }) {
    super();
    this.authService = authService;
  }

  listPartners = () => {
    const params = {
      state: 'active',
      type: 'downstream',
      include_inactive_company: false,
      page_size: 1000,
    };
    return this.authService
      .get(this.getUmbrellaApiUrl('dispatcher/partners'), params)
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };
}

export const partnerService = new PartnerService({ authService });
