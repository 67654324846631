import { authService } from '@yojee/api/authService';
import { BaseService } from '@yojee/api/baseService';

export class WorkerTeamManagementService extends BaseService {
  constructor({ authService }) {
    super();
    this.authService = authService;
  }

  getListWorkerTeams = ({ q, page, page_size }) => {
    return this.authService
      .get(this.getUmbrellaApiUrl('dispatcher/worker_teams'), {
        page,
        page_size,
        ...(q ? { q } : {}),
      })
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result : null;
      })
      .catch((error) => this.handleError(error));
  };

  getWorkerTeam = (workerTeamId) => {
    return this.authService
      .get(this.getUmbrellaApiUrl(`dispatcher/worker_teams/${workerTeamId}`))
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  getDriverByWorkerTeam = ({ q, page, page_size, workerTeamId }) => {
    return this.authService
      .get(this.getUmbrellaApiUrl(`dispatcher/worker_teams/${workerTeamId}/workers`), {
        page,
        page_size,
        ...(q ? { q } : {}),
      })
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result : null;
      })
      .catch((error) => this.handleError(error));
  };

  updateWorkerTeam = (workerTeam) => {
    return this.authService
      .put(this.getUmbrellaApiUrl(`dispatcher/worker_teams/${workerTeam.id}`), workerTeam)
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  createWorkerTeam = (workerTeam) => {
    return this.authService
      .post(this.getUmbrellaApiUrl(`dispatcher/worker_teams`), workerTeam)
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  addWorkersToTeam = (params) => {
    const { workerTeamId, workerIds } = params;
    return this.authService
      .post(this.getUmbrellaApiUrl(`dispatcher/worker_teams/${workerTeamId}/add_workers`), workerIds)
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  removeWorkersFromTeam = (params) => {
    const { workerTeamId, workerIds } = params;
    return this.authService
      .post(this.getUmbrellaApiUrl(`dispatcher/worker_teams/${workerTeamId}/remove_workers`), { worker_ids: workerIds })
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  deleteWorkerTeam = ({ workerTeamId }) => {
    return this.authService
      .delete(this.getUmbrellaApiUrl(`dispatcher/worker_teams/${workerTeamId}`))
      .then((response) => {
        const result = this.extractData(response);
        return result && result['worker_team_id'];
      })
      .catch((error) => this.handleError(error));
  };
}

export const workerTeamManagementService = new WorkerTeamManagementService({ authService });
