import { authService } from '@yojee/api/authService';
import { BaseService } from '@yojee/api/baseService';

export class VehicleService extends BaseService {
  constructor({ authService }) {
    super();
    this.authService = authService;
  }

  getVehicles = (data) => {
    return this.authService
      .get(this.getUmbrellaApiUrl(`dispatcher/company/vehicles`), data)
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };

  getVehicle = (id) => {
    return this.authService
      .get(this.getUmbrellaApiUrl(`dispatcher/company/vehicles/${id}`))
      .then((response) => {
        const result = this.extractData(response);
        return (result && result['data']) || [];
      })
      .catch((error) => this.handleError(error));
  };

  createVehicle = (data) => {
    return this.authService
      .post(this.getUmbrellaApiUrl(`dispatcher/company/vehicles`), data)
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };

  deleteVehicle = (id) => {
    return this.authService
      .delete(this.getUmbrellaApiUrl(`dispatcher/company/vehicles/${id}`))
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };

  updateVehicle = (data) => {
    return this.authService
      .put(this.getUmbrellaApiUrl(`dispatcher/company/vehicles/${data.id}`), data)
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };

  getListVehicleTypes = (params) => {
    return this.authService
      .get(this.getUmbrellaApiUrl('dispatcher/vehicle_types2'), params)
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };

  getVehicleTypeById = (vehicleId) => {
    return this.authService
      .get(this.getUmbrellaApiUrl(`dispatcher/vehicle_types2/${vehicleId}`))
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  getMasterVehicleTypes = () => {
    return this.authService
      .get(this.getUmbrellaApiUrl('dispatcher/master_vehicle_types'))
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  updateVehicleType = (vehicleType) => {
    const { id } = vehicleType;
    return this.authService
      .put(this.getUmbrellaApiUrl(`dispatcher/vehicle_types2/${id}`), vehicleType)
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : result;
      })
      .catch((error) => this.handleError(error));
  };

  createVehicleType = (vehicleType) => {
    return this.authService
      .post(this.getUmbrellaApiUrl('dispatcher/vehicle_types2'), vehicleType)
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : result;
      })
      .catch((error) => this.handleError(error));
  };

  deleteVehicleType = ({ vehicleTypeId, newVehicleTypeId }) => {
    return this.authService
      .delete(
        this.getUmbrellaApiUrl(`dispatcher/vehicle_types2/${vehicleTypeId}`),
        newVehicleTypeId
          ? {
              params: {
                new_vehicle_type_id: newVehicleTypeId,
              },
            }
          : undefined
      )
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : result;
      })
      .catch((error) => this.handleError(error));
  };

  batchUpload = ({ file, format }) => {
    const uploadJson = new FormData();
    uploadJson.set('file', file);
    uploadJson.set('format', format);

    return this.authService
      .post(this.getUmbrellaApiUrl(`dispatcher/company/vehicles/batches`), uploadJson, {
        'Content-Type': 'multipart/form-data',
      })
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };

  checkBatchStatus = ({ batchId }) => {
    return this.authService
      .get(this.getUmbrellaApiUrl(`dispatcher/company/vehicles/batches/${batchId}/status`))
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };

  downloadVehicleTemplateSample = ({ format }) => {
    return this.getUmbrellaApiUrl(`public/vehicles/download_sample?format=${format}`);
  };
}

const vehicleService = new VehicleService({ authService });
export default vehicleService;
