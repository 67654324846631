const defaultState = {
  loading: {},
  info: {
    subTaskRules: [],
  },
};
export const init = () => {
  return defaultState;
};

export const driverProcessMessagesSuccessEvents = {
  REQUEST_UPDATE_SUB_TASK_RULE_SUCCESS: 'Task action updated successfully',
  REQUEST_DELETE_SUB_TASK_RULE_SUCCESS: 'Task action(s) removed successfully',
  REQUEST_CREATE_SUB_TASK_RULE_SUCCESS: 'Task action(s) added successfully',
};

export const driverProcessMessagesErrorEvents = {
  REQUEST_UPDATE_SUB_TASK_RULE_ERROR: ({ error }) =>
    `Failed to update task action: ${error.statusCode} : ${error.message}`,
  REQUEST_DELETE_SUB_TASK_RULE_ERROR: 'Failed to delete task action',
  REQUEST_CREATE_SUB_TASK_RULE_ERROR: ({ error }) =>
    `Failed to create task action: ${error.statusCode} : ${error.message}`,
};

const ACTION_HANDLERS = {
  REQUEST_GET_SUB_TASK_RULE_SUCCESS: (state, data) => {
    return {
      ...state,
      info: { ...state.info, subTaskRules: data.result },
    };
  },
};

const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};

export default reducer;
