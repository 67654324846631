import { authService } from '@yojee/api/authService';
import { BaseService } from '@yojee/api/baseService';

export class DispatchRuleService extends BaseService {
  constructor({ authService }) {
    super();
    this.authService = authService;
  }

  listDispatchRules = ({ page, page_size }) => {
    return this.authService
      .get(this.getUmbrellaApiUrl('dispatcher/dispatch_rule'), { page, page_size })
      .then((response) => {
        return this.extractData(response);
      })
      .catch((error) => this.handleError(error));
  };

  createDispatchRule = (rule) => {
    return this.authService
      .post(this.getUmbrellaApiUrl('dispatcher/dispatch_rule'), rule)
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };
  updateDispatchRule = (rule) => {
    return this.authService
      .put(this.getUmbrellaApiUrl('dispatcher/dispatch_rule/' + rule.id), rule)
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };
  deleteDispatchRule = (id) => {
    return this.authService
      .delete(this.getUmbrellaApiUrl('dispatcher/dispatch_rule/' + id))
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };
}

export const dispatchRuleService = new DispatchRuleService({ authService });
