import { call, put, select, takeLatest } from 'redux-saga/effects';

import { userService } from '@yojee/api/userService';
import { decodeJwtData } from '@yojee/auth/jwt-storage';

import { batchUpload } from '../../helpers/batchUploadHelper';

export const getUsersInfo = (state) => state.users.info;

export function* UIUserManagementSagas() {
  yield takeLatest('UI_USER_MANAGEMENT_REQUEST_GET_USERS', requestGetUsers);
  yield takeLatest('UI_USER_MANAGEMENT_REQUEST_CREATE_USER', requestCreateUser);
  yield takeLatest('UI_USER_MANAGEMENT_REQUEST_UPDATE_USER', requestUpdateUser);
  yield takeLatest('UI_USER_MANAGEMENT_REQUEST_DELETE_USER', requestDeleteUser);
  yield takeLatest('UI_USER_MANAGEMENT_REQUEST_GET_FILTERS', requestGetFilters);
  yield takeLatest('UI_USER_MANAGEMENT_REQUEST_GET_ROLES', requestGetRoles);
  yield takeLatest('UI_USER_MANAGEMENT_REQUEST_GET_ENTERPRISE_PERMISSIONS', requestGetEnterprisePermissions);
  yield takeLatest('UI_USER_MANAGEMENT_REQUEST_CREATE_ROLE', requestCreateRole);
  yield takeLatest('UI_USER_MANAGEMENT_REQUEST_UPDATE_ROLE', requestUpdateRole);
  yield takeLatest('UI_USER_MANAGEMENT_REQUEST_DELETE_ROLE', requestDeleteRole);
  yield takeLatest('UI_USER_MANAGEMENT_DOWNLOAD_USER_TEMPLATE_SAMPLE', downloadUserTemplateSample);
  yield takeLatest('UI_USER_MANAGEMENT_BATCH_UPLOAD', requestBatchUploadEnterpriseUser);
}

function* requestGetUsers(action) {
  try {
    yield put({ type: 'UI_USER_MANAGEMENT_START_PROGRESS', key: 'requestGetUsers' });
    const { data, pagination } = yield call(userService.getUsers, action.payload);
    yield put({ type: 'UI_USER_MANAGEMENT_REQUEST_GET_USERS_SUCCESSFUL', data, pagination, q: action.payload.q });
  } catch (error) {
    yield put({ type: 'UI_USER_MANAGEMENT_SET_ERROR', message: error.message, key: 'requestGetUsers' });
  }
}

function* requestUpdateUser(action) {
  try {
    yield put({ type: 'UI_USER_MANAGEMENT_START_PROGRESS', key: 'requestUpdateUser' });
    yield call(userService.updateUser, action.payload);
    const info = yield select(getUsersInfo);
    yield put({
      type: 'UI_USER_MANAGEMENT_REQUEST_GET_USERS',
      payload: {
        q: info.q,
        page: info?.pagination?.current_page || 1,
        page_size: info?.pagination?.limit_value || 25,
      },
    });
    yield put({ type: 'UI_USER_MANAGEMENT_REQUEST_UPDATE_USER_SUCCESSFUL' });
  } catch (error) {
    yield put({ type: 'UI_USER_MANAGEMENT_SET_ERROR', message: error.message, key: 'requestUpdateUser' });
  }
}

function* requestCreateUser(action) {
  try {
    yield put({ type: 'UI_USER_MANAGEMENT_START_PROGRESS', key: 'requestCreateUser' });
    yield call(userService.createUser, action.payload);
    const info = yield select(getUsersInfo);
    yield put({
      type: 'UI_USER_MANAGEMENT_REQUEST_GET_USERS',
      payload: {
        q: info.q,
        page: info?.pagination?.current_page || 1,
        page_size: info?.pagination?.limit_value || 25,
      },
    });
    yield put({ type: 'UI_USER_MANAGEMENT_REQUEST_CREATE_USER_SUCCESSFUL' });
  } catch (error) {
    yield put({ type: 'UI_USER_MANAGEMENT_SET_ERROR', message: error.message, key: 'requestCreateUser' });
  }
}

function* requestDeleteUser(action) {
  try {
    yield put({ type: 'UI_USER_MANAGEMENT_START_PROGRESS', key: 'requestDeleteUser' });
    yield call(userService.deleteUser, action.payload);
    const info = yield select(getUsersInfo);
    yield put({
      type: 'UI_USER_MANAGEMENT_REQUEST_GET_USERS',
      payload: {
        q: info.q,
        page: info?.pagination?.current_page || 1,
        page_size: info?.pagination?.limit_value || 25,
      },
    });
    yield put({ type: 'UI_USER_MANAGEMENT_REQUEST_DELETE_USER_SUCCESSFUL' });
  } catch (error) {
    yield put({ type: 'UI_USER_MANAGEMENT_SET_ERROR', message: error.message, key: 'requestDeleteUser' });
  }
}

function* requestGetFilters() {
  try {
    yield put({ type: 'UI_USER_MANAGEMENT_START_PROGRESS', key: 'requestGetFilters' });
    const { data } = yield call(userService.getFilters);
    yield put({ type: 'UI_USER_MANAGEMENT_REQUEST_GET_FILTERS_SUCCESSFUL', data });
  } catch (error) {
    yield put({ type: 'UI_USER_MANAGEMENT_SET_ERROR', message: error.message, key: 'requestGetFilters' });
  }
}

function* requestGetRoles(action) {
  try {
    yield put({ type: 'UI_USER_MANAGEMENT_START_PROGRESS', key: 'requestGetRoles' });
    const params = action?.payload || {
      page: 1,
      page_size: 25,
    };
    const { data } = yield call(userService.getRoles, params);
    yield put({ type: 'UI_USER_MANAGEMENT_REQUEST_GET_ROLES_SUCCESSFUL', data });
  } catch (error) {
    yield put({ type: 'UI_USER_MANAGEMENT_SET_ERROR', message: error.message, key: 'requestGetRoles' });
  }
}

function* requestGetEnterprisePermissions() {
  try {
    yield put({ type: 'UI_USER_MANAGEMENT_START_PROGRESS', key: 'requestGetRoles' });
    const { data } = yield call(userService.getEnterprisePermissions);
    yield put({ type: 'UI_USER_MANAGEMENT_REQUEST_GET_ENTERPRISE_PERMISSIONS_SUCCESSFUL', data });
  } catch (error) {
    yield put({ type: 'UI_USER_MANAGEMENT_SET_ERROR', message: error.message, key: 'requestGetRoles' });
  }
}

function* requestCreateRole(action) {
  try {
    yield put({ type: 'UI_USER_MANAGEMENT_START_PROGRESS', key: 'requestCreateRole' });
    const Authorization = yield call(_getAuthorizationForRelatedCompany, action.payload.slug);
    yield call(userService.createRole, { ...action.payload, Authorization });
    yield put({ type: 'UI_USER_MANAGEMENT_REQUEST_GET_ROLES', payload: action.pagination });
    yield put({ type: 'UI_USER_MANAGEMENT_REQUEST_CREATE_ROLE_SUCCESSFUL' });
  } catch (error) {
    yield put({ type: 'UI_USER_MANAGEMENT_SET_ERROR', message: error.message, key: 'requestCreateRole' });
  }
}

function* requestUpdateRole(action) {
  try {
    yield put({ type: 'UI_USER_MANAGEMENT_START_PROGRESS', key: 'requestUpdateRole' });
    const Authorization = yield call(_getAuthorizationForRelatedCompany, action.payload.slug);
    yield call(userService.updateRole, { ...action.payload, Authorization });
    yield put({ type: 'UI_USER_MANAGEMENT_REQUEST_GET_ROLES', payload: action.pagination });
    yield put({ type: 'UI_USER_MANAGEMENT_REQUEST_UPDATE_ROLE_SUCCESSFUL' });
  } catch (error) {
    yield put({ type: 'UI_USER_MANAGEMENT_SET_ERROR', message: error.message, key: 'requestUpdateRole' });
  }
}

function* requestDeleteRole(action) {
  try {
    yield put({ type: 'UI_USER_MANAGEMENT_START_PROGRESS', key: 'requestDeleteRole' });
    const Authorization = yield call(_getAuthorizationForRelatedCompany, action.payload.slug);
    yield call(userService.deleteRole, { ...action.payload, Authorization });
    yield put({ type: 'UI_USER_MANAGEMENT_REQUEST_GET_ROLES', payload: action.pagination });
    yield put({ type: 'UI_USER_MANAGEMENT_REQUEST_DELETE_ROLE_SUCCESSFUL' });
  } catch (error) {
    yield put({ type: 'UI_USER_MANAGEMENT_SET_ERROR', message: error.message, key: 'requestDeleteRole' });
  }
}

function* _getAuthorizationForRelatedCompany(slug) {
  try {
    const { data } = yield call(userService.getTokenForRelatedCompany, { slug: slug });
    const jwtData = decodeJwtData(data.jwt_tokens);
    return 'Bearer ' + jwtData.access_token;
  } catch (error) {
    return null;
  }
}

function* downloadUserTemplateSample(action) {
  try {
    yield;
    const { format } = action.payload;
    window.open(userService.downloadUserTemplateSample({ format }), '_self');
  } catch (error) {
    console.error('downloadUserTemplateSample error', error);
    return null;
  }
}

function* requestBatchUploadEnterpriseUser(action) {
  try {
    const { file, format } = action.payload;
    yield batchUpload(
      { file, format },
      {
        uploadApiMethod: userService.batchUpload,
        statusCheckApiMethod: userService.checkBatchStatus,
        events: {
          started: 'ENTERPRISE_BATCH_UPLOAD_STARTED',
          processing: 'ENTERPRISE_BATCH_UPLOAD_PROCESSING',
          succeeded: 'ENTERPRISE_BATCH_UPLOAD_SUCCEEDED',
          failed: 'ENTERPRISE_BATCH_UPLOAD_FAILED',
        },
      }
    );
  } catch (error) {
    console.error('Batch Upload: error', error);
    return null;
  }
}
