import { authService } from '@yojee/api/authService';
import { BaseService } from '@yojee/api/baseService';

export class BulkUpdateService extends BaseService {
  constructor({ authService }) {
    super();
    this.authService = authService;
  }

  fetchData = ({ path, type, data }) => {
    switch (type) {
      case 'GET':
        return this.authService
          .get(this.getUmbrellaApiUrl(path), data)
          .then((response) => {
            const result = this.extractData(response);
            return result && result['data'] ? result['data'] : null;
          })
          .catch((error) => this.handleError(error));
    }
  };

  checkDeleteProcess = ({ path, type }) => {
    switch (type) {
      case 'GET':
        return this.authService
          .get(this.getUmbrellaApiUrl(path))
          .then((response) => {
            const result = this.extractData(response);
            return result ? result : null;
          })
          .catch((error) => this.handleError(error));
    }
  };

  postData = ({ path, type, ids, options }) => {
    return this.authService
      .post(this.getUmbrellaApiUrl(path), { type, ids }, options)
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  deleteData = ({ path, type, ids }) => {
    return this.authService
      .delete(this.getUmbrellaApiUrl(path), { params: { type, ids } })
      .then((response) => {
        const result = this.extractData(response);
        return result ? result : null;
      })
      .catch((error) => this.handleError(error));
  };

  updateAllFields = ({ path, ids, params, type, options }) => {
    return this.authService
      .put(this.getUmbrellaApiUrl(path), { ids, params, type }, options)
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  downloadMasterData = (data) => {
    return this.authService
      .post(this.getUmbrellaApiUrl('dispatcher/master_data/download'), data)
      .then((response) => {
        return this.extractData(response);
      })
      .catch((error) => this.handleError(error));
  };
}

export const bulkUpdateService = new BulkUpdateService({ authService });
