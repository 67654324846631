import { t } from 'i18next';

import {
  batchUploadDefaultState,
  generateBatchUploadReducerMethods,
} from '@yojee/ui/onboarding/helpers/batchUploadHelper';

const defaultState = {
  status: 'normal',
  createUpdateStatus: 'normal',
  list: [],
  pagination: {
    current_page: 1,
    limit_value: 25,
    total_count: 0,
    total_pages: 0,
  },

  openAddExceptionDaliog: false,
  editException: undefined,

  openConfirmDeleteExceptionDiaglog: false,
  deleteException: undefined,
  batchUpload: batchUploadDefaultState,
};

export const taskExceptionMessagesSuccessEvents = {
  CREATE_TASK_EXCEPTION_SUCCESS: 'Report reason create successfully',
  UPDATE_TASK_EXCEPTION_SUCCESS: 'Report reason updated successfully',
  DELETE_TASK_EXCEPTION_SUCCESS: 'Report reason delete successfully',
  BULK_REQUEST_DELETE_TASK_EXCEPTION_SUCCESSFUL: 'Report reasons deleted successfully',
  REQUEST_DELETE_TASK_EXCEPTION_IN_PROCESS: 'Report reasons deleted processing',
  TASK_EXCEPTION_BATCH_UPLOAD_SUCCEEDED: t('Batch upload completed'),
};

export const taskExceptionMessagesErrorEvents = {
  CREATE_TASK_EXCEPTION_ERROR: ({ error }) => (error ? error : 'Failed to create task exception'),
  UPDATE_TASK_EXCEPTION_ERROR: ({ error }) => (error ? error : 'Failed to update task exception'),
  DELETE_TASK_EXCEPTION_ERROR: ({ error }) => (error ? error : 'Failed to delete task exception'),
  BULK_REQUEST_DELETE_TASK_EXCEPTION_FAIL: ({ payload }) => (payload ? payload : `Failed to delete task exception!`),
};

export const init = () => {
  return defaultState;
};

const ACTION_HANDLERS = {
  SET_CREATE_UPDATE_STATUS: (state, { payload }) => {
    return { ...state, createUpdateStatus: payload };
  },
  GET_LIST_EXCEPTIONS: (state) => {
    return { ...state, status: 'loading' };
  },
  GET_LIST_EXCEPTIONS_SUCCESS: (state, data) => {
    return {
      ...state,
      list: data.result.data,
      pagination: data.result.pagination,
      status: 'normal',
    };
  },
  GET_LIST_EXCEPTIONS_ERROR: (state, { error }) => {
    return { ...state, errorMessage: error, status: 'normal' };
  },
  RESET_TASK_EXCEPTION: (state) => {
    return { ...state, editException: { description: '', reason_code: '', selectedOption: [] } };
  },
  OPEN_ADD_EXCEPTION_DIALOG: (state, { exception }) => {
    return { ...state, openAddExceptionDaliog: true, editException: exception };
  },
  OPEN_CONFIRM_DELETE_TASK_EXCEPTION: (state, { exception }) => {
    return {
      ...state,
      openConfirmDeleteExceptionDiaglog: true,
      deleteException: exception,
    };
  },
  CLOSE_CONFIRM_DELETE_TASK_EXCEPTION: (state) => {
    return {
      ...state,
      openConfirmDeleteExceptionDiaglog: false,
      deleteException: undefined,
    };
  },
  CREATE_TASK_EXCEPTION_SUCCESS: (state) => {
    return {
      ...state,
      openAddExceptionDaliog: false,
      editException: undefined,
    };
  },
  CREATE_TASK_EXCEPTION_ERROR: (state) => {
    return {
      ...state,
      createUpdateStatus: 'normal',
      editException: undefined,
    };
  },
  UPDATE_TASK_EXCEPTION_SUCCESS: (state) => {
    return {
      ...state,
      openAddExceptionDaliog: false,
      editException: undefined,
    };
  },
  UPDATE_TASK_EXCEPTION_ERROR: (state) => {
    return {
      ...state,
      createUpdateStatus: 'normal',
      editException: undefined,
    };
  },
  DELETE_TASK_EXCEPTION_SUCCESS: (state) => {
    return {
      ...state,
      openConfirmDeleteExceptionDiaglog: false,
      deleteException: undefined,
    };
  },
  DELETE_TASK_EXCEPTION_ERROR: (state) => {
    return {
      ...state,
      openConfirmDeleteExceptionDiaglog: false,
      deleteException: undefined,
    };
  },
  CLOSE_ADD_EXCEPTION_DIALOG: (state) => {
    return { ...state, openAddExceptionDaliog: false };
  },
  BULK_REQUEST_DELETE_TASK_EXCEPTION_SUCCESSFUL: (state, { payload }) => {
    return {
      ...state,
      list: state.list.filter((s) => !payload.includes(s.id)),
    };
  },
  BULK_REQUEST_DELETE_TASK_EXCEPTION_FAIL: (state) => {
    return {
      ...state,
      isLoading: false,
    };
  },
  ...generateBatchUploadReducerMethods({ prefix: 'TASK_EXCEPTION' }),
};

const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};

export default reducer;
