import { call, put, takeLatest } from 'redux-saga/effects';

import { financeService } from '../../services/financeService';

export default function* sagas() {
  yield takeLatest('REQUEST_GET_VAS', requestGetVAS);
  yield takeLatest('REQUEST_CREATE_VAS', requestCreateVAS);
  yield takeLatest('REQUEST_UPDATE_VAS', requestUpdateVAS);
  yield takeLatest('REQUEST_TOGGLE_VAS_STATUS', requestToggleVASStatus);
}

function* requestGetVAS({ payload: { page, page_size, q } }) {
  try {
    yield put({ type: 'SET_VAS_IN_PROGRESS', key: 'getVAS' });
    const { data, pagination } = yield call(financeService.getVAS, { page, page_size, q });
    yield put({ type: 'REQUEST_GET_VAS_SUCCESSFUL', data, pagination, q });
  } catch (error) {
    yield put({ type: 'REQUEST_GET_VAS_FAILED', error });
  }
}

function* requestCreateVAS({ payload }) {
  try {
    yield put({ type: 'SET_VAS_IN_PROGRESS', key: 'createVAS' });
    const data = yield call(financeService.createVAS, payload);
    yield put({ type: 'REQUEST_CREATE_VAS_SUCCESSFUL', ...data });
  } catch (error) {
    yield put({ type: 'REQUEST_CREATE_VAS_FAILED', error });
  }
}

function* requestUpdateVAS({ payload }) {
  try {
    yield put({ type: 'SET_VAS_IN_PROGRESS', key: 'updateVAS' });
    const data = yield call(financeService.updateVAS, payload);
    yield put({ type: 'REQUEST_UPDATE_VAS_SUCCESSFUL', ...data });
  } catch (error) {
    yield put({ type: 'REQUEST_UPDATE_VAS_FAILED', error });
  }
}

function* requestToggleVASStatus({ payload: { id, isActive } }) {
  try {
    yield put({ type: 'REQUEST_TOGGLE_VAS_STATUS_IN_PROGRESS', id });
    yield call(isActive ? financeService.deactivateVAS : financeService.activateVAS, { id });
    yield put({ type: 'REQUEST_TOGGLE_VAS_STATUS_SUCCESSFUL', isActive, id });
  } catch (error) {
    yield put({ type: 'REQUEST_TOGGLE_VAS_STATUS_FAILED', error, id });
  }
}
