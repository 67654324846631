import moment from 'moment-timezone';

import { getSlugFromUrl } from '@yojee/auth/utils/AuthUtils';
import { convertUnderscoreToCamel, isObjectEmpty, parsePrice, parseString } from '@yojee/helpers/string-helper';
import { DATE_TIME_FORMAT, parseISO8601Date } from '@yojee/helpers/time-helper';

import { authService } from './authService/index';
import { BookingBaseService } from './baseService/bookingBaseService';

export class SenderOrderService extends BookingBaseService {
  constructor({ authService }) {
    super();
    this.authService = authService;
  }

  createOrder = (payload, options) => {
    const newPayload = this._transformCreateOrderRequestData(payload);
    return this.authService
      .post(this.getUmbrellaApiUrl('sender/orders'), JSON.stringify(newPayload), options)
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? this._transformCreateOrderResponseData(result['data']) : null;
      })
      .catch((error) => this.handleError(error));
  };

  validateOrder = (payload, options) => {
    const newPayload = this._transformValidateOrderRequestData(payload);
    return this.authService
      .post(this.getUmbrellaApiUrl('public/orders/validate'), JSON.stringify(newPayload), options)
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? this._transformValidateOrderResponseData(result['data']) : null;
      })
      .catch((error) => this.handleError(error));
  };

  _transformValidateOrderResponseData = (data) => {
    const errors = [];
    if (data && data.errors && data.errors.items) {
      const { items } = data.errors;
      Object.keys(items).forEach((key) => {
        const error = this._transformItemError(items[key]);
        if (error) {
          error.idx = parseInt(key);
          errors.push(error);
        }
      });
    }
    return errors;
  };

  _transformItemError = (data) => {
    if (!data) {
      return null;
    }
    if (data.steps) {
      return {
        steps: Object.keys(data.steps).reduce((res, key) => {
          res[key] = Object.keys(data.steps[key]).reduce((stepErrors, errorKey) => {
            const errObj = data.steps[key][errorKey];
            const _msg = errObj.msg || '';
            const _key = convertUnderscoreToCamel(errorKey);
            stepErrors[_key] = [`${errorKey.replace('_', ' ')} ${_msg}`];
            return stepErrors;
          }, {});
          return res;
        }, {}),
      };
    }
    return null;
  };

  _transformValidateOrderRequestData = (data) => {
    if (!data || data.length < 1) {
      return;
    }
    const items = data.map((item) => {
      const volume = parseFloat(item.length) * parseFloat(item.height) * parseFloat(item.width);
      const { selectedSlot } = item;
      const pickUpFrom = selectedSlot && selectedSlot.pickUpFrom ? selectedSlot.pickUpFrom : null;
      const pickUpTo = selectedSlot && selectedSlot.pickUpTo ? selectedSlot.pickUpTo : null;
      const dropOffFrom = selectedSlot && selectedSlot.dropOffFrom ? selectedSlot.dropOffFrom : null;
      const dropOffTo = selectedSlot && selectedSlot.dropOffTo ? selectedSlot.dropOffTo : null;

      return {
        item_info: {
          quantity: parseString(item.quantity),
          service_type: parseString(item.serviceType),
          payload_type: parseString(item.itemType),
          description: parseString(item.description),
          info: parseString(item.additionalInfo),
          width: parseFloat(item.width),
          height: parseFloat(item.height),
          length: parseFloat(item.length),
          weight: parseFloat(item.weight),
          price_amount: parseString(item.priceAmount),
          price_info: parseString(item.priceInfo || ''),
          volume,
          volumetric_weight: volume / 5000 > 1 ? volume / 5000 : 1,
          external_customer_id: parseString(item.externalID1),
          external_customer_id2: parseString(item.externalID2),
          external_customer_id3: parseString(item.externalID3),
          cod_price_amount: parseString(item.cod),
          // cod_price_currency : parseString(),
        },
        steps: item.tasks.map((task) => {
          return {
            type: parseString(task.type),
            address: parseString(task.addressLine1),
            address2: parseString(task.addressLine2),
            contact_company: parseString(task.contactCompany),
            contact_email: parseString(task.contactEmail),
            contact_name: parseString(task.contactName),
            contact_phone: task.contactPhone ? `${task.contactAreaCode}${task.contactPhone}` : '',
            country: parseString(task.country),
            city: parseString(task.city),
            from_time: task.type === 'pickup' ? pickUpFrom : dropOffFrom,
            lat: parseString(task.lat),
            lng: parseString(task.lng),
            postal_code: parseString(task.postalCode),
            state: parseString(task.state),
            to_time: task.type === 'pickup' ? pickUpTo : dropOffTo,
          };
        }),
      };
    });

    return {
      items,
    };
  };

  createSingleItemOrder = (payload, options) => {
    const newPayload = this._transformCreateSingleItemOrderRequestData(payload);
    return this.authService
      .post(this.getUmbrellaApiUrl('sender/orders'), JSON.stringify(newPayload), options)
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? this._transformCreateOrderResponseData(result['data']) : null;
      })
      .catch((error) => this.handleError(error));
  };

  createMultilegOrder = (payload, options) => {
    const newPayload = this._transformCreateMultilegOrderRequestData(payload);
    return this.authService
      .post(this.getUmbrellaApiUrl('sender/orders_multi_leg'), JSON.stringify(newPayload), options)
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? this._transformCreateOrderResponseData(result['data']) : null;
      })
      .catch((error) => this.handleError(error));
  };

  v4Pay = (payload, options) => {
    const newPayload = this._transformDataForPaymentRequestV4(payload);
    return this.authService
      .post(this.getUmbrellaApiUrlV4(`sender/orders/payments`), JSON.stringify(newPayload), options)
      .then((response) => {
        return response['message'] ? response['message'] : null;
      })
      .catch((error) => this.handleNewErrorFormat(error));
  };

  getOrderInvoiceUrl = (orderTrackingNumber, format = 'pdf') => {
    return this.getUmbrellaApiUrl(`public/invoices/${orderTrackingNumber.trim()}?format=${format}`);
  };

  getOrderTrackingPageUrl = ({ trackingNumber, type = 'order' }) => {
    const slug = getSlugFromUrl();
    const ENV = this._getEnv();
    const subDomainByENVMap = {
      production: 'track',
      development: `track-dev`,
      eks: `tracking-qa`,
    };

    const domainPrefix = slug ? `${slug}.` : '';
    const subDomain = subDomainByENVMap[ENV] || `track-${ENV}`;

    return `https://${domainPrefix}${subDomain}.yojee.com/${type}/${trackingNumber}`;
  };

  getSlots = (payload, options) => {
    const { pickUpDate, timezone } = payload;
    const newPayload = this._transformGetSlotsRequestData(payload);
    return this.authService
      .get(this.getUmbrellaApiUrl('public/orders/get_slots'), newPayload, options)
      .then((response) => {
        const result = this.extractData(response);
        return result ? this._transformGetSlotsResponseData({ data: result, pickUpDate, timezone }) : null;
      })
      .catch((error) => this.handleError(error));
  };

  getItemsPrice = ({ items, slug }, options) => {
    const payload = this._transformGetItemsPriceRequestData(items, slug);
    return this.authService
      .post(this.getUmbrellaApiUrl('public/orders/get_prices'), JSON.stringify(payload), options)
      .then((response) => {
        const result = this.extractData(response);
        return result ? this._transformGetItemsPriceResponseData(result) : null;
      })
      .catch((error) => this.handleError(error));
  };

  //  APIs for hub-operation use case
  getServiceHubsForLocation = ({ lat, lng, company_slug }, options) => {
    return this.authService
      .get(this.getUmbrellaApiUrl('public/orders/get_service_hubs'), { lat, lng, company_slug }, options)
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  getCapacityDataForHub = ({ slug, hub_id, from_time, to_time }) => {
    return this.authService
      .get(this._getCapacityMicroserviceUrl(), { slug, hub_id, from_time, to_time }, { noSlug: true })
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? this._processCapacityDataResponse(result['data']) : [];
      })
      .catch((error) => this.handleError(error));
  };

  getFromAndToTimeForGetCapacityDataRequest = ({ startHour, endHour, pickUpDate, timezone }) => {
    if (!pickUpDate) return { from_time: null, to_time: null };
    const _date = timezone ? moment(pickUpDate).tz(timezone) : moment(pickUpDate);
    let _fromTime = null;
    let _toTime = null;
    if (startHour && endHour) {
      _date.set({ minute: 0, second: 0 });
      _fromTime = _date.hour(startHour).toISOString();
      _toTime = _date.hour(endHour).toISOString();
    } else {
      _fromTime = _date.startOf('day').toISOString();
      _toTime = _date.endOf('day').toISOString();
    }
    return {
      from_time: _fromTime,
      to_time: _toTime,
    };
  };

  //  remove slots with max capacity reached
  filterSlotsUsingCapacity = ({ slots, hubCapacityData }) => {
    if (!slots || Object.keys(slots).length === 0) return {};
    if (!hubCapacityData || hubCapacityData.length === 0) return slots;

    return Object.keys(slots).reduce((result, key) => {
      const _slotData = slots[key];

      if (!_slotData || _slotData.length === 0) {
        return result;
      }
      const _pickUpFrom = _slotData[0]['pickUpFrom'] || '';
      const _pickUpTo = _slotData[0]['pickUpTo'] || '';
      if (!_pickUpFrom || !_pickUpTo) {
        return result;
      }
      const _pickUpFromMoment = moment(_pickUpFrom);
      const _pickUpToMoment = moment(_pickUpTo);
      let i = 0;

      //  if no hubCapacityData is found for the booking slot, consider the booking slot valid
      //  if hubCapacityData is found for the booking slot, consider the slot valid if at least one hubCapacityData's slot
      //  which falls between the range of the booking slot has `remaining_units > 0`.
      let _hasCapacity = true;

      //  hubCapacityData is sorted from the earliest `from_datetime` to the latest
      //  iterate through hubCapacityData until
      //  the slot's pickUpFrom < capacityData's from_datetime AND slot's pickUpTo < capacityData's to_datetime
      //  OR until an slot with capacity (`remaining_units > 0`) is found
      while (i < hubCapacityData.length) {
        const _capacityData = hubCapacityData[i];
        const _fromMoment = _capacityData['from_datetime'] ? moment(_capacityData['from_datetime']) : null;
        const _toMoment = _capacityData['to_datetime'] ? moment(_capacityData['to_datetime']) : null;

        if (_fromMoment.isAfter(_pickUpFromMoment) && _toMoment.isAfter(_pickUpToMoment)) {
          break;
        }

        if (
          _fromMoment &&
          _toMoment &&
          _fromMoment.isBetween(_pickUpFromMoment, _pickUpToMoment, null, '[]') &&
          _toMoment.isBetween(_pickUpFromMoment, _pickUpToMoment, null, '[]')
        ) {
          const _remainingUnits = _capacityData['remaining_units'];
          if (_remainingUnits && _remainingUnits > 0) {
            _hasCapacity = true;
            break;
          } else {
            _hasCapacity = false;
          }
        }
        i++;
      }
      return _hasCapacity
        ? {
            ...result,
            [key]: _slotData,
          }
        : result;
    }, {});
  };

  _processCapacityDataResponse = (data) => {
    if (!data || data.length === 0) return [];
    return data
      .filter((slot) => slot['from_datetime'] && slot['to_datetime'])
      .sort((a, b) => (a['from_datetime'] < b['from_datetime'] ? -1 : a['from_datetime'] > b['from_datetime'] ? 1 : 0));
  };

  //  end of APIs for hub-operation use case

  sortSlots = (slots) => {
    if (!slots || Object.keys(slots).length < 2) return slots;

    const _keys = Object.keys(slots);
    _keys.sort((a, b) => {
      const _pickUpFromA = slots[a] && slots[a][0] && slots[a][0]['pickUpFrom'];
      const _pickUpFromB = slots[b] && slots[b][0] && slots[b][0]['pickUpFrom'];
      return _pickUpFromA < _pickUpFromB ? -1 : _pickUpFromA > _pickUpFromB ? 1 : 0;
    });
    return _keys.reduce((result, key) => {
      result[key] = slots[key];
      return result;
    }, {});
  };

  _transformGetItemsPriceRequestData = (items, slug) => {
    const result = {
      slug,
      order_items: [],
    };

    items.forEach((item) => {
      if (item && item.tasks && item.tasks.length === 2) {
        const _pickUpDetails = item.tasks.find((task) => task.type === 'pickup');
        const _dropOffDetails = item.tasks.find((task) => task.type === 'dropoff');
        const { selectedSlot } = item;
        const custom = {};
        if (!isObjectEmpty(selectedSlot)) {
          custom['pickup_from_time'] = selectedSlot['pickUpFrom'] || '';
          custom['pickup_to_time'] = selectedSlot['pickUpTo'] || '';
          custom['dropoff_from_time'] = selectedSlot['dropOffFrom'] || '';
          custom['dropoff_to_time'] = selectedSlot['dropOffTo'] || '';
        }
        if (_pickUpDetails && _dropOffDetails) {
          result.order_items.push({
            custom,
            slug: parseString(slug),
            from_address: _pickUpDetails
              ? {
                  lat: parseString(_pickUpDetails['lat']),
                  lng: parseString(_pickUpDetails['lng']),
                  zipcode: parseString(_pickUpDetails['postalCode']),
                }
              : null,
            to_address: _dropOffDetails
              ? {
                  lat: parseString(_dropOffDetails['lat']),
                  lng: parseString(_dropOffDetails['lng']),
                  zipcode: parseString(_dropOffDetails['postalCode']),
                }
              : null,
            service_type: parseString(item['serviceType']),
            item: {
              weight: parseInt(item['weight']),
              quantity: parseInt(item['quantity'] ? item['quantity'] : 1),
            },
          });
        }
      }
    });

    return result;
  };

  _transformGetItemsPriceResponseData = (data) => {
    if (!data) {
      return null;
    }
    return data.order_items.map((priceData) => {
      const _priceMeta = priceData['price_meta'] || null;
      return {
        price: parsePrice(priceData['price']),
        priceInfo: parseString(priceData['price_info']),
        priceMeta: _priceMeta
          ? {
              pickUpSurcharges: _priceMeta['pickup_surchages'] ? _priceMeta['pickup_surchages'] : null,
              dropOffSurcharges: _priceMeta['dropoff_surcharges'] ? _priceMeta['dropoff_surcharges'] : null,
              basePrice: parsePrice(_priceMeta['base_price']),
            }
          : null,
      };
    });
  };

  _transformCreateOrderResponseData = (data) => {
    if (!data) {
      return null;
    }
    return {
      cancelledAt: parseISO8601Date(data['cancelled_at']),
      containerNumber: parseString(data['container_no']),
      displayPrice: parseString(data['display_price']),
      externalId: parseString(data['external_id']),
      id: parseInt(data['id']),
      insertedAt: parseISO8601Date(data['inserted_at']),
      orderNumber: parseString(data['number']),
      invoiceRef: parseString(data['invoice_ref']),
      items:
        data['order_items'] && data['order_items'].length > 0
          ? data['order_items'].map((item) => {
              return {
                itemNumber: parseString(item['tracking_number']),
                id: parseInt(item['id']),
              };
            })
          : [],
      placedByUserProfileId: parseInt(data['placed_by_user_profile_id']),
      price: data['price']
        ? {
            amount: parseString(data['price']['amount']),
            currency: parseString(data['price']['currency']),
          }
        : null,
      senderId: parseInt(data['sender_id']),
      status: parseString(data['status']),
    };
  };

  _transformGetSlotsResponseData = ({ data, pickUpDate, timezone }) => {
    if (!data || !pickUpDate) {
      return null;
    }
    const _datetimeObj = timezone ? moment(pickUpDate).tz(timezone) : moment(pickUpDate);
    const keys = [];
    return Object.keys(data).reduce((slots, key) => {
      const _slotGroup = data[key];
      if (_slotGroup && _slotGroup.length > 0) {
        slots[key] = _slotGroup.reduce((arr, currentSlot) => {
          //  filter out slots whose pickup date is not the same as selected date for pickup
          const _pickUpFromObj = currentSlot['pickup_from'] ? moment(currentSlot['pickup_from']) : null;
          if (timezone) {
            _pickUpFromObj.tz(timezone);
          }
          const _key = `${currentSlot['dropoff_from']}${currentSlot['dropoff_to']}${currentSlot['pickup_from']}${currentSlot['pickup_to']}`;
          if (_pickUpFromObj && _datetimeObj.isSame(_pickUpFromObj, 'day') && !keys.includes(_key)) {
            keys.push(_key);
            return [
              ...arr,
              {
                dropOffFrom: parseString(currentSlot['dropoff_from']),
                dropOffTo: parseString(currentSlot['dropoff_to']),
                dropOffCutoffTime: parseString(currentSlot['dropoff_cutoff_time']),
                pickUpFrom: parseString(currentSlot['pickup_from']),
                pickUpTo: parseString(currentSlot['pickup_to']),
                pickUpCutoffTime: parseString(currentSlot['pickup_cutoff_time']),
              },
            ];
          }
          return arr;
        }, []);
      }
      return slots;
    }, {});
  };

  _transformGetSlotsRequestData = (data) => {
    if (!data || !data.pickUpDate) {
      return null;
    }
    const { pickUpDate, timezone, serviceType, slug, type } = data;
    const _today = timezone ? moment().startOf('day').tz(timezone) : moment();
    const _pickUpDateMoment = timezone ? moment(pickUpDate).tz(timezone) : moment(pickUpDate);
    let datetime;
    if (_pickUpDateMoment.isSame(_today, 'days')) {
      datetime = timezone ? moment().tz(timezone).toISOString(true) : moment().toISOString(true);
    } else {
      datetime = _pickUpDateMoment.startOf('day').toISOString(true);
    }
    return {
      datetime: datetime,
      service_type: parseString(serviceType),
      slug: parseString(slug),
      type: parseString(type),
    };
  };

  _transformCreateMultilegOrderRequestData = (data) => {
    const body = {
      items: [],
      steps: [],
      item_steps: [],
      placed_by_user_profile_id: '1',
      container_no: '',
      sender_type: data.accountInfo.senderType,
      sender_id: data.accountInfo.id,
      price_amount: data.priceAmount,
      price_currency: data.priceCurrency,
      external_id: '',
    };

    let orderStepId = 0;

    data.orderData.forEach((item, itemIndex) => {
      const {
        description,
        height,
        length,
        weight,
        width,
        serviceType,
        serviceTypeObject,
        itemType,
        externalID1,
        externalID2,
        externalID3,
        additionalInfo,
        quantity,
        cod,
      } = item;
      const volume = parseFloat(width) * parseFloat(height) * parseFloat(length);
      const _item = {
        description: parseString(description),
        height: parseFloat(height),
        length: parseFloat(length),
        weight: parseFloat(weight),
        width: parseFloat(width),
        volume,
        quantity,
        volumetric_weight: volume / 5000 > 1 ? volume / 5000 : 1,
        payload_type: itemType,
        service_type: serviceType,
        external_customer_id: parseString(externalID1),
        external_customer_id2: parseString(externalID2),
        external_customer_id3: parseString(externalID3),
        info: parseString(additionalInfo),
      };

      if (cod) {
        _item.cod_price_amount = parseString(cod);
        _item.cod_price_currency = data.priceCurrency;
      }

      body.items.push(_item);

      let group = 1;
      let stepSequence = 1;
      item.tasks.forEach((task, taskIndex) => {
        let from;
        let to;

        if ((!serviceTypeObject.taskTypeSequence || taskIndex === 0) && !task.linkedToPrev) {
          group++;
        } else if (serviceTypeObject.taskTypeSequence) {
          const currentGroupId = this._getSequenceGroupId(taskIndex, serviceTypeObject.taskTypeSequence);
          if (group !== currentGroupId) {
            group = currentGroupId;
          }
        }
        if (!serviceTypeObject.taskTypeSequence) {
          from = new Date(`${task.fromDate} ${task.fromTime}`);
          to = new Date(`${task.toDate} ${task.toTime}`);
        } else {
          if (task.type === 'pickup') {
            const hours = item.pickUpTime.split('-');
            from = moment(`${item.pickUpDate} ${hours[0]}`, DATE_TIME_FORMAT);
            to = moment(`${item.pickUpDate} ${hours[1]}`, DATE_TIME_FORMAT);
          } else {
            const hours = item.dropOffTime.split('-');
            from = moment(`${item.dropOffDate} ${hours[0]}`, DATE_TIME_FORMAT);
            to = moment(`${item.dropOffDate} ${hours[1]}`, DATE_TIME_FORMAT);
          }
        }

        body.item_steps.push({
          item_id: itemIndex,
          order_step_id: orderStepId++,
          step_group: group,
          step_sequence: stepSequence++,
          type: task.type.toLowerCase(),
        });

        const step = {
          address: task.addressLine1,
          address2: task.addressLine2,
          contact_company: task.contactCompany,
          contact_email: task.contactEmail,
          contact_name: task.contactName,
          contact_phone: task.contactPhone ? `${task.contactAreaCode}${task.contactPhone}` : '',
          country: task.country,
          from_time: from && from.toISOString(),
          lat: task.lat,
          lng: task.lng,
          postal_code: task.postalCode,
          state: task.state,
          to_time: to && to.toISOString(),
        };

        body.steps.push(step);
      });
    });
    return body;
  };

  _getSequenceGroupId = (taskIndex, taskTypeSequence) => {
    let elementIndex = 0;
    let result = -1;
    taskTypeSequence.forEach((group, groupIndex) => {
      elementIndex = elementIndex + group.length;
      if (elementIndex >= taskIndex && result === -1) {
        result = groupIndex;
      }
    });

    return result + 1;
  };

  _transformCreateSingleItemOrderRequestData = (data) => {
    if (!data) {
      return null;
    }

    const { accountInfo, orderData, priceCurrency } = data;
    if (!accountInfo || !orderData) {
      return null;
    }

    const { pickUpDetails, dropOffDetails } = orderData;

    const _pickUpTimeRange = pickUpDetails['time'] && pickUpDetails['time'].split('-');
    const _dropOffTimeRange = dropOffDetails['time'] && dropOffDetails['time'].split('-');

    const _pickUpFrom = moment(`${pickUpDetails['date']} ${_pickUpTimeRange[0]}`).toISOString();
    const _pickUpTo = moment(`${pickUpDetails['date']} ${_pickUpTimeRange[1]}`).toISOString();

    const _dropOffFrom = moment(`${dropOffDetails['date']} ${_dropOffTimeRange[0]}`).toISOString();
    const _dropOffTo = moment(`${dropOffDetails['date']} ${_dropOffTimeRange[1]}`).toISOString();

    const items = [];
    const item = {
      service_type: parseString(orderData.serviceType),
      payload_type: parseString(orderData.itemType),
      description: parseString(orderData.description),
      info: parseString(orderData.additionalInfo),
      width: parseInt(orderData.width),
      height: parseInt(orderData.height),
      length: parseInt(orderData.length),
      weight: parseInt(orderData.weight),
      price_amount: `${orderData.priceAmount}`,
      volume: orderData.volume,
      external_customer_id: parseString(orderData.externalID1),
    };

    items.push(item);

    const item_steps = [
      {
        item_id: 0,
        order_step_id: 0,
        type: 'pickup',
      },
      {
        item_id: 0,
        order_step_id: 1,
        type: 'dropoff',
      },
    ];

    const steps = [
      {
        address: pickUpDetails.addressLine1,
        contact_name: pickUpDetails.contactName,
        contact_phone: pickUpDetails.contactPhone
          ? `${pickUpDetails.contactAreaCode}${pickUpDetails.contactPhone}`
          : '',
        lat: pickUpDetails.lat,
        lng: pickUpDetails.lng,
        from_time: _pickUpFrom,
        to_time: _pickUpTo,
      },
      {
        address: dropOffDetails.addressLine1,
        contact_name: dropOffDetails.contactName,
        contact_phone: dropOffDetails.contactPhone
          ? `${dropOffDetails.contactAreaCode}${dropOffDetails.contactPhone}`
          : '',
        lat: dropOffDetails.lat,
        lng: dropOffDetails.lng,
        from_time: _dropOffFrom,
        to_time: _dropOffTo,
      },
    ];

    const requestPayload = {
      sender_type: parseString(accountInfo.senderType),
      sender_id: parseInt(accountInfo.id),
      external_id: '',
      items,
      item_steps,
      steps,
      price_amount: `${orderData.priceAmount}`,
      price_currency: priceCurrency,
    };

    if (orderData.vehicle) {
      requestPayload['worker_id'] = orderData.vehicle;
    }

    return requestPayload;
  };

  _transformCreateOrderRequestData = (data) => {
    if (!data) {
      return null;
    }

    const { accountInfo, orderData, priceAmount, priceCurrency } = data;

    if (!accountInfo || !orderData || orderData.length < 1) {
      return null;
    }
    const items = [];
    const item_steps = [];
    const steps = [];
    let orderStepId = 0;

    orderData.forEach((item, index) => {
      if (!isObjectEmpty(item.selectedSlot)) {
        const { selectedSlot } = item;
        const _volume =
          item.width && item.height && item.length
            ? parseFloat(item.width) * parseFloat(item.height) * parseFloat(item.length)
            : null;
        const _volumetricWeight = _volume / 5000 > 1 ? Math.round(_volume / 5000) : 1;
        const _item = {
          quantity: parseString(item.quantity),
          service_type: parseString(item.serviceType),
          payload_type: parseString(item.itemType),
          description: parseString(item.description),
          info: parseString(item.additionalInfo),
          width: parseFloat(item.width),
          height: parseFloat(item.height),
          length: parseFloat(item.length),
          weight: parseFloat(item.weight),
          price_amount: `${item.priceAmount}`,
          price_info: `${item.priceInfo || ''}`,
          volume: _volume,
          volumetric_weight: _volumetricWeight,
          external_customer_id: parseString(item.externalID1),
          external_customer_id2: parseString(item.externalID2),
          external_customer_id3: parseString(item.externalID3),
        };

        if (item.cod) {
          _item.cod_price_amount = parseString(item.cod);
          _item.cod_price_currency = priceCurrency;
        }

        items.push(_item);

        item.tasks.forEach((task) => {
          let from;
          let to;
          if (task.type === 'pickup') {
            from = selectedSlot.pickUpFrom || null;
            to = selectedSlot.pickUpTo || null;
          } else {
            from = selectedSlot.dropOffFrom || null;
            to = selectedSlot.dropOffTo || null;
          }

          const _item_step = {
            item_id: index,
            order_step_id: orderStepId++,
            type: task.type.toLowerCase(),
          };
          if (task.serviceHubId) {
            _item_step['service_by_hub_id'] = task.serviceHubId;
          }
          item_steps.push(_item_step);

          const step = {
            address: task.addressLine1,
            address2: task.addressLine2,
            contact_company: task.contactCompany,
            contact_email: task.contactEmail,
            contact_name: task.contactName,
            contact_phone: task.contactPhone ? `${task.contactAreaCode}${task.contactPhone}` : '',
            country: task.country,
            city: task.city,
            from_time: from,
            lat: task.lat,
            lng: task.lng,
            postal_code: task.postalCode,
            state: task.state,
            to_time: to,
          };

          steps.push(step);
        });
      }
    });

    const requestPayload = {
      sender_type: parseString(accountInfo.senderType),
      sender_id: parseInt(accountInfo.id),
      price_amount: `${priceAmount}`,
      price_currency: priceCurrency,
      external_id: '',
      items,
      item_steps,
      steps,
    };

    if (orderData.workerId) {
      requestPayload['worker_id'] = orderData.workerId;
    }

    return requestPayload;
  };

  _transformDataForPaymentRequestV4 = (payload) => {
    return {
      order_numbers: payload.orderNumbers ?? [],
      description: `Payment for ${payload.orderNumbers?.join(', ')}`,
      payment_method: 'stripe',
      amount: payload.totalPrice.toString() ?? null,
      currency: payload.currency ?? null,
      token: parseString(payload.token),
    };
  };
}

export const senderOrderService = new SenderOrderService({
  authService,
});
