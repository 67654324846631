class Actions {
  login = (payload) => {
    return { type: 'UI_AUTH_REQUEST_LOGIN', payload: payload };
  };
  sendSSOLink = (payload) => {
    return { type: 'UI_AUTH_REQUEST_SEND_SSO_LINK', payload: payload };
  };
  loadDispatcherInfo = (payload) => {
    return { type: 'UI_AUTH_REQUEST_DISPATCHER_INFO', payload: payload };
  };
  loadRelatedCompanies = () => {
    return { type: 'UI_AUTH_REQUEST_RELATED_COMPANIES' };
  };
  getTokenForRelatedCompany = (payload) => {
    return { type: 'UI_AUTH_REQUEST_TOKEN_FOR_RELATED_COMPANY', payload: payload };
  };
  requestNewPassword = (payload) => {
    return { type: 'UI_AUTH_REQUEST_NEW_PASSWORD', payload: payload };
  };
  editPassword = (payload) => {
    return { type: 'UI_AUTH_EDIT_PASSWORD', payload: payload };
  };
  resetNewPasswordSettings = (payload) => {
    return { type: 'UI_AUTH_RESET_NEW_PASSWORD_SETTINGS', payload: payload };
  };
  logout = () => {
    return { type: 'UI_AUTH_LOGOUT' };
  };
  signUp = (payload) => {
    return { type: 'UI_AUTH_REQUEST_SIGN_UP', payload: payload };
  };
  setClearIframeData = (value = true) => {
    return { type: 'UI_AUTH_SET_CLEAR_IFRAME_DATA', value };
  };
  clearMessage = () => {
    return { type: 'UI_AUTH_CLEAR_MESSAGE' };
  };
  saveJwtToken = (payload) => {
    return { type: 'UI_AUTH_SAVE_JWT_TOKEN', payload };
  };
  ssoLoginFailed = (payload) => {
    return { type: 'UI_AUTH_SSO_LOGIN_FAILED', payload };
  };
  setDispatcherInfo = (payload) => {
    return { type: 'UI_AUTH_SET_DISPATCHER_INFO', payload };
  };
  setUserLanguage = (payload) => {
    return { type: 'UI_AUTH_REQUEST_SET_USER_LANGUAGE', payload };
  };
}

export const UIAuthActions = new Actions();
