import { t } from 'i18next';

import {
  batchUploadDefaultState,
  generateBatchUploadReducerMethods,
} from '@yojee/ui/onboarding/helpers/batchUploadHelper';

const defaultState = {
  inProgress: {
    listTags: false,
    createTag: false,
    updateTag: false,
    deleteTag: false,
  },
  tagData: [],
  filter: {},
  pagination: {
    current_page: 1,
    limit_value: 25,
    total_count: 0,
    total_pages: 0,
  },
  batchUpload: batchUploadDefaultState,
  bulkDeleteSuccess: false,
};

export const tagMessagesSuccessEvents = {
  CREATE_TAG_SUCCESSFUL: 'Tag created successfully',
  UPDATE_TAG_SUCCESSFUL: 'Tag updated successfully',
  DELETE_TAG_SUCCESSFUL: 'Tag deleted successfully',
  BULK_REQUEST_DELETE_TAG_SUCCESSFUL: 'Tags deleted successfully',
  REQUEST_DELETE_TAG_IN_PROCESS: 'Tags delete in process',
  TAG_BATCH_UPLOAD_SUCCEEDED: t('Batch upload completed'),
};

export const tagMessagesErrorEvents = {
  LIST_TAGS_FAILED: ({ error }) => error?.message ?? 'Failed to get tag info',
  CREATE_TAG_FAILED: ({ error }) => error?.message ?? 'Error creating tag.',
  UPDATE_TAG_FAILED: ({ error }) => error?.message ?? 'Error updating tag.',
  DELETE_TAG_FAILED: ({ error }) => error?.message ?? 'Error deleting tag.',
  BULK_REQUEST_DELETE_TAG_FAIL: ({ payload }) => payload || `Failed to delete tag!`,
};

export const init = () => {
  return defaultState;
};

const ACTION_HANDLERS = {
  SET_TAG_LOADING: (state, sagaData) => {
    return {
      ...state,
      inProgress: {
        ...state.inProgress,
        [sagaData.key]: true,
      },
    };
  },
  CLEAR_TAG_MESSAGE: (state) => {
    return {
      ...state,
      errorMessage: null,
      successMessage: null,
    };
  },
  LIST_TAGS_SUCCESSFUL: (state, sagaData) => {
    const data = sagaData.tagData;
    return {
      ...state,
      inProgress: {
        ...state.inProgress,
        listTags: false,
      },
      tagData: data?.['data'],
      pagination: data?.['pagination'],
      filter: sagaData.params,
    };
  },
  LIST_TAGS_FAILED: (state) => {
    return {
      ...state,
      inProgress: {
        ...state.inProgress,
        listTags: false,
      },
    };
  },
  CREATE_TAG_SUCCESSFUL: (state) => {
    return {
      ...state,
      inProgress: {
        ...state.inProgress,
        createTag: false,
      },
    };
  },
  CREATE_TAG_FAILED: (state) => {
    return {
      ...state,
      inProgress: {
        ...state.inProgress,
        createTag: false,
      },
    };
  },

  UPDATE_TAG_SUCCESSFUL: (state) => {
    return {
      ...state,
      inProgress: {
        ...state.inProgress,
        updateTag: false,
      },
    };
  },
  UPDATE_TAG_FAILED: (state) => {
    return {
      ...state,
      inProgress: {
        ...state.inProgress,
        updateTag: false,
      },
    };
  },
  REQUEST_DELETE_TAG: (state) => {
    return {
      ...state,
      inProgress: {
        ...state.inProgress,
        deleteTag: true,
      },
    };
  },
  DELETE_TAG_SUCCESSFUL: (state) => {
    return {
      ...state,
      inProgress: {
        ...state.inProgress,
        deleteTag: false,
      },
    };
  },
  DELETE_TAG_FAILED: (state) => {
    return {
      ...state,
      inProgress: {
        ...state.inProgress,
        deleteTag: false,
      },
    };
  },
  BULK_REQUEST_DELETE_TAG_SUCCESSFUL: (state) => {
    return {
      ...state,
      bulkDeleteSuccess: true,
    };
  },
  BULK_REQUEST_DELETE_TAG_FAIL: (state) => {
    return {
      ...state,
      isLoading: false,
    };
  },
  REQUEST_DELETE_TAG_IN_PROCESS: (state) => {
    return {
      ...state,
      bulkDeleteSuccess: false,
    };
  },
  ...generateBatchUploadReducerMethods({ prefix: 'TAG' }),
};

const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};

export default reducer;
