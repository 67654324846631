import { getSlugFromUrl } from '@yojee/auth/utils/AuthUtils';

import { authService } from '../authService/index';
import { BaseService } from '../baseService/index';

export class TemplateApiV4 extends BaseService {
  constructor({ authService }) {
    super();
    this.authService = authService;
  }

  getCompanyTemplates = (params = {}) => {
    const { page = 1, size = 200, q = '' } = params;
    return this.authService
      .get(this.getUmbrellaApiUrlV4(`company/template_types?q=${q}&page=${page}&page_size=${size}`))
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  createCompanyTemplate = (template) => {
    return this.authService
      .post(this.getUmbrellaApiUrlV4(`company/template_types`), template)
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleNewErrorFormat(error));
  };

  updateTemplateType = (templateType) => {
    return this.authService
      .put(this.getUmbrellaApiUrlV4(`company/template_types/${templateType.id}`), templateType)
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  setDefaultTemplate = ({ id }) => {
    return this.authService
      .put(this.getUmbrellaApiUrlV4(`company/template_types/${id}/set_default`))
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  setActiveTemplateVersion = (id, params) => {
    return this.authService
      .put(this.getUmbrellaApiUrlV4(`company/templates/${id}/set_active`), params)
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  getCompanyActivatedTemplates = () => {
    return this.authService
      .get(this.getUmbrellaApiUrlV4(`company/templates/active`))
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  getPublicCompanyActivatedTemplates = () => {
    const requestPayload = { company_slug: getSlugFromUrl() };

    return this.authService
      .get(this.getUmbrellaApiUrlV4('public/templates/active'), requestPayload)
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  getTemplateVersions = (templateTypeId) => {
    return this.authService
      .get(this.getUmbrellaApiUrlV4(`company/templates?template_type_id=${templateTypeId}`))
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  createTemplateVersion = (template) => {
    return this.authService
      .post(this.getUmbrellaApiUrlV4(`company/templates`), template)
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  getTemplateVersionDetail = (templateId) => {
    return this.authService
      .get(this.getUmbrellaApiUrlV4(`company/templates/${templateId}`))
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  getPublicCompanyTemplateDetail = (id) => {
    const requestPayload = { company_slug: getSlugFromUrl() };

    return this.authService
      .get(this.getUmbrellaApiUrlV4(`public/templates/${id}`), requestPayload)
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  getTemplateDownloadUrl = ({ id, slug, fileType = 'csv' }) => {
    return this.getUmbrellaApiUrlV4(
      `public/orders/download_sample?company_slug=${slug}&format=${fileType}&template_id=${id}`
    );
  };

  deleteTemplateVersion = (templateId) => {
    return this.authService
      .delete(this.getUmbrellaApiUrlV4(`company/templates/${templateId}`))
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  getDefaultTemplate = () => {
    return this.authService
      .get(this.getUmbrellaApiUrlV4(`company/templates/master_template`))
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };
}

export const templateApiV4 = new TemplateApiV4({ authService });
