import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
// UI
import FormControl from '@material-ui/core/FormControl';
import React, { useEffect } from 'react';

const ConjsButtons = ({
  id,
  not,
  setNot,
  conjunctionOptions,
  setConjunction,
  disabled,
  readonly,
  config,
  showNot,
  notLabel,
  selectedConjunction,
  reversedConj,
  customConjs = { AND: {}, OR: {} },
}) => {
  //TIP: disabled=true if only 1 rule; readonly=true if immutable mode
  const conjsCount = Object.keys(conjunctionOptions).length;
  const lessThenTwo = disabled;
  const { forceShowConj } = config.settings;
  const showConj = forceShowConj || (conjsCount > 1 && !lessThenTwo);

  useEffect(() => {
    if (reversedConj && selectedConjunction !== reversedConj) {
      setConjunction(reversedConj);
    }
  }, [selectedConjunction, reversedConj]);

  const renderOptions = () =>
    Object.keys(conjunctionOptions).map((key) => {
      const { id, label, checked } = conjunctionOptions[key];
      const postfix = setConjunction.isDummyFn ? '__dummy' : '';

      if ((readonly || disabled) && !checked) return null;

      return (
        <Button
          key={id + postfix}
          id={id + postfix}
          color={checked ? 'primary' : 'default'}
          value={key}
          onClick={onClick.bind(null, key)}
          disabled={readonly || disabled}
          {...customConjs[key]}
        >
          {label}
        </Button>
      );
    });

  const renderNot = () => {
    if (readonly && !not) return null;

    return (
      <Button
        key={id}
        id={id + '__not'}
        color={not ? 'secondary' : 'default'}
        onClick={onNotClick.bind(null, !not)}
        disabled={readonly}
      >
        {notLabel || 'NOT'}
      </Button>
    );
  };

  const onClick = (value) => setConjunction(value);
  const onNotClick = (checked) => setNot(checked);

  return (
    <FormControl>
      <ButtonGroup disableElevation variant="contained" size="small" disabled={readonly}>
        {showNot && renderNot()}
        {showConj && renderOptions()}
      </ButtonGroup>
    </FormControl>
  );
};

export default ConjsButtons;
