import {
  batchUploadDefaultState,
  generateBatchUploadReducerMethods,
} from '@yojee/ui/onboarding/helpers/batchUploadHelper';

const defaultState = {
  loading: {},
  info: {
    users: [],
    pagination: {
      current_page: 1,
      limit_value: 25,
      total_count: 0,
      total_pages: 0,
    },
    q: '',
  },
  openDialog: false,
  inProgress: {
    requestGetUsers: false,
  },
  batchUpload: batchUploadDefaultState,
};

export const userMessagesSuccessEvents = {
  UPDATE_USER: ({ message }) => message,
};

export const userMessagesErrorEvents = {
  UPDATE_USER_ERROR: ({ message }) => message,
};

export const init = () => {
  return defaultState;
};

const ACTION_HANDLERS = {
  UPDATE_USERS: (state, { data, pagination, q }) => {
    return {
      ...state,
      info: {
        ...state.info,
        users: data,
        pagination,
        q,
      },
      inProgress: {
        ...state.inProgress,
        requestGetUsers: false,
      },
    };
  },
  UPDATE_USER: (state) => {
    if (state.info && state.info && state.info.users) {
      return {
        ...state,
        openDialog: false,
        inProgress: {
          ...state.inProgress,
          requestGetUsers: false,
        },
      };
    }

    return {
      ...state,
    };
  },
  UPDATE_USER_ERROR: (state) => {
    return {
      ...state,
      openDialog: true,
      inProgress: {
        ...state.inProgress,
        requestGetUsers: false,
      },
    };
  },
  OPEN_DIALOG: (state) => {
    return {
      ...state,
      openDialog: true,
    };
  },
  CLOSE_DIALOG: (state) => {
    return {
      ...state,
      openDialog: false,
    };
  },
  START_USERS_PROGRESS: (state, sagaData) => {
    return {
      ...state,
      inProgress: {
        ...state.inProgress,
        [sagaData.key]: true,
      },
    };
  },
  ...generateBatchUploadReducerMethods({ prefix: 'DISPATCHER' }),
};

const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};

export default reducer;
