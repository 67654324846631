import './styles.scss';

import withStyles from '@material-ui/core/styles/withStyles';
import React from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';

import CustomErrorBoundary from '@yojee/ui/common/CustomErrorBoundary';
import FallbackWithoutRetryAbility from '@yojee/ui/common/CustomErrorBoundary/fallbacks/FallbackWithoutRetryAbility';
import LoadingOverlay from '@yojee/ui/common/LoadingOverlay/LoadingOverlay';

import TopMenu from '../TopMenu';
import Messages from './Messages';

const styles = () => ({
  root: {
    width: '100%',
  },
});

const MainLayout = ({ classes }) => {
  const {
    location: { search },
  } = window;

  const isEmbedded = search?.indexOf('embed=true') > -1;
  const loggedIn = useSelector((state) => !!state?.login?.accountInfo?.dispatcher_info?.id);

  if (isEmbedded) {
    return (
      <CustomErrorBoundary CustomFallbackComponent={FallbackWithoutRetryAbility}>
        <Outlet />
      </CustomErrorBoundary>
    );
  }

  if (!loggedIn) {
    return <LoadingOverlay active={true} data-cy="loading-overlay" />;
  }

  return (
    <div className="manage-content-container">
      <div className="menu-list" data-cy="menu-list">
        <TopMenu />
      </div>

      <div className={classes.root}>
        <CustomErrorBoundary CustomFallbackComponent={FallbackWithoutRetryAbility}>
          <Messages />
          <Outlet />
        </CustomErrorBoundary>
      </div>
    </div>
  );
};

export default withStyles(styles)(MainLayout);
