import { t } from 'i18next';

import {
  batchUploadDefaultState,
  generateBatchUploadReducerMethods,
} from '@yojee/ui/onboarding/helpers/batchUploadHelper';

const defaultState = {
  workers: [],
  createOrUpdate: false,
  worker: {},
  schedule: {},
  batchUpload: batchUploadDefaultState,
  inProgress: {
    listWorkers: false,
    getWorkers: false,
    createWorker: false,
    updateWorker: false,
  },
  list: [],
  pagination: {
    current_page: 1,
    limit_value: 25,
    total_count: 0,
    total_pages: 0,
  },
  query: null,
};

export const workerMessagesSuccessEvents = {
  REQUEST_CREATE_WORKER_SUCCESS: 'New driver created',
  REQUEST_UPDATE_WORKER_SUCCESS: 'Driver details updated',
  REQUEST_DELETE_WORKER_SUCCESS: 'Driver deleted successfully',
  BULK_REQUEST_DELETE_WORKER_SUCCESS: 'Driver deleted successfully',
  REQUEST_DELETE_WORKER_IN_PROCESS: 'Driver deleted processing',
  WORKERS_BATCH_UPLOAD_SUCCEEDED: t('Batch upload completed'),
};

export const workerMessagesErrorEvents = {
  REQUEST_GET_WORKER_ERROR: ({ error }) => `Failed to get driver details : ${error.message}`,
  REQUEST_CREATE_WORKER_ERROR: ({ error }) => `Failed to create driver: ${error.message}`,
  REQUEST_UPDATE_WORKER_ERROR: ({ error }) => `Failed to update driver details: ${error.message}`,
  REQUEST_DELETE_WORKER_ERROR: ({ error }) => `Failed to delete driver: ${error.message}`,
  BULK_REQUEST_DELETE_WORKER_ERROR: ({ payload }) => payload || `Failed to delete driver!`,
};

export const init = () => {
  return defaultState;
};

const addOrUpdateArray = (array, input, compareFunction) => {
  const newArray = array;
  const currentIndex = newArray.findIndex(compareFunction);
  if (currentIndex > -1) {
    newArray[currentIndex] = input;
  } else {
    newArray.push(input);
  }
  return newArray;
};

const ACTION_HANDLERS = {
  LIST_WORKERS_SUCCESSFUL: (state, sagaData) => {
    return {
      ...state,
      workers: sagaData.workers,
      inProgress: {
        ...state.inProgress,
        listWorkers: false,
      },
    };
  },
  REQUEST_GET_WORKERS_SUCCESSFUL: (state, { data, pagination, q }) => {
    return {
      ...state,
      list: data,
      pagination,
      query: q,
      inProgress: {
        ...state.inProgress,
        getWorkers: false,
      },
    };
  },
  REQUEST_GET_WORKER_SUCCESS: (state, data) => {
    return {
      ...state,
      worker: data.result,
    };
  },
  REQUEST_CREATE_WORKER_SUCCESS: (state) => {
    return {
      ...state,
      createOrUpdate: true,
      inProgress: {
        ...state.inProgress,
        createWorker: false,
      },
    };
  },
  REQUEST_CREATE_WORKER_ERROR: (state) => {
    return {
      ...state,
      createOrUpdate: true,
      inProgress: {
        ...state.inProgress,
        createWorker: false,
      },
    };
  },
  REQUEST_UPDATE_WORKER_SUCCESS: (state, { worker }) => {
    if (state.list.length > 0) {
      const workers = addOrUpdateArray(state.list, worker, (w) => {
        return w.id === worker.id;
      });
      return {
        ...state,
        list: workers,
        createOrUpdate: true,
        inProgress: {
          ...state.inProgress,
          updateWorker: false,
        },
      };
    }
  },
  REQUEST_UPDATE_WORKER_ERROR: (state) => {
    return {
      ...state,
      createOrUpdate: true,
      inProgress: {
        ...state.inProgress,
        updateWorker: false,
      },
    };
  },
  REQUEST_DELETE_WORKER_SUCCESS: (state, data) => {
    return {
      ...state,
      workers: state.workers.filter((worker) => worker.id !== data.result),
    };
  },
  BULK_REQUEST_DELETE_WORKER_SUCCESS: (state, { payload }) => {
    return {
      ...state,
      workers: state.workers.filter((worker) => !payload.includes(worker.id)),
      list: state.list.filter((worker) => !payload.includes(worker.id)),
    };
  },
  ...generateBatchUploadReducerMethods({ prefix: 'WORKERS' }),
  CLEAR_MESSAGES: (state) => {
    return {
      ...state,
      createOrUpdate: false,
    };
  },
  SET_WORKERS_PROGRESS: (state, { key }) => {
    return {
      ...state,
      inProgress: {
        ...state.loading,
        [key]: true,
      },
    };
  },
};

const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};

export default reducer;
