import { call, put, select, takeLatest } from 'redux-saga/effects';

import { batchUpload } from '@yojee/ui/onboarding/helpers/batchUploadHelper';

import { userService } from '../../services/userService';

export const usersData = (state) => state.manageUsers.info;

export default function* sagas() {
  yield takeLatest('REQUEST_GET_USERS', requestGetUsers);
  yield takeLatest('REQUEST_CREATE_USER', requestCreateUser);
  yield takeLatest('REQUEST_UPDATE_USER', requestUpdateUser);
  yield takeLatest('USERS_BATCH_UPLOAD', requestDispatcherBatchUpload);
  yield takeLatest('DOWNLOAD_USER_TEMPLATE_SAMPLE', downloadUserTemplateSample);
}

function* requestGetUsers(action) {
  try {
    yield put({ type: 'START_USERS_PROGRESS', key: 'requestGetUsers' });
    const { data, pagination } = yield call(userService.requestGetUsers, action.payload);
    yield put({ type: 'UPDATE_USERS', data, pagination, q: action.payload.q });
  } catch (error) {
    yield put({ type: 'UPDATE_USERS_FAILED', error });
  }
}

function* requestCreateUser(action) {
  try {
    yield put({ type: 'START_USERS_PROGRESS', key: 'requestGetUsers' });
    const { message } = yield call(userService.createUser, action.payload);
    yield put({ type: 'UPDATE_USER', message });
    const {
      pagination: { current_page: page, limit_value: page_size },
      q,
    } = yield select(usersData);
    yield put({ type: 'REQUEST_GET_USERS', payload: { q, page, page_size } });
  } catch (error) {
    yield put({ type: 'UPDATE_USER_ERROR', message: error.message });
  }
}

function* requestUpdateUser(action) {
  try {
    yield put({ type: 'START_USERS_PROGRESS', key: 'requestGetUsers' });
    const { message } = yield call(userService.updateUser, action.payload);
    yield put({ type: 'UPDATE_USER', message });
    const {
      pagination: { current_page: page, limit_value: page_size },
      q,
    } = yield select(usersData);
    yield put({ type: 'REQUEST_GET_USERS', payload: { q, page, page_size } });
  } catch (error) {
    yield put({ type: 'UPDATE_USER_ERROR', message: error.message });
  }
}

function* requestDispatcherBatchUpload(action) {
  const { file, format } = action.uploadData;

  yield batchUpload(
    { file, format },
    {
      uploadApiMethod: userService.batchUpload,
      statusCheckApiMethod: userService.checkBatchStatus,
      events: {
        started: 'DISPATCHER_BATCH_UPLOAD_STARTED',
        processing: 'DISPATCHER_BATCH_UPLOAD_PROCESSING',
        succeeded: 'DISPATCHER_BATCH_UPLOAD_SUCCEEDED',
        failed: 'DISPATCHER_BATCH_UPLOAD_FAILED',
      },
    }
  );
}

function downloadUserTemplateSample({ format }) {
  window.open(userService.downloadUserTemplateSample({ format }), '_self');
}
