const defaultState = {
  loading: {
    getListWorkerTeam: false,
    getWorkerTeam: false,
    updateWorkerTeam: false,
    bulkDeleteWorkerTeam: false,
  },
  info: {
    workerTeams: undefined,
    driver: undefined,
  },
  pagination: {
    current_page: 1,
    limit_value: 25,
    total_count: 0,
    total_pages: 0,
  },
  query: null,
};

export const workerTeamsMessagesSuccessEvents = {
  REQUEST_CREATE_WORKER_TEAM_SUCCESS: 'Driver team created',
  REQUEST_UPDATE_WORKER_TEAM_SUCCESS: 'Driver team updated!',
  REQUEST_DELETE_WORKER_TEAM_SUCCESS: 'Driver team deleted successfully',
  BULK_REQUEST_DELETE_WORKER_TEAM_SUCCESS: 'Worker team deleted successfully',
  REQUEST_DELETE_WORKER_TEAM_IN_PROCESS: 'Worker team deleted processing',
  REQUEST_REMOVE_WORKERS_FROM_TEAM_SUCCESS: 'Drivers in team deleted successfully',
};

export const workerTeamsMessagesErrorEvents = {
  REQUEST_LIST_WORKER_TEAMS_ERROR: ({ error }) => `Failed to get driver teams : ${error.message}`,
  REQUEST_GET_WORKER_TEAM_ERROR: ({ error }) => `Failed to get driver team details : ${error.message}`,
  REQUEST_GET_DRIVER_BY_WORKER_TEAM_ERROR: ({ error }) =>
    `Failed to get driver by worker team details : ${error.message}`,
  REQUEST_CREATE_WORKER_TEAM_ERROR: ({ error }) => `Failed to create driver: ${error.message}`,
  REQUEST_UPDATE_WORKER_TEAM_ERROR: ({ error }) => `Failed to update driver team: ${error.message}`,
  REQUEST_DELETE_WORKER_TEAM_ERROR: ({ error }) => `Failed to delete driver team: ${error.message}`,
  BULK_REQUEST_DELETE_WORKER_TEAM_ERROR: `Failed to delete work teams!`,
  REQUEST_REMOVE_WORKERS_FROM_TEAM_ERROR: `Failed to bulk delete drivers in team`,
};

export const init = () => {
  return defaultState;
};

const addOrUpdateArray = (array, input, compareFunction) => {
  const newArray = array;
  const currentIndex = newArray.findIndex(compareFunction);
  if (currentIndex > -1) {
    newArray[currentIndex] = input;
  } else {
    newArray.push(input);
  }
  return newArray;
};

const ACTION_HANDLERS = {
  REQUEST_GET_LIST_WORKER_TEAMS_SUCCESS: (state, { result: { pagination, q, data } }) => {
    return {
      ...state,
      loading: { ...state.loading, getListWorkerTeam: false },
      info: { ...state.info, workerTeams: data },
      pagination,
      query: q,
    };
  },
  REQUEST_GET_WORKER_TEAM_SUCCESS: (state, data) => {
    return {
      ...state,
      info: { ...state.info, workerTeams: [data.result] },
      loading: {
        ...state.loading,
        getWorkerTeam: false,
      },
    };
  },
  REQUEST_GET_WORKER_TEAM_ERROR: (state) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        getWorkerTeam: false,
      },
    };
  },
  REQUEST_GET_DRIVER_BY_WORKER_TEAM_SUCCESS: (state, { result: { pagination, q, data } }) => {
    return {
      ...state,
      info: { ...state.info, driver: data },
      pagination,
      query: q,
    };
  },
  REQUEST_UPDATE_WORKER_TEAM_SUCCESS: (state, data) => {
    const updatedWorkerTeam = data.result;
    if (state.info && state.info && state.info.workerTeams && state.info.workerTeams.length > 0) {
      const newArray = addOrUpdateArray(state.info.workerTeams, updatedWorkerTeam, (workerTeam) => {
        return workerTeam.id === updatedWorkerTeam.id;
      });
      return {
        ...state,
        info: { ...state.info, workerTeams: newArray },
        loading: { ...state.loading, updateWorkerTeam: false },
      };
    }
  },
  REQUEST_UPDATE_WORKER_TEAM_ERROR: (state) => {
    return {
      ...state,
      loading: { ...state.loading, updateWorkerTeam: false },
    };
  },
  REQUEST_DELETE_WORKER_TEAM_SUCCESS: (state) => {
    return {
      ...state,
      loading: { ...state.loading, updateWorkerTeam: false },
    };
  },
  BULK_REQUEST_DELETE_WORKER_TEAM_SUCCESS: (state, { payload }) => {
    return {
      ...state,
      info: {
        ...state.info,
        workerTeams: state.info.workerTeams.filter((workerTeam) => !payload.includes(workerTeam.id)),
      },
    };
  },
  REQUEST_REMOVE_WORKERS_FROM_TEAM_SUCCESS: (state) => {
    return {
      ...state,
      loading: { ...state.loading, bulkDeleteWorkerTeam: false },
    };
  },
  REQUEST_REMOVE_WORKERS_FROM_TEAM_ERROR: (state) => {
    return {
      ...state,
      loading: { ...state.loading, bulkDeleteWorkerTeam: false },
    };
  },
  RESET_STATE: (state) => {
    return {
      ...state,
      ...defaultState,
    };
  },
  SET_LOADING: (state, { key }) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        [key]: true,
      },
    };
  },
};

const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};

export default reducer;
