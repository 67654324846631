const defaultState = {
  rules: [],
  partners: [],
};

export const init = () => defaultState;

const ACTION_HANDLERS = {
  LIST_PARTNERS_SUCCESSFUL: (state, sagaData) => ({
    ...state,
    partners: sagaData.partners,
  }),
};

const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};

export default reducer;
