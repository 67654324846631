import { createGenerateClassName, StylesProvider, ThemeProvider } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const generateClassName = createGenerateClassName({
  productionPrefix: 'manage',
  seed: 'manage',
});

const ThemeContext = ({ children, theme }) => {
  const [activeTheme] = useState(theme);
  return (
    <StylesProvider generateClassName={generateClassName}>
      <ThemeProvider theme={activeTheme}>{children}</ThemeProvider>
    </StylesProvider>
  );
};

ThemeContext.propTypes = {
  children: PropTypes.node.isRequired,
  theme: PropTypes.object.isRequired,
};

export default ThemeContext;
