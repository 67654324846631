import { authService } from '@yojee/api/authService';
import { BaseService } from '@yojee/api/baseService';

export class ServiceTimeService extends BaseService {
  constructor({ authService }) {
    super();
    this.authService = authService;
  }

  listServiceTimes = (params) => {
    return this.authService
      .get(this.getUmbrellaApiUrl('dispatcher/service_time_conditions'), params)
      .then((response) => {
        return this.extractData(response);
      })
      .catch((error) => this.handleError(error));
  };

  createServiceTime = (service_time) => {
    return this.authService
      .post(this.getUmbrellaApiUrl('dispatcher/service_time_conditions'), service_time)
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  updateServiceTime = (service_time) => {
    return this.authService
      .put(this.getUmbrellaApiUrl(`dispatcher/service_time_conditions/${service_time.id}`), service_time)
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  deleteServiceTime = (id) => {
    return this.authService
      .delete(this.getUmbrellaApiUrl(`dispatcher/service_time_conditions/${id}`))
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };
}

export const serviceTimeService = new ServiceTimeService({ authService });
