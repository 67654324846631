import { call, put, takeLatest } from 'redux-saga/effects';

import { manifestService } from '../../services/manifestService';

export default function* manifestTemplatesSaga() {
  yield takeLatest('REQUEST_GET_MANIFEST', getManifest);
  yield takeLatest('REQUEST_GET_MANIFEST_PDF', getManifestPDF);
  yield takeLatest('RESET_ALL_PDF_DATA', resetAllPDF);
}

function* getManifest({ payload: { q, page, page_size } }) {
  try {
    const { data, pagination } = yield call(manifestService.getManifest, { q, page, page_size });
    yield put({ type: 'REQUEST_GET_MANIFEST_SUCCESSFUL', data, pagination, q });
  } catch (error) {
    console.error(error);
    yield put({ type: 'REQUEST_GET_WORKERS_FAILED', error });
  }
}

function* getManifestPDF({ params: { workersId, totalPDF, type_pdf } }) {
  try {
    const allPDF = [];

    for (let i = 0; i < totalPDF; i++) {
      const pdfFile = yield call(manifestService.generatePDF, { worker_id: workersId[i], type: type_pdf });
      allPDF.push(pdfFile);
    }

    if (type_pdf === 'worker') {
      yield put({ type: 'REQUEST_GET_MANIFEST_PDF_SUCCESSFUL', data: [...allPDF] });
    }

    if (type_pdf === 'loader') {
      yield put({ type: 'REQUEST_GET_LOADLIST_PDF_SUCCESSFUL', data: [...allPDF] });
    }
  } catch (error) {
    console.error(error);
    yield put({ type: 'REQUEST_GET_WORKERS_FAILED', error });
  }
}

function* resetAllPDF() {
  yield put({ type: 'REQUEST_GET_MANIFEST_PDF_SUCCESSFUL', data: [] });
  yield put({ type: 'REQUEST_GET_LOADLIST_PDF_SUCCESSFUL', data: [] });
}
