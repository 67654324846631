const defaultState = {
  loading: {},
  info: {
    logoUpdateURL: undefined,
    invoiceLogoUpdateURL: undefined,
    bannerUpdateURL: undefined,
    faviconUpdateURL: undefined,
    labelLogoUpdateURL: undefined,
  },
};

export const init = () => {
  return defaultState;
};

export const brandingMessagesSuccessEvents = {
  UPDATE_BRANDING_SETTING_SUCCESS: 'Branding Setting updated successfully',
  REQUEST_UPLOAD_IMAGE_SUCCESS: ({
    data: {
      result: { isWarning, file_type: fileType },
    },
  }) => (isWarning ? null : `Image ${fileType} uploaded successfully`),
};

export const brandingMessagesErrorEvents = {
  UPDATE_BRANDING_SETTING_ERROR: (data) =>
    `Failed to update branding setting: ${data.error && data.error.statusCode} - ${data.error && data.error.message}`,
  REQUEST_UPLOAD_IMAGE_ERROR: ({ error: { file_type: fileType, error } }) =>
    `Failed to upload image ${fileType}: ${error.statusCode} - ${error.message}`,
};

export const brandingMessagesWarningEvents = {
  REQUEST_UPLOAD_IMAGE_SUCCESS: ({
    data: {
      result: { isWarning, file_type: fileType, dimensions },
    },
  }) =>
    isWarning
      ? `Please Note: The ${fileType} image does not have the recommended size of ${dimensions.width}x${dimensions.height}px!`
      : undefined,
};

const ACTION_HANDLERS = {
  UPDATE_BRANDING_SETTING_SUCCESS: (state) => {
    return {
      ...state,
      info: {
        ...defaultState.info,
      },
    };
  },
  REQUEST_UPLOAD_IMAGE_SUCCESS: (state, data) => {
    const { file_type, fileURL } = data.data.result;
    const keyUpdate =
      file_type === 'favicon'
        ? 'faviconUpdateURL'
        : file_type === 'banner'
        ? 'bannerUpdateURL'
        : file_type === 'label_logo'
        ? 'labelLogoUpdateURL'
        : file_type === 'high_resolution_logo'
        ? 'invoiceLogoUpdateURL'
        : 'logoUpdateURL';
    return {
      ...state,
      info: { ...state.info, [keyUpdate]: fileURL },
    };
  },
  REMOVE_UPDATE_IMAGE_URL: (state, data) => {
    const file_type = data.payload;
    const keyUpdate =
      file_type === 'favicon'
        ? 'faviconUpdateURL'
        : file_type === 'banner'
        ? 'bannerUpdateURL'
        : file_type === 'label_logo'
        ? 'labelLogoUrl'
        : file_type === 'high_resolution_logo'
        ? 'invoiceLogoUrl'
        : 'logoUpdateURL';
    return {
      ...state,
      info: { ...state.info, [keyUpdate]: null },
    };
  },
};

const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};

export default reducer;
