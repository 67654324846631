import { all, fork, select, takeEvery } from 'redux-saga/effects';

import getEnv from '@yojee/helpers/env/getEnv';
import addressPickerSagas from '@yojee/ui/address-picker/saga/saga';
import bulk from '@yojee/ui/BulkUpdate/redux/bulkSaga';
import featureManagementSagas from '@yojee/ui/feature-management/saga/saga';
import { OrderBookingSagas } from '@yojee/ui/new-order-booking/saga/saga';
import { UIAuthSagas } from '@yojee/ui/onboarding/sagas/auth/saga';
import { UIUserManagementSagas } from '@yojee/ui/onboarding/sagas/userManagement/saga';

import address from './addresses/addressSaga';
import billingParty from './billing_party/billingPartySaga';
import brandingManagement from './branding/brandingSaga';
import company from './company/companySaga';
import companySetting from './companySettings/companySettingSaga';
import connote from './connote/connoteSaga';
import dispatchRule from './dispatch_rule/dispatchRuleSaga';
import driverRoutes from './driver_routes/driverRoutesSaga';
import driverProcess from './driverProcess/driverProcessSaga';
import financeSaga from './finance/financeSaga';
import integrations from './integrations/integrationsSaga';
import itemType from './itemTypes/itemTypeSaga';
import login from './login/loginSaga';
import manifest from './manifest/manifestSaga';
import manifestTemplates from './manifestTemplates/manifestTemplatesSaga';
import network from './network/networkSaga';
import notification from './notifications/notificationSaga';
import operations from './operations/operationSaga';
import partner from './partner/partnerSaga';
import pod from './pod/podSaga';
import chargeCode from './rate_charge_types/chargeCodeSaga';
import { rateChargeTypesSaga } from './rate_charge_types/rateChargeTypesSaga';
import ratings from './ratings/ratingsSaga';
import reasonCode from './reason_codes/reasonCodeSaga';
import savedFilter from './saved_filter/savedFilterSaga';
import schedules from './schedule/scheduleSaga';
import senderOrganisation from './sender_organisations/senderOrganisationSaga';
import serviceTime from './serviceTimes/serviceTimeSaga';
import startup from './startup/startupSaga';
import tag from './tags/tagSaga';
import taskExceptions from './taskExceptions/taskExceptionsSaga';
import users from './userManagement/userSaga';
import vehicles from './vehicles/vehicleSaga';
import waybill from './waybill/waybillSaga';
import worker from './worker/workerSaga';
import workerTeamManagement from './workerTeamManagement/workerTeamManagementSaga';

function* watchAndLog() {
  if (getEnv('REACT_APP_IS_LOCAL') === 'true' && getEnv('REACT_OUTPUT_STATE_CHANGES')) {
    yield takeEvery('*', function* logger(action) {
      const state = yield select();
      console.log('action from Manage', action);
      console.log("Manage's state after", state);
    });
  }
}

export default function* root() {
  yield all([
    fork(watchAndLog),
    fork(login),
    fork(company),
    fork(dispatchRule),
    fork(savedFilter),
    fork(partner),
    fork(worker),
    fork(connote),
    fork(manifest),
    fork(manifestTemplates),
    fork(companySetting),
    fork(users),
    fork(driverProcess),
    fork(workerTeamManagement),
    fork(notification),
    fork(brandingManagement),
    fork(address),
    fork(billingParty),
    fork(senderOrganisation),
    fork(startup),
    fork(driverRoutes),
    fork(operations),
    fork(network),
    fork(pod),
    fork(vehicles),
    fork(schedules),
    fork(waybill),
    fork(bulk),
    fork(taskExceptions),
    fork(itemType),
    fork(tag),
    fork(serviceTime),
    fork(ratings),
    fork(rateChargeTypesSaga),
    fork(OrderBookingSagas),
    fork(UIUserManagementSagas),
    fork(UIAuthSagas),
    fork(financeSaga),
    fork(chargeCode),
    fork(addressPickerSagas),
    fork(featureManagementSagas),
    fork(integrations),
    fork(reasonCode),
  ]);
}
