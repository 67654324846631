import { authService } from '@yojee/api/authService';
import { BaseService } from '@yojee/api/baseService';

export class OperationService extends BaseService {
  constructor({ authService }) {
    super();
    this.authService = authService;
  }

  getOperations = () => {
    return this.authService
      .get(this.getUmbrellaApiUrl(`dispatcher/operations`))
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? this.transformOperationsData(result['data']) : null;
      })
      .catch((error) => this.handleError(error));
  };

  createOperation = (data) => {
    return this.authService
      .post(this.getUmbrellaApiUrl(`dispatcher/operations`), this.transformCreateEditRequestData(data))
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  deleteOperation = (id) => {
    return this.authService
      .delete(this.getUmbrellaApiUrl(`dispatcher/operations/${id}`))
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  updateOperation = (data) => {
    return this.authService
      .put(this.getUmbrellaApiUrl(`dispatcher/operations/${data.id}`), this.transformCreateEditRequestData(data))
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  transformOperationsData = (data) => {
    if (!data) {
      return;
    }
    return data.map((op) => {
      return {
        ...op,
        operation_steps: op['operation_steps'].map((step, idx) => {
          let isLinkedToPrev = false;
          if (idx === 0) {
            isLinkedToPrev = op['link_to_previous'];
          } else {
            isLinkedToPrev = step['step_group'] === op['operation_steps'][idx - 1]['step_group'];
          }
          return {
            ...step,
            //  additional data to be used in component
            is_linked_to_prev: isLinkedToPrev,
            invalidated: false,
            is_open: false,
            errors: {
              address1: null,
              taskType: null,
            },
          };
        }),
      };
    });
  };

  transformCreateEditRequestData = (data) => {
    if (!data) {
      return;
    }
    const validatedSteps =
      data['operation_steps'].length > 0 ? data['operation_steps'].filter((step) => !step.invalidated) : [];
    let stepGroupId = 0;
    const isLinkedToPrev = data['link_to_previous'];
    const isLinkedToNext = data['link_to_next'];
    return {
      name: data['name'] || '',
      link_to_previous: isLinkedToPrev,
      link_to_next: isLinkedToNext,
      operation_steps:
        validatedSteps.length > 0
          ? validatedSteps.map((step) => {
              const stepData = {
                type: step['type'],
                step_group: step['is_linked_to_prev'] ? stepGroupId : ++stepGroupId,
                address_item_id: step['address_item_id'],
              };
              if (step['service_by_hub_id'] && step['service_by_hub_id'] > 0) {
                stepData['service_by_hub_id'] = step['service_by_hub_id'];
              }
              return stepData;
            })
          : [],
    };
  };
}

const operationService = new OperationService({ authService });
export default operationService;
