import { authService } from '@yojee/api/authService';
import { BaseService } from '@yojee/api/baseService';

export class NetworkService extends BaseService {
  constructor({ authService }) {
    super();
    this.authService = authService;
  }

  getHubs = () => {
    return this.authService
      .get(this.getUmbrellaApiUrl(`dispatcher/network/hubs`))
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  getRegions = () => {
    return this.authService
      .get(this.getUmbrellaApiUrl(`dispatcher/network/regions`))
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };
}

const networkService = new NetworkService({ authService });
export default networkService;
