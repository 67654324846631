import { t } from 'i18next';

import {
  batchUploadDefaultState,
  generateBatchUploadReducerMethods,
} from '@yojee/ui/onboarding/helpers/batchUploadHelper';

const defaultState = {
  batchUpload: batchUploadDefaultState,
  error: {
    failed: false,
    message: null,
  },
  deleteCompleted: false,
};

export const chargeCodeMessagesSuccessEvents = {
  BULK_REQUEST_DELETE_CHARGE_CODE_SUCCESS: 'Charge code deleted successfully',
  CHARGE_CODE_BATCH_UPLOAD_SUCCEEDED: t('Batch upload completed'),
};

export const chargeCodeMessagesErrorEvents = {
  BULK_REQUEST_DELETE_CHARGE_CODE_FAIL: ({ payload }) => payload || `Failed to delete charge code!`,
};

export const init = () => {
  return defaultState;
};

const ACTION_HANDLERS = {
  REQUEST_DELETE_CHARGE_CODE_IN_PROCESS: (state) => {
    return {
      ...state,
      deleteCompleted: false,
    };
  },
  BULK_REQUEST_DELETE_CHARGE_CODE_SUCCESS: (state) => {
    return {
      ...state,
      deleteCompleted: true,
    };
  },
  ...generateBatchUploadReducerMethods({ prefix: 'CHARGE_CODE' }),
};

const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};

export default reducer;
