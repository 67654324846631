import { IntegrationProfile } from '../../components/Integrations/types';
import { defaultState, State } from '../../reducers/integrations';

export const getIntegrationTypes = () => {
  return { type: 'GET_INTEGRATION_TYPES' };
};

export const updateIntegrationProfile = (data: IntegrationProfile) => {
  return { type: 'UPDATE_INTEGRATION_PROFILE', data };
};

export const createIntegrationProfile = (data: IntegrationProfile) => {
  return { type: 'CREATE_INTEGRATION_PROFILE', data };
};

export const getProfileDetails = () => {
  return { type: 'GET_PROFILE_DETAILS' };
};

export const updateIntegrationData = (data: Partial<State>) => {
  return { type: 'UPDATE_INTEGRATION_DATA', ...data };
};

export const clearIntegrationData = () => {
  return { type: 'UPDATE_INTEGRATION_DATA', ...defaultState };
};
