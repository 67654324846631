import MuiFormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import CreateTableSelect from 'react-select/creatable';

// todo change theme here if possible
import { theme } from '../../theme';
import InputLabel from '../InputLabel/InputLabel';
import CircleNumber from './CircleNumber';
import DefaultOption from './DefaultOption';
import MultiCheckboxOption from './MultiCheckboxOption';
import ValueContainer from './ValueContainer';

const useStyles = makeStyles(() => ({
  labelContainer: ({ labelHeight }) => ({
    height: labelHeight || '0.75rem',
  }),
  extraLabelAlignmentRight: {
    textAlign: 'right',
    pointerEvents: 'auto',
    fontSize: '0.75rem',
  },
}));

const styledBy = (property, mapping) => {
  return mapping[property] || mapping.default;
};

const customStyle = (props) => ({
  menuPortal: (base) => ({ ...base, zIndex: theme.zIndex.tooltip }),
  container: () => ({
    fontSize: '0.875rem',
    minHeight: theme.spacing(5),
    // height: theme.spacing(5),
    marginTop: theme.spacing(1),
  }),
  control: (base, state) => ({
    ...base,
    minHeight: theme.spacing(5),
    // height: theme.spacing(5),
    display: 'flex',
    alignContent: 'center',
    boxShadow: 'none',
    backgroundColor: state.isDisabled ? theme.palette?.action?.disabledBackground : theme.palette.common.white,
    borderColor: state.isFocused
      ? styledBy(props.color, {
          default: theme.palette.primary.main,
          primary: theme.palette.primary.main,
          success: theme.palette.success.main,
          error: theme.palette.error.main,
          disable: theme.palette.grey[200], //#c4c4c4
        })
      : props.error
      ? theme.palette.error.main
      : state.isDisabled
      ? 'transparent'
      : '#c4c4c4',
    '&:hover': {
      borderColor:
        state.isFocused || state.isDisabled
          ? 'none'
          : props.error
          ? theme.palette.error.dark
          : theme.palette.primaryBlack.main,
    },
  }),
  valueContainer: (base) => ({
    ...base,
    minHeight: theme.spacing(3),
    // height: theme.spacing(3),
    display: 'flex',
    padding: `0px ${theme.spacing(2)}px`,
    gap: theme.spacing(0.5),
  }),
  indicatorsContainer: (base) => ({
    ...base,
    minHeight: theme.spacing(3),
    // height: theme.spacing(3),
  }),
  dropdownIndicator: () => ({
    // display: props.isMulti ? 'none' : 'flex',
    marginRight: theme.spacing(2),
  }),
  singleValue: (styles) => ({
    ...styles,
    marginLeft: 0,
    marginRight: 0,
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  multiValue: (styles) => ({
    ...styles,
    backgroundColor: styledBy(props.color, {
      default: theme.palette.black.B4,
      primary: theme.palette.primary.tint,
      success: theme.palette.success.tint,
      error: theme.palette.error.tint,
      disable: theme.palette.grey[200],
    }),
    // height: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }),
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: theme.palette.common.black,
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    pointerEvents: props.disabled ? 'none' : 'auto',
  }),
  clearIndicator: () => ({
    display: 'none',
  }),
  labelStyles: {
    display: 'block',
    lineHeight: '12px',
    wordWrap: 'break-word',
    color: theme.palette?.black?.['B60'],
    fontSize: '0.75rem',
    fontWeight: 600,
  },
  labelRequired: {
    color: theme.palette.error.main,
  },
  option: (styles, { isSelected, data }) => ({
    ...styles,
    backgroundColor: isSelected ? theme.palette?.primary?.tint : null,
    color: 'inherit',
    '&:hover': {
      backgroundColor: theme.palette?.primary?.tint,
    },
    ...(data?.css || {}),
  }),
  groupHeading: (styles) => ({
    ...styles,
    color: theme.palette.primaryBlack.main,
    fontSize: '0.875rem',
    textTransform: 'initial',
    fontWeight: theme.typography.fontWeightBold,
  }),
});

const ModifiedValueContainer = (props) => <ValueContainer data-cy="123" showSum={true} {...props} />;

const TYPE_ASYNC = 'async';
const TYPE_DEFAULT = 'default';
const TYPE_CREATE_TABLE = 'creatable';

const selectComponents = {
  [TYPE_DEFAULT]: Select,
  [TYPE_ASYNC]: AsyncSelect,
  [TYPE_CREATE_TABLE]: CreateTableSelect,
};

const ReactSelect = ({
  selectType = 'default',
  showSum = false,
  showCircleSum = false,
  multiCheckbox = false,
  disabled = false,
  dataCy = '',
  optionDataCy = 'option-item',
  isRawValue = false,
  labelHeight = '',
  labelRootClassName = '',
  ...restProps
}) => {
  const { t } = useTranslation();
  const classes = useStyles({ labelHeight });
  const { error, helperText, isMulti, extraLabel = null, options, value } = restProps;
  const { labelStyles, labelRequired, ...restStyles } = customStyle({ disabled, ...restProps });
  const isShowLabel = restProps.label || extraLabel;

  // configs
  const Component = selectComponents[selectType];
  const showSelectedOptions = showSum || multiCheckbox;

  const selectionLabel = (
    <Grid container spacing={0} className={classes.labelContainer}>
      {restProps.label && (
        <Grid item xs>
          <InputLabel
            className={labelRootClassName}
            label={restProps.label}
            required={restProps.required}
            error={error}
          />
        </Grid>
      )}
      {extraLabel && (
        <Grid item xs className={classes.extraLabelAlignmentRight}>
          {extraLabel}
        </Grid>
      )}
    </Grid>
  );

  const findSelectedObject = (value) => options?.find((option) => option.value === value) || value;
  const findSelectedOptions = (value) => {
    if (Array.isArray(value)) {
      return value.map(findSelectedObject);
    } else {
      return findSelectedObject(value);
    }
  };

  return (
    <>
      {isShowLabel && selectionLabel}
      <div data-cy={dataCy || undefined}>
        <Component
          menuPortalTarget={window.body}
          isDisabled={disabled}
          menuPosition="fixed"
          menuPlacement="auto"
          styles={restStyles}
          defaultOptions
          backspaceRemovesValue={false}
          closeMenuOnSelect={!isMulti}
          hideSelectedOptions={!showSelectedOptions}
          components={{
            ...(showSum ? { ValueContainer: ModifiedValueContainer } : {}),
            ...(showCircleSum ? { DropdownIndicator: CircleNumber } : {}),
            ...(multiCheckbox
              ? { Option: MultiCheckboxOption({ optionDataCy }) }
              : { Option: DefaultOption({ optionDataCy }) }),
          }}
          {...restProps}
          placeholder={restProps.placeholder || t('Select...')}
          value={isRawValue ? findSelectedOptions(value) : value}
        />
      </div>

      {helperText && <MuiFormHelperText error={error}>{helperText}</MuiFormHelperText>}
    </>
  );
};

ReactSelect.propTypes = {
  selectType: PropTypes.oneOf(['default', 'async', 'creatable']),
  showSum: PropTypes.bool,
  multiCheckbox: PropTypes.bool,
  isRawValue: PropTypes.bool,
  labelRootClassName: PropTypes.string,
};

export default React.memo(ReactSelect);
