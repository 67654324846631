import getEnv from '@yojee/helpers/env/getEnv';

export default class Utilities {
  static domain = () => (window.location.hostname || '').split('.').slice(-2).join('.');
  static manageUrl = (slug) => getEnv('REACT_APP_MANAGE_URL').replace('://', `://${slug}.`);
  static networkUrl = (slug) => getEnv('REACT_APP_NETWORK_URL').replace('://', `://${slug}.`);
  static prefix = () => getEnv('REACT_APP_PREFIX') || '';
  static buildPath = (path) => `${Utilities.prefix()}${path}`;
  static getDispatcherUrlBySlug = (slug, downstreamCompanySlug) =>
    `${window.location.protocol}//${(window.location.hostname || '').replace(slug, downstreamCompanySlug)}`;
  static parseDecimal = (value, places) => {
    if (typeof value !== 'string') {
      value = value.toString();
    }
    return value.indexOf('.') >= 0
      ? value.substr(0, value.indexOf('.')) + value.substr(value.indexOf('.'), places + 1)
      : value;
  };

  static numberOfDecimal = (number) => String(number).split('.')?.[1]?.length || 0;

  static getBookingUrl = () => {
    const ENV = getEnv('REACT_APP_ENV');

    return `${window.location.protocol}//${window.location.hostname.replace(
      'manage',
      ENV === 'eks' ? 'booking' : 'book'
    )}`;
  };
}
