import { authService } from '@yojee/api/authService';
import { BaseService } from '@yojee/api/baseService';

export class ItemTypeService extends BaseService {
  constructor({ authService }) {
    super();
    this.authService = authService;
  }

  listItemTypes = (params) => {
    return this.authService
      .get(this.getUmbrellaApiUrl('dispatcher/item_types'), params)
      .then((response) => {
        return this.extractData(response);
      })
      .catch((error) => this.handleError(error));
  };

  createItemType = (itemType) => {
    return this.authService
      .post(this.getUmbrellaApiUrl('dispatcher/item_types'), itemType)
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  updateItemType = (itemType) => {
    return this.authService
      .put(this.getUmbrellaApiUrl(`dispatcher/item_types/${itemType.id}`), itemType)
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  deleteItemType = (id) => {
    return this.authService
      .delete(this.getUmbrellaApiUrl(`dispatcher/item_types/${id}`))
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };
}

export const itemTypeService = new ItemTypeService({ authService });
