import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { Trans } from 'react-i18next';

import Button from '@yojee/ui/base/furnitures/Button';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '8px',
    justifyContent: 'center',
  },
}));

const DefaultFallback = ({ resetErrorBoundary }) => {
  const classes = useStyles();

  return (
    <div role="alert" className={classes.container}>
      <Typography color="textSecondary" gutterBottom>
        <Trans>Something went wrong</Trans>
      </Typography>
      <Button onClick={resetErrorBoundary} color="primary" variant="contained" data-cy="error-fallback-retry-button">
        Try again
      </Button>
    </div>
  );
};

export default DefaultFallback;
