import './before-index';

import { configureStore } from '@reduxjs/toolkit';
import { createBrowserHistory } from 'history';
import React from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { createReduxHistoryContext } from 'redux-first-history';
import createSagaMiddleware from 'redux-saga';

import i18n from '@yojee/helpers/i18nLoader';

import App from './App';
import { history } from './createHistory';
import createRootReducer from './reducers';
import sagas from './sagas';

const sagaMiddleware = createSagaMiddleware();

const name = 'yojeeManage';
const containerId = `${name}-container`;

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
  history,
});

let sagaRunner;
window[`render${name}`] = (containerId, props) => {
  const { preloadedState, authData } = props;

  const rootReducer = createRootReducer(routerReducer);
  const store = configureStore({
    reducer: rootReducer,
    middleware: [sagaMiddleware, routerMiddleware],
    preloadedState,
    devTools: process.env.NODE_ENV === 'development',
  });

  const history = createReduxHistory(store);

  sagaRunner = sagaMiddleware.run(sagas);

  ReactDOM.render(
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <App history={history} initialAuth={authData} />
      </Provider>
    </I18nextProvider>,
    document.getElementById(containerId)
  );
};

window[`unmount${name}`] = () => {
  if (document.getElementById(containerId)) {
    sagaRunner.cancel();
    ReactDOM.unmountComponentAtNode(document.getElementById(containerId));
  }
};

if (!document.getElementById(containerId)) {
  const history = createBrowserHistory();
  window[`render${name}`]('root', { preloadedState: {}, history });
}
