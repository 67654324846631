const defaultState = {
  company: {},
  id: null,
  sections: [],
  isLoading: false,
  isError: false,
  isSuccess: false,
  statusCode: null,
  sectionsInfo: [],
};

export const manifestTemplatesMessagesSuccessEvents = {
  REQUEST_UPDATE_MANIFEST_TEMPLATES_SUCCESS: ({ manifestTemplates: { message } }) => message,
};

export const manifestTemplatesMessagesErrorEvents = {
  REQUEST_UPDATE_MANIFEST_TEMPLATES_FAILED: ({ error: { message } }) => message,
};

export const init = () => {
  return defaultState;
};

const ACTION_HANDLERS = {
  REQUEST_GET_MANIFEST_TEMPLATES_SUCCESSFUL: (state, data) => {
    return {
      ...state,
      ...data.data[0],
    };
  },
  REQUEST_GET_MANIFEST_TEMPLATES_SECTIONS_SUCCESSFUL: (state, data) => {
    return {
      ...state,
      sectionsInfo: data.data,
    };
  },
  REQUEST_UPDATE_MANIFEST_TEMPLATES_SUCCESS: (state) => {
    return {
      ...state,
      isError: false,
      isSuccess: true,
      isLoading: false,
    };
  },
  REQUEST_UPDATE_MANIFEST_TEMPLATES_FAILED: (state) => {
    return {
      ...state,
      isError: true,
      isSuccess: false,
      isLoading: false,
    };
  },
  SET_MANIFEST_TEMPLATES_PROGRESS: (state) => {
    return {
      ...state,
      isLoading: true,
    };
  },
};

const reducer = (state = defaultState, action = null) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};

export default reducer;
