import MenuGroup from './components/MenuGroup';
import useTopMenuStyles from './components/topMenuStyles';
import useTopMenuItems from './hooks/useTopMenuItems';

const TopMenu = () => {
  const classes = useTopMenuStyles();
  const topMenuItems = useTopMenuItems();

  const onNavScroll = ({ currentTarget: nav }) => {
    nav.style.setProperty('--scrollLeft', `-${nav.scrollLeft}px`);
  };

  return (
    <>
      <nav className={classes.topMenu}>
        <div className={classes.inner} onScroll={onNavScroll}>
          <MenuGroup childItems={topMenuItems} menuLevel={0} />
        </div>
      </nav>
      <div className={classes.fixedPlaceholder} />
    </>
  );
};

export default TopMenu;
