import { MuiThemeProvider } from '@material-ui/core/styles';
import React, { useContext, useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { ManageHeader, theme } from '@yojee/ui/base';
import BaseSettingComponent from '@yojee/ui/common/BaseSettingComponent/BaseSettingComponent';
import { ReadOnlyContext } from '@yojee/ui/ReadOnlyHelper';

import { getParnershipInfo, updateCompanySettings } from '../../../sagas/companySettings/companySettingActions';
import { getValue } from '../../../utils/accessHelper';
import CompanyBookingPage from './CompanyBookingPage';
import CompanyInformation from './CompanyInformation';
import CompanyLinks from './CompanyLinks';
import CompanyTrackingPage from './CompanyTrackingPage';
import { getLabelError, getUrlError, prefixProtocol } from './helper';

const CompanySettings = () => {
  const readOnly = useContext(ReadOnlyContext);
  const dispatch = useDispatch();

  const [address, setAddress] = useState(undefined);
  const [phone, setPhone] = useState(undefined);
  const [senderLanguage, setSenderLanguage] = useState(undefined);
  const [trackingPageLanguage, setTrackingPageLanguage] = useState(undefined);
  const [taskServiceTime, setTaskServiceTime] = useState(undefined);
  const [link1Label, setLink1Label] = useState(undefined);
  const [link1Url, setLink1Url] = useState(undefined);
  const [link2Label, setLink2Label] = useState(undefined);
  const [link2Url, setLink2Url] = useState(undefined);
  const [isLiveTrackingEnabled, setIsLiveTrackingEnabled] = useState(false);
  const [isSenderInfoAndAddressShown, setIsSenderInfoAndAddressShown] = useState(false);

  const cip = useSelector((state) => getValue(state, 'companySettings.info.partnerShipInfo.cip', ''));
  const company = useSelector((state) => state.company.companyData);
  const accessToken = useSelector((state) => state.login.accountInfo.token);
  const companyServiceTime = getValue(company, 'settings.company.task_service_time');
  const isLiveTrackingEnabledSetting = getValue(company, 'settings.features.live_tracking_map', false);
  const isSenderInfoAndAddressShownSetting = company?.settings?.sender?.['live_tracking.address.show'];
  const companySenderLanguage = getValue(company, 'settings.sender.language', '')?.toLowerCase();
  const companyTrackingPageLanguage = company?.settings?.sender?.['live_tracking.language']?.toLowerCase();
  const companySettings = getValue(company, 'settings.company');
  const companyAddress = companySettings.company_address;
  const companyPhone = companySettings.company_phone;
  const companyTermsAndConditions = companySettings.company_terms_conditions;
  const companyAgreement1Label = companyTermsAndConditions?.company_agreement1_label;
  const companyAgreement1Url = companyTermsAndConditions?.company_agreement1_url;
  const companyAgreement2Label = companyTermsAndConditions?.company_agreement2_label;
  const companyAgreement2Url = companyTermsAndConditions?.company_agreement2_url;

  const link1LabelError = getLabelError(link1Label, link1Url);
  const link2LabelError = getLabelError(link2Label, link2Url);
  const link1UrlError = getUrlError(link1Label, link1Url);
  const link2UrlError = getUrlError(link2Label, link2Url);

  useEffect(() => {
    setAddress(companyAddress);
  }, [companyAddress]);

  useEffect(() => {
    setPhone(companyPhone);
  }, [companyPhone]);

  useEffect(() => {
    setTaskServiceTime(companyServiceTime);
  }, [companyServiceTime]);

  useEffect(() => {
    setSenderLanguage(companySenderLanguage);
  }, [companySenderLanguage]);

  useEffect(() => {
    setTrackingPageLanguage(companyTrackingPageLanguage);
  }, [companyTrackingPageLanguage]);

  useEffect(() => {
    setIsLiveTrackingEnabled(!!isLiveTrackingEnabledSetting);
  }, [isLiveTrackingEnabledSetting]);

  useEffect(() => {
    setIsSenderInfoAndAddressShown(!!isSenderInfoAndAddressShownSetting);
  }, [isSenderInfoAndAddressShownSetting]);

  useEffect(() => {
    if (companyTermsAndConditions) {
      setLink1Label(companyAgreement1Label);
      setLink1Url(companyAgreement1Url);
      setLink2Label(companyAgreement2Label);
      setLink2Url(companyAgreement2Url);
    }
  }, [companyTermsAndConditions]);

  useEffect(() => {
    if (accessToken && !cip) {
      dispatch(getParnershipInfo());
    }
  }, [accessToken]);

  const resetcompanySettings = () => {
    setAddress(companyAddress);
    setPhone(companyPhone);
    setTaskServiceTime(companyServiceTime);
    setSenderLanguage(companySenderLanguage);
    setIsLiveTrackingEnabled(isLiveTrackingEnabledSetting);
  };

  const isInformationSectionChanged = () => {
    const pair1Changed = link1Label !== companyAgreement1Label || link1Url !== companyAgreement1Url;
    const pair1Valid = (!link1Label && !link1Url) || (!!link1Label && !!link1Url && !link1UrlError);

    const pair2Changed = link2Label !== companyAgreement2Label || link2Url !== companyAgreement2Url;
    const pair2Valid = (!link2Label && !link2Url) || (!!link2Label && !!link2Url && !link2UrlError);
    return (
      (!!address && address !== companyAddress) ||
      (!!phone && phone !== companyPhone) ||
      (!!senderLanguage && senderLanguage !== companySenderLanguage) ||
      (!!trackingPageLanguage && trackingPageLanguage !== companyTrackingPageLanguage) ||
      (!!taskServiceTime && taskServiceTime !== companyServiceTime) ||
      (pair1Valid && pair2Valid && (pair1Changed || pair2Changed)) ||
      isLiveTrackingEnabled !== isLiveTrackingEnabledSetting ||
      isSenderInfoAndAddressShown !== isSenderInfoAndAddressShownSetting
    );
  };

  const onSaveSettings = () => {
    dispatch(
      updateCompanySettings({
        address,
        phone,
        senderLanguage: senderLanguage,
        trackingPageLanguage,
        taskServiceTime: taskServiceTime ? taskServiceTime : companyServiceTime,
        link1Label,
        link1Url: prefixProtocol(link1Url),
        link2Label,
        link2Url: prefixProtocol(link2Url),
        isLiveTrackingEnabled: isLiveTrackingEnabled,
        isSenderInfoAndAddressShown,
      })
    );
  };

  const isDataChanged = isInformationSectionChanged();

  const primaryBtns = [
    {
      label: <Trans>Cancel</Trans>,
      color: 'default',
      onClick: resetcompanySettings,
      'data-cy': 'cancel-settings',
    },
    {
      label: <Trans>Save</Trans>,
      color: 'primary',
      onClick: onSaveSettings,
      'data-cy': 'save-settings',
    },
  ];

  return (
    <MuiThemeProvider theme={theme}>
      <BaseSettingComponent isLoading={false} customContentPadding={{ padding: '16px' }}>
        <ManageHeader
          title={<Trans>Company Settings</Trans>}
          titleVariant="main"
          primaryBtns={isDataChanged && primaryBtns}
          containerStyle={{ marginBottom: theme.spacing(4) }}
        />

        <CompanyInformation
          company={company}
          cip={cip}
          taskServiceTime={taskServiceTime}
          setTaskServiceTime={setTaskServiceTime}
          address={address}
          setAddress={setAddress}
          phone={phone}
          setPhone={setPhone}
        />

        <CompanyLinks
          readOnly={readOnly}
          link1Label={link1Label}
          setLink1Label={setLink1Label}
          link1Url={link1Url}
          setLink1Url={setLink1Url}
          link2Label={link2Label}
          setLink2Label={setLink2Label}
          link2Url={link2Url}
          setLink2Url={setLink2Url}
          link1LabelError={link1LabelError}
          link1UrlError={link1UrlError}
          link2LabelError={link2LabelError}
          link2UrlError={link2UrlError}
        />

        <CompanyBookingPage senderLanguage={senderLanguage} setSenderLanguage={setSenderLanguage} />

        <CompanyTrackingPage
          isLiveTrackingEnabled={isLiveTrackingEnabled}
          setIsLiveTrackingEnabled={setIsLiveTrackingEnabled}
          isSenderInfoAndAddressShown={isSenderInfoAndAddressShown}
          setIsSenderInfoAndAddressShown={setIsSenderInfoAndAddressShown}
          trackingPageLanguage={trackingPageLanguage}
          setTrackingPageLanguage={setTrackingPageLanguage}
        />
      </BaseSettingComponent>
    </MuiThemeProvider>
  );
};
export default CompanySettings;
