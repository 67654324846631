import { authService } from './authService/index';
import { BaseService } from './baseService/index';

export class ReasonCodeApi extends BaseService {
  constructor({ authService }) {
    super();
    this.authService = authService;
  }

  getReasonCodes = () => {
    return this.authService
      .get(this.getUmbrellaApiUrlV4(`company/reason_codes`))
      .then((response) => this.extractData(response))
      .catch((error) => this.handleNewErrorFormat(error));
  };
}

export const reasonCodeApi = new ReasonCodeApi({ authService });
