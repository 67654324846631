import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MuiSwitch from '@material-ui/core/Switch';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import CircularLoading from '../CircularLoading';

const NormalSwitch = withStyles((theme) => ({
  root: {
    width: 28,
    height: 16,
    padding: 1,
    display: 'flex',
  },
  switchBase: {
    padding: 2,
    color: 'white',
    '&$checked': {
      transform: 'translateX(12px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: 'none',
  },
  track: {
    backgroundColor: theme.palette?.black?.B32,
    borderRadius: 8,
    opacity: 1,
  },
  checked: {},
}))(MuiSwitch);

const LargeSwitch = withStyles((theme) => ({
  root: {
    width: 44,
    height: 24,
    padding: 1,
    display: 'flex',
  },
  switchBase: {
    padding: 3,
    color: 'white',
    '&$checked': {
      transform: 'translateX(20px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 18,
    height: 18,
    boxShadow: 'none',
  },
  track: {
    backgroundColor: theme.palette?.black?.B32,
    borderRadius: 12,
    opacity: 1,
  },
  checked: {},
}))(MuiSwitch);

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: 0,
  },
  label: {
    ...theme.typography.body2,
    color: theme.palette.text.primary,
    marginLeft: theme.spacing(1),
  },
  progressIcon: (props) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: props.size === 'large' ? -10 : -5,
    marginLeft: props.size === 'large' ? -10 : -5,
  }),
  switchContainer: {
    position: 'relative',
  },
  loadingOverlay: {
    filter: 'blur(0.5px)',
  },
}));

const SwitchComponent = {
  small: NormalSwitch,
  normal: NormalSwitch,
  large: LargeSwitch,
};

const progressSize = {
  small: 10,
  normal: 10,
  large: 20,
};

const Switch = ({ switchProps = {}, isLoading = false, size = 'normal', disabled = false, checked, ...restProps }) => {
  const classes = useStyles({ size });
  const Component = SwitchComponent[size];

  return (
    <FormControlLabel
      control={
        <div className={classes.switchContainer}>
          <Component
            {...switchProps}
            checked={checked}
            className={clsx({ [classes.loadingOverlay]: isLoading })}
            disabled={disabled || isLoading}
          />
          {isLoading && <CircularLoading size={progressSize[size]} className={classes.progressIcon} />}
        </div>
      }
      classes={{
        root: classes.root,
        label: classes.label,
      }}
      {...restProps}
    />
  );
};

Switch.propTypes = {
  size: PropTypes.string,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default Switch;
