export const getParnershipInfo = () => {
  return { type: 'REQUEST_PARTNERSHIP_INFO' };
};
export const updateCompanySettings = (payload) => {
  return { type: 'REQUEST_UPDATE_COMPANY_SETTINGS', payload };
};

export const updateCompanyDisplaySettings = (payload) => {
  return { type: 'REQUEST_UPDATE_COMPANY_DISPLAY_SETTINGS', payload };
};

export const updateTaskExceptionOtherReasonCompanySettings = (payload) => {
  return { type: 'REQUEST_UPDATE_TASK_EXCEPTION_OTHER_REASON_COMPANY_SETTINGS', payload };
};

export const getTemplateDetail = (payload) => {
  return { type: 'REQUEST_GET_TEMPLATE', payload };
};
