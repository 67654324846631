import { authService } from '@yojee/api/authService';
import { BaseService } from '@yojee/api/baseService';

/**
 * This has all the default REST APIs
 * Extend with more methods.
  Eg:
    const resourceAPI = new ResourceAPI({ authService });
 */
export class ResourceAPI extends BaseService {
  constructor({ resourcePath }) {
    if (!authService) throw new Error('authService is required');
    if (!resourcePath) throw new Error('resourcePath is required');
    super();
    this.authService = authService;
    this.resourcePath = resourcePath;
  }

  processResponse = (response) => {
    const result = this.extractData(response);
    return result && result['data'] ? result['data'] : result;
  };

  processResponsePagination = (response) => {
    const result = this.extractData(response);
    const { data, pagination } = result;
    return data
      ? {
          data,
          pagination,
        }
      : result;
  };

  getList = (params = {}) => {
    return this.authService
      .get(this.getUmbrellaApiUrl(this.resourcePath), params)
      .then(this.processResponsePagination)
      .catch((error) => this.handleError(error));
  };

  getResource = (id) => {
    return this.authService
      .get(this.getUmbrellaApiUrl(`${this.resourcePath}/${id}`))
      .then(this.processResponse)
      .catch((error) => this.handleError(error));
  };

  createResource = (payload) => {
    // todo: fix only use v4 api to create new charge code
    const isRateChargeTypes = this.resourcePath.includes('rate_charge_types');
    const umbrellaApiUrl = isRateChargeTypes
      ? this.getUmbrellaApiUrlV4(`company/rates/rate_charge_types`)
      : this.getUmbrellaApiUrl(`${this.resourcePath}`);
    const data = isRateChargeTypes ? payload?.rate_charge_types?.[0] : payload;
    return this.authService
      .post(umbrellaApiUrl, data)
      .then(this.processResponse)
      .catch((error) => (isRateChargeTypes ? this.handleNewErrorFormat(error) : this.handleError(error)));
  };

  updateResource = (id, payload) => {
    return this.authService
      .put(this.getUmbrellaApiUrl(`${this.resourcePath}/${id}`), payload)
      .then(this.processResponse)
      .catch((error) => this.handleError(error));
  };

  deleteResource = (id) => {
    return this.authService
      .delete(this.getUmbrellaApiUrl(`${this.resourcePath}/${id}`))
      .then(this.processResponse)
      .catch((error) => this.handleError(error));
  };
}

export default ResourceAPI;
