import { Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { Trans } from 'react-i18next';

// Custom components
import Button from '../../furnitures/Button';

const useStyles = makeStyles(() => ({
  btn: ({ noMargin }) => ({
    '&:not(:last-child)': {
      marginRight: noMargin ? '0px' : '10px',
    },
  }),
}));

const ManageButtonGroup = ({ btnGroup, disabledAll, ...restProps }) => {
  const classes = useStyles(restProps);

  return btnGroup.map(
    ({ size = 'small', variant = 'contained', classNames, label, disabled, tooltip = '', ...restBtnProps }, idx) => (
      <Tooltip key={idx} title={tooltip ? tooltip : ''}>
        <span className={clsx(classes.btn, classNames)}>
          <Button
            {...restBtnProps}
            key={`action-btns-${label}`}
            disabled={disabledAll || disabled}
            variant={variant}
            size={size}
          >
            {label}
          </Button>
        </span>
      </Tooltip>
    )
  );
};

ManageButtonGroup.defaultProps = {
  btnGroup: [
    {
      label: <Trans>Add</Trans>,
      color: 'primary',
      onClick: () => {},
      disabled: false,
    },
  ],
  noMargin: false,
};

ManageButtonGroup.propTypes = {
  btnGroup: PropTypes.array,
  noMargin: PropTypes.bool,
};

export default ManageButtonGroup;
