import { bulkMessagesErrorEvents, bulkMessagesSuccessEvents } from '@yojee/ui/BulkUpdate/redux/bulkReducer';
import { generateMessagesEvents } from '@yojee/ui/wired-data-grid/reducers/generateReducer';

import {
  customFieldMessagesErrorEvents,
  customFieldMessagesSuccessEvents,
} from '../components/CustomField/utils/customFieldEventMessages';
import { addressMessagesErrorEvents, addressMessagesSuccessEvents } from './addressReducer';
import { billingPartyMessagesErrorEvents, billingPartyMessagesSuccessEvents } from './billingPartyReducer';
import { brandingMessagesErrorEvents, brandingMessagesSuccessEvents, brandingMessagesWarningEvents } from './branding';
import { chargeCodeMessagesErrorEvents, chargeCodeMessagesSuccessEvents } from './chargeCodeReducer';
import { companyMessagesErrorEvents, companyMessagesSuccessEvents } from './company';
import { connoteMessagesErrorEvents, connoteMessagesSuccessEvents } from './connoteReducer';
import { dispatchRuleMessagesErrorEvents, dispatchRuleMessagesSuccessEvents } from './dispatchRuleReducer';
import { driverProcessMessagesErrorEvents, driverProcessMessagesSuccessEvents } from './driverProcess';
import { financeMessagesErrorEvents, financeMessagesSuccessEvents } from './finance';
import { integrationMessagesErrorEvents, integrationMessagesSuccessEvents } from './integrations';
import { itemTypeMessagesErrorEvents, itemTypeMessagesSuccessEvents } from './itemTypeReducer';
import { loginMessagesErrorEvents } from './login';
import {
  manifestTemplatesMessagesErrorEvents,
  manifestTemplatesMessagesSuccessEvents,
} from './manifestTemplatesReducer';
import { networkMessagesErrorEvents } from './network';
import { notificationsMessagesErrorEvents, notificationsMessagesSuccessEvents } from './notifications';
import { operationsMessagesErrorEvents, operationsMessagesSuccessEvents } from './operations';
import { ratingsMessagesErrorEvents, ratingsMessagesSuccessEvents } from './ratingsReducer';
import { reasonCodeMessagesErrorEvents, reasonCodeMessagesSuccessEvents } from './reasonCodeReducer';
import { savedFiltersMessagesErrorEvents, savedFiltersMessagesSuccessEvents } from './savedFilterReducer';
import { scheduleMessagesErrorEvents, scheduleMessagesSuccessEvents } from './scheduleReducer';
import {
  senderOrganisationMessagesErrorEvents,
  senderOrganisationMessagesSuccessEvents,
} from './senderOrganisationReducer';
import { senderMessagesErrorEvents, senderMessagesSuccessEvents } from './senderReducer';
import { serviceTimeMessagesErrorEvents, serviceTimeMessagesSuccessEvents } from './serviceTimeReducer';
import { tagMessagesErrorEvents, tagMessagesSuccessEvents } from './tagReducer';
import { taskExceptionMessagesErrorEvents, taskExceptionMessagesSuccessEvents } from './taskExceptionReducer';
import { userMessagesErrorEvents, userMessagesSuccessEvents } from './users';
import { vehicleMessagesSuccessEvents, vehiclesMessagesErrorEvents } from './vehicles';
import { waybillMessagesErrorEvents, waybillMessagesSuccessEvents } from './waybill';
import { workerMessagesErrorEvents, workerMessagesSuccessEvents } from './workerReducer';
import { workerTeamsMessagesErrorEvents, workerTeamsMessagesSuccessEvents } from './workerTeams';

const rateChargeTypesMessages = generateMessagesEvents('rateChargeTypes', 'Rate Charge Type');

const defaultState = {
  error: undefined,
  success: undefined,
  warning: undefined,
};

const successEvents = {
  ...addressMessagesSuccessEvents,
  ...billingPartyMessagesSuccessEvents,
  ...brandingMessagesSuccessEvents,
  ...bulkMessagesSuccessEvents,
  ...chargeCodeMessagesSuccessEvents,
  ...companyMessagesSuccessEvents,
  ...connoteMessagesSuccessEvents,
  ...manifestTemplatesMessagesSuccessEvents,
  ...dispatchRuleMessagesSuccessEvents,
  ...financeMessagesSuccessEvents,
  ...itemTypeMessagesSuccessEvents,
  ...networkMessagesErrorEvents,
  ...notificationsMessagesSuccessEvents,
  ...ratingsMessagesSuccessEvents,
  ...savedFiltersMessagesSuccessEvents,
  ...scheduleMessagesSuccessEvents,
  ...senderOrganisationMessagesSuccessEvents,
  ...senderMessagesSuccessEvents,
  ...serviceTimeMessagesSuccessEvents,
  ...tagMessagesSuccessEvents,
  ...taskExceptionMessagesSuccessEvents,
  ...userMessagesSuccessEvents,
  ...waybillMessagesSuccessEvents,
  ...workerMessagesSuccessEvents,
  ...workerTeamsMessagesSuccessEvents,
  ...operationsMessagesSuccessEvents,
  ...vehicleMessagesSuccessEvents,
  ...driverProcessMessagesSuccessEvents,
  ...rateChargeTypesMessages.success,
  ...customFieldMessagesSuccessEvents,
  ...integrationMessagesSuccessEvents,
  ...reasonCodeMessagesSuccessEvents,
};

const errorEvents = {
  ...addressMessagesErrorEvents,
  ...billingPartyMessagesErrorEvents,
  ...brandingMessagesErrorEvents,
  ...bulkMessagesErrorEvents,
  ...chargeCodeMessagesErrorEvents,
  ...companyMessagesErrorEvents,
  ...connoteMessagesErrorEvents,
  ...manifestTemplatesMessagesErrorEvents,
  ...dispatchRuleMessagesErrorEvents,
  ...financeMessagesErrorEvents,
  ...itemTypeMessagesErrorEvents,
  ...networkMessagesErrorEvents,
  ...notificationsMessagesErrorEvents,
  ...ratingsMessagesErrorEvents,
  ...savedFiltersMessagesErrorEvents,
  ...scheduleMessagesErrorEvents,
  ...senderOrganisationMessagesErrorEvents,
  ...senderMessagesErrorEvents,
  ...serviceTimeMessagesErrorEvents,
  ...tagMessagesErrorEvents,
  ...taskExceptionMessagesErrorEvents,
  ...userMessagesErrorEvents,
  ...waybillMessagesErrorEvents,
  ...workerMessagesErrorEvents,
  ...workerTeamsMessagesErrorEvents,
  ...loginMessagesErrorEvents,
  ...operationsMessagesErrorEvents,
  ...vehiclesMessagesErrorEvents,
  ...driverProcessMessagesErrorEvents,
  ...rateChargeTypesMessages.error,
  ...customFieldMessagesErrorEvents,
  ...integrationMessagesErrorEvents,
  ...reasonCodeMessagesErrorEvents,
};

const warningEvents = {
  ...brandingMessagesWarningEvents,
};

const ACTION_HANDLERS = {
  ADD_MESSAGE: (state, { message, variant }) => ({
    ...state,
    [variant]: message,
  }),
  CLEAR_MESSAGE: () => defaultState,
};

const doUpdate = (state, variant, eventHandler, action) => {
  if (eventHandler === undefined || eventHandler === null) {
    return state;
  }

  if (typeof eventHandler === 'string') {
    return {
      ...state,
      [variant]: eventHandler,
    };
  } else {
    return {
      ...state,
      [variant]: eventHandler(action),
    };
  }
};

const reducer = (state = defaultState, action = {}) => {
  state = doUpdate(state, 'success', successEvents[action.type], action);
  state = doUpdate(state, 'error', errorEvents[action.type], action);
  state = doUpdate(state, 'warning', warningEvents[action.type], action);

  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};

export default reducer;
