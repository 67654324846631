import { call, put, select, takeLatest } from 'redux-saga/effects';

import { commonService } from '@yojee/api/commonService';
import { LocalStorage } from '@yojee/local-storage/local-storage';

import { companyService } from '../../services/companyService';

export const companyStoreData = (state) => state.company;

export default function* sagas() {
  yield takeLatest('REQUEST_GET_COMPANY_INFO', getCompanyInfo);
  yield takeLatest('SET_COMPANY_INFO', setCompanyInfo);
  yield takeLatest('REQUEST_GET_COMPANY_TEMPLATE', getCompanyTemplate);
  yield takeLatest('CHANGE_LOCAL_TEMPLATE', changeLocalTemplate);
  yield takeLatest('REQUEST_GET_TEMPLATE_DOWNLOAD_URL', getTemplateDownloadUrl);
  yield takeLatest('REQUEST_UPDATE_COMPANY_TEMPLATE', updateCompanyTemplate);
  yield takeLatest('REQUEST_GET_CREDIT_CARD_INFO', getCreditCardInfo);
  yield takeLatest('REQUEST_GET_SUBSCRIPTION_INFO', getSubscriptionInfo);
  yield takeLatest('REQUEST_GET_PLANS', getPlans);
  yield takeLatest('REQUEST_CANCEL_SUBSCRIPTION', cancelSubscription);
  yield takeLatest('REQUEST_GET_SUBSCRIPTION_HISTORY', getSubscriptionHistory);
  yield takeLatest('REQUEST_GET_SERVICE_TYPES', getServiceTypes);
  yield takeLatest('REQUEST_UPDATE_SERVICE_TYPE', updateServiceType);
  yield takeLatest('REQUEST_CREATE_SERVICE_TYPE', createServiceType);
  yield takeLatest('REQUEST_DELETE_SERVICE_TYPE', deleteServiceType);
  yield takeLatest('REQUEST_GET_TASK_TYPES', getTaskTypes);
  yield takeLatest('REQUEST_UPDATE_TASK_TYPE', updateTaskType);
  yield takeLatest('REQUEST_CREATE_TASK_TYPE', createTaskType);
  yield takeLatest('REQUEST_DELETE_TASK_TYPE', deleteTaskType);
  yield takeLatest('@@router/LOCATION_CHANGE', getCompanyInfoOnLocationChange);
}

function* getCompanyInfoOnLocationChange({ payload }) {
  const isInjectedApp = yield select((state) => state.login?.isAppInjected);
  if (!isInjectedApp || (payload?.location?.pathname ?? '').startsWith('/manage')) {
    yield getCompanyInfo();
  }
}

function* setCompanyInfo({ payload: { companyData } }) {
  yield put({ type: 'GET_COMPANY_INFO_SUCCESSFUL', companyData });
}

function* getCompanyInfo() {
  if (LocalStorage.getItem('access_token')) {
    try {
      yield put({ type: 'START_LOADING', loadingAction: 'getCompanyInfo' });
      const companyData = yield call(companyService.getCompanyInfo);
      yield setCompanyInfo({ payload: { companyData } });
    } catch (error) {
      yield put({ type: 'GET_COMPANY_INFO_FAILED', error });
    }
  }
}

function* getCompanyTemplate({ payload: { type } }) {
  try {
    yield put({ type: 'START_LOADING', loadingAction: 'getCompanyTemplate' });
    const data = yield call(companyService.getCompanyTemplate, { type });
    yield put({ type: 'GET_COMPANY_TEMPLATE_SUCCESSFUL', data });
  } catch (error) {
    yield put({ type: 'GET_COMPANY_TEMPLATE_FAILED', error });
  }
}

function* updateCompanyTemplate({ payload: { type } }) {
  try {
    yield put({ type: 'START_LOADING', loadingAction: 'updateCompanyTemplate' });
    const { changedTemplate } = yield select(companyStoreData);
    const data = yield call(companyService.updateCompanyTemplate, { template: changedTemplate, type });
    yield put({ type: 'UPDATE_COMPANY_TEMPLATE_SUCCESSFUL', data });
  } catch (error) {
    yield put({ type: 'UPDATE_COMPANY_TEMPLATE_FAILED', error });
  }
}

function* changeLocalTemplate(action) {
  yield put({
    type: 'CHANGE_LOCAL_TEMPLATE_SUCCESSFUL',
    data: action.payload.map((item, index) => ({ ...item, position: index })),
  });
}

function* getTemplateDownloadUrl({ payload: { type } }) {
  try {
    yield put({ type: 'START_LOADING', loadingAction: 'getTemplateDownloadUrl' });
    const { companyData } = yield select(companyStoreData);
    let slug = companyData.slug;
    if (!slug) {
      yield call(getCompanyInfo);
      const { companyData } = yield select(companyStoreData);
      slug = companyData.slug;
    }
    const data = yield call(companyService.getTemplateDownloadUrl, {
      slug,
      templateType: type,
      fileType: 'csv',
    });
    yield put({ type: 'GET_TEMPLATE_DOWNLOAD_URL_SUCCESSFUL', data });
  } catch (error) {
    yield put({ type: 'GET_TEMPLATE_DOWNLOAD_URL_FAILED', error });
  }
}

function* getCreditCardInfo() {
  try {
    yield put({ type: 'START_LOADING', loadingAction: 'getCreditCardInfo' });
    const data = yield call(companyService.getCreditCardInfo);
    yield put({ type: 'GET_CREDIT_CARD_INFO_SUCCESSFUL', data });
  } catch (error) {
    yield put({ type: 'GET_CREDIT_CARD_INFO_FAILED', error });
  }
}

function* getSubscriptionHistory() {
  try {
    yield put({ type: 'START_LOADING', loadingAction: 'getSubscriptionHistory' });
    const { data } = yield call(companyService.getSubscriptionHistory);
    yield put({ type: 'GET_SUBSCRIPTION_HISTORY_SUCCESSFUL', data });
  } catch (error) {
    yield put({ type: 'GET_SUBSCRIPTION_HISTORY_FAILED', error });
  }
}

function* getPlans() {
  try {
    yield put({ type: 'START_LOADING', loadingAction: 'getPlans' });
    const {
      data: { plans },
    } = yield call(companyService.getPlans);
    yield put({ type: 'GET_PLANS_SUCCESSFUL', plans });
  } catch (error) {
    yield put({ type: 'GET_PLANS_FAILED', error });
  }
}

function* getSubscriptionInfo() {
  try {
    yield put({ type: 'START_LOADING', loadingAction: 'getSubscriptionInfo' });
    const data = yield call(companyService.getSubscriptionInfo);
    yield put({ type: 'GET_SUBSCRIPTION_INFO_SUCCESSFUL', data });
  } catch (error) {
    yield put({ type: 'GET_SUBSCRIPTION_INFO_FAILED', error });
  }
}

function* cancelSubscription({ payload }) {
  try {
    yield put({ type: 'START_LOADING', loadingAction: 'cancelSubscription' });
    const data = yield call(companyService.cancelSubscription, { id: payload });
    yield put({ type: 'CANCEL_SUBSCRIPTION_SUCCESSFUL', data });
  } catch (error) {
    yield put({ type: 'CANCEL_SUBSCRIPTION_FAILED', error });
  }
}

function* getServiceTypes() {
  try {
    yield put({ type: 'START_LOADING', loadingAction: 'getServiceTypes' });
    const data = yield call(companyService.getServiceTypes);
    yield put({ type: 'GET_SERVICE_TYPES_SUCCESSFUL', data });
  } catch (error) {
    yield put({ type: 'GET_SERVICE_TYPE_FAILED', error });
  }
}

function* updateServiceType({ data }) {
  try {
    yield put({ type: 'START_LOADING', loadingAction: 'updateServiceType' });
    const response = yield call(companyService.updateServiceType, data);
    yield put({ type: 'UPDATE_SERVICE_TYPE_SUCCESSFUL', response });
    yield put({ type: 'REQUEST_GET_SERVICE_TYPES' });
  } catch (error) {
    yield put({ type: 'UPDATE_SERVICE_TYPE_FAILED', error });
  }
}

function* createServiceType({ data }) {
  try {
    yield put({ type: 'START_LOADING', loadingAction: 'createServiceType' });
    const response = yield call(companyService.createServiceType, data);
    yield put({ type: 'CREATE_SERVICE_TYPE_SUCCESSFUL', response });
    yield put({ type: 'REQUEST_GET_SERVICE_TYPES' });
  } catch (error) {
    yield put({ type: 'CREATE_SERVICE_TYPE_FAILED', error });
  }
}

function* deleteServiceType({ serviceTypeId }) {
  try {
    yield put({ type: 'START_LOADING', loadingAction: 'deleteServiceType' });
    const response = yield call(companyService.deleteServiceType, serviceTypeId);
    yield put({ type: 'DELETE_SERVICE_TYPE_SUCCESSFUL', response });
    yield put({ type: 'REQUEST_GET_SERVICE_TYPES' });
  } catch (error) {
    yield put({ type: 'DELETE_SERVICE_TYPE_FAILED', error });
  }
}

function* getTaskTypes() {
  try {
    const slug = commonService.getSlugFromUrl();
    yield put({ type: 'START_LOADING', loadingAction: 'getTaskTypes' });
    const data = yield call(companyService.getTaskTypes, { company_slug: slug });
    yield put({ type: 'GET_TASK_TYPES_SUCCESSFUL', data });
  } catch (error) {
    yield put({ type: 'GET_TASK_TYPE_FAILED', error });
  }
}

function* updateTaskType({ data }) {
  try {
    yield put({ type: 'START_LOADING', loadingAction: 'updateTaskType' });
    const response = yield call(companyService.updateTaskType, data);
    yield put({ type: 'UPDATE_TASK_TYPE_SUCCESSFUL', response });
    yield put({ type: 'REQUEST_GET_TASK_TYPES' });
  } catch (error) {
    yield put({ type: 'UPDATE_TASK_TYPE_FAILED', error });
  }
}

function* createTaskType({ data }) {
  try {
    yield put({ type: 'START_LOADING', loadingAction: 'createTaskType' });
    const response = yield call(companyService.createTaskType, data);
    yield put({ type: 'CREATE_TASK_TYPE_SUCCESSFUL', response });
    yield put({ type: 'REQUEST_GET_TASK_TYPES' });
  } catch (error) {
    yield put({ type: 'CREATE_TASK_TYPE_FAILED', error });
  }
}

function* deleteTaskType({ taskTypeId }) {
  try {
    yield put({ type: 'START_LOADING', loadingAction: 'deleteTaskType' });
    const response = yield call(companyService.deleteTaskType, taskTypeId);
    yield put({ type: 'DELETE_TASK_TYPE_SUCCESSFUL', response });
    yield put({ type: 'REQUEST_GET_TASK_TYPES' });
  } catch (error) {
    yield put({ type: 'DELETE_TASK_TYPE_FAILED', error });
  }
}
