import {
  batchUploadDefaultState,
  generateBatchUploadReducerMethods,
} from '@yojee/ui/onboarding/helpers/batchUploadHelper';

const defaultState = {
  loading: {
    listBillingParty: false,
    createBillingParty: false,
    updateBillingParty: false,
    activateBillingParty: false,
    deactivateBillingParty: false,
    updateDefaultBillingParty: false,
    checkValidContactBillingParty: false,
  },
  batchUpload: batchUploadDefaultState,
  billingPartyData: [],
  billingPartyDetail: {},
  pagination: { current_page: 1, limit_value: 25, total_count: 0, total_pages: 0 },
};

export const init = () => {
  return defaultState;
};

export const billingPartyMessagesSuccessEvents = {
  CREATE_BILLING_PARTY_SUCCESSFUL: 'Billing party created successfully',
  UPDATE_BILLING_PARTY_SUCCESSFUL: 'Billing party updated successfully',
  ACTIVATE_BILLING_PARTY_SUCCESSFUL: 'Billing party updated successfully',
  SET_BILLING_PARTY_MESSAGE: ({ payload }) => payload.successMessage,
};

export const billingPartyMessagesErrorEvents = {
  LIST_BILLING_PARTY_FAILED: ({ error }) => error?.message ?? 'Failed to get sender info',
  CREATE_BILLING_PARTY_FAILED: (data) => data?.error?.message || 'Failed to create billing party',
  UPDATE_BILLING_PARTY_FAILED: (data) => data?.error?.message || 'Failed to update billing party',
  ACTIVATE_BILLING_PARTY_FAILED: (data) => data?.error?.message || 'Failed to update billing party',
  CHECK_VALID_CONTACT_BILLING_PARTY_FAILED: 'Failed to check same contact',
  SET_BILLING_PARTY_MESSAGE: ({ payload }) => payload.errorMessage,
};

const ACTION_HANDLERS = {
  LIST_BILLING_PARTY_SUCCESSFUL: (state, sagaData) => {
    const data = sagaData;
    return {
      ...state,
      loading: {
        ...state.loading,
        listBillingParty: false,
      },
      billingPartyData: data && data['data'],
      pagination: data && data['pagination'],
      filter: sagaData.params,
    };
  },
  LIST_BILLING_PARTY_FAILED: (state) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        listBillingParty: false,
      },
      error: {
        failed: true,
      },
    };
  },
  SET_BILLING_PARTY_LOADING: (state, sagaData) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        [sagaData.key]: true,
      },
    };
  },
  CREATE_BILLING_PARTY_FAILED: (state) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        createBillingParty: false,
      },
    };
  },

  CREATE_BILLING_PARTY_SUCCESSFUL: (state) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        createBillingParty: false,
      },
    };
  },

  UPDATE_BILLING_PARTY_FAILED: (state) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        updateBillingParty: false,
        updateDefaultBillingParty: false,
      },
    };
  },

  UPDATE_BILLING_PARTY_SUCCESSFUL: (state) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        updateBillingParty: false,
        updateDefaultBillingParty: false,
      },
    };
  },
  ACTIVATE_BILLING_PARTY_FAILED: (state) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        activateBillingParty: false,
      },
    };
  },

  ACTIVATE_BILLING_PARTY_SUCCESSFUL: (state) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        activateBillingParty: false,
      },
    };
  },
  CHECK_VALID_CONTACT_BILLING_PARTY_SUCCESSFUL: (state) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        checkValidContactBillingParty: false,
      },
    };
  },
  CHECK_VALID_CONTACT_BILLING_PARTY_FAILED: (state) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        checkValidContactBillingParty: false,
      },
    };
  },
  ...generateBatchUploadReducerMethods({ prefix: 'BILLING' }),
};

const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};

export default reducer;
