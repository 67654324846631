import { FormControl } from '@material-ui/core';
import React from 'react';

import ReactSelect from '@yojee/ui/base/furnitures/Select/ReactSelect';
import { getMappedLabelByKey, getMappedOperators } from '@yojee/ui/condition/mappedOperatorsHelper';

const Operator = ({ items, setField, selectedKey, selectedLabel, showEmptyOperators = false }) => {
  return (
    <FormControl>
      <ReactSelect
        options={getMappedOperators(items, showEmptyOperators)}
        onChange={(option) => setField(option.value)}
        value={{ value: selectedKey, label: getMappedLabelByKey(selectedKey, selectedLabel) }}
        optionDataCy="operator-item"
        dataCy="list-operator"
        className="condition-select-container"
      />
    </FormControl>
  );
};

export default Operator;
