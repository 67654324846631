export type CompanyServiceType = {
  id: number;
  isolate_tasks: boolean;
  key: string;
  name: string;
  preassign: boolean;
  pricing_model_uuid: string;
};

export type WorkerGatePassSetting = {
  reference: string;
  type: string;
};

export type CompanyData = {
  slug: string | null;
  country: string;
  display_settings: {
    admin_access: {
      'senders.show.checkbox_to_create_charge_at_order_creation': boolean;
      'organisations.update': boolean;
      'organisations.create': boolean;
    };
  };
  settings: {
    company: {
      timezone: string | null;
    };
    worker: {
      gate_pass: WorkerGatePassSetting | null;
      task_list: string;
    };
  };
  service_types: {
    [k: string]: CompanyServiceType;
  };
};

export const getCompanyDefaultData = (): CompanyData => ({
  slug: null,
  country: '',
  display_settings: {
    // @ts-ignore
    admin_access: {
      'senders.show.checkbox_to_create_charge_at_order_creation': false,
    },
    admin_copy: {},
  },
  settings: {
    company: {
      timezone: null,
    },
    worker: {
      gate_pass: null,
      task_list: 'commit_date',
    },
  },
  service_types: {},
});
