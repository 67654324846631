const defaultState = {
  operations: [],
  //  todo: implement pagination when API handles it
  pagination: null,
  loading: {
    getOperations: false,
    createOperation: false,
    deleteOperation: false,
    updateOperation: false,
    bulkDeleteOperation: false,
  },
};

export const operationsMessagesSuccessEvents = {
  CREATE_OPERATION_SUCCESSFUL: 'Operation created successfully.',
  UPDATE_OPERATION_SUCCESSFUL: 'Operation updated successfully.',
  DELETE_OPERATION_SUCCESSFUL: 'Operation deleted successfully.',
  REQUEST_DELETE_OPERATION_IN_PROCESS: 'Operation delete in process.',
  BULK_REQUEST_DELETE_OPERATION_SUCCESS: 'Operation deleted successfully',
};

export const operationsMessagesErrorEvents = {
  GET_OPERATIONS_FAILED: ({ error }) => error,
  CREATE_OPERATION_FAILED: ({ error }) => error,
  UPDATE_OPERATION_FAILED: ({ error }) => error,
  DELETE_OPERATION_FAILED: ({ error }) => error,
};

export const init = () => {
  return defaultState;
};

const ACTION_HANDLERS = {
  START_LOADING: (state, sagaData) => {
    return {
      ...state,
      loading: { ...state.loading, [sagaData.loadingAction]: true },
    };
  },
  GET_OPERATIONS_SUCCESSFUL: (state, sagaData) => {
    return {
      ...state,
      operations: sagaData.data,
      loading: {
        ...state.loading,
        getOperations: false,
      },
    };
  },
  GET_OPERATIONS_FAILED: (state) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        getOperations: false,
      },
    };
  },
  CREATE_OPERATION_SUCCESSFUL: (state) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        createOperation: false,
      },
    };
  },
  CREATE_OPERATION_FAILED: (state) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        createOperation: false,
      },
    };
  },
  UPDATE_OPERATION_SUCCESSFUL: (state) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        updateOperation: false,
      },
    };
  },
  UPDATE_OPERATION_FAILED: (state) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        updateOperation: false,
      },
    };
  },
  DELETE_OPERATION: (state) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        deleteOperation: true,
      },
    };
  },
  DELETE_OPERATION_SUCCESSFUL: (state) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        deleteOperation: false,
      },
    };
  },
  DELETE_OPERATION_FAILED: (state) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        deleteOperation: false,
      },
    };
  },
  REQUEST_DELETE_OPERATION_IN_PROCESS: (state) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        bulkDeleteOperation: true,
      },
    };
  },
  BULK_REQUEST_DELETE_OPERATION_SUCCESS: (state) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        bulkDeleteOperation: false,
      },
    };
  },
  BULK_REQUEST_DELETE_OPERATION_FAIL: (state) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        bulkDeleteOperation: false,
      },
    };
  },
};

const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};
export default reducer;
