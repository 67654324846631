import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { Trans } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 'min(17.5em, 220px)',
    color: (props) => (props.error ? theme.palette?.error?.main : theme.palette?.black?.B24),
    border: (props) => (props.border ? `1px solid` : 0),
    borderColor: (props) => (props.error ? theme.palette?.error?.main : theme.palette?.grey?.G4),
    borderRadius: 5,
    fontSize: theme.typography?.h4?.fontSize || '1.25rem',
  },
}));

const EmptyZone = ({ message, className, error = false, border = false, ...restProps }) => {
  const classes = useStyles({ error, border });
  return (
    <div className={clsx(classes.container, className)} {...restProps}>
      <span>{message}</span>
    </div>
  );
};

EmptyZone.propTypes = {
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

EmptyZone.defaultProps = {
  message: <Trans>No data is available</Trans>,
};

export default EmptyZone;
