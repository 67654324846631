import { getCompanyDefaultData } from '@yojee/helpers/common-reducers';

const defaultState = {
  loading: {
    getCompanyTemplate: false,
    getCompanyInfo: false,
    getTemplateDownloadUrl: false,
    updateCompanyTemplate: false,
    getCreditCardInfo: false,
    getSubscriptionInfo: false,
    cancelSubscription: false,
    getPlans: false,
    getServiceTypes: false,
    updateServiceType: false,
    createServiceType: false,
    deleteServiceType: false,
    bulkDeleteServiceType: false,
    getTaskTypes: false,
    updateTaskType: false,
    createTaskType: false,
    deleteTaskType: false,
  },
  companyData: getCompanyDefaultData(),
  creditCard: {
    last4: null,
    type: null,
  },
  subscription: {
    id: null,
  },
  template: [],
  templateDownloadUrl: null,
  changedTemplate: [],
  serviceTypes: [],
  taskTypes: [],
  error: {
    failed: false,
    subscriptionMessage: null,
  },
  plans: [],
};

export const companyMessagesSuccessEvents = {
  UPDATE_COMPANY: 'Company data updated',
  UPDATE_COMPANY_TEMPLATE_SUCCESSFUL: 'Template updated',
  UPDATE_SERVICE_TYPE_SUCCESSFUL: 'Service type updated',
  CREATE_SERVICE_TYPE_SUCCESSFUL: 'New service type created',
  DELETE_SERVICE_TYPE_SUCCESSFUL: 'Service type deleted successfully',
  UPDATE_TASK_TYPE_SUCCESSFUL: 'Task type updated successfully',
  CREATE_TASK_TYPE_SUCCESSFUL: 'New task type created successfully',
  DELETE_TASK_TYPE_SUCCESSFUL: 'Task type deleted successfully',
  CANCEL_SUBSCRIPTION_SUCCESSFUL: 'You have been successfully unsubscribed',
  REQUEST_DELETE_SERVICE_TYPE_IN_PROCESS: 'Service type delete in process',
  BULK_REQUEST_DELETE_SERVICE_TYPE_SUCCESS: 'Service type deleted successfully',
};

export const companyMessagesErrorEvents = {
  GET_COMPANY_TEMPLATE_FAILED: ({ error }) => error?.message ?? 'Failed to get company template',
  UPDATE_COMPANY_TEMPLATE_FAILED: ({ error }) => error?.message ?? "Failed to update company's csv template",
  GET_TEMPLATE_DOWNLOAD_URL_FAILED: ({ error }) => error?.message ?? 'Failed to get company template download URL',
  GET_PLANS_FAILED: ({ error }) => error?.message ?? 'Failed to get plans',
  GET_SERVICE_TYPE_FAILED: ({ error }) => error?.message ?? "Failed to get company's service types",
  UPDATE_SERVICE_TYPE_FAILED: ({ error }) => error?.message ?? "Failed to update company's service type",
  CREATE_SERVICE_TYPE_FAILED: ({ error }) => error?.message ?? "Failed to create company's service type",
  DELETE_SERVICE_TYPE_FAILED: ({ error }) => error?.message ?? "Failed to delete company's service type",
  GET_TASK_TYPE_FAILED: ({ error }) => error?.message ?? "Failed to get company's task types",
  UPDATE_TASK_TYPE_FAILED: ({ error }) => error?.message ?? "Failed to update company's task type",
  CREATE_TASK_TYPE_FAILED: ({ error }) => error?.message ?? "Failed to create company's task type",
  DELETE_TASK_TYPE_FAILED: ({ error }) => error?.message ?? "Failed to delete company's task type",
  GET_SUBSCRIPTION_INFO_FAILED: ({ error }) => error?.message ?? 'Failed to get credit card info',
  CANCEL_SUBSCRIPTION_FAILED: ({ error }) => error?.message ?? 'Failed to cancel subscription',
};

export const init = () => {
  return defaultState;
};

const ACTION_HANDLERS = {
  UPDATE_COMPANY: (state, sagaData) => {
    return {
      ...state,
      companyData: { ...state.companyData, ...sagaData.data },
    };
  },
  GET_COMPANY_INFO_SUCCESSFUL: (state, sagaData) => {
    const data = sagaData.companyData;
    return {
      ...state,
      loading: { ...state.loading, getCompanyInfo: false },
      companyData: data,
    };
  },
  GET_COMPANY_INFO_FAILED: (state) => {
    return {
      ...state,
      loading: { ...state.loading, getCompanyInfo: false },
      error: {
        failed: true,
      },
    };
  },
  GET_COMPANY_TEMPLATE_SUCCESSFUL: (state, sagaData) => {
    return {
      ...state,
      loading: { ...state.loading, getCompanyTemplate: false },
      changedTemplate: [],
      template: sagaData.data,
    };
  },
  GET_COMPANY_TEMPLATE_FAILED: (state) => {
    return {
      ...state,
      loading: { ...state.loading, getCompanyTemplate: false },
      error: {
        failed: true,
      },
    };
  },
  UPDATE_COMPANY_TEMPLATE_SUCCESSFUL: (state, sagaData) => {
    return {
      ...state,
      loading: { ...state.loading, updateCompanyTemplate: false },
      template: sagaData.data,
      changedTemplate: [],
    };
  },
  UPDATE_COMPANY_TEMPLATE_FAILED: (state) => {
    return {
      ...state,
      loading: { ...state.loading, updateCompanyTemplate: false },
      error: {
        failed: true,
      },
    };
  },
  CHANGE_LOCAL_TEMPLATE_SUCCESSFUL: (state, sagaData) => {
    return {
      ...state,
      changedTemplate: sagaData.data,
    };
  },
  GET_TEMPLATE_DOWNLOAD_URL_SUCCESSFUL: (state, sagaData) => {
    return {
      ...state,
      loading: { ...state.loading, getTemplateDownloadUrl: false },
      templateDownloadUrl: sagaData.data,
    };
  },
  GET_TEMPLATE_DOWNLOAD_URL_FAILED: (state) => {
    return {
      ...state,
      loading: { ...state.loading, getTemplateDownloadUrl: false },
      error: {
        failed: true,
      },
    };
  },
  GET_CREDIT_CARD_INFO_SUCCESSFUL: (state, sagaData) => {
    return {
      ...state,
      loading: { ...state.loading, getCreditCardInfo: false },
      creditCard: sagaData.data,
    };
  },
  GET_CREDIT_CARD_INFO_FAILED: (state) => {
    return {
      ...state,
      loading: { ...state.loading, getCreditCardInfo: false },
    };
  },
  GET_PLANS_SUCCESSFUL: (state, sagaData) => {
    return {
      ...state,
      loading: { ...state.loading, getPlans: false },
      plans: sagaData.plans,
    };
  },
  GET_PLANS_FAILED: (state) => {
    return {
      ...state,
      loading: { ...state.loading, getPlans: false },
      error: {
        failed: true,
      },
    };
  },
  GET_SERVICE_TYPES_SUCCESSFUL: (state, sagaData) => {
    return {
      ...state,
      loading: { ...state.loading, getServiceTypes: false },
      serviceTypes: sagaData && sagaData.data,
    };
  },
  GET_SERVICE_TYPE_FAILED: (state) => {
    return {
      ...state,
      loading: { ...state.loading, getServiceTypes: false },
      error: {
        failed: true,
      },
    };
  },
  UPDATE_SERVICE_TYPE_SUCCESSFUL: (state) => {
    return {
      ...state,
      loading: { ...state.loading, updateServiceType: false },
    };
  },
  UPDATE_SERVICE_TYPE_FAILED: (state) => {
    return {
      ...state,
      loading: { ...state.loading, updateServiceType: false },
      error: {
        failed: true,
      },
    };
  },
  CREATE_SERVICE_TYPE_SUCCESSFUL: (state) => {
    return {
      ...state,
      loading: { ...state.loading, createServiceType: false },
    };
  },
  CREATE_SERVICE_TYPE_FAILED: (state) => {
    return {
      ...state,
      loading: { ...state.loading, createServiceType: false },
      error: {
        failed: true,
      },
    };
  },
  REQUEST_DELETE_SERVICE_TYPE: (state) => {
    return {
      ...state,
      loading: { ...state.loading, deleteServiceType: true },
    };
  },
  DELETE_SERVICE_TYPE_SUCCESSFUL: (state) => {
    return {
      ...state,
      loading: { ...state.loading, deleteServiceType: false },
    };
  },
  DELETE_SERVICE_TYPE_FAILED: (state) => {
    return {
      ...state,
      loading: { ...state.loading, deleteServiceType: false },
      error: {
        failed: true,
      },
    };
  },
  REQUEST_DELETE_SERVICE_TYPE_IN_PROCESS: (state, data) => {
    return {
      ...state,
      successMessage: 'Service types deleted processing',
      loading: { ...state.loading, bulkDeleteServiceType: true },
    };
  },
  BULK_REQUEST_DELETE_SERVICE_TYPE_SUCCESS: (state, data) => {
    return {
      ...state,
      successMessage: 'Service types deleted successfully',
      loading: { ...state.loading, bulkDeleteServiceType: false },
    };
  },
  BULK_REQUEST_DELETE_SERVICE_TYPE_FAIL: (state, data) => {
    return {
      ...state,
      errorMessage: `Failed to bulk delete service types`,
      loading: { ...state.loading, bulkDeleteServiceType: false },
    };
  },
  //  task type
  GET_TASK_TYPES_SUCCESSFUL: (state, sagaData) => {
    return {
      ...state,
      loading: { ...state.loading, getTaskTypes: false },
      taskTypes: sagaData && sagaData.data,
    };
  },
  GET_TASK_TYPE_FAILED: (state) => {
    return {
      ...state,
      loading: { ...state.loading, getTaskTypes: false },
      error: {
        failed: true,
      },
    };
  },
  UPDATE_TASK_TYPE_SUCCESSFUL: (state) => {
    return {
      ...state,
      loading: { ...state.loading, updateTaskType: false },
    };
  },
  UPDATE_TASK_TYPE_FAILED: (state) => {
    return {
      ...state,
      loading: { ...state.loading, updateTaskType: false },
      error: {
        failed: true,
      },
    };
  },
  CREATE_TASK_TYPE_SUCCESSFUL: (state) => {
    return {
      ...state,
      loading: { ...state.loading, createTaskType: false },
    };
  },
  CREATE_TASK_TYPE_FAILED: (state) => {
    return {
      ...state,
      loading: { ...state.loading, createTaskType: false },
      error: {
        failed: true,
      },
    };
  },
  DELETE_TASK_TYPE_SUCCESSFUL: (state) => {
    return {
      ...state,
      loading: { ...state.loading, deleteTaskType: false },
    };
  },
  DELETE_TASK_TYPE_FAILED: (state) => {
    return {
      ...state,
      loading: { ...state.loading, deleteTaskType: false },
      error: {
        failed: true,
      },
    };
  },

  //  subscription
  GET_SUBSCRIPTION_INFO_SUCCESSFUL: (state, sagaData) => {
    return {
      ...state,
      loading: { ...state.loading, getSubscriptionInfo: false },
      subscription: sagaData.data,
    };
  },
  GET_SUBSCRIPTION_INFO_FAILED: (state) => {
    return {
      ...state,
      loading: { ...state.loading, getSubscriptionInfo: false },
      error: {
        failed: true,
      },
    };
  },
  GET_SUBSCRIPTION_HISTORY_SUCCESSFUL: (state, sagaData) => {
    return {
      ...state,
      loading: { ...state.loading, getSubscriptionHistory: false },
      subscriptionHistory: sagaData.data,
    };
  },
  GET_SUBSCRIPTION_HISTORY_FAILED: (state) => {
    return {
      ...state,
      loading: { ...state.loading, getSubscriptionHistory: false },
      subscriptionHistory: [],
    };
  },
  CANCEL_SUBSCRIPTION_SUCCESSFUL: (state) => {
    return {
      ...state,
      loading: { ...state.loading, cancelSubscription: false },
    };
  },
  CANCEL_SUBSCRIPTION_FAILED: (state) => {
    return {
      ...state,
      loading: { ...state.loading, cancelSubscription: false },
      error: {
        failed: true,
      },
    };
  },
  START_LOADING: (state, sagaData) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        [sagaData.loadingAction]: true,
      },
    };
  },
  CLEAR_COMPANY_MESSAGE: (state) => {
    return {
      ...state,
      successMessage: null,
      error: {
        ...state.error,
        message: null,
      },
    };
  },
};

const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};

export default reducer;
