import { ButtonBase, Collapse } from '@material-ui/core';
import { ChevronRight, KeyboardArrowDown } from '@material-ui/icons';
import clsx from 'clsx';
import { useState } from 'react';

import useMenuItemLogic from '../hooks/useMenuItemLogic';
import useTopMenuStyles from './topMenuStyles';

const SubMenuItem = ({ menuItem, children }) => {
  const classes = useTopMenuStyles();
  const { isSelected, onClickLeafMenu, link } = useMenuItemLogic(menuItem);
  const [isOpened, setIsOpened] = useState(isSelected);

  const renderCollapseIcon = () =>
    isOpened ? (
      <KeyboardArrowDown fontSize="small" className={classes.collapseIcon} />
    ) : (
      <ChevronRight fontSize="small" className={classes.collapseIcon} />
    );

  return (
    <li
      data-cy={`menu-item-${menuItem.key}`}
      className={clsx(classes.subMenuItem, isSelected && classes.subMenuItemActive)}
    >
      <ButtonBase
        component="a"
        href={link}
        onClick={(event) => {
          event.preventDefault();

          if (children) {
            setIsOpened((prev) => !prev);
          } else {
            onClickLeafMenu();
          }
        }}
      >
        <span>{menuItem.title}</span>

        {children && renderCollapseIcon()}
      </ButtonBase>
      {children && <Collapse in={isOpened}>{children}</Collapse>}
    </li>
  );
};

export default SubMenuItem;
