import { call, put, select, takeLatest } from 'redux-saga/effects';

import { batchUpload } from '@yojee/ui/onboarding/helpers/batchUploadHelper';

import { workerService } from '../../services/workerService';

export default function* sagas() {
  yield takeLatest('REQUEST_LIST_WORKERS', listWorkers);
  yield takeLatest('REQUEST_GET_WORKERS', getWorkers);
  yield takeLatest('REQUEST_GET_WORKER', getWorker);
  yield takeLatest('REQUEST_CREATE_WORKER', createWorker);
  yield takeLatest('REQUEST_UPDATE_WORKER', updateWorker);
  yield takeLatest('REQUEST_DELETE_WORKER', deleteWorker);
  yield takeLatest('WORKERS_BATCH_UPLOAD', workersBatchUpload);
  yield takeLatest('DOWNLOAD_WORKERS_TEMPLATE_SAMPLE', downloadWorkersTemplateSample);
}

function* listWorkers({ payload }) {
  try {
    yield put({ type: 'SET_WORKERS_PROGRESS', key: 'listWorkers' });
    const data = yield call(workerService.listWorkers, payload);
    yield put({ type: 'LIST_WORKERS_SUCCESSFUL', workers: data });
  } catch (error) {
    yield put({ type: 'LIST_WORKERS_FAILED', error });
  }
}

function* getWorkers({ payload: { q, page, page_size } }) {
  try {
    yield put({ type: 'SET_WORKERS_PROGRESS', key: 'getWorkers' });
    const { data, pagination } = yield call(workerService.getWorkers, { q, page, page_size });
    yield put({ type: 'REQUEST_GET_WORKERS_SUCCESSFUL', data, pagination, q });
  } catch (error) {
    yield put({ type: 'REQUEST_GET_WORKERS_FAILED', error });
  }
}

function* getWorker(action) {
  try {
    const result = yield call(workerService.getWorker, action.payload);
    yield put({ type: 'REQUEST_GET_WORKER_SUCCESS', result: result });
  } catch (error) {
    yield put({ type: 'REQUEST_GET_WORKER_ERROR', error });
  }
}

function* createWorker(action) {
  try {
    yield put({ type: 'CLEAR_MESSAGE' });
    yield put({ type: 'SET_WORKERS_PROGRESS', key: 'createWorker' });
    yield call(workerService.createWorker, action.payload);
    yield put({ type: 'REQUEST_CREATE_WORKER_SUCCESS' });

    const { current_page: page, limit_value: page_size } = yield select((state) => state.worker.pagination);
    const q = yield select((state) => state.worker.query);
    yield put({ type: 'REQUEST_GET_WORKERS', payload: { page, page_size, q } });
    if (window.parent) {
      window.parent.postMessage(
        {
          type: 'REQUEST_CREATE_WORKER_SUCCESS',
        },
        '*'
      );
    }
  } catch (error) {
    yield put({ type: 'REQUEST_CREATE_WORKER_ERROR', error });
    if (window.parent) {
      window.parent.postMessage(
        {
          type: 'REQUEST_CREATE_WORKER_ERROR',
          errorMessage: `Failed to create driver: ${error.message}`,
        },
        '*'
      );
    }
  }
}

function* updateWorker(action) {
  try {
    yield put({ type: 'CLEAR_MESSAGE' });
    yield put({ type: 'SET_WORKERS_PROGRESS', key: 'updateWorker' });
    const worker = yield call(workerService.updateWorker, action.payload);
    yield put({ type: 'REQUEST_UPDATE_WORKER_SUCCESS', worker });

    const { current_page: page, limit_value: page_size } = yield select((state) => state.worker.pagination);
    const q = yield select((state) => state.worker.query);
    yield put({ type: 'REQUEST_GET_WORKERS', payload: { page, page_size, q } });
  } catch (error) {
    yield put({ type: 'REQUEST_UPDATE_WORKER_ERROR', error });
  }
}

function* deleteWorker(action) {
  try {
    yield call(workerService.deleteWorker, action.payload);
    yield put({ type: 'REQUEST_DELETE_WORKER_SUCCESS', result: action.payload });

    const { current_page: page, limit_value: page_size } = yield select((state) => state.worker.pagination);
    const q = yield select((state) => state.worker.query);
    yield put({ type: 'REQUEST_GET_WORKERS', payload: { page, page_size, q } });
  } catch (error) {
    yield put({ type: 'REQUEST_DELETE_WORKER_ERROR', error });
  }
}

function* workersBatchUpload(info) {
  const { file, format } = info.uploadData;
  yield batchUpload(
    { file, format },
    {
      uploadApiMethod: workerService.batchUpload,
      statusCheckApiMethod: workerService.checkBatchStatus,
      events: {
        started: 'WORKERS_BATCH_UPLOAD_STARTED',
        processing: 'WORKERS_BATCH_UPLOAD_PROCESSING',
        succeeded: 'WORKERS_BATCH_UPLOAD_SUCCEEDED',
        failed: 'WORKERS_BATCH_UPLOAD_FAILED',
      },
    }
  );
}

function downloadWorkersTemplateSample(format) {
  window.open(workerService.downloadWorkersTemplateSample(format), '_self');
}
