import { call, put, select, takeLatest } from 'redux-saga/effects';

import { itemTypeService } from '../../services/itemTypeService';

export default function* sagas() {
  yield takeLatest('REQUEST_LIST_ITEM_TYPES', listItemTypes);
  yield takeLatest('REQUEST_CREATE_ITEM_TYPE', createItemType);
  yield takeLatest('REQUEST_UPDATE_ITEM_TYPE', updateItemType);
  yield takeLatest('REQUEST_DELETE_ITEM_TYPE', deleteItemType);
}

export const getFilter = (state) => {
  return state.itemType.filter;
};

function* listItemTypes(action) {
  try {
    yield put({ type: 'SET_ITEM_TYPE_LOADING', key: 'listItemTypes' });
    const response = yield call(itemTypeService.listItemTypes, action.params);
    const sortedData = {
      ...response,
      data: response.data.sort((a, b) => a.id - b.id),
    };
    yield put({
      type: 'LIST_ITEM_TYPES_SUCCESSFUL',
      itemTypeData: sortedData,
      params: action.params,
    });
  } catch (error) {
    yield put({ type: 'LIST_ITEM_TYPES_FAILED', error });
  }
}

/**
 *
 *
 * @param {{options: {onSuccess?, onError?}}} action
 */
function* createItemType(action) {
  try {
    yield put({ type: 'SET_ITEM_TYPE_LOADING', key: 'createItemType' });
    const itemTypeData = yield call(itemTypeService.createItemType, action.itemType);
    yield put({ type: 'CREATE_ITEM_TYPE_SUCCESSFUL', itemTypeData });
    action.options.onSuccess?.();
    yield put({
      type: 'REQUEST_LIST_ITEM_TYPES',
      params: yield select(getFilter),
    });
  } catch (error) {
    action.options.onError?.(error);
    yield put({ type: 'CREATE_ITEM_TYPE_FAILED', error });
  }
}
/**
 *
 *
 * @param {{options: {onSuccess?, onError?}}} action
 */
function* updateItemType(action) {
  try {
    yield put({ type: 'SET_ITEM_TYPE_LOADING', key: 'updateItemType' });
    const itemTypeData = yield call(itemTypeService.updateItemType, action.itemType);
    yield put({ type: 'UPDATE_ITEM_TYPE_SUCCESSFUL', itemTypeData });
    action.options.onSuccess?.();
    yield put({
      type: 'REQUEST_LIST_ITEM_TYPES',
      params: yield select(getFilter),
    });
  } catch (error) {
    yield put({ type: 'UPDATE_ITEM_TYPE_FAILED', error });
    action.options.onError?.(error);
  }
}

function* deleteItemType(action) {
  try {
    yield put({ type: 'SET_ITEM_TYPE_LOADING', key: 'deleteItemType' });
    const itemTypeData = yield call(itemTypeService.deleteItemType, action.itemType.id);
    yield put({ type: 'DELETE_ITEM_TYPE_SUCCESSFUL', itemTypeData });
    yield put({
      type: 'REQUEST_LIST_ITEM_TYPES',
      params: yield select(getFilter),
    });
  } catch (error) {
    yield put({ type: 'DELETE_ITEM_TYPE_FAILED', error });
  }
}
