import { call, put, takeLatest } from 'redux-saga/effects';

import { manifestTemplatesService } from '../../services/manifestTemplatesService';

export default function* manifestTemplatesSaga() {
  yield takeLatest('REQUEST_GET_MANIFEST_TEMPLATES', getManifestTemplates);
  yield takeLatest('REQUEST_UPDATE_MANIFEST_TEMPLATES', updateManifestTemplates);
  yield takeLatest('SAVE_LAST_SECTIONS_ORDER', saveSectionsOrder);
}

function* getManifestTemplates() {
  try {
    const { data } = yield call(manifestTemplatesService.getManifestTemplates);
    yield put({ type: 'REQUEST_GET_MANIFEST_TEMPLATES_SUCCESSFUL', data });
    const sections = yield call(manifestTemplatesService.getManifestTemplatesSections);
    yield put({ type: 'REQUEST_GET_MANIFEST_TEMPLATES_SECTIONS_SUCCESSFUL', data: sections.data });
  } catch (error) {
    console.error(error);
    yield put({ type: 'REQUEST_GET_WORKERS_FAILED', error });
  }
}

function* updateManifestTemplates(action) {
  try {
    yield put({ type: 'SET_MANIFEST_TEMPLATES_PROGRESS' });
    const manifestTemplates = yield call(manifestTemplatesService.updateManifestTemplates, action.payload);
    yield put({ type: 'REQUEST_UPDATE_MANIFEST_TEMPLATES_SUCCESS', manifestTemplates });
  } catch (error) {
    yield put({ type: 'REQUEST_UPDATE_MANIFEST_TEMPLATES_FAILED', error });
  }
}

function* saveSectionsOrder(data) {
  try {
    yield put({ type: 'SAVE_SECTION_ORDER', data: data.data });
  } catch (error) {
    console.error(error);
  }
}
