import './styles/main.scss';

import React, { Suspense, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Routes } from 'react-router-dom';

import { clearJwtData } from '@yojee/auth/jwt-storage';
import { initGA } from '@yojee/helpers/GAHelper';
import i18n from '@yojee/helpers/i18nLoader';
import lazyLoadComponent from '@yojee/helpers/lazyLoadComponent';
import Authorization from '@yojee/ui/Authorization';
import Snackbar from '@yojee/ui/base/furnitures/Snackbar';
import LoadingOverlay from '@yojee/ui/common/LoadingOverlay/LoadingOverlay';
import { globalTheme } from '@yojee/ui/common/style/manage/GlobalThemeOverride.js';
import * as featureManagementActions from '@yojee/ui/feature-management/saga/actions';
import { HistoryRouter } from '@yojee/ui/router/Router';

import packageJson from '../../../package.json';
import CompanySettings from './components/Account/CompanySettings';
import MainLayout from './components/Common/MainLayout';
import Redirect from './components/Common/Redirect';
import CheckPathChange from './components/Common/TopMenu/CheckPathChange';
import { updatePathToExplore } from './components/Common/TopMenu/hooks/useMenuItemLogic';
import { setDataFromExplore } from './sagas/login/loginActions';
import ThemeContext from './ThemeContext';

const Branding = lazyLoadComponent(() => import('./components/Account/CompanyBranding'));
const NewTemplate = lazyLoadComponent(() => import('./components/Template/NewTemplate'));
const TemplateDetail = lazyLoadComponent(() => import('./components/Template/TemplateDetail/TemplateDetail'));
const DispatchRules = lazyLoadComponent(() => import('./components/DispatchRules/DispatchRules'));
const SavedFilterList = lazyLoadComponent(() => import('./components/SavedFilters/SavedFilterList'));
const ConnoteTemplate = lazyLoadComponent(() => import('./components/ConnoteTemplate'));
const ManifestTemplate = lazyLoadComponent(() => import('./components/ManifestTemplate'));
const AddressList = lazyLoadComponent(() => import('./components/Addresses/AddressList'));
const UserManagement = lazyLoadComponent(() => import('./components/UserManagement/UserManagement'));
const OrganisationalUnits = lazyLoadComponent(() => import('./modules/organisationalUnits/OrganisationalUnits'));
const TeamNotifications = lazyLoadComponent(() => import('./modules/teamNotifications'));
const Roles = lazyLoadComponent(() => import('./components/UserManagement/Roles'));
const DispatcherManagement = lazyLoadComponent(() => import('./components/DispatcherManagement/DispatcherManagement'));
const DriverWorkflow = lazyLoadComponent(() => import('./components/DriverWorkflow/DriverWorkflow'));
const DriverSurveys = lazyLoadComponent(() => import('./modules/driverSurveys/DriverSurveys'));
const DriverSurveyForm = lazyLoadComponent(() => import('./modules/driverSurveys/DriverSurveyForm'));
const Manifest = lazyLoadComponent(() => import('./components/Manifest'));
const AllDrivers = lazyLoadComponent(() => import('./components/WorkerManagement/AllDrivers'));
const WorkerTeamManagement = lazyLoadComponent(() => import('./components/WorkerTeamManagement/WorkerTeamManagement'));
const TeamDetailManagement = lazyLoadComponent(() => import('./components/WorkerTeamManagement/TeamDetail'));
const FleetManagement = lazyLoadComponent(() => import('./components/FleetManagement/FleetManagement'));
const Notifications = lazyLoadComponent(() => import('./components/Notifications'));
const PODs = lazyLoadComponent(() => import('./components/PODs'));

const SendersOrganisations = lazyLoadComponent(() => import('./components/Senders/CorporateSenders/Organisations'));
const SendersOrganisationDetails = lazyLoadComponent(() =>
  import('./components/Senders/CorporateSenders/OrganisationDetails')
);
const AddOrganisationDetails = lazyLoadComponent(() => import('./components/Senders/CorporateSenders/AddOrganisation'));
const DriverSchedule = lazyLoadComponent(() => import('./components/WorkerManagement/DriverSchedule'));
const DriverRoutes = lazyLoadComponent(() => import('./components/DriverRoutes'));
const Subscription = lazyLoadComponent(() => import('./components/Subscription/Subscription'));
const WorkerManagementAddFrame = lazyLoadComponent(() =>
  import('./components/WorkerManagement/WorkerManagementAddFrame')
);
const CreateAddress = lazyLoadComponent(() => import('./components/Addresses/CreateAddress'));
const ServiceTypes = lazyLoadComponent(() => import('./components/ServiceTypes/ServiceTypes'));
const Operations = lazyLoadComponent(() => import('./components/Operations/Operations'));
const Vehicles = lazyLoadComponent(() => import('./components/Vehicles/Vehicles'));
const VehicleSchedule = lazyLoadComponent(() => import('./components/Vehicles/VehicleSchedule'));
const VehicleAddFrame = lazyLoadComponent(() => import('./components/Vehicles/VehicleAddFrame'));
const Labels = lazyLoadComponent(() => import('./components/WayBill/LabelManagement'));
const LabelDetails = lazyLoadComponent(() => import('./components/WayBill/WayBill'));
const ItemTypes = lazyLoadComponent(() => import('./components/ItemTypes/ItemTypes'));
const Tags = lazyLoadComponent(() => import('./components/Tags/Tags'));
const Integrations = lazyLoadComponent(() => import('./components/Integrations/Integrations'));
const ServiceTimes = lazyLoadComponent(() => import('./components/ServiceTimes'));
const Rating = lazyLoadComponent(() => import('./components/Rating'));
const RatingDetail = lazyLoadComponent(() => import('./components/Rating/RatingDetail'));
const PartnerRatingDetail = lazyLoadComponent(() => import('./components/Rating/PartnerRateDetails'));
const RateChargeTypes = lazyLoadComponent(() => import('./components/RateChargeTypes'));
const RateChargeTypesDetail = lazyLoadComponent(() => import('./components/RateChargeTypes/RateChargeTypesDetail'));
const ContainerTypes = lazyLoadComponent(() => import('./components/FleetManagement/ContainerTypes'));
const VAS = lazyLoadComponent(() => import('./components/VAS/VAS'));
const FileViewer = lazyLoadComponent(() => import('@yojee/ui/common/FileViewer'));
const CustomFieldList = lazyLoadComponent(() => import('./components/CustomField/CustomFieldList'));
const CustomLabelList = lazyLoadComponent(() => import('./components/CustomLabel/CustomLabel'));
const DocumentCodeList = lazyLoadComponent(() => import('./components/DocumentCode/DocumentCodeList'));
const ReasonCodes = lazyLoadComponent(() => import('./components/ReasonCodes/ReasonCodes'));

window.appVersion = window.appVersion || packageJson.version;

initGA('manage', window.appVersion);

/**
 *
 *
 * @param {{history: ReturnType<import('history').createBrowserHistory>}} { history, initialAuth }
 * @return {*}
 */
const App = ({ history, initialAuth }) => {
  const dispatch = useDispatch();
  const [languageRerenderFlag, setLanguageRerenderFlag] = useState(false);

  useEffect(() => {
    const onLanguageLoaded = () => {
      setTimeout(() => {
        setLanguageRerenderFlag(!languageRerenderFlag);
      }, 500);
    };

    i18n.on('loaded', onLanguageLoaded);

    return () => {
      i18n.off('loaded', onLanguageLoaded);
    };
  }, [languageRerenderFlag]);

  useEffect(() => {
    if (initialAuth) {
      dispatch({ type: 'REQUEST_SET_DATA_FROM_EXPLORE', payload: { ...initialAuth, isAppInjected: true } });
    }

    dispatch(featureManagementActions.fetchReleaseToggles());
  }, []);

  const handleFrameMessages = (e) => {
    if (!e || !e.data || !e.data.type) {
      return;
    }
    const { data } = e;

    if (data.type === 'PASS_DATA_TO_MANAGE') {
      dispatch(setDataFromExplore(data.data));
    } else if (data.type === 'CLEAR_DATA') {
      clearJwtData();
    }
  };

  useEffect(() => {
    if (window.parent) {
      window.parent.postMessage(
        {
          type: 'MANAGE_FRAME_LISTENING',
        },
        '*'
      );

      const unlisten = history.listen((update) => {
        updatePathToExplore(update.location.pathname);
      });

      return () => unlisten();
    }
  }, []);

  useEffect(() => {
    window.addEventListener('message', handleFrameMessages, true);
    return () => window.removeEventListener('message', handleFrameMessages, true);
  }, []);

  const prefix = initialAuth ? '/manage' : '';
  return (
    <ThemeContext theme={globalTheme}>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <HistoryRouter history={history}>
          <CheckPathChange />

          <Suspense fallback={<LoadingOverlay active={true} />}>
            <Routes>
              <Route path={prefix} element={<MainLayout />}>
                <Route path="" element={<Authorization prefix={prefix} component={CompanySettings} role="manage" />} />
                <Route
                  path="company-settings"
                  element={<Authorization prefix={prefix} component={CompanySettings} role="manage" />}
                />
                <Route path="branding" element={<Authorization prefix={prefix} component={Branding} role="manage" />} />
                <Route
                  path="booking-template"
                  element={<Authorization prefix={prefix} component={NewTemplate} role="manage" />}
                />
                <Route
                  path="booking-template/:id"
                  element={<Authorization prefix={prefix} component={TemplateDetail} role="manage" />}
                />
                <Route
                  path="user-management"
                  element={<Authorization prefix={prefix} component={UserManagement} role="manage" />}
                />
                <Route
                  path="organisational-units"
                  element={<Authorization prefix={prefix} component={OrganisationalUnits} role="manage" />}
                />
                <Route
                  path="team-notifications"
                  element={<Authorization prefix={prefix} component={TeamNotifications} role="manage" />}
                />
                <Route path="roles" element={<Authorization prefix={prefix} component={Roles} role="manage" />} />
                <Route
                  path="dispatcher-management"
                  element={<Authorization prefix={prefix} component={DispatcherManagement} role="manage" />}
                />
                <Route
                  path="driver-workflow"
                  element={<Authorization prefix={prefix} component={DriverWorkflow} role="manage" />}
                />
                <Route
                  path="driver-surveys"
                  element={<Authorization prefix={prefix} component={DriverSurveys} role="manage" />}
                />
                <Route
                  path="driver-survey/new"
                  element={<Authorization prefix={prefix} component={DriverSurveyForm} role="manage" />}
                />

                <Route
                  path="driver-survey/:surveyId"
                  element={<Authorization prefix={prefix} component={DriverSurveyForm} role="manage" />}
                />
                <Route path="driver-routes" element={<DriverRoutes />} />
                <Route path="driver-management">
                  <Route
                    index
                    element={
                      <Authorization
                        prefix={prefix}
                        index
                        path={`${prefix}/driver-management`}
                        component={AllDrivers}
                        role="manage"
                      />
                    }
                  />
                  <Route
                    path=":workerId"
                    element={<Authorization prefix={prefix} component={DriverSchedule} role="manage" />}
                  />
                  <Route path="frame/add" element={<WorkerManagementAddFrame />} />
                </Route>
                <Route path="manifest">
                  <Route index element={<Authorization prefix={prefix} component={Manifest} role="manage" />} />
                </Route>
                <Route path="teams">
                  <Route
                    index
                    element={<Authorization prefix={prefix} component={WorkerTeamManagement} role="manage" />}
                  />
                  <Route
                    path=":teamId"
                    element={<Authorization prefix={prefix} component={TeamDetailManagement} role="manage" />}
                  />
                </Route>
                <Route
                  path="vehicle-types"
                  element={<Authorization prefix={prefix} component={FleetManagement} role="manage" />}
                />
                <Route
                  path="notifications"
                  element={<Authorization prefix={prefix} component={Notifications} role="manage" />}
                />
                <Route path="pods" element={<Authorization prefix={prefix} component={PODs} role="manage" />} />
                <Route path="labels" element={<Authorization prefix={prefix} component={Labels} role="manage" />} />
                <Route
                  path="labels/:labelId"
                  element={<Authorization prefix={prefix} component={LabelDetails} role="manage" />}
                />
                <Route
                  path="dispatch-rules"
                  element={<Authorization prefix={prefix} component={DispatchRules} role="manage" />}
                />
                <Route
                  path="saved-filters"
                  element={<Authorization prefix={prefix} component={SavedFilterList} role="manage" />}
                />
                <Route
                  path="connote-template"
                  element={<Authorization prefix={prefix} component={ConnoteTemplate} role="manage" />}
                />
                <Route
                  path="manifest-template"
                  element={<Authorization prefix={prefix} component={ManifestTemplate} role="manage" />}
                />
                <Route
                  path="addresses"
                  element={<Authorization prefix={prefix} component={AddressList} role="manage" />}
                />
                <Route
                  path="addresses/create"
                  element={<Authorization prefix={prefix} component={CreateAddress} role="manage" />}
                />

                <Route
                  path="senders"
                  element={<Authorization prefix={prefix} component={SendersOrganisations} role="manage" />}
                />
                <Route
                  path="senders/:corporateId"
                  element={<Authorization prefix={prefix} component={SendersOrganisationDetails} role="manage" />}
                />
                <Route
                  path="senders/corporate-new"
                  element={<Authorization prefix={prefix} component={AddOrganisationDetails} role="manage" />}
                />
                <Route
                  path="subscription"
                  element={<Authorization prefix={prefix} component={Subscription} role="manage" />}
                />
                <Route
                  path="service-types"
                  element={<Authorization prefix={prefix} component={ServiceTypes} role="manage" />}
                />
                <Route
                  path="operations"
                  element={<Authorization prefix={prefix} component={Operations} role="manage" />}
                />
                <Route path="task-types" element={<Redirect to="" />} />
                <Route
                  path="container-types"
                  element={<Authorization prefix={prefix} component={ContainerTypes} role="manage" />}
                />
                <Route
                  path="fleetmanagement"
                  element={<Authorization prefix={prefix} component={Vehicles} role="manage" />}
                />
                <Route
                  path="fleetmanagement/:vehicleId"
                  element={<Authorization prefix={prefix} component={VehicleSchedule} role="manage" />}
                />
                <Route
                  path="fleetmanagement/frame/add"
                  element={<Authorization prefix={prefix} component={VehicleAddFrame} role="manage" />}
                />
                <Route
                  path="item-types"
                  element={<Authorization prefix={prefix} component={ItemTypes} role="manage" />}
                />
                <Route path="tags" element={<Authorization prefix={prefix} component={Tags} role="manage" />} />
                <Route
                  path="integrations"
                  element={<Authorization prefix={prefix} component={Integrations} role="manage" />}
                />
                <Route
                  path="service-times"
                  element={<Authorization prefix={prefix} component={ServiceTimes} role="manage" />}
                />
                <Route path="rates" element={<Authorization prefix={prefix} component={Rating} role="manage" />} />
                <Route
                  path="rates/new"
                  element={<Authorization prefix={prefix} component={RatingDetail} role="manage" />}
                />
                <Route
                  path="rates/edit/:id"
                  element={<Authorization prefix={prefix} component={RatingDetail} role="manage" />}
                />
                <Route
                  path="rates/partners/:id"
                  element={<Authorization prefix={prefix} component={PartnerRatingDetail} role="manage" />}
                />
                <Route
                  path="rate-charge-codes"
                  element={<Authorization prefix={prefix} component={RateChargeTypes} role="manage" />}
                />
                <Route
                  path="rate-charge-codes/:id"
                  element={<Authorization prefix={prefix} component={RateChargeTypesDetail} role="manage" />}
                />
                <Route path="vas" element={<VAS />} />
                <Route path="custom-fields" element={<CustomFieldList />} />
                <Route path="custom-labels" element={<CustomLabelList />} />
                <Route path="document-codes" element={<DocumentCodeList />} />
                <Route
                  path="reason-codes"
                  element={<Authorization prefix={prefix} component={ReasonCodes} role="manage" />}
                />
              </Route>
              <Route path="/preview" element={<FileViewer />} />
            </Routes>
          </Suspense>
        </HistoryRouter>
      </Snackbar>
    </ThemeContext>
  );
};

export default App;
