const defaultState = {
  loading: false,
  inProgress: {
    deleteSavedFilter: false,
  },
  saved_filter: [],
  savedFiltersData: [],
  error: {
    failed: false,
  },
  bulkDeleteSuccess: false,
  pagination: { current_page: 1, limit_value: 25, total_count: 0, total_pages: 0 },
};

export const savedFiltersMessagesSuccessEvents = {
  CREATE_SAVED_FILTER_SUCCESSFUL: 'Saved Filter created successfully',
  UPDATE_SAVED_FILTER_SUCCESSFUL: 'Saved Filter updated successfully',
  DELETE_SAVED_FILTER_SUCCESSFUL: 'Saved Filter deleted successfully',
  REQUEST_DELETE_SAVED_FILTER_IN_PROCESS: 'Saved Filter delete in process',
  BULK_REQUEST_DELETE_SAVED_FILTER_SUCCESS: 'Saved Filter deleted successfully',
};

export const savedFiltersMessagesErrorEvents = {
  LIST_SAVED_FILTERS_FAILED: ({ error }) => error?.message ?? 'Failed to get saved filter info',
  CREATE_SAVED_FILTER_FAILED: 'Error creating saved filter.',
  UPDATE_SAVED_FILTER_FAILED: 'Error updating saved filter.',
  DELETE_SAVED_FILTER_FAILED: (payload) => payload?.error?.message ?? 'Error deleting saved filter.',
};

export const init = () => {
  return defaultState;
};

const ACTION_HANDLERS = {
  LIST_SAVED_FILTERS_SUCCESSFUL: (state, sagaData) => {
    return {
      ...state,
      loading: false,
      savedFiltersData: sagaData.savedFiltersData.data,
      pagination: sagaData.savedFiltersData.pagination,
    };
  },
  LIST_SAVED_FILTERS_FAILED: (state) => {
    return {
      ...state,
      loading: false,
      error: {
        failed: true,
      },
    };
  },
  SET_SAVED_FILTER_LOADING: (state, sagaData) => {
    return {
      ...state,
      loading: sagaData,
    };
  },
  REQUEST_DELETE_SAVED_FILTER: (state) => {
    return {
      ...state,
      inProgress: {
        deleteSavedFilter: true,
      },
    };
  },
  DELETE_SAVED_FILTER_SUCCESSFUL: (state) => {
    return {
      ...state,
      inProgress: {
        deleteSavedFilter: false,
      },
    };
  },
  DELETE_SAVED_FILTER_FAILED: (state) => {
    return {
      ...state,
      inProgress: {
        deleteSavedFilter: false,
      },
    };
  },
  REQUEST_DELETE_SAVED_FILTER_IN_PROCESS: (state) => {
    return {
      ...state,
      loading: true,
      bulkDeleteSuccess: false,
    };
  },
  BULK_REQUEST_DELETE_SAVED_FILTER_SUCCESS: (state) => {
    return {
      ...state,
      loading: false,
      bulkDeleteSuccess: true,
    };
  },
  BULK_REQUEST_DELETE_SAVED_FILTER_FAIL: (state) => {
    return {
      ...state,
      loading: false,
      bulkDeleteSuccess: false,
    };
  },
};

const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};

export default reducer;
