import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import React from 'react';

import Button from '@yojee/ui/base/furnitures/Button';

const useStyles = makeStyles((theme) => ({
  addBtn: {
    margin: `0 2px`,
    textTransform: 'unset',
  },
}));

const MaterialButton = ({ label, type, newUI, ...other }) => {
  const classes = useStyles();
  let newUIProperty = {};
  if (newUI) {
    newUIProperty = {
      variant: 'contained',
    };
  }

  switch (type) {
    case 'addRule':
    case 'addGroup':
      return (
        <Button
          data-cy={`${type}-btn`}
          className={classes.addBtn}
          type={type}
          size="small"
          variant="outlined"
          color="primary"
          {...newUIProperty}
          {...other}
        >
          {label}
        </Button>
      );

    case 'delRule':
    case 'delGroup':
      return (
        <IconButton data-cy={`${type}-btn`} type={type} size="small" color="default" aria-label="delete" {...other}>
          <DeleteIcon fontSize="small" />
        </IconButton>
      );

    default:
      return <Button data-cy={`${type}-btn`} type={type} size="small" color="primary" {...other} />;
  }
};

export default MaterialButton;
