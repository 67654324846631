const defaultState = {
  routesList: [],
  loading: false,
};

export const init = () => defaultState;

const ACTION_HANDLERS = {
  REQUEST_CREATE_DRIVER_ROUTE: (state) => {
    return {
      ...state,
      loading: true,
    };
  },
  GENERAL_DRIVER_ROUTE_ACTION_FAILED: (state) => {
    return {
      ...state,
      loading: false,
    };
  },
  REQUEST_CREATE_DRIVER_ROUTE_SUCCESS: (state, sagaData) => {
    const copy = state.routesList.slice();
    copy.push(sagaData.data);
    return {
      ...state,
      routesList: copy,
      loading: false,
    };
  },
  REQUEST_LIST_DRIVER_ROUTES_SUCCESS: (state, sagaData) => {
    return {
      ...state,
      routesList: sagaData.data,
    };
  },
  REQUEST_UPDATE_DRIVER_ROUTE: (state) => {
    return {
      ...state,
      loading: true,
    };
  },
  REQUEST_UPDATE_DRIVER_ROUTE_SUCCESS: (state, sagaData) => {
    const route = sagaData.data;
    const newList = state.routesList.slice();
    const index = newList.findIndex((e) => e.id === route.id);
    if (index >= 0) {
      newList[index] = route;
    }
    return {
      ...state,
      loading: false,
      routesList: newList,
    };
  },
  REQUEST_DELETE_DRIVER_ROUTE_SUCCESS: (state, sagaData) => {
    const route = sagaData.data;
    let newList = state.routesList.slice();
    if (route && route.id) {
      newList = newList.filter((e) => e.id !== route.id);
    }
    return {
      ...state,
      routesList: newList,
    };
  },
};

const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};

export default reducer;
