import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import { batchUpload } from '@yojee/ui/onboarding/helpers/batchUploadHelper';

import vehicleService from '../../services/vehicleService';

const vehicleTypeSelector = (state) => state.vehicles;

export default function* sagas() {
  // vehicles
  yield takeLatest('REQUEST_GET_VEHICLES', getVehicles);
  yield takeLatest('REQUEST_GET_VEHICLE', getVehicle);
  yield takeLatest('REQUEST_CREATE_VEHICLE', createVehicle);
  yield takeLatest('REQUEST_DELETE_VEHICLE', deleteVehicle);
  yield takeLatest('REQUEST_UPDATE_VEHICLE', updateVehicle);
  yield takeLatest('REQUEST_SEARCH_VEHICLE_TAGS', searchVehicleTags);
  yield takeLatest('REQUEST_VEHICLE_BATCH_UPLOAD', requestVehicleBatchUpload);
  yield takeLatest('DOWNLOAD_VEHICLE_TEMPLATE_SAMPLE', downloadVehicleTemplateSample);

  // vehicle types
  yield takeEvery('REQUEST_GET_VEHICLE_TYPES', getListVehicleTypes);
  yield takeLatest('REQUEST_GET_VEHICLE_TYPES_MASTER', getListVehicleTypeMaster);
  yield takeLatest('REQUEST_CREATE_VEHICLE_TYPE', createVehicleType);
  yield takeLatest('REQUEST_DELETE_VEHICLE_TYPES', deleteVehicleType);
  yield takeLatest('REQUEST_UPDATE_VEHICLE_TYPE', updateVehicleType);
}

// Fleet management
function* refreshVehiclesType(isFleetPage = false) {
  const {
    pagination: { current_page, limit_value },
    fleetPagePagination: { current_page: fleetPageCurrentPage, limit_value: fleetPageCurrentLimit },
  } = yield select(vehicleTypeSelector);

  if (isFleetPage) {
    yield put({
      type: 'REQUEST_GET_VEHICLE_TYPES',
      payload: {
        page: fleetPageCurrentPage,
        page_size: fleetPageCurrentLimit,
        include_vehicle_count: true,
        include_worker_count: true,
        fleetPage: true,
      },
    });
  } else {
    yield put({
      type: 'REQUEST_GET_VEHICLE_TYPES',
      payload: { page: current_page, page_size: limit_value },
    });
  }
}

// Vehicle Page
function* refreshVehicles() {
  const {
    vehiclePagePagintation: { current_page, limit_value },
  } = yield select(vehicleTypeSelector);

  yield put({ type: 'REQUEST_GET_VEHICLES', payload: { page: current_page, page_size: limit_value } });

  if (window.parent) {
    window.parent.postMessage(
      {
        type: 'REQUEST_GET_VEHICLES',
        payload: { page: current_page, page_size: limit_value },
      },
      '*'
    );
  }
}

function* getVehicles(action) {
  try {
    yield put({ type: 'START_VEHICLE_PROGRESS', action: 'getVehicles' });
    const data = yield call(vehicleService.getVehicles, action.payload);
    yield put({ type: 'REQUEST_GET_VEHICLES_SUCCESSFUL', data });
  } catch (error) {
    yield put({ type: 'REQUEST_GET_VEHICLES_FAILED', error });
  }
}

function* getVehicle(action) {
  try {
    yield put({ type: 'START_VEHICLE_PROGRESS', action: 'getVehicle' });
    const data = yield call(vehicleService.getVehicle, action.payload);
    yield put({ type: 'REQUEST_GET_VEHICLE_SUCCESSFUL', data });
  } catch (error) {
    yield put({ type: 'REQUEST_GET_VEHICLE_FAILED', error });
  }
}

function* createVehicle(action) {
  try {
    yield put({ type: 'START_VEHICLE_PROGRESS', action: 'createVehicle' });
    const data = yield call(vehicleService.createVehicle, action.payload);
    yield put({ type: 'REQUEST_CREATE_VEHICLE_SUCCESSFUL', message: data.message });
    yield put({ type: 'REQUEST_GET_VEHICLES' });
    yield call(refreshVehicles);

    if (window.parent) {
      window.parent.postMessage(
        {
          type: 'REQUEST_CREATE_VEHICLE_SUCCESSFUL',
        },
        '*'
      );
    }
  } catch (error) {
    yield put({ type: 'REQUEST_CREATE_VEHICLE_FAILED', error });
    if (window.parent) {
      window.parent.postMessage(
        {
          type: 'REQUEST_CREATE_VEHICLE_FAILED',
          errorMessage: `Failed to create vehicle: ${error.message}`,
        },
        '*'
      );
    }
  }
}

function* deleteVehicle(action) {
  try {
    yield put({ type: 'START_VEHICLE_PROGRESS', action: 'deleteVehicle' });
    const { message } = yield call(vehicleService.deleteVehicle, action.payload);
    yield put({ type: 'REQUEST_DELETE_VEHICLE_SUCCESSFUL', message });
    yield call(refreshVehicles);
  } catch (error) {
    yield put({ type: 'REQUEST_DELETE_VEHICLE_FAILED', error });
  }
}

function* updateVehicle(action) {
  try {
    yield put({ type: 'START_VEHICLE_PROGRESS', action: 'updateVehicle' });
    const { message } = yield call(vehicleService.updateVehicle, action.payload);
    yield put({ type: 'REQUEST_UPDATE_VEHICLE_SUCCESSFUL', message });
    yield call(refreshVehicles);
  } catch (error) {
    yield put({ type: 'REQUEST_UPDATE_VEHICLE_FAILED', error });
  }
}

function* searchVehicleTags({ query }) {
  try {
    yield put({ type: 'START_VEHICLE_PROGRESS', action: 'searchVehicleTags' });
    yield put({
      type: 'REQUEST_SEARCH_VEHICLE_TAGS_SUCCESS',
      data: [
        { value: 1, label: 1 },
        { value: 2, label: 2 },
      ],
    });
  } catch (error) {
    yield put({ type: 'UPDATE_VEHICLE_ERROR', error });
  }
}

function downloadVehicleTemplateSample({ format }) {
  window.open(vehicleService.downloadVehicleTemplateSample({ format }), '_self');
}

function* getListVehicleTypes(action) {
  try {
    yield put({ type: 'START_VEHICLE_PROGRESS', action: 'getListVehicles' });
    // Default params will include worker & vehicle counts
    const configParams = action?.payload || {
      include_worker_count: true,
      include_vehicle_count: true,
      page_size: 100, // get most of the vehicle types for select dropdown
    };
    const lstVehicleTypes = yield call(vehicleService.getListVehicleTypes, configParams);

    yield put({
      type: 'REQUEST_GET_VEHICLE_TYPES_SUCCESS',
      result: {
        ...lstVehicleTypes,
        fleetPage: !!action?.payload?.fleetPage,
      },
    });
  } catch (error) {
    yield put({ type: 'REQUEST_GET_VEHICLE_TYPES_ERROR', message: error.message });
  }
}

function* getListVehicleTypeMaster(action) {
  try {
    const lstVehicleTypesMaster = yield call(vehicleService.getMasterVehicleTypes);
    yield put({ type: 'REQUEST_GET_VEHICLE_TYPES_MASTER_SUCCESS', result: lstVehicleTypesMaster });
  } catch (error) {
    yield put({ type: 'REQUEST_GET_VEHICLE_TYPES_MASTER_ERROR', error });
  }
}

function* createVehicleType(action) {
  try {
    yield put({ type: 'START_VEHICLE_PROGRESS', action: 'createVehicle' });
    const { fleetPage = false, ...restPayload } = action.payload;
    const resultCreateVehicleType = yield call(vehicleService.createVehicleType, restPayload);
    yield put({ type: 'REQUEST_CREATE_VEHICLE_TYPE_SUCCESS', result: resultCreateVehicleType });
    yield call(refreshVehiclesType, fleetPage);
  } catch (error) {
    yield put({ type: 'REQUEST_CREATE_VEHICLE_TYPE_ERROR', message: error.message });
  }
}

function* updateVehicleType(action) {
  try {
    yield put({ type: 'START_VEHICLE_PROGRESS', action: 'updateVehicle' });
    const { fleetPage = false, ...restPayload } = action.payload;
    const resultUpdateVehicleType = yield call(vehicleService.updateVehicleType, restPayload);
    yield put({ type: 'REQUEST_UPDATE_VEHICLE_TYPE_SUCCESS', result: resultUpdateVehicleType });
    yield call(refreshVehiclesType, fleetPage);
  } catch (error) {
    yield put({ type: 'REQUEST_UPDATE_VEHICLE_TYPE_ERROR', message: error.message });
  }
}

function* deleteVehicleType(action) {
  try {
    const { replaceVehicle, vehicleTypeId, newVehicleTypeId, fleetPage = false } = action.payload;

    if (replaceVehicle) {
      const createNewVehicleResult = yield call(vehicleService.createVehicleType, replaceVehicle);
      const newVehicleTypeId = createNewVehicleResult && createNewVehicleResult.id;
      if (newVehicleTypeId) {
        const resultDeleteVehicleType = yield call(vehicleService.deleteVehicleType, {
          vehicleTypeId,
          newVehicleTypeId,
        });
        yield put({
          type: 'REQUEST_DELETE_VEHICLE_TYPES_SUCCESS',
          result: resultDeleteVehicleType && resultDeleteVehicleType.id,
        });
        yield call(refreshVehiclesType, fleetPage);
      }
    } else {
      const resultDeleteVehicleType = yield call(vehicleService.deleteVehicleType, { vehicleTypeId, newVehicleTypeId });
      yield put({
        type: 'REQUEST_DELETE_VEHICLE_TYPES_SUCCESS',
        result: resultDeleteVehicleType && resultDeleteVehicleType.id,
      });
      yield call(refreshVehiclesType, fleetPage);
    }
  } catch (error) {
    yield put({ type: 'REQUEST_DELETE_VEHICLE_TYPES_ERROR', message: error.message });
  }
}

function* requestVehicleBatchUpload(action) {
  const { file, format } = action.uploadData;

  yield batchUpload(
    { file, format },
    {
      uploadApiMethod: vehicleService.batchUpload,
      statusCheckApiMethod: vehicleService.checkBatchStatus,
      events: {
        started: 'VEHICLE_BATCH_UPLOAD_STARTED',
        processing: 'VEHICLE_BATCH_UPLOAD_PROCESSING',
        succeeded: 'VEHICLE_BATCH_UPLOAD_SUCCEEDED',
        failed: 'VEHICLE_BATCH_UPLOAD_FAILED',
      },
    }
  );
}
