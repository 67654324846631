import { t } from 'i18next';
import { map } from 'lodash';

import { batchUploadDefaultState } from '@yojee/ui/onboarding/helpers/batchUploadHelper';

import { ResponseListReasonCodes } from '../sagas/reason_codes/reasonCodeSaga';

const defaultState = {
  batchUpload: batchUploadDefaultState,
  lastCompletedActionUuid: undefined,
  listReasonCodes: {
    loading: false,
    data: [],
  },
};

type Action = {
  type: string;
  loadingAction: string;
  data: ResponseListReasonCodes;
  uuid?: string;
  total?: number;
  id?: number;
  batchId?: string;
  errors?: Message;
};

type Message = {
  message: string;
};
type Error = {
  code: string;
  message: string;
};
type Errors = {
  error: {
    data: {
      errors: Error[];
    };
  };
};

const processErrors = ({ error: { data: errors } }: Errors) => {
  return map(errors.errors, 'message').join(', ');
};

export const reasonCodeMessagesSuccessEvents = {
  BULK_DELETE_REASON_CODE_SUCCESSFUL: ({ message }: Message) => message,
  CREATE_REASON_CODE_SUCCESSFUL: ({ message }: Message) => message,
  REASON_CODE_BATCH_UPLOAD_SUCCEEDED: () => t('Batch upload completed'),
};

export const reasonCodeMessagesErrorEvents = {
  LIST_REASON_CODE_FAILED: () => t('Failed to get reason code info'),
  BULK_DELETE_REASON_CODE_FAILED: () => t('Failed to delete reason code(s)'),
  CREATE_REASON_CODE_FAILED: (errors: Errors) => processErrors(errors) ?? 'Failed to create new reason code',
  EDIT_REASON_CODE_FAILED: (errors: Errors) => processErrors(errors) ?? 'Failed to edit reason code',
};

const reducer = (state = defaultState, action: Action) => {
  switch (action.type) {
    case 'LIST_REASON_CODE_SUCCESSFUL':
      return {
        ...state,
        listReasonCodes: {
          data: action && action['data'],
          loading: false,
        },
      };
    case 'SET_REASON_CODES_LOADING':
      return {
        ...state,
        listReasonCodes: {
          loading: true,
        },
      };
    case 'LIST_REASON_CODE_FAILED':
      return {
        ...state,
        listReasonCodes: {
          loading: false,
        },
      };
    case 'CREATE_REASON_CODE_SUCCESSFUL':
      return {
        ...state,
        lastCompletedActionUuid: action.uuid,
      };
    case 'EDIT_REASON_CODE_SUCCESSFUL':
      return {
        ...state,
        lastCompletedActionUuid: action.uuid,
      };
    case 'REASON_CODE_UPLOAD_STARTED':
      return {
        ...state,
        batchUpload: {
          ...state.batchUpload,
          state: 'processing',
        },
      };
    case 'REASON_CODE_BATCH_UPLOAD_PROCESSING':
      return {
        ...state,
        batchUpload: {
          ...state.batchUpload,
          state: 'processing',
          batchId: action.id,
        },
      };
    case 'REASON_CODE_BATCH_UPLOAD_SUCCEEDED':
      return {
        ...state,
        batchUpload: {
          ...state.batchUpload,
          state: 'completed',
          errors: {},
          total: action.total,
        },
      };
    case 'REASON_CODE_BATCH_UPLOAD_FAILED':
      return {
        ...state,
        batchUpload: {
          ...state.batchUpload,
          state: 'failed',
          batchId: action.batchId,
          errors: action.errors,
        },
      };
    case 'REFRESH_BATCH_UPLOAD':
      return {
        ...state,
        batchUpload: {
          ...batchUploadDefaultState,
        },
      };
    default:
      return state;
  }
};

export default reducer;
