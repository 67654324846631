import * as _ from 'lodash-es';

import { fromOrderFieldTemplateDateToOrderFormModels } from '../saga/helpers';

/**
 * Ex: formKeyPath = 'bookingInfoSections.0.itemDetails.0.item'
 * => parentKeys: [root, bookingInfoSections, bookingInfoSections.0,
 * bookingInfoSections.0.itemDetails, bookingInfoSections.0.itemDetails.0 ]
 * @param formKeyPath
 * @returns {*[]|string[]}
 */
function getParentKeys(formKeyPath) {
  const parentKeys = formKeyPath === 'root' ? [] : ['root'];
  for (let i = 0; i < formKeyPath.length; ++i) {
    if (formKeyPath[i] === '.') {
      parentKeys.push(formKeyPath.substring(0, i));
    }
  }

  return parentKeys;
}

function getParentKey(formKeyPath) {
  const parentKeys = getParentKeys(formKeyPath);
  return parentKeys[parentKeys.length - 1];
}

function getChildrenKey(parentKey, formKeysMetaData) {
  const childrenKey = [];
  for (const key in formKeysMetaData) {
    const metaData = formKeysMetaData[key];
    if (metaData.parentKey === parentKey) {
      childrenKey.push(key);
    }
  }

  return childrenKey;
}

/**
 * It used in some use case: we change a leaf form, so parent forms/sections should be changed too
 * @param formKeyPath
 * @param formKeysMetaData
 * @returns {*}
 */
function updateFormKeysMetaDataOfParents(formKeyPath, formKeysMetaData) {
  let newFormKeysMetaData = { ...formKeysMetaData };

  const parentKeys = getParentKeys(formKeyPath);
  const parentKeysFromLeafToRoot = parentKeys.reverse();
  const isParentChangedStatus = (key) => key !== 'sender' && newFormKeysMetaData[key].changed;
  const isParentHaveErrors = (childKey) => !!newFormKeysMetaData[childKey].isHaveError;
  for (const parentKey of parentKeysFromLeafToRoot) {
    const childrenKey = getChildrenKey(parentKey, newFormKeysMetaData);
    // Ignore sender form because, in edit mode, sender form always readonly and data always changed
    // (because we have some custom logic to inject correct value for sender and corporate)

    newFormKeysMetaData[parentKey] = {
      ...newFormKeysMetaData[parentKey],
      parentKey: getParentKey(parentKey),
      changed: childrenKey.some(isParentChangedStatus),
      isHaveError: childrenKey.some(isParentHaveErrors),
    };
  }

  // Keep order form changed when have any sub form deleted
  if (newFormKeysMetaData.isHaveDeletedForm) {
    newFormKeysMetaData = {
      ...newFormKeysMetaData,
      root: {
        ...newFormKeysMetaData?.root,
        changed: true,
      },
    };
  }

  // Keep order form changed when have any new sub form
  if (newFormKeysMetaData.isHaveNewForm) {
    newFormKeysMetaData = {
      ...newFormKeysMetaData,
      root: {
        ...newFormKeysMetaData?.root,
        changed: true,
      },
    };
  }

  return newFormKeysMetaData;
}

export function getNewFormKeysMetaDataWhenUpdate(formKeysMetaData, formKeyPath, formMetaData) {
  const { changed, forceUnChange } = formMetaData || {};

  let newFormKeysMetaDataMap = {
    ...formKeysMetaData,
    [formKeyPath]: {
      ...formKeysMetaData[formKeyPath],
      ...formMetaData,
      // If a form already changed => it should marked changed equal true forever (unless force unchange)
      // Do this because in virtualization list, form can re-created when scroll up/down
      // so change status can be reset
      changed: changed ? changed : forceUnChange ? false : !!formKeysMetaData[formKeyPath]?.changed,
      parentKey: getParentKey(formKeyPath),
    },
  };
  newFormKeysMetaDataMap = updateFormKeysMetaDataOfParents(formKeyPath, newFormKeysMetaDataMap);

  return newFormKeysMetaDataMap;
}

export function getNewFormKeysMetaDataWhenDelete(formKeysMetaData, formKeyPath) {
  let newFormKeysMetaDataMap = { ...formKeysMetaData, isHaveDeletedForm: true };
  delete newFormKeysMetaDataMap[formKeyPath];
  newFormKeysMetaDataMap = updateFormKeysMetaDataOfParents(formKeyPath, newFormKeysMetaDataMap);

  return newFormKeysMetaDataMap;
}

/**
 * Handle cases like: add new form, delete form (change template)
 * @param state
 * @param formKeyPath
 * @param formModel
 * @returns {*|{isHaveDeletedForm: boolean}|{isHaveNewForm: boolean, root: (*&{changed: boolean})}}
 */
export function getNewFormKeysMetaDataWhenUpdateFormModel(state, formKeyPath, formModel) {
  let newFormKeysMetaDataMap;

  // Case delete a sub form form => need update parent sections
  if (!formModel) {
    newFormKeysMetaDataMap = getNewFormKeysMetaDataWhenDelete(state.formKeysMetaDataMap, formKeyPath);
  }

  const oldFormModel = _.get(state.orderFormModels, formKeyPath);
  // Case add new leg, step, item => we mark order changed
  if (!oldFormModel) {
    newFormKeysMetaDataMap = {
      ...(newFormKeysMetaDataMap ?? state.formKeysMetaDataMap),
      root: {
        ...newFormKeysMetaDataMap?.root,
        changed: true,
      },
      isHaveNewForm: true,
    };
  }

  return newFormKeysMetaDataMap ?? state.formKeysMetaDataMap;
}

export const handleOrderFieldData = (state, data) => {
  const orderFormModels = fromOrderFieldTemplateDateToOrderFormModels(data.data);

  return {
    orderDetail: {
      ...state.orderDetail,
      originalOrderFormModels: orderFormModels,
    },
    orderFormModels: _.cloneDeep(orderFormModels),
    orderFieldTemplate: {
      id: data.id,
      version: data.version,
      template_id: data.data?.order_info?.template_id,
    },
  };
};

/**
 * This function to copy data from newFormModel to oldFormModel
 * We do it because we want to keep object reference of oldFormModel to ovoid re-render forms
 * @param oldFormModel
 * @param newFormModel
 * @returns {unknown}
 */
export function copyValueOfNewModelToOldModel(oldFormModel, newFormModel) {
  if (!newFormModel) return undefined;

  const result = Object.assign(oldFormModel || {}, newFormModel);

  for (const prop in result) {
    // Remove prop not exist in new form model
    if (newFormModel[prop] === undefined) {
      delete result[prop];
    }
  }

  return result;
}
