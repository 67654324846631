import { call, put, select, takeLatest } from 'redux-saga/effects';

import { serviceTimeService } from '../../services/serviceTimeService';

export default function* sagas() {
  yield takeLatest('REQUEST_LIST_SERVICE_TIMES', listServiceTimes);
  yield takeLatest('REQUEST_CREATE_SERVICE_TIME', createServiceTime);
  yield takeLatest('REQUEST_UPDATE_SERVICE_TIME', updateServiceTime);
  yield takeLatest('REQUEST_DELETE_SERVICE_TIME', deleteServiceTime);
}

export const getFilterPagination = (state) => {
  return {
    ...state.sender.filter,
    ...state.serviceTime.pagination,
    page_size: state.serviceTime.pagination?.limit_value || 25,
  };
};

function* listServiceTimes(action) {
  try {
    yield put({ type: 'SET_SERVICE_TIME_LOADING', key: 'listServiceTimes' });
    const response = yield call(serviceTimeService.listServiceTimes, action.params);
    yield put({ type: 'LIST_SERVICE_TIMES_SUCCESSFUL', serviceTimeData: response, params: action.params });
  } catch (error) {
    yield put({ type: 'LIST_SERVICE_TIMES_FAILED', error });
  }
}

function* createServiceTime(action) {
  try {
    yield put({ type: 'SET_SERVICE_TIME_LOADING', key: 'createServiceTime' });
    const serviceTimeData = yield call(serviceTimeService.createServiceTime, action.serviceTime);
    yield put({ type: 'CREATE_SERVICE_TIME_SUCCESSFUL', serviceTimeData });
    yield put({ type: 'REQUEST_LIST_SERVICE_TIMES', params: yield select(getFilterPagination) });
  } catch (error) {
    yield put({ type: 'CREATE_SERVICE_TIME_FAILED', error });
  }
}
function* updateServiceTime(action) {
  try {
    yield put({ type: 'SET_SERVICE_TIME_LOADING', key: 'updateServiceTime' });
    const serviceTimeData = yield call(serviceTimeService.updateServiceTime, action.serviceTime);
    yield put({ type: 'UPDATE_SERVICE_TIME_SUCCESSFUL', serviceTimeData });
    yield put({ type: 'REQUEST_LIST_SERVICE_TIMES', params: yield select(getFilterPagination) });
  } catch (error) {
    yield put({ type: 'UPDATE_SERVICE_TIME_FAILED', error });
  }
}
function* deleteServiceTime(action) {
  try {
    yield put({ type: 'SET_SERVICE_TIME_LOADING', key: 'deleteServiceTime' });
    const serviceTimeData = yield call(serviceTimeService.deleteServiceTime, action.serviceTime.id);
    yield put({ type: 'DELETE_SERVICE_TIME_SUCCESSFUL', serviceTimeData });
    yield put({ type: 'REQUEST_LIST_SERVICE_TIMES', params: yield select(getFilterPagination) });
  } catch (error) {
    yield put({ type: 'DELETE_SERVICE_TIME_FAILED', error });
  }
}
