import { push } from 'redux-first-history';
import { call, put, takeLatest } from 'redux-saga/effects';

import ActionTypes from './ActionTypes';
import ResourceApi from './ResourceApi';
/**
 {
   resourcePath: `dispatcher/network/hubs`,
   resource: "hubs",
   overrideSagas: {}
  }
*/
const defaultError = 'Something went wrong';

export default function generateSaga({
  resource,
  isPaginated = false,
  resourcePath = null,
  overrideSagas = {},
  sagaParamsTransformations = {},
}) {
  return function* sagas() {
    const resourceAPI = new ResourceApi({ resourcePath });
    const actionTypes = new ActionTypes({ resource });

    function* getList({ payload }) {
      if (isPaginated) {
        yield getPaginatedList({ payload });
      } else {
        try {
          const { data, pagination } = yield call(resourceAPI.getList, payload);
          yield put({
            type: actionTypes.getActionType('LIST').success(),
            payload: { data, pagination },
          });
        } catch (error) {
          console.log(error);
          yield put({
            type: actionTypes.getActionType('LIST').failed(),
            payload: { errorMessage: error.message || defaultError },
          });
        }
      }
    }

    function* getPaginatedList({ payload }) {
      try {
        const { data, pagination } = yield call(resourceAPI.getList, payload);
        yield put({
          type: actionTypes.getActionType('LIST').success(),
          payload: { data, pagination },
        });
      } catch (error) {
        yield put({
          type: actionTypes.getActionType('LIST').failed(),
          payload: { errorMessage: error.message || defaultError },
        });
      }
    }

    function* edit({ payload: { id, baseUrl } }) {
      yield put(push(`${baseUrl}/${id}`));
    }

    function* add({ payload: { baseUrl } }) {
      yield put(push(`${baseUrl}/new`));
    }

    function* getResource({ payload }) {
      const { id } = payload;
      try {
        const resource = yield call(resourceAPI.getResource, id);
        yield put({
          type: actionTypes.getActionType('GET').success(),
          payload: { ...resource },
        });
      } catch (error) {
        yield put({
          type: actionTypes.getActionType('GET').failed(),
          payload: { errorMessage: error.message || defaultError },
        });
      }
    }

    function* createResource(params) {
      const {
        payload: { baseUrl, data },
      } = sagaParamsTransformations.create ? sagaParamsTransformations.create(params) : params;
      try {
        yield call(resourceAPI.createResource, data);
        yield put({
          type: actionTypes.getActionType('CREATE').success(),
          payload: { ...data },
        });
        yield put(push(`${baseUrl}`));
      } catch (error) {
        yield put({
          type: actionTypes.getActionType('CREATE').failed(),
          payload: { errorMessage: error.message || defaultError },
        });
      }
    }

    function* updateResource({ payload: { id, baseUrl, data } }) {
      try {
        yield call(resourceAPI.updateResource, id, data);
        yield put({
          type: actionTypes.getActionType('UPDATE').success(),
          payload: { ...data },
        });
        yield put(push(`${baseUrl}`));
      } catch (error) {
        yield put({
          type: actionTypes.getActionType('UPDATE').failed(),
          payload: { errorMessage: error.message || defaultError },
        });
      }
    }

    function* deleteResource({ payload: { id } }) {
      try {
        yield call(resourceAPI.deleteResource, id);
        yield put({
          type: actionTypes.getActionType('DELETE').success(),
          payload: { id },
        });
        yield getList({});
      } catch (error) {
        yield put({
          type: actionTypes.getActionType('DELETE').failed(),
          payload: { errorMessage: error.message || defaultError },
        });
      }
    }

    function* clearMessage() {
      yield put({
        type: actionTypes.getActionType('CLEAR_MESSAGE').success(),
        payload: {},
      });
    }

    yield takeLatest(actionTypes.getActionType('LIST').start(), overrideSagas.getList || getList);
    yield takeLatest(actionTypes.getActionType('EDIT').start(), edit);
    yield takeLatest(actionTypes.getActionType('GET').start(), getResource);
    yield takeLatest(actionTypes.getActionType('ADD').start(), add);
    yield takeLatest(actionTypes.getActionType('CREATE').start(), createResource);
    yield takeLatest(actionTypes.getActionType('UPDATE').start(), updateResource);
    yield takeLatest(actionTypes.getActionType('DELETE').start(), deleteResource);
    yield takeLatest(actionTypes.getActionType('CLEAR_MESSAGE').start(), clearMessage);
  };
}
