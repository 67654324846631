import { call, put, select, takeLatest } from 'redux-saga/effects';

import { dispatchRuleService } from '../../services/dispatchRuleService';

export default function* sagas() {
  yield takeLatest('REQUEST_LIST_DISPATCH_RULES', listDispatchRules);
  yield takeLatest('REQUEST_CREATE_DISPATCH_RULE', createDispatchRule);
  yield takeLatest('REQUEST_UPDATE_DISPATCH_RULE', updateDispatchRule);
  yield takeLatest('REQUEST_DELETE_DISPATCH_RULE', deleteDispatchRule);
}

function* listDispatchRules({ payload }) {
  try {
    yield put({ type: 'SET_DISPATCH_RULE_LOADING', loading: true });
    const dispatchRulesData = yield call(dispatchRuleService.listDispatchRules, payload);
    yield put({ type: 'LIST_DISPATCH_RULES_SUCCESSFUL', dispatchRulesData });
  } catch (error) {
    yield put({ type: 'LIST_DISPATCH_RULES_FAILED', error });
  }
}

function* createDispatchRule(action) {
  try {
    const dispatchRulesData = yield call(dispatchRuleService.createDispatchRule, action.rule);
    yield put({ type: 'CREATE_DISPATCH_RULE_SUCCESSFUL', dispatchRulesData });
    const { current_page: page, limit_value: page_size } = yield select((state) => state.dispatchRule.pagination);
    yield put({ type: 'REQUEST_LIST_DISPATCH_RULES', payload: { page, page_size } });
  } catch (error) {
    yield put({ type: 'CREATE_DISPATCH_RULE_FAILED', error });
  }
}
function* updateDispatchRule(action) {
  try {
    const dispatchRulesData = yield call(dispatchRuleService.updateDispatchRule, action.rule);
    yield put({ type: 'UPDATE_DISPATCH_RULE_SUCCESSFUL', dispatchRulesData });
    const { current_page: page, limit_value: page_size } = yield select((state) => state.dispatchRule.pagination);
    yield put({ type: 'REQUEST_LIST_DISPATCH_RULES', payload: { page, page_size } });
  } catch (error) {
    yield put({ type: 'UPDATE_DISPATCH_RULE_FAILED', error });
  }
}
function* deleteDispatchRule(action) {
  try {
    const dispatchRulesData = yield call(dispatchRuleService.deleteDispatchRule, action.id);
    yield put({ type: 'DELETE_DISPATCH_RULE_SUCCESSFUL', dispatchRulesData });
    const { current_page: page, limit_value: page_size } = yield select((state) => state.dispatchRule.pagination);
    yield put({ type: 'REQUEST_LIST_DISPATCH_RULES', payload: { page, page_size } });
  } catch (error) {
    yield put({ type: 'DELETE_DISPATCH_RULE_FAILED', error });
  }
}
