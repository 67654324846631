import lodashGet from 'lodash-es/get';
import { useCallback, useMemo } from 'react';

import { uuidv4 } from '@yojee/helpers/uuidv4';

// This id used for case order transferred from partner
// Order transferred from partner don't have sender information in current company
// So in this case, we show direct data in order_info.sender, don't get from list organizations, senders
export const DUMMY_CORP_ID = -1;

export const addUniqueIDToLegAndStep = (legs = []) => {
  return legs.map((leg) => ({
    steps: leg.steps.map((step) => ({ ...step, uniqueID: uuidv4() })),
    uniqueID: uuidv4(),
  }));
};

export const addUniqueIDToItemDetails = (items = []) => {
  return items.map((item) => ({
    ...item,
    uniqueID: uuidv4(),
  }));
};

export const isEmptyList = (list) => !list || list?.length === 0;

function getDefaultStepsInLeg(task_sequence, isEmptyLeg) {
  return task_sequence.map((taskType) => ({
    type: taskType,
    isNewStep: true,
    is_empty: isEmptyLeg,
  }));
}

export function getDefaultLeg(task_sequence = defaultTaskSequence, isEmptyLeg = false) {
  return {
    steps: getDefaultStepsInLeg(task_sequence, isEmptyLeg),
  };
}

const defaultNumberLeg = 1,
  defaultTaskSequence = ['pickup', 'dropoff'];

export const getDefaultLegsFromTemplateSettings = (templateSettings = {}) => {
  const {
    number_of_legs = defaultNumberLeg,
    task_sequence = defaultTaskSequence,
    empty_legs_index = [],
  } = templateSettings;

  const defaultLegsData = new Array(number_of_legs).fill(0).map((_, legIndex) => {
    const isEmptyLeg = empty_legs_index.includes(legIndex);
    return getDefaultLeg(task_sequence, isEmptyLeg);
  });

  return defaultLegsData;
};

export const initBookingInfoSections = (bookingInfoSectionsData) => {
  const numberOfSection = bookingInfoSectionsData?.length ?? 1;

  return [...Array(numberOfSection).keys()].map((_) => ({ uniqueID: uuidv4() }));
};

/**
 *
 * @param bookingInfoSectionIndex
 * @param legIndex
 * @param stepIndex
 * @param itemIndex
 * @param type this for item or container
 * @returns {string}
 */
export function getFormKeyPath({
  bookingInfoSectionIndex = 0,
  legIndex = undefined,
  stepIndex = undefined,
  itemIndex = undefined,
  type = '',
} = {}) {
  let formKeyPath = '';
  if (bookingInfoSectionIndex?.toString()) {
    formKeyPath = `bookingInfoSections.${bookingInfoSectionIndex}`;
  }
  if (legIndex?.toString()) {
    formKeyPath = `${formKeyPath}.legs.${legIndex}`;
  }
  if (stepIndex?.toString()) {
    formKeyPath = `${formKeyPath}.${stepIndex}`;
  }
  if (itemIndex?.toString()) {
    formKeyPath = `${formKeyPath}.itemDetails.${itemIndex}`;
  }
  switch (type) {
    case 'items':
      formKeyPath = `${formKeyPath}.itemDetails`;
      break;
    case 'item':
      formKeyPath = `${formKeyPath}.item`;
      break;
    case 'container':
      formKeyPath = `${formKeyPath}.item_container`;
      break;
    case 'legs':
      formKeyPath = `${formKeyPath}.legs`;
      break;
    case 'order':
      formKeyPath = 'order';
      break;
    case 'sender':
      formKeyPath = 'sender';
      break;
    case 'vessel':
      formKeyPath = 'order_voyage_info';
      break;
  }

  return formKeyPath;
}

export function getFormKeyPathOfItemDetailsFromItem(itemFromKeyPath) {
  const paths = itemFromKeyPath.split('.');
  paths.pop();
  return paths.join('.');
}

export function getFormKeyPathOfContainerFromItemKeyPath(itemFormKeyPath) {
  return getFormKeyPathOfItemDetailsFromItem(itemFormKeyPath) + '.item_container';
}

/**
 * Example stepFormKeyPath: bookingInfoSections.0.legs.0.0
 * => itemDetails form key path: bookingInfoSections.0.itemDetails
 * @param stepFormKetPath
 * @returns {string}
 */
export function getFormKeyPathItemDetailsFromStepFormKeyPath(stepFormKetPath) {
  const paths = stepFormKetPath.split('.legs.');
  return paths[0] + '.itemDetails';
}

function isValueNotInListOption(value, allowedValues) {
  return value && !allowedValues?.includes(value);
}

export function useAddValueAsOptionIfValueMissingInOptionsForSelect({ value, label, allowedValues, transform }) {
  const newAllowValues = useMemo(() => {
    if (isValueNotInListOption(value, allowedValues)) {
      return [...allowedValues, value];
    }

    return allowedValues;
  }, [allowedValues, value]);

  const newTransform = useCallback(
    (key) => {
      const displayLabel = transform?.(key);
      if (!displayLabel) {
        if (isValueNotInListOption(value, allowedValues)) {
          return label;
        }
      }

      return displayLabel;
    },
    [allowedValues, label, transform, value]
  );

  return { newAllowValues, newTransform };
}

export function useCustomOnChange({ onChange, field: { type } }) {
  const customOnChange = useCallback(
    (value, ...params) => {
      if (isNumberType(type)) {
        let newValue = value;

        const isStringValue = typeof newValue === 'string' && newValue;

        if (type === 'positive_number') {
          newValue = isStringValue ? Math.abs(Number(newValue)) : newValue || '';

          // Not allow decimal value
          if (String(newValue).includes('.')) {
            newValue = parseInt(newValue);
          }
        } else {
          newValue = isStringValue ? Number(newValue) : newValue || '';
        }

        onChange(newValue, ...params);
      } else {
        onChange(value, ...params);
      }
    },
    [onChange, type]
  );

  return { customOnChange };
}

export function useCustomOnKeyDown({ onKeyDown, field: { type } }) {
  const customOnKeyDown = useCallback(
    (e, ...params) => {
      if (isNumberType(type) || type === 'money') {
        if (['-', '+', 'e'].includes(e.key)) {
          e.preventDefault();
        } else {
          onKeyDown?.(e, ...params);
        }
      }
      onKeyDown?.(e, ...params);
    },
    [onKeyDown, type]
  );
  return { customOnKeyDown };
}

export function useGenerateOptionsForAutoComplete({ value, name, options }) {
  const newOptions = useMemo(() => {
    if (value && name) {
      const isValueInList = options?.some((item) => item.id === value);
      if (!isValueInList) {
        return [...(options || []), { id: value, name }];
      }
    }

    return options || [];
  }, [value, name, options]);

  return { options: newOptions };
}

/**
 * Use this function to get formRef instead of use useSelector, because formRefs is
 * a mutable variable, so it doesn't reactive when formRefs value change
 * @param formRefs
 * @param formKeyPath
 * @returns {Exclude<object[keyof object], undefined>}
 */
export function getFormRef(formRefs, formKeyPath) {
  return lodashGet(formRefs, formKeyPath, null);
}

export function isNumberType(type) {
  return ['positive_decimal', 'integer', 'positive_number', 'unit_number'].includes(type);
}
