import { AnyAction, Reducer } from '@reduxjs/toolkit';
import { RouterState } from 'redux-first-history';

import { GetReducerState } from '@yojee/types';
import addressPickerReducer from '@yojee/ui/address-picker/reducers/reducer';
import bulk from '@yojee/ui/BulkUpdate/redux/bulkReducer';
import { featuresReducer } from '@yojee/ui/feature-management/reducers/reducer';
import { OrderBookingReducer } from '@yojee/ui/new-order-booking/reducers/reducer';
import { UIAuthReducer } from '@yojee/ui/onboarding/reducers/auth';
import { UIUserEnterpriseManagementReducer } from '@yojee/ui/onboarding/reducers/userEnterpriseManagement';
import { UIUserManagementReducer } from '@yojee/ui/onboarding/reducers/userManagement';
import { generateReducerWDG } from '@yojee/ui/wired-data-grid';

import address from './addressReducer';
import billingParty from './billingPartyReducer';
import branding from './branding';
import chargeCode from './chargeCodeReducer';
import company from './company';
import companySettings from './companySettings';
import connote from './connoteReducer';
import dispatchRule from './dispatchRuleReducer';
import driverProcess from './driverProcess';
import driverRoute from './driverRouteReducer';
import finance from './finance';
import integrations from './integrations';
import itemType from './itemTypeReducer';
import login from './login';
import manifest from './manifestReducer';
import manifestTemplates from './manifestTemplatesReducer';
import messages from './messages';
import network from './network';
import notifications from './notifications';
import operations from './operations';
import partner from './partnerReducer';
import pod from './pod';
import ratings from './ratingsReducer';
import reasonCode from './reasonCodeReducer';
import savedFilter from './savedFilterReducer';
import schedules from './scheduleReducer';
import senderOrganisation from './senderOrganisationReducer';
import sender from './senderReducer';
import serviceTime from './serviceTimeReducer';
import tag from './tagReducer';
import exceptions from './taskExceptionReducer';
import { default as manageUsers } from './users';
import vehicles from './vehicles';
import waybill from './waybill';
import worker from './workerReducer';
import workerTeams from './workerTeams';

const reducers = (routerReducer: Reducer<RouterState, AnyAction>) => ({
  router: routerReducer,
  login,
  company,
  companySettings,
  manageUsers,
  workerTeams,
  driverProcess,
  notifications,
  branding,
  dispatchRule,
  savedFilter,
  worker,
  connote,
  manifest,
  manifestTemplates,
  partner,
  address,
  senderOrganisation,
  sender,
  driverRoute,
  operations,
  network,
  pod,
  vehicles,
  schedules,
  waybill,
  bulk,
  exceptions,
  itemType,
  tag,
  serviceTime,
  finance,
  users: UIUserManagementReducer,
  enterprise: UIUserEnterpriseManagementReducer,
  auth: UIAuthReducer,
  ratings,
  chargeCode,
  rateChargeTypes: generateReducerWDG('rateChargeTypes'),
  billingParty,
  addressPicker: addressPickerReducer,
  messages,
  features: featuresReducer,
  integrations,
  reasonCode,
  ...OrderBookingReducer,
});

type Reducers = ReturnType<typeof reducers>;

export type ManageState = {
  [k in keyof Reducers]: GetReducerState<Reducers[k]>;
};

export default reducers;
