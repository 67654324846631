import { authService } from '@yojee/api/authService';
import { BaseService } from '@yojee/api/baseService';

export class NotificationService extends BaseService {
  constructor({ authService }) {
    super();
    this.authService = authService;
  }

  getNotification = (filter = {}) => {
    const { notificationTypes, eventTypes, userTypes, active } = filter;

    const notiParams = (notificationTypes || []).map((event) => `notification_types[]=${event}`);
    const eventParams = (eventTypes || []).map((event) => `event_types[]=${event}`);
    const userParams = (userTypes || []).map((event) => `user_types[]=${event}`);
    const activeParam = active ? `active=${active}` : null;

    const params = [...notiParams, ...eventParams, ...userParams, activeParam, 'page_size=100']
      .filter(Boolean)
      .join('&');

    return this.authService
      .get(this.getUmbrellaApiUrl(`dispatcher/notification_settings?${params}`))
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  updateNotification = (notificationData) => {
    return this.authService
      .put(this.getUmbrellaApiUrl('dispatcher/notification_settings'), notificationData)
      .then((response) => {
        const result = this.extractData(response);
        return result ? result : null;
      })
      .catch((error) => this.handleError(error));
  };

  getNotificationAllSettingsTemplates = (type) => {
    return this.authService
      .get(this.getUmbrellaApiUrl(`dispatcher/notification_setting_templates?notification_type=${type}`))
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  getNotificationSettingsTemplate = (notification_type, event_type) => {
    return this.authService
      .get(
        this.getUmbrellaApiUrl(
          `dispatcher/notification_setting_templates?notification_type=${notification_type}&event_type=${event_type}`
        )
      )
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  updateNotificationSettingTemplates = ({ id, template, metadata }) => {
    return this.authService
      .put(this.getUmbrellaApiUrl(`dispatcher/notification_setting_templates/${id}`), {
        templates: {
          en: template,
        },
        metadata,
      })
      .then((response) => {
        const result = this.extractData(response);
        return result ? result : null;
      })
      .catch((error) => this.handleError(error));
  };
}

export const notificationService = new NotificationService({ authService });
