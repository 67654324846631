import { authService } from '@yojee/api/authService';
import { BaseService } from '@yojee/api/baseService';

export class UserService extends BaseService {
  constructor({ authService }) {
    super();
    this.authService = authService;
  }

  requestGetUsers = ({ page, page_size, q = null }) => {
    return this.authService
      .get(this.getUmbrellaApiUrl('dispatcher/users'), {
        page,
        page_size,
        ...(q ? { q } : {}),
      })
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };

  updateUser = ({ userId, data }) => {
    return this.authService
      .put(this.getUmbrellaApiUrl(`dispatcher/users/${userId}`), data)
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };

  createUser = (user) => {
    return this.authService
      .post(this.getUmbrellaApiUrl(`dispatcher/users`), user)
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };

  batchUpload = ({ file, format }) => {
    const uploadJson = new FormData();
    uploadJson.set('file', file);
    uploadJson.set('format', format);

    return this.authService
      .post(this.getUmbrellaApiUrl(`dispatcher/users/batches`), uploadJson, { 'Content-Type': 'multipart/form-data' })
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };

  checkBatchStatus = ({ batchId }) => {
    return this.authService
      .get(this.getUmbrellaApiUrl(`dispatcher/users/batches/${batchId}/status`))
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };

  downloadUserTemplateSample = ({ format }) => {
    return this.getUmbrellaApiUrl(`public/dispatchers/download_sample?format=${format}`);
  };
}

export const userService = new UserService({ authService });
