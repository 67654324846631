import { call, put, takeLatest } from 'redux-saga/effects';

import { isTwoScheduleOverlapping, transformSchedule } from '@yojee/helpers/workerShedule/scheduleHelper';
import { batchUpload } from '@yojee/ui/onboarding/helpers/batchUploadHelper';

import { scheduleService } from '../../services/scheduleService';

export default function* sagas() {
  yield takeLatest('REQUEST_ADD_SCHEDULE', addSchedule);
  yield takeLatest('REQUEST_SCHEDULES_LIST', getSchedulesList);
  yield takeLatest('REQUEST_GET_SCHEDULE', getSchedule);
  yield takeLatest('REQUEST_DELETE_SCHEDULE', deleteSchedule);
  yield takeLatest('REQUEST_UPDATE_SCHEDULE', updateSchedule);
  yield takeLatest('REQUEST_SCHEDULE_BATCH_UPLOAD', scheduleBatchUpload);
  yield takeLatest('DOWNLOAD_SCHEDULE_TEMPLATE_SAMPLE', downloadScheduleTemplateSample);
}

function* getSchedulesList({ payload }) {
  try {
    yield put({ type: 'SET_SCHEDULES_LOADING', loading: true });
    const result = yield call(scheduleService.getSchedulesList, payload);
    yield put({
      type: 'REQUEST_SCHEDULES_LIST_SUCCESS',
      schedule: result,
      options: payload,
    });
  } catch (error) {
    yield put({ type: 'REQUEST_SCHEDULES_LIST_ERROR', error });
  }
}

function* addSchedule(action) {
  try {
    yield put({ type: 'SET_CREATE_UPDATE_LOADING' });
    const result = yield call(scheduleService.addSchedule, action.payload.schedule);
    yield put({ type: 'REQUEST_ADD_SCHEDULE_SUCCESS', schedule: result });

    yield put({ type: 'SET_SCHEDULES_LOADING', loading: true });
    const resultList = yield call(scheduleService.getSchedulesList, action.payload.loadOptions);

    yield put({
      type: 'REQUEST_SCHEDULES_LIST_SUCCESS',
      schedule: resultList,
      options: action.payload.loadOptions,
    });
  } catch (error) {
    yield put({ type: 'REQUEST_ADD_SCHEDULE_ERROR', error });
  }
}

function* updateSchedule(action) {
  try {
    yield put({ type: 'SET_CREATE_UPDATE_LOADING', loading: true });
    const result = yield call(scheduleService.updateSchedule, action.payload.schedule);
    yield put({ type: 'REQUEST_UPDATE_SCHEDULE_SUCCESS', schedule: result });
    if (action.payload.loadOptions?.worker_id) {
      yield call(updateScheduleHadConflictedWith, action.payload);
      yield call(updateScheduleWillConflictWith, action.payload);
    }
    yield put({ type: 'SET_SCHEDULES_LOADING', loading: true });
    const resultList = yield call(scheduleService.getSchedulesList, action.payload.loadOptions);
    yield put({
      type: 'REQUEST_SCHEDULES_LIST_SUCCESS',
      schedule: resultList,
      options: action.payload.loadOptions,
    });
  } catch (error) {
    yield put({ type: 'REQUEST_UPDATE_SCHEDULE_ERROR', error });
  }
}

function* updateScheduleHadConflictedWith(payload) {
  const scheduleUpdated = { ...payload.schedule };
  const schedules = [...payload.schedules];

  const schedulesHadConflictedWith = schedules.filter(
    (schedule) => schedule.conflict_schedule_id === scheduleUpdated.id
  );
  for (const schedule of schedulesHadConflictedWith) {
    const isOverLapping = isTwoScheduleOverlapping(transformSchedule(schedule), transformSchedule(scheduleUpdated));
    if (!isOverLapping && schedule.id !== scheduleUpdated.id) {
      schedule.conflict_schedule_id = null;
      yield call(scheduleService.updateSchedule, schedule);
    }
  }
}

function* updateScheduleWillConflictWith(payload) {
  const scheduleUpdated = { ...payload.schedule };
  const schedules = [...payload.schedules];

  const schedulesWillConflictWith = schedules.filter(
    (schedule) => schedule.conflict_schedule_id === null && schedule.id > scheduleUpdated.id
  );
  for (const schedule of schedulesWillConflictWith) {
    const isOverLapping = isTwoScheduleOverlapping(transformSchedule(schedule), transformSchedule(scheduleUpdated));
    if (isOverLapping && schedule.id !== scheduleUpdated.id) {
      schedule.conflict_schedule_id = scheduleUpdated.id;
      yield call(scheduleService.updateSchedule, schedule);
    }
  }
}

function* deleteSchedule(action) {
  try {
    yield put({ type: 'SET_SCHEDULES_LOADING', loading: true });
    const result = yield call(scheduleService.deleteSchedule, action.payload.scheduleId);
    yield put({ type: 'REQUEST_DELETE_SCHEDULE_SUCCESS', schedule: result });

    yield put({ type: 'SET_SCHEDULES_LOADING', loading: true });
    const resultList = yield call(scheduleService.getSchedulesList, action.payload.loadOptions);

    yield put({
      type: 'REQUEST_SCHEDULES_LIST_SUCCESS',
      schedule: resultList,
      options: action.payload.loadOptions,
    });
  } catch (error) {
    yield put({ type: 'REQUEST_DELETE_SCHEDULE_ERROR', error });
  }
}

function* getSchedule(action) {
  try {
    yield put({ type: 'SET_SCHEDULES_LOADING', loading: true });
    const result = yield call(scheduleService.getSchedule, action.payload);
    yield put({ type: 'REQUEST_GET_SCHEDULE_SUCCESS', schedule: result });
  } catch (error) {
    yield put({ type: 'REQUEST_GET_SCHEDULE_ERROR', error });
  }
}

function* scheduleBatchUpload({ payload: { file, format, type } }) {
  yield batchUpload(
    { file, format, type },
    {
      uploadApiMethod: scheduleService.batchUpload,
      statusCheckApiMethod: scheduleService.checkBatchStatus,
      events: {
        started: 'SCHEDULE_BATCH_UPLOAD_STARTED',
        processing: 'SCHEDULE_BATCH_UPLOAD_PROCESSING',
        succeeded: 'SCHEDULE_BATCH_UPLOAD_SUCCEEDED',
        failed: 'SCHEDULE_BATCH_UPLOAD_FAILED',
      },
    }
  );
}

function downloadScheduleTemplateSample({ payload: { format, type } }) {
  window.open(scheduleService.downloadTemplateSample({ format, type }), '_self');
}
