import { all, call, put, takeLatest } from 'redux-saga/effects';

import { POST_MESSAGE_TYPE } from '@yojee/helpers/constants';
import { postMessage } from '@yojee/helpers/postMessage';

import { brandingService } from '../../services/brandingService';
export const companyStoreData = (state) => state.company;

export default function* sagas() {
  yield takeLatest('UPDATE_BRANDING_SETTING', updateBrandingSetting);
  yield takeLatest('REQUEST_UPLOAD_IMAGE', requestUploadImage);
}

function* requestUploadImage(action) {
  try {
    const { file_type, file, payload, ...other } = action.payload;
    let uploadImageResult;
    if (file_type === 'high_resolution_logo') {
      uploadImageResult = yield all([
        call(brandingService.requestUploadImage, { file_type: 'invoice_logo', file }),
        call(brandingService.requestUploadImage, { file_type: 'manifest_logo', file }),
        call(brandingService.requestUploadImage, { file_type: 'consignment_note_logo', file }),
      ]);
    } else {
      uploadImageResult = yield call(brandingService.requestUploadImage, { file_type, file });
    }
    const imageUrl = file_type === 'high_resolution_logo' ? uploadImageResult?.[0] : uploadImageResult;
    if (file_type === 'logo') {
      postMessage(window.parent, {
        type: POST_MESSAGE_TYPE.BRANDING_SETTING,
        payload: { ...payload, logo: imageUrl },
      });
    }
    yield put({ type: 'REQUEST_UPLOAD_IMAGE_SUCCESS', data: { result: { file_type, fileURL: imageUrl, ...other } } });
  } catch (error) {
    const { file_type } = action.payload;
    error.message = file_type === 'high_resolution_logo' ? 'High Resolution Logo is invalid' : error.message;
    yield put({ type: 'REQUEST_UPLOAD_IMAGE_ERROR', error: { file_type, error } });
  }
}

function* updateBrandingSetting(action) {
  try {
    const resultUpdate = yield call(brandingService.updateBrandingSetting, action.payload);
    postMessage(window.parent, {
      type: POST_MESSAGE_TYPE.SAVED_BRANDING_SETTING,
    });
    yield put({ type: 'UPDATE_BRANDING_SETTING_SUCCESS', data: resultUpdate });
    yield put({ type: 'REQUEST_GET_COMPANY_INFO' });
  } catch (error) {
    yield put({ type: 'UPDATE_BRANDING_SETTING_ERROR', error });
  }
}
