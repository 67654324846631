import { addressService } from '@yojee/api/addressService';
import { companyService } from '@yojee/api/companyService';
import { itemTypesApi } from '@yojee/api/itemTypesApi';
import { orderService } from '@yojee/api/orderService';
import { ratingsService } from '@yojee/api/ratingsService';
import { senderApi } from '@yojee/api/senderApi';
import { senderOrderService } from '@yojee/api/senderOrderService';
import { serviceTypesApi } from '@yojee/api/serviceTypesApi';
import { tasksSearchService } from '@yojee/api/tasksSearchService';
import { containerTypeApi } from '@yojee/api/v4/containerTypeApi';
import { invoiceApi } from '@yojee/api/v4/invoiceApi';
import { labelApi } from '@yojee/api/v4/labelApi';
import { orderApiV4 } from '@yojee/api/v4/orderApi';
import { templateApiV4 } from '@yojee/api/v4/templateApi';

const serviceLocator = {
  companyService,
  itemTypesApi,
  senderApi,
  serviceTypesApi,
  orderApiV4,
  templateApiV4,
  ratingsService,
  addressService,
  senderOrderService,
  tasksSearchService,
  containerTypeApi,
  labelApi,
  invoiceApi,
  orderService,
};

export function updateServiceLocator(serviceName, value) {
  serviceLocator[serviceName] = value;
}

export default serviceLocator;
