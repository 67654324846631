import BaseActionTypes from '@yojee/helpers/BaseActionTypes';

class ActionTypes extends BaseActionTypes {
  addSuccessMessage = () => this.custom('ADD_SUCCESS_MESSAGE');
  addErrorMessage = () => this.custom('ADD_FAILED_MESSAGE');
  registerForm = () => this.custom('REGISTER_FORM');
  syncFormModel = () => this.custom('SYNC_FORM_DATA');
  updateFormMetaData = () => this.custom('UPDATE_FORM_META_DATA');
  deleteFormModels = () => this.custom('DELETE_FORM_STATUS');
  deleteContainerData = () => this.custom('DELETE_CONTAINER_DATA');
  searchAddress = () => this.custom('SEARCH_ADDRESS');
  clearAddressOptions = () => this.custom('CLEAR_ADDRESS_OPTIONS');
  searchAddressBook = () => this.customWithSubType('SEARCH_ADDRESS_BOOK');
  searchGoogleAddress = () => this.customWithSubType('SEARCH_GOOGLE_ADDRESS');
  reCalculateCharge = () => this.custom('RE_CALCULATE_CHARGE');
  createOrderFieldTemplate = () => this.customWithSubType('CREATE_ORDER_FIELD_TEMPLATE');
  updateOrderFieldTemplate = () => this.customWithSubType('UPDATE_ORDER_FIELD_TEMPLATE');
  getOrderFieldTemplate = () => this.customWithSubType('GET_ORDER_FIELD_TEMPLATE');
  deleteOrderFieldTemplate = () => this.customWithSubType('DELETE_ORDER_FIELD_TEMPLATE');
  recalculateStepsTimeBaseOnTemplate = () => this.custom('RECALCULATE_STEPS_TIME_BASE_ON_TEMPLATE');
  setOrderFieldTemplateSender = () => this.custom('SET_ORDER_FIELD_TEMPLATE_SENDER');
  selectServiceType = () => this.custom('SELECT_SERVICE_TYPE');
  selectOrganisationalUnit = () => this.custom('SELECT_ORGANISATIONAL_UNIT');
  updateStateInCaseCreateOrderFailed = () => this.custom('UPDATE_STATE_IN_CASE_CREATE_ORDER_FAILED');
  updateStateInCaseUpdateOrderFailed = () => this.custom('UPDATE_STATE_IN_CASE_UPDATE_ORDER_FAILED');
  updateStateInCaseBulkReapplyOperationsForOrderFailed = () =>
    this.custom('UPDATE_STATE_IN_CASE_BULK_REAPPLY_OPERATIONS_FOR_ORDER_FAILED');
  duplicateOrder = () => this.custom('DUPLICATE_ORDER');
}

export default new ActionTypes({ prefix: 'ORDER_BOOKING' });
