import MuiButton from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';

import CircularLoading from '../CircularLoading';

const getPresets = (theme) => (props) => {
  const presets = {
    contained: {
      color: theme.palette[props.color]?.contrastText,
      backgroundColor: theme.palette[props.color]?.main,
      '&:hover': {
        backgroundColor: theme.palette[props.color]?.dark,
      },
    },
    outlined: {
      color: theme.palette[props.color]?.main,
      borderColor: theme.palette[props.color]?.light,
      '&:hover': {
        backgroundColor: theme.palette[props.color]?.tint,
        borderColor: theme.palette[props.color]?.dark,
      },
    },
    default: {
      color: theme.palette[props.color]?.main,
      '&:hover': {
        backgroundColor: theme.palette[props.color]?.tint,
      },
    },
  };

  return presets[props.variant] || presets.default;
};

const styledBy = (property, mapping) => (props) => mapping[props[property]] || mapping.default;

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    fontWeight: 'bold',
    boxShadow: 'none',
    textTransform: 'none',
    height: styledBy('size', {
      default: '44px',
      small: '32px',
      medium: '44px',
      large: '64px',
    }),
    lineHeight: styledBy('size', {
      default: '16px',
      small: '16px',
      medium: '16px',
      large: '32px',
    }),
    // TODO: use the theme font size
    fontSize: styledBy('size', {
      default: '1rem',
      small: '0.875rem',
      medium: '1rem',
      large: '1.25rem',
    }),
    borderRadius: styledBy('size', {
      default: '8px',
      small: '6px',
      medium: '8px',
      large: '12px',
    }),
    borderWidth: styledBy('variant', {
      default: 'none',
      outlined: '1px',
    }),
    // TODO: change this in theme shape
    padding: styledBy('size', {
      default: '10px 16.5px',
      small: '8px 12.5px',
      medium: '10px 16.5px',
      large: '17px 33px 15px',
    }),
    '&:hover': {
      borderWidth: styledBy('variant', {
        default: 'none',
        outlined: '1px',
      }),
      boxShadow: 'none',
    },
  },
  borderedPadding: {
    padding: styledBy('size', {
      default: '8px 14.5px',
      small: '6px 10.5px',
      medium: '8px 14.5px',
      large: '15px 31px 13px',
    }),
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  variants: getPresets(theme),
}));

const MUI_SUPPORTED_COLOR_VARIANTS = ['default', 'inherit', 'primary', 'secondary'];

// To remove the invalid prop `color` warning when `color` is set to `"error"`.
// This color variant is supported by Yojee (by means of the `getPresets`)
// above, but isn't supported by MUI.
// Note that: this doesn't in any way affect the styling of buttons!
const removeUnsupportedColor = (props) => {
  const { color, ...rest } = props;

  if (!color || MUI_SUPPORTED_COLOR_VARIANTS.includes(color)) {
    return props;
  } else {
    return rest;
  }
};

/**
 *
 *
 * @param {import('react').ComponentProps<typeof import('@material-ui/core').Button>} { children, isLoading = false, className = undefined, disabled, ...restProps }
 * @return {*}
 */
const Button = ({ children, isLoading = false, className = undefined, disabled, ...restProps }) => {
  const classes = useStyles(restProps);

  return (
    <MuiButton
      {...removeUnsupportedColor(restProps)}
      disabled={disabled || isLoading}
      className={clsx(classes.root, classes.variants, className, {
        [classes.borderedPadding]: restProps?.variant === 'outlined',
      })}
    >
      {children}
      {isLoading && <CircularLoading className={classes.buttonProgress} />}
    </MuiButton>
  );
};

export default Button;
