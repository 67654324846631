import { call, put, takeLatest } from 'redux-saga/effects';

import { podService } from '../../services/podService';

export default function* saga() {
  yield takeLatest('GET_POD_VERSIONS_REQUEST', requestGetPodVersions);
  yield takeLatest('SELECT_POD_VERSION', requestGetPodByVersion);
  yield takeLatest('CREATE_NEW_POD_REQUEST', createNewPodRequest);
  yield takeLatest('REMOVE_POD_REQUEST', removePodRequest);
  yield takeLatest('UPDATE_POD_REQUEST', updatePodRequest);
}

function* requestGetPodVersions() {
  try {
    const data = yield call(podService.getAllVersions);
    yield put({ type: 'GET_POD_VERSION_SUCCESS', data });
    const activeVersion = data.find((i) => i.is_active || i.is_active === null);
    const content = yield call(podService.getPodContentByVersion, activeVersion.id);
    yield put({ type: 'GET_POD_CONTENT_SUCCESS', data: content, selectedVersion: activeVersion.id });
  } catch (error) {
    yield put({ type: 'GET_POD_VERSION_ERROR', error });
  }
}

function* requestGetPodByVersion({ payload }) {
  try {
    const content = yield call(podService.getPodContentByVersion, payload);
    yield put({ type: 'GET_POD_CONTENT_SUCCESS', data: content, selectedVersion: payload });
  } catch (error) {
    yield put({ type: 'GET_POD_VERSION_ERROR', error });
  }
}

function* createNewPodRequest({ payload }) {
  try {
    yield call(podService.createNewPod, payload);
    yield call(requestGetPodVersions);
  } catch (error) {
    yield put({ type: 'GET_POD_VERSION_ERROR', error });
  }
}

function* removePodRequest({ payload }) {
  try {
    yield call(podService.removePod, payload);
    yield call(requestGetPodVersions);
  } catch (error) {
    yield put({ type: 'GET_POD_VERSION_ERROR', error });
  }
}

function* updatePodRequest({ payload }) {
  try {
    yield call(podService.updatePod, payload.id, payload);
    yield call(requestGetPodVersions);
  } catch (error) {
    yield put({ type: 'GET_POD_VERSION_ERROR', error });
  }
}
