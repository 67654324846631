import { useState } from 'react';

/**
 * When add data to rows, remember to add the ID key into the each data object
 */

export const DEBOUNCE_SEARCH_TIME = 500; //500ms

const useTable = ({ rows, data = [], disabledCheckBox = [] }) => {
  const [selected, setSelected] = useState([]);

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const handleClick = (id) => {
    if (disabledCheckBox.includes(id)) return;

    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows
        .map((n, index) => n.id ?? index)
        .filter((_, index) => !disabledCheckBox.includes(index));
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const getSingleDataById = (id, field = 'id') => data.find((item) => item[field] === id);

  return {
    DEBOUNCE_SEARCH_TIME,
    selected,
    setSelected,
    isSelected,
    handleClick,
    handleSelectAllClick,
    getSingleDataById,
  };
};

export default useTable;
