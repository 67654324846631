const defaultState = {
  labelsList: [],
  versions: [],
  content: {},
  labelDetails: {},
  isChanged: false,
  selectedVersion: 0,
  inProgress: {
    getVersionsListRequest: false,
    createNewWayBillRequest: false,
    removeWayBillRequest: false,
    updateWayBillRequest: false,
    getLabelsListRequest: false,
    getLabelDetailsRequest: false,
    createLabelRequest: false,
    updateLabelRequest: false,
    setDefaultLabelRequest: false,
  },
  pagination: {
    current_page: 1,
    limit_value: 25,
    total_count: 0,
    total_pages: 0,
  },
};

export const waybillMessagesSuccessEvents = {
  CREATE_LABEL_SUCCESS: 'Label created successfully',
  UPDATE_LABEL_SUCCESS: 'Label updated successfully',
  SET_DEFAULT_LABEL_SUCCESS: 'Set default label successfully',
};

export const waybillMessagesErrorEvents = {
  CREATE_LABEL_ERROR: 'Create label failed',
  UPDATE_LABEL_ERROR: 'Update label failed',
  SET_DEFAULT_LABEL_ERROR: 'Set default label failed',
};

export const init = () => {
  return defaultState;
};

const ACTION_HANDLERS = {
  GET_WAYBILL_VERSION_SUCCESS: (state, payload) => {
    return {
      ...state,
      versions: payload.data,
    };
  },
  GET_WAYBILL_CONTENT_SUCCESS: (state, payload) => {
    return {
      ...state,
      content: payload.data,
      selectedVersion: payload.selectedVersion,
    };
  },
  SET_WAYBILL_PROGRESS: (state, { key, value = true }) => {
    return {
      ...state,
      inProgress: {
        ...state.loading,
        [key]: value,
      },
    };
  },
  GET_LABELS_LIST_SUCCESS: (state, { data, pagination }) => {
    return {
      ...state,
      labelsList: data,
      pagination: pagination || {},
    };
  },
  GET_LABEL_DETAILS_SUCCESS: (state, payload) => {
    return {
      ...state,
      labelDetails: payload.data,
    };
  },
  RESET_DETAILS_DATA: (state) => {
    return {
      ...state,
      versions: [],
      content: {},
      labelDetails: {},
    };
  },
};

const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};

export default reducer;
