import { styled } from '@mui/material';
import { ComponentProps } from 'react';

import { theme } from '@yojee/ui/base/theme';

const StyledSpan = styled('span')<{ error?: boolean }>(({ error }) => ({
  display: 'block',
  lineHeight: '12px',
  wordWrap: 'break-word',
  color: error ? theme.palette.error.main : theme.palette?.black?.['B60'],
  fontSize: '0.75rem',
  fontWeight: 600,
}));

const InputLabel = ({
  label = '\u00A0',
  required,
  error,
  ...restProps
}: {
  required?: boolean;
  label: string;
} & ComponentProps<typeof StyledSpan>) => {
  return (
    <StyledSpan error={error} {...restProps}>
      {label}
      <span>{required ? ' *' : ''}</span>
    </StyledSpan>
  );
};

export default InputLabel;
