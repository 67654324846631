import React from 'react';

/**
 * This function is used to retry to load a component
 * @param lazyComponent
 * @param attemptsLeft
 * @returns {Promise<unknown>}
 */
function componentLoader(lazyComponent, attemptsLeft = 3) {
  return new Promise((resolve, reject) => {
    lazyComponent()
      .then(resolve)
      .catch((e) => {
        console.error(e);
        // let us retry after 1500 ms
        setTimeout(() => {
          if (attemptsLeft === 1) {
            // If we have no more attempts left, just reload webpage
            // Usually this is a sign of a network error
            return window.location.reload();
          }
          console.log(`Retrying to load component`);
          componentLoader(lazyComponent, attemptsLeft - 1).then(resolve, reject);
        }, 1500);
      });
  });
}

export default function lazyLoadComponent(lazyComponent) {
  return React.lazy(() => componentLoader(lazyComponent));
}
