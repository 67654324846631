import { authService } from '@yojee/api/authService';
import { BaseService } from '@yojee/api/baseService';

export class FinanceService extends BaseService {
  constructor({ authService }) {
    super();
    this.authService = authService;
  }

  getVAS = ({ page, page_size, q }) => {
    return this.authService
      .get(this.getUmbrellaApiUrlV4('company/accessorial_services'), {
        page,
        page_size,
        ...(q ? { key_search: q } : {}),
      })
      .then((response) => this.extractData(response))
      .catch((error) => this.handleNewErrorFormat(error));
  };

  createVAS = (data) => {
    return this.authService
      .post(this.getUmbrellaApiUrlV4('company/accessorial_services'), this.transformToSaveVASBody(data))
      .then((response) => this.extractData(response))
      .catch((error) => this.handleNewErrorFormat(error));
  };

  updateVAS = (data) => {
    return this.authService
      .put(this.getUmbrellaApiUrlV4(`company/accessorial_services/${data.id}`), this.transformToSaveVASBody(data))
      .then((response) => this.extractData(response))
      .catch((error) => this.handleNewErrorFormat(error));
  };

  deactivateVAS = (data) => {
    return this.authService
      .put(this.getUmbrellaApiUrlV4(`company/accessorial_services/${data.id}/deactivate`))
      .then((response) => this.extractData(response))
      .catch((error) => this.handleNewErrorFormat(error));
  };

  activateVAS = (data) => {
    return this.authService
      .put(this.getUmbrellaApiUrlV4(`company/accessorial_services/${data.id}/activate`))
      .then((response) => this.extractData(response))
      .catch((error) => this.handleNewErrorFormat(error));
  };

  transformToSaveVASBody = (data) => {
    return {
      data: {
        name: data.name,
        code: data.code,
        level: data.level.value,
        unit: data.unit,
        description: data.description,
      },
    };
  };
}

export const financeService = new FinanceService({ authService });
