import uniqBy from 'lodash-es/uniqBy';
import { call, put, takeLatest } from 'redux-saga/effects';

import { getGooglePlacePredictions, getGooglePlacesFromText } from '@yojee/helpers/google';

import serviceLocator from '../serviceLocator';
import actionTypes from './actionTypes';
import { transformAddressBookDataToOptionData } from './helpers';

export function* OrderBookingSagas() {
  yield takeLatest(actionTypes.searchAddress(), searchAddress);
  yield takeLatest(actionTypes.createOrderFieldTemplate().start(), createOrderFieldTemplate);
  yield takeLatest(actionTypes.updateOrderFieldTemplate().start(), updateOrderFieldTemplate);
  yield takeLatest(actionTypes.getOrderFieldTemplate().start(), getOrderFieldTemplate);
  yield takeLatest(actionTypes.deleteOrderFieldTemplate().start(), deleteOrderFieldTemplate);
}

function* searchAddress({
  searchKey,
  includeAddressBook = true,
  operationCountry = null,
  allowToUsePlaceSearchApi = true,
  senderLinkType = null,
  senderId = null,
}) {
  const companyService = serviceLocator.companyService;

  try {
    if (!searchKey) {
      yield put({ type: actionTypes.clearAddressOptions() });
      return;
    }

    if (includeAddressBook) {
      yield put({ type: actionTypes.startProgress(), key: 'searchAddressBook' });

      const addressBookData = yield call(companyService.getCompanyAddressBookEntries, {
        query: searchKey,
        senderLinkType,
        senderId,
      });
      const transformedAddressBookData = addressBookData?.map(transformAddressBookDataToOptionData) ?? [];

      yield put({ type: actionTypes.searchAddressBook().success(), items: transformedAddressBookData });
    }

    const googlePlacesPredictions = yield call(getGooglePlacePredictions, { searchKey, operationCountry });
    let googlePlacesSearched = [];
    if (allowToUsePlaceSearchApi) {
      googlePlacesSearched = yield call(getGooglePlacesFromText, { searchKey });
    }
    const googleResult = uniqBy([...googlePlacesPredictions, ...googlePlacesSearched], 'id');

    yield put({ type: actionTypes.searchGoogleAddress().success(), items: googleResult });
  } catch (error) {
    yield put({ type: actionTypes.setError(), message: error.message });
  }
}

function* createOrderFieldTemplate({ formData, senderId }) {
  const senderApi = serviceLocator.senderApi;
  try {
    yield put({ type: actionTypes.startProgress(), key: 'createOrderFieldTemplate' });
    const data = yield call(senderApi.createOrderFieldTemplate, {
      senderId,
      data: formData,
    });
    yield put({ type: actionTypes.createOrderFieldTemplate().success(), data });
  } catch (error) {
    yield put({ type: actionTypes.setError(), message: error.message });
  }
}

function* updateOrderFieldTemplate({ formData, templateId, senderId }) {
  const senderApi = serviceLocator.senderApi;
  try {
    yield put({ type: actionTypes.startProgress(), key: 'updateOrderFieldTemplate' });
    const data = yield call(senderApi.updateOrderFieldTemplate, {
      senderId,
      data: formData,
    });
    yield put({ type: actionTypes.updateOrderFieldTemplate().success(), data });
  } catch (error) {
    yield put({ type: actionTypes.setError(), message: error.message });
  }
}

function* getOrderFieldTemplate({ senderId }) {
  const senderApi = serviceLocator.senderApi;
  try {
    yield put({ type: actionTypes.startProgress(), key: 'getOrderFieldTemplate' });
    const data = yield call(senderApi.getOrderFieldTemplate, { senderId });
    yield put({ type: actionTypes.getOrderFieldTemplate().success(), data });
  } catch (error) {
    yield put({ type: actionTypes.getOrderFieldTemplate().failed() });
  }
}

function* deleteOrderFieldTemplate({ senderId }) {
  const senderApi = serviceLocator.senderApi;
  try {
    yield put({ type: actionTypes.startProgress(), key: 'deleteOrderFieldTemplate' });
    const data = yield call(senderApi.deleteOrderFieldTemplate, { senderId });
    yield put({ type: actionTypes.deleteOrderFieldTemplate().success(), data });
  } catch (error) {
    yield put({ type: actionTypes.setError(), message: error.message });
  }
}
