import { call, put, select, takeLatest } from 'redux-saga/effects';

import { workerService } from '../../services/workerService';
import { workerTeamManagementService } from '../../services/workerTeamManagementService';

const workerTeamsSelector = (state) => state.workerTeams;

export default function* sagas() {
  yield takeLatest('REQUEST_GET_LIST_WORKER_TEAMS', getListWorkerTeams);
  yield takeLatest('REQUEST_GET_WORKER_TEAM', getWorkerTeam);
  yield takeLatest('REQUEST_GET_DRIVER_BY_WORKER_TEAM', getDriverByWorkerTeam);
  yield takeLatest('REQUEST_CREATE_WORKER_TEAM', createWorkerTeam);
  yield takeLatest('REQUEST_UPDATE_WORKER_TEAM', updateWorkerTeam);
  yield takeLatest('REQUEST_DELETE_WORKER_TEAM', deleteWorkerTeam);
  yield takeLatest('REQUEST_ADD_WORKERS_TO_TEAM', addWorkersToTeam);
  yield takeLatest('REQUEST_REMOVE_WORKERS_FROM_TEAM', removeWorkersFromTeam);
}

function* refreshWorkerTeam() {
  const {
    pagination: { current_page, limit_value },
  } = yield select(workerTeamsSelector);
  yield put({
    type: 'REQUEST_GET_LIST_WORKER_TEAMS',
    payload: { page: current_page, page_size: limit_value },
  });
}

function* getListWorkerTeams(action) {
  try {
    yield put({ type: 'SET_LOADING', key: 'getListWorkerTeam' });
    const result = yield call(workerTeamManagementService.getListWorkerTeams, action.payload);
    yield put({ type: 'REQUEST_GET_LIST_WORKER_TEAMS_SUCCESS', result: result });
  } catch (error) {
    yield put({ type: 'REQUEST_GET_LIST_WORKER_TEAMS_ERROR', error });
  }
}

function* getWorkerTeam(action) {
  try {
    yield put({ type: 'SET_LOADING', key: 'getWorkerTeam' });
    const result = yield call(workerTeamManagementService.getWorkerTeam, action.payload);
    yield put({ type: 'REQUEST_GET_WORKER_TEAM_SUCCESS', result: result });
  } catch (error) {
    yield put({ type: 'REQUEST_GET_WORKER_TEAM_ERROR', error });
  }
}

function* getDriverByWorkerTeam(action) {
  try {
    const result = yield call(workerTeamManagementService.getDriverByWorkerTeam, action.payload);
    yield put({ type: 'REQUEST_GET_DRIVER_BY_WORKER_TEAM_SUCCESS', result: result });
  } catch (error) {
    yield put({ type: 'REQUEST_GET_DRIVER_BY_WORKER_TEAM_ERROR', error });
  }
}

function* createWorkerTeam(action) {
  try {
    const result = yield call(workerTeamManagementService.createWorkerTeam, action.payload);
    yield put({ type: 'REQUEST_CREATE_WORKER_TEAM_SUCCESS', result });
    yield call(refreshWorkerTeam);
  } catch (error) {
    yield put({ type: 'REQUEST_CREATE_WORKER_TEAM_ERROR', error });
  }
}

function* updateWorkerTeam(action) {
  try {
    yield put({ type: 'SET_LOADING', key: 'updateWorkerTeam' });
    const result = yield call(workerTeamManagementService.updateWorkerTeam, action.payload);
    yield put({ type: 'REQUEST_UPDATE_WORKER_TEAM_SUCCESS', result });
    yield put({ type: 'SET_WORKERS_PROGRESS', key: 'listWorkers' });
    const data = yield call(workerService.listWorkers);
    yield put({ type: 'LIST_WORKERS_SUCCESSFUL', workers: data });
  } catch (error) {
    yield put({ type: 'REQUEST_UPDATE_WORKER_TEAM_ERROR', error });
  }
}

function* deleteWorkerTeam(action) {
  try {
    yield put({ type: 'SET_LOADING', key: 'updateWorkerTeam' });
    const workerTeamId = yield call(workerTeamManagementService.deleteWorkerTeam, action.payload);
    yield put({ type: 'REQUEST_DELETE_WORKER_TEAM_SUCCESS', workerTeamId });
    yield call(refreshWorkerTeam);
  } catch (error) {
    yield put({ type: 'REQUEST_DELETE_WORKER_TEAM_ERROR', error });
  }
}

function* addWorkersToTeam(action) {
  try {
    const workerTeam = yield call(workerTeamManagementService.addWorkersToTeam, action.payload);
    yield put({ type: 'REQUEST_ADD_WORKERS_TO_TEAM_SUCCESS', workerTeam });
  } catch (error) {
    yield put({ type: 'REQUEST_ADD_WORKERS_TO_TEAM_ERROR', error });
  }
}

function* removeWorkersFromTeam(action) {
  try {
    yield put({ type: 'SET_LOADING', key: 'bulkDeleteWorkerTeam' });
    const workerTeam = yield call(workerTeamManagementService.removeWorkersFromTeam, action.payload);
    yield put({ type: 'REQUEST_REMOVE_WORKERS_FROM_TEAM_SUCCESS', workerTeam });
  } catch (error) {
    yield put({ type: 'REQUEST_REMOVE_WORKERS_FROM_TEAM_ERROR', error });
  }
}
