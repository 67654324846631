import { authService } from './authService/index';
import { BaseService } from './baseService/index';

export class UserService extends BaseService {
  constructor({ authService }) {
    super();
    this.authService = authService;
  }

  login = (payload) => {
    if (payload.code) {
      return this.loginByOneTimeCode(payload);
    } else {
      return this.loginByEmail(payload);
    }
  };

  loginByEmail = ({ email, password }) => {
    return this.authService
      .post(
        this.getUmbrellaApiUrl('auth/signin'),
        JSON.stringify({
          email,
          password,
        })
      )
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };

  loginByOneTimeCode = ({ email, code }) => {
    return this.authService
      .post(this.getUmbrellaApiUrl('auth/signin'), {
        email,
        one_time_code: code,
      })
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };

  signUp = (data) => {
    return this.authService
      .post(this.getUmbrellaApiUrl('public/companies'), JSON.stringify(data))
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };

  getUserInfo = ({ options }) => {
    return this.authService
      .get(this.getUmbrellaApiUrl('dispatcher/info'), {}, options)
      .then((response) => response.data)
      .catch((error) => this.handleError(error));
  };

  getRelatedCompanies = () => {
    return this.authService
      .get(this.getUmbrellaApiUrl('enterprise/related_companies'))
      .then(this.extractData)
      .catch((error) => this.handleError(error));
  };

  getTokenForRelatedCompany = ({ slug }) => {
    return this.authService
      .get(this.getUmbrellaApiUrl('enterprise/enterprise_related_access'), { target_company_slug: slug })
      .then(this.extractData)
      .catch((error) => this.handleError(error));
  };

  editPassword = ({ token, password }) => {
    return this.authService
      .put(this.getUmbrellaApiUrl('public/password'), {
        reset_password_token: token,
        password,
      })
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };

  requestNewPassword = ({ email, company_slug }) => {
    return this.authService
      .post(this.getUmbrellaApiUrl('public/password'), {
        email,
        company_slug,
      })
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };

  getUsers = ({ page, page_size, q = null, name = null }) => {
    return this.authService
      .get(this.getUmbrellaApiUrl('enterprise/users'), {
        page,
        page_size,
        ...(q ? { q } : {}),
        ...(name ? { name } : {}),
      })
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };

  createUser = (data) => {
    return this.authService
      .post(this.getUmbrellaApiUrl('enterprise/user'), data)
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };

  updateUser = ({ userId, data }) => {
    return this.authService
      .put(this.getUmbrellaApiUrl(`enterprise/user/${userId}/update`), data)
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };

  deleteUser = ({ userId }) => {
    return this.authService
      .post(this.getUmbrellaApiUrl(`enterprise/user/${userId}/remove_from_enterprise`))
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };

  getFilters = () => {
    return this.authService
      .get(this.getUmbrellaApiUrl('enterprise/company_filters'))
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };

  getRoles = (params) => {
    return this.authService
      .get(this.getUmbrellaApiUrl('enterprise/company_roles'), params)
      .then((response) => response)
      .catch((error) => this.handleError(error));
  };

  getEnterprisePermissions = () => {
    return this.authService
      .get(this.getUmbrellaApiUrl('dispatcher/permissions'))
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };

  createRole = ({ role, slug, Authorization }) => {
    return this.authService
      .post(this.getUmbrellaApiUrl('dispatcher/roles'), role, { Authorization, slug })
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };

  updateRole = ({ roleId, data, Authorization, slug }) => {
    return this.authService
      .put(this.getUmbrellaApiUrl(`dispatcher/roles/${roleId}`), data, { Authorization, slug })
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };

  deleteRole = ({ roleId, slug, Authorization }) => {
    return this.authService
      .delete(this.getUmbrellaApiUrl(`dispatcher/roles/${roleId}`), { Authorization, slug })
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };

  downloadUserTemplateSample = ({ format }) => {
    return this.getUmbrellaApiUrl(`public/enterprise_users/download_sample?format=${format}`);
  };

  batchUpload = ({ file, format }) => {
    const uploadJson = new FormData();
    uploadJson.set('file', file);
    uploadJson.set('format', format);

    return this.authService
      .post(this.getUmbrellaApiUrl(`enterprise/users/batches`), uploadJson, { 'Content-Type': 'multipart/form-data' })
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };

  checkBatchStatus = ({ batchId }) => {
    return this.authService
      .get(this.getUmbrellaApiUrl(`enterprise/users/batches/${batchId}/status`))
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };

  downloadMasterData = (data) => {
    return this.authService
      .post(this.getUmbrellaApiUrl('dispatcher/master_data/download'), data)
      .then((response) => {
        return this.extractData(response);
      })
      .catch((error) => this.handleError(error));
  };

  setUserLanguage = (data) => {
    return this.authService
      .put(this.getUmbrellaApiUrlV4('dispatcher/accounts/apply_language'), data)
      .then((response) => {
        return this.extractData(response);
      })
      .catch((error) => this.handleError(error));
  };

  sendSSOLink = ({ email, type }) => {
    return this.authService
      .post(this.getUmbrellaApiUrlV4('sso/send_one_time_link'), {
        email,
        type,
      })
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };
  getLoginId = (resetToken) => {
    return this.authService
      .get(this.getUmbrellaApiUrlV4(`public/account/get_login_id?reset_password_token=${resetToken}`))
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };
}

export const userService = new UserService({ authService });
