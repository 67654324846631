import { BaseService } from '../baseService/index';

export class LabelApi extends BaseService {
  getOrderBatchLabelUrl = (orderBatchLabel, format = 'html') => {
    return this.getUmbrellaApiUrl(`public/labels/order_batch_label/${orderBatchLabel}?format=${format}`);
  };

  getOrderLabelUrl = (orderNumber, format = 'pdf') => {
    return this.getUmbrellaApiUrl(`public/labels/order/${orderNumber}?format=${format}`);
  };

  getOrderItemLabelUrl = (orderItemNumber, format = 'html') => {
    return this.getUmbrellaApiUrl(`public/labels/order_item/${orderItemNumber}?format=${format}`);
  };
}

export const labelApi = new LabelApi();
