export const resetErrors = () => {
  return { type: 'RESET_ERRORS' };
};

export const signOut = () => {
  return { type: 'SIGN_OUT' };
};

export const setDataFromExplore = (payload) => {
  return { type: 'REQUEST_SET_DATA_FROM_EXPLORE', payload };
};

export const setSlugAndToken = (payload) => {
  return { type: 'REQUEST_SET_SLUG_AND_TOKEN', payload };
};
