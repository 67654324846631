export const camelize = (str) =>
  str
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');

export const lowerCaseAndSpaceReplace = (str) => str.replace(/\s+/g, '-').toLowerCase();

export const convertUnderscoreToCamel = (text) => {
  if (!text) return '';
  const arr = text.split('');
  return arr.reduce((str, char, idx) => {
    if (char !== '_') {
      if (idx > 0 && arr[idx - 1] === '_') {
        return str + char.toUpperCase();
      }
      return str + char;
    }
    return str;
  }, '');
};

function isNull(target) {
  return target === undefined || target === null;
}

function isNotNull(target) {
  return !isNull(target);
}

export function parseString(target) {
  return isNotNull(target) ? target : '';
}

export function isObjectEmpty(object) {
  return (
    object === null ||
    object === undefined ||
    object.length === 0 ||
    object.toString().trim() === '' ||
    (Object.keys(object).length === 0 && object.constructor === Object)
  );
}

export function parsePrice(priceStr) {
  const _price = parseFloat(priceStr);
  return _price ? _price.toFixed(2) : '0.0';
}

export function capitalizeFirstLetter(string) {
  if (isNull(string)) return string;
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function formatSize(bytes) {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (!bytes) return '0 Byte';
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return `${Math.round(bytes / Math.pow(1024, i), 2)}${sizes[i]}`;
}

export const toPluralWord = (word, count = 1) => {
  if (typeof word !== 'string') {
    return null;
  }

  if (word.length === 0 || count <= 1) return word;

  const specialCases = ['s', 'ss', 'z', 'ch', 'sh', 'x'];
  const lastChar = word.charAt(word.length - 1);

  if (specialCases.includes(lastChar)) return `${word}es`;

  return `${word}s`;
};

export function toSnakeCase(string) {
  return string.split(' ').join('_').toLowerCase();
}

// encode RFC 3986 because s3 library in BE need to encode in this format
export function encodeRFC3986(str) {
  return encodeURIComponent(str).replace(/[!'()*]/g, (c) => `%${c.charCodeAt(0).toString(16).toUpperCase()}`);
}
