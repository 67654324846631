import getEnv from '@yojee/helpers/env/getEnv';

import { handleNewErrorFormat } from './index';

const UMBRELLA_HOST = getEnv('REACT_APP_UMBRELLA_HOST_URL');
const CAPACITY_MICROSERVICE_URL = getEnv('REACT_APP_CAPACITY_MICROSERVICE_URL');
const API_URL_V4 = getEnv('REACT_APP_API_URL_V4');
const ENV = process.env.REACT_APP_ENV;

export class BookingBaseService {
  static getUmbrellaApiHostUrl() {
    return `${UMBRELLA_HOST}/api/v3/`;
  }

  getUmbrellaApiUrl(url) {
    return `${UMBRELLA_HOST}/api/v3/${url}`;
  }

  getUmbrellaApiUrlV4(path) {
    return `${API_URL_V4}/${path}`;
  }

  _getCapacityMicroserviceUrl() {
    return CAPACITY_MICROSERVICE_URL;
  }

  _getEnv() {
    return ENV;
  }

  extractData(response) {
    return response && response['data'] ? response['data'] : response;
  }

  handleError = (error) => {
    const { response } = error;
    const errorObj = {
      statusCode: response.status ? response.status : null,
      message: null,
      statusText: response.statusText ? response.statusText : null,
      errors: [],
    };

    if (response['data']) {
      const { data } = response;
      if (data['failed_operation'] && data['failed_value']) {
        errorObj.message = data['failed_value'];
      } else if (data['message']) {
        errorObj.message = data['message'];
      } else if (data['data']) {
        const _data = data['data'];
        if (Array.isArray(_data) && _data.length > 0) {
          const _errorMessages = Object.keys(_data[0]).map((key) => _data[0][key][0]);
          errorObj.message = _errorMessages && _errorMessages.length > 0 ? _errorMessages[0] : '';
        } else {
          let _errorList = [];
          Object.keys(_data).map((key) => {
            const _errorValue = _data[key];
            if (Array.isArray(_errorValue) && _errorValue.length > 0) {
              _errorList = [..._errorList, ..._errorValue];
            } else {
              _errorList.push(_errorValue);
            }
            return null;
          });
          errorObj.message = _errorList.length > 0 ? _errorList.join(', ') : '';
        }
      }

      if (data['data'] && data['data']['errors'] && data['data']['errors'].length > 0) {
        errorObj.errors = data['data']['errors'];
        if (data['data']['missing_info_count']) {
          errorObj.missingInfoCount = data['data']['missing_info_count'];
        }
        if (data['data']['total_items']) {
          errorObj.totalItemCount = data['data']['total_items'];
        }
      }
    }
    throw errorObj;
  };

  handleNewErrorFormat = (error) => {
    handleNewErrorFormat(error);
  };
}
