import { t } from 'i18next';

import {
  batchUploadDefaultState,
  generateBatchUploadReducerMethods,
} from '@yojee/ui/onboarding/helpers/batchUploadHelper';

const defaultState = {
  batchUpload: batchUploadDefaultState,
  senderOrganisations: [],
  senderOrganisationData: {},
  corporateRateCardsData: {
    data: [],
    pagination: {
      current_page: 1,
      limit_value: 25,
      total_count: 0,
      total_pages: 1,
    },
  },
  loading: {
    listAllSenderOrganisations: false,
    listSenderOrganisations: false,
    loadSenderOrganisation: false,
    createSenderOrganisation: false,
    updateSenderOrganisation: false,
    deleteSenderOrganisation: false,
    createSenderOrganisationUser: false,
    updateSenderOrganisationUser: false,
    deleteSenderOrganisationUser: false,
    bulkDeleteSenderOrganisation: false,
    getCorporateRateCards: false,
  },
  filter: { sort_by: 'id' },
  pagination: { current_page: 1, limit_value: 25, total_count: 0, total_pages: 0 },
  organisationData: [],
  lastCompletedActionId: null,
  listOfUsersForResetPassword: [],
};

export const senderOrganisationMessagesSuccessEvents = {
  DELETE_SENDER_ORGANISATION_SUCCESSFUL: 'Corporate deleted successfully',
  DELETE_SENDER_ORGANISATION_USER_SUCCESSFUL: 'Corporate user deleted successfully',
  CREATE_SENDER_ORGANISATION_SUCCESSFUL: 'Corporate created successfully',
  CREATE_SENDER_ORGANISATION_USER_SUCCESSFUL: 'Corporate user created successfully',
  UPDATE_SENDER_ORGANISATION_SUCCESSFUL: 'Corporate updated successfully',
  UPDATE_SENDER_ORGANISATION_USER_SUCCESSFUL: 'Corporate user updated successfully',
  REQUEST_DELETE_SENDER_ORGANISATION_IN_PROCESS: 'Sender Organisation deleted in process',
  BULK_REQUEST_DELETE_SENDER_ORGANISATION_SUCCESSFUL: 'Sender Organisation deleted successfully',
  RESET_PASSWORD_SENDER_ORGANISATION_SUCCESSFUL: 'Sent email successfully',
  SENDER_ORGANISATIONS_BATCH_UPLOAD_SUCCEEDED: t('Batch upload completed'),
};

export const senderOrganisationMessagesErrorEvents = {
  DELETE_SENDER_ORGANISATION_FAILED: ({ error }) => error?.message ?? 'Failed to delete corporate',
  DELETE_SENDER_ORGANISATION_USER_FAILED: ({ error }) => error?.message ?? 'Failed to delete corporate user',
  CREATE_SENDER_ORGANISATION_FAILED: ({ error }) => error?.message ?? 'Failed to create corporate',
  CREATE_SENDER_ORGANISATION_USER_FAILED: ({ error }) => error?.message ?? 'Failed to create corporate user',
  UPDATE_SENDER_ORGANISATION_FAILED: ({ error }) => error?.message ?? 'Failed to update corporate',
  UPDATE_SENDER_ORGANISATION_USER_FAILED: ({ error }) => error?.message ?? 'Failed to update corporate user',
  LIST_SENDER_ORGANISATIONS_FAILED: ({ error }) => error?.message ?? 'Failed to get corporates list',
  LOAD_SENDER_ORGANISATION_FAILED: ({ error }) => error?.message ?? 'Failed to get corporate info',
  BULK_REQUEST_DELETE_SENDER_ORGANISATION_FAIL: ({ payload }) => payload || 'Sender Organisation deleted fail!',
  GET_CORPORATE_RATE_CARDS_FAILURE: ({ payload }) => payload?.message ?? 'Failed to get corporate rate cards',
  RESET_PASSWORD_SENDER_ORGANISATION_FAILED: ({ error }) => error?.message ?? 'Failed to send email for password reset',
};

export const init = () => {
  return defaultState;
};

const ACTION_HANDLERS = {
  LIST_ALL_SENDER_ORGANISATIONS_SUCCESSFUL: (state, sagaData) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        listAllSenderOrganisations: false,
      },
      senderOrganisations: sagaData.senderOrganisations,
    };
  },
  LOAD_SENDER_ORGANISATION_SUCCESSFUL: (state, sagaData) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        loadSenderOrganisation: false,
      },
      senderOrganisationData: sagaData.senderOrganisation,
    };
  },
  LIST_SENDER_ORGANISATIONS_SUCCESSFUL: (state, sagaData) => {
    const data = sagaData.organisationData;
    return {
      ...state,
      loading: {
        ...state.loading,
        listSenderOrganisations: false,
      },
      organisationData: data && data['data'],
      pagination: data && data['pagination'],
      filter: sagaData.params,
    };
  },
  DELETE_SENDER_ORGANISATION_SUCCESSFUL: (state) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        deleteSenderOrganisation: false,
      },
    };
  },
  DELETE_SENDER_ORGANISATION_FAILED: (state) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        deleteSenderOrganisation: false,
      },
    };
  },
  DELETE_SENDER_ORGANISATION_USER_SUCCESSFUL: (state) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        deleteSenderOrganisationUser: false,
      },
    };
  },
  DELETE_SENDER_ORGANISATION_USER_FAILED: (state) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        deleteSenderOrganisationUser: false,
      },
    };
  },
  CREATE_SENDER_ORGANISATION_SUCCESSFUL: (state, { uuid }) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        createSenderOrganisation: false,
      },
      lastCompletedActionUuid: uuid,
    };
  },
  CREATE_SENDER_ORGANISATION_FAILED: (state) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        createSenderOrganisation: false,
      },
    };
  },
  CREATE_SENDER_ORGANISATION_USER_SUCCESSFUL: (state, { uuid }) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        createSenderOrganisationUser: false,
      },
      lastCompletedActionUuid: uuid,
    };
  },
  CREATE_SENDER_ORGANISATION_USER_FAILED: (state) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        createSenderOrganisationUser: false,
      },
    };
  },
  RESET_PASSWORD_SENDER_ORGANISATION_SUCCESSFUL: (state, sagaData) => {
    return {
      ...state,
      listOfUsersForResetPassword: sagaData.listOfUsers,
    };
  },

  UPDATE_LIST_OF_USERS_FOR_RESET_PASSWORD: (state, sagaData) => {
    return {
      ...state,
      listOfUsersForResetPassword: sagaData.listOfUsers,
    };
  },
  RESET_PASSWORD_SENDER_ORGANISATION_FAILED: (state, sagaData) => {
    return {
      ...state,
      listOfUsersForResetPassword: sagaData.listOfUsers,
    };
  },
  UPDATE_SENDER_ORGANISATION_SUCCESSFUL: (state, { uuid, senderData }) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        updateSenderOrganisation: false,
      },
      lastCompletedActionUuid: uuid,
      senderOrganisationData: senderData,
    };
  },
  UPDATE_SENDER_ORGANISATION_FAILED: (state) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        updateSenderOrganisation: false,
      },
    };
  },
  UPDATE_SENDER_ORGANISATION_USER_SUCCESSFUL: (state, { uuid }) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        updateSenderOrganisationUser: false,
      },
      lastCompletedActionUuid: uuid,
    };
  },
  UPDATE_SENDER_ORGANISATION_USER_FAILED: (state) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        updateSenderOrganisationUser: false,
      },
    };
  },
  LIST_SENDER_ORGANISATIONS_FAILED: (state) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        listSenderOrganisations: false,
      },
    };
  },
  LOAD_SENDER_ORGANISATION_FAILED: (state) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        loadSenderOrganisation: false,
      },
    };
  },
  SET_SENDER_ORGANISATION_LOADING: (state, sagaData) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        [sagaData.key]: true,
      },
    };
  },
  REQUEST_DELETE_SENDER_ORGANISATION_IN_PROCESS: (state) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        bulkDeleteSenderOrganisation: true,
      },
    };
  },
  BULK_REQUEST_DELETE_SENDER_ORGANISATION_SUCCESSFUL: (state, { payload }) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        bulkDeleteSenderOrganisation: false,
      },
      organisationData: state.organisationData.filter((s) => !payload.includes(s.sender.id)),
    };
  },
  BULK_REQUEST_DELETE_SENDER_ORGANISATION_FAIL: (state) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        loading: {
          ...state.loading,
          bulkDeleteSenderOrganisation: false,
        },
        deleteSenderOrganisation: false,
      },
    };
  },
  ...generateBatchUploadReducerMethods({ prefix: 'SENDER_ORGANISATIONS' }),
  GET_CORPORATE_RATE_CARDS_REQUEST: (state) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        getCorporateRateCards: true,
      },
    };
  },
  GET_CORPORATE_RATE_CARDS_SUCCESS: (state, { payload }) => {
    return {
      ...state,
      corporateRateCardsData: payload,
      loading: {
        ...state.loading,
        getCorporateRateCards: false,
      },
    };
  },
  GET_CORPORATE_RATE_CARDS_FAILURE: (state) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        getCorporateRateCards: false,
      },
    };
  },
};

const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};

export default reducer;
