import MuiIconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';

const styledBy = (property, mapping) => (props) => mapping[props[property]] || mapping.default;

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: styledBy('size', {
      default: '8px',
      small: '6px',
      medium: '8px',
    }),
    '& > span > svg': {
      fill: styledBy('color', {
        default: '#060a22',
        primary: '#ffffff',
        error: '#ffffff',
        warning: '#ffffff',
        success: '#ffffff',
        info: '#ffffff',
      }),
    },
    backgroundColor: styledBy('color', {
      default: '',
      primary: theme.palette.primary.main,
      secondary: theme.palette.secondary.main,
      error: theme.palette.error.main,
      warning: theme.palette.warning.main,
      success: theme.palette.success.main,
      info: theme.palette.info.main,
    }),
  },
  customHoverFocus: {
    '&:hover, &.Mui-focusVisible': {
      backgroundColor: styledBy('color', {
        default: '',
        primary: theme.palette.primary.dark,
        secondary: theme.palette.secondary.dark,
        error: theme.palette.error.dark,
        warning: theme.palette.warning.dark,
        success: theme.palette.success.dark,
        info: theme.palette.info.dark,
      }),
    },
  },
  //TODO: adjust this
  customDisabled: {
    '&.Mui-disabled': {
      backgroundColor: '#e6e6e9',
    },
    '& > span > svg': {
      fill: ['#c3c3c9', '!important'],
    },
  },
  //TODO: adjust this
  customOutlinedVariant: {
    border: '2px solid #b0b1b9',
    padding: styledBy('size', {
      default: '10px',
      small: '1px',
      medium: '10px',
    }),
    '&:hover': {
      borderColor: '#060a22',
      backgroundColor: 'transparent',
    },
    '& > span > svg': {
      fill: '#060a22',
    },
  },
}));

const IconButton = ({ children, className, ...restProps }) => {
  const classes = useStyles(restProps);
  return (
    <MuiIconButton
      {...restProps}
      className={clsx(
        classes.root,
        classes.customHoverFocus,
        { [classes.customDisabled]: !!restProps?.disabled },
        { [classes.customOutlinedVariant]: restProps?.variant === 'outlined' },
        className
      )}
    >
      {children}
    </MuiIconButton>
  );
};

export default IconButton;
