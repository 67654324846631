import { createTheme } from '@material-ui/core/styles';

export const globalTheme = createTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: {
      main: '#2f7ae2',
    },
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        borderColor: '#b7c0c5',
        padding: 0,
        fontSize: '14px',
        height: '30px',
        '&$focused $notchedOutline': {
          borderColor: 'b7c0c5',
          borderWidth: '1px',
          backgroundColor: 'clear',
        },
        '&$disabled': {
          fontFamily: 'Arial',
          fontSize: '14px',
          fontWeight: 'normal',
          fontStyle: 'normal',
          color: '#38464c',
          backgroundColor: '#f6f9fa',
        },
      },
      input: {
        padding: 'none',
        paddingLeft: '10px',
        paddingRight: '10px',
      },
    },
    MuiTableCell: {
      root: {
        padding: '0px 5px',
      },
    },
    MuiDialog: {
      paper: {
        overflowY: 'visible',
      },
    },
    MuiDialogContent: {
      root: {
        padding: '24px',
      },
    },
    MuiCheckbox: {
      colorSecondary: {
        '&$checked': {
          color: '#2f7ae2',
        },
      },
    },
    MuiList: {
      padding: {
        paddingBottom: '0px',
      },
    },
    MuiTableRow: {
      root: {
        '&$hover': {
          '&:hover': {
            backgroundColor: '#f0f8fb',
          },
        },
      },
    },
  },
});
