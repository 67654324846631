import { t } from 'i18next';

import {
  batchUploadDefaultState,
  generateBatchUploadReducerMethods,
} from '@yojee/ui/onboarding/helpers/batchUploadHelper';

const defaultState = {
  addressData: [],
  filter: { sort_by: 'external_id' },
  pagination: { current_page: 1, limit_value: 25, total_count: 0, total_pages: 0 },
  organisationData: [],
  error: {
    failed: false,
  },
  loading: {
    getAddresses: false,
    createAddress: false,
    updateAddress: false,
    deleteAddress: false,
    uploadAddress: false,
    bulkDeleteAddress: false,
  },
  newAddressId: undefined,
  batchUpload: batchUploadDefaultState,
};

export const init = () => {
  return defaultState;
};

export const addressMessagesSuccessEvents = {
  CREATE_ADDRESS_SUCCESSFUL: 'Address created successfully',
  UPDATE_ADDRESS_SUCCESSFUL: 'Address updated successfully',
  DELETE_ADDRESS_SUCCESSFUL: 'Address deleted successfully',
  UPLOAD_ADDRESSES_SUCCESSFUL: ({ addressData = {} }) => `Addresses uploaded: ${addressData.length}`,
  ADDRESS_BATCH_UPLOAD_SUCCEEDED: t('Batch upload completed'),
};

export const addressMessagesErrorEvents = {
  CREATE_ADDRESS_FAILED: ({ error = {} }) => error.message || 'Error creating address.',
  UPDATE_ADDRESS_FAILED: ({ error = {} }) => error.message || 'Error updating address.',
  DELETE_ADDRESS_FAILED: ({ error = {} }) => error.message || 'Error deleting address.',
  UPLOAD_ADDRESSES_FAILED: ({ error = {} }) => `Error uploading addresses${error.message ? `: ${error.message}` : ''}`,
  GET_ADDRESSES_FAILED: ({ error = {} }) => error?.message ?? 'Failed to get address info',
};

const ACTION_HANDLERS = {
  GET_ADDRESSES_SUCCESSFUL: (state, sagaData) => {
    const data = sagaData.addressData;
    return {
      ...state,
      loading: false,
      addressData: data && data['data'],
      pagination: data && data['pagination'],
      filter: sagaData.params,
    };
  },
  GET_ADDRESSES_FAILED: (state) => {
    return {
      ...state,
      loading: false,
      error: {
        failed: true,
      },
    };
  },
  START_LOADING: (state, sagaData) => {
    return {
      ...state,
      loading: { ...state.loading, [sagaData.loadingAction]: true },
    };
  },
  CLEAR_NEW_ADDRESS_ID: (state) => {
    return {
      ...state,
      newAddressId: undefined,
    };
  },
  CREATE_ADDRESS_SUCCESSFUL: (state, sagaData) => {
    const { addressData } = sagaData;
    return {
      ...state,
      loading: { ...state.loading, createAddress: false },
      newAddressId: addressData && addressData.length > 0 && addressData[0].id,
    };
  },
  CREATE_ADDRESS_FAILED: (state) => {
    return {
      ...state,
      loading: { ...state.loading, createAddress: false },
    };
  },
  UPDATE_ADDRESS_SUCCESSFUL: (state) => {
    return {
      ...state,
      loading: { ...state.loading, updateAddress: false },
    };
  },
  UPDATE_ADDRESS_FAILED: (state) => {
    return {
      ...state,
      loading: { ...state.loading, updateAddress: false },
    };
  },
  DELETE_ADDRESS_SUCCESSFUL: (state) => {
    return {
      ...state,
      loading: { ...state.loading, deleteAddress: false },
    };
  },
  DELETE_ADDRESS_FAILED: (state) => {
    return {
      ...state,
      loading: { ...state.loading, deleteAddress: false },
    };
  },
  UPLOAD_ADDRESSES_SUCCESSFUL: (state) => {
    return {
      ...state,
      loading: { ...state.loading, uploadAddress: false },
    };
  },
  UPLOAD_ADDRESSES_FAILED: (state) => {
    return {
      ...state,
      loading: { ...state.loading, uploadAddress: false },
    };
  },
  REQUEST_DELETE_ADDRESS_ITEM_IN_PROCESS: (state) => {
    return {
      ...state,
      loading: { ...state.loading, bulkDeleteAddress: true },
    };
  },
  BULK_REQUEST_DELETE_ADDRESS_ITEM_SUCCESS: (state) => {
    return {
      ...state,
      loading: { ...state.loading, bulkDeleteAddress: false },
      successMessage: 'Address deleted successfully',
    };
  },
  BULK_REQUEST_DELETE_ADDRESS_ITEM_FAIL: (state) => {
    return {
      ...state,
      loading: { ...state.loading, bulkDeleteAddress: false },
      errorMessage: 'Error deleting address.',
    };
  },
  ...generateBatchUploadReducerMethods({ prefix: 'ADDRESS' }),
};

const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};

export default reducer;
