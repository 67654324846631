import { call, put, takeLatest } from 'redux-saga/effects';

import { savedFilterService } from '../../services/savedFilterService';

export default function* sagas() {
  yield takeLatest('REQUEST_LIST_SAVED_FILTERS', listSavedFilters);
  yield takeLatest('REQUEST_CREATE_SAVED_FILTER', createSavedFilter);
  yield takeLatest('REQUEST_UPDATE_SAVED_FILTER', updateSavedFilter);
  yield takeLatest('REQUEST_DELETE_SAVED_FILTER', deleteSavedFilter);
}

function* listSavedFilters(payload) {
  try {
    yield put({ type: 'SET_SAVED_FILTER_LOADING', loading: true });
    const savedFiltersData = yield call(savedFilterService.listSavedFilters, payload);
    yield put({ type: 'LIST_SAVED_FILTERS_SUCCESSFUL', savedFiltersData });
  } catch (error) {
    yield put({ type: 'LIST_SAVED_FILTERS_FAILED', error });
  }
}

function* createSavedFilter(action) {
  try {
    const savedFiltersData = yield call(savedFilterService.createSavedFilter, action.saved_filter);
    yield put({ type: 'CREATE_SAVED_FILTER_SUCCESSFUL', savedFiltersData });
    yield put({ type: 'REQUEST_LIST_SAVED_FILTERS' });
  } catch (error) {
    yield put({ type: 'CREATE_SAVED_FILTER_FAILED', error });
  }
}
function* updateSavedFilter(action) {
  try {
    const savedFiltersData = yield call(savedFilterService.updateSavedFilter, action.saved_filter);
    yield put({ type: 'UPDATE_SAVED_FILTER_SUCCESSFUL', savedFiltersData });
    yield put({ type: 'REQUEST_LIST_SAVED_FILTERS' });
  } catch (error) {
    yield put({ type: 'UPDATE_SAVED_FILTER_FAILED', error });
  }
}
function* deleteSavedFilter(action) {
  try {
    const savedFiltersData = yield call(savedFilterService.deleteSavedFilter, action.id);
    yield put({ type: 'DELETE_SAVED_FILTER_SUCCESSFUL', savedFiltersData });
    yield put({ type: 'REQUEST_LIST_SAVED_FILTERS' });
  } catch (error) {
    yield put({ type: 'DELETE_SAVED_FILTER_FAILED', error });
  }
}
