import { makeStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
// icons
import CloseIcon from '@material-ui/icons/Close';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import clsx from 'clsx';
import { SnackbarProvider, useSnackbar } from 'notistack';
import React from 'react';

import { InfoOutlinedIcon, ReportProblemOutlinedIcon } from '../../icons';
import { theme as yojeeTheme } from '../../theme';
import IconButton from '../IconButton';

/**
 * @param {import('../../theme').YojeeTheme | import('../../theme').YojeeLegacyTheme} theme
 */
const styleHelper = (variant, theme, customColor = null) => ({
  [variant]: {
    backgroundColor: theme.palette[customColor || variant].tint,
    color: theme.palette.common.black,
  },
  [`icon${variant}`]: {
    color: theme.palette[customColor || variant].main,
  },
});

const useStyles = makeStyles((theme) => {
  /**
   * This is temporary to fix manage global theme issue
   * In Manage, Snackbar is not wrapped by the new theme but the old now
   * Change the wrapper will affect the current components
   * Remove this when complete overhaul Manage
   */
  const customTheme = yojeeTheme;

  return {
    root: {
      pointerEvents: 'all',
      '& > div': {
        boxShadow: 'none',
      },
    },
    icon: {
      marginRight: theme.spacing(1),
    },
    ...styleHelper('success', customTheme),
    ...styleHelper('error', customTheme),
    ...styleHelper('warning', customTheme),
    ...styleHelper('info', customTheme, 'primary'),
  };
});

const CloseButton = ({ snackbarKey }) => {
  const { closeSnackbar } = useSnackbar();

  const onClick = () => {
    closeSnackbar(snackbarKey);
  };

  return (
    <IconButton size="small" onClick={onClick}>
      <CloseIcon size="small" />
    </IconButton>
  );
};

const Notistack = ({ children, onClose, ...restProps }) => {
  const classes = useStyles();

  return (
    <SnackbarProvider
      {...restProps}
      iconVariant={{
        success: <CheckIcon size="small" className={clsx(classes.icon, classes.iconsuccess)} />,
        error: <ErrorOutlineIcon size="small" className={clsx(classes.icon, classes.iconerror)} />,
        warning: <ReportProblemOutlinedIcon size="small" className={clsx(classes.icon, classes.iconwarning)} />,
        info: <InfoOutlinedIcon size="small" className={clsx(classes.icon, classes.iconinfo)} />,
      }}
      classes={{
        root: classes.root,
        variantSuccess: classes.success,
        variantError: classes.error,
        variantWarning: classes.warning,
        variantInfo: classes.info,
      }}
      action={(key) => <CloseButton snackbarKey={key} onClose={onClose} />}
    >
      {children}
    </SnackbarProvider>
  );
};

export default Notistack;
