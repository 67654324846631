import { useSelector } from 'react-redux';

import { getDispatcherSupportedLanguages } from './getDispatcherSupportedLanguages';
import { languageDB } from './languagesDB';

export const useLanguageOptions = () => {
  const supportedLanguages = useSelector(getDispatcherSupportedLanguages) as [];

  return supportedLanguages.map((langCode: string) => ({
    value: langCode,
    label: languageDB[langCode]?.local,
  }));
};
