export const ADDRESS_PICKER_STORE_ID = 'BIZ_ADDRESS_PICKER';

export const ADDRESS_PICKER_ACTION_TYPES = {
  SAVE_TO_ADDRESS_BOOK: `${ADDRESS_PICKER_STORE_ID}:SAVE_TO_ADDRESS_BOOK`,
  SAVE_TO_ADDRESS_BOOK_SUCCESS: `${ADDRESS_PICKER_STORE_ID}:SAVE_TO_ADDRESS_BOOK_SUCCESS`,
  SAVE_TO_ADDRESS_BOOK_ERROR: `${ADDRESS_PICKER_STORE_ID}:SAVE_TO_ADDRESS_BOOK_ERROR`,
  CLEAR_ADDRESS_PICKER_MESSAGE: `${ADDRESS_PICKER_STORE_ID}:CLEAR_ADDRESS_PICKER_MESSAGE`,
};

export const saveToAddressBook = (payload) => ({ type: ADDRESS_PICKER_ACTION_TYPES.SAVE_TO_ADDRESS_BOOK, payload });
export const clearMesageAddressPicker = () => ({ type: ADDRESS_PICKER_ACTION_TYPES.CLEAR_ADDRESS_PICKER_MESSAGE });
