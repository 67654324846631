import { authService } from '@yojee/api/authService';
import { BaseService } from '@yojee/api/baseService';

export class WayBillService extends BaseService {
  constructor({ authService }) {
    super();
    this.authService = authService;
  }

  getAllVersions = (id) => {
    return this.authService
      .get(this.getUmbrellaApiUrl(`dispatcher/labels/${id}`))
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  getWayBillContentByVersion = (id) => {
    return this.authService
      .get(this.getUmbrellaApiUrl(`dispatcher/company/waybill_templates/${id}`))
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  createNewWayBill = (data) => {
    return this.authService
      .post(this.getUmbrellaApiUrl('dispatcher/company/waybill_templates'), data)
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  updateWayBill = (id, data) => {
    return this.authService
      .put(this.getUmbrellaApiUrl(`dispatcher/company/waybill_templates/${id}/set_active`), data)
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  removeWayBill = (id) => {
    return this.authService
      .delete(this.getUmbrellaApiUrl(`dispatcher/company/waybill_templates/${id}`))
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  getAllLabels = ({ page, page_size }) => {
    return this.authService
      .get(this.getUmbrellaApiUrl(`dispatcher/labels`), { page, page_size })
      .then((response) => {
        return this.extractData(response);
      })
      .catch((error) => this.handleError(error));
  };

  getLabelDetail = (id) => {
    return this.authService
      .get(this.getUmbrellaApiUrl(`dispatcher/labels/${id}`))
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  createLabel = (data) => {
    return this.authService
      .post(this.getUmbrellaApiUrl('dispatcher/labels'), data)
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  updateLabels = (id, data) => {
    return this.authService
      .put(this.getUmbrellaApiUrl(`dispatcher/labels/${id}`), data)
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };
}

export const wayBillService = new WayBillService({ authService });
