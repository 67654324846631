import { takeLatest } from 'redux-saga/effects';

import { batchUpload } from '@yojee/ui/onboarding/helpers/batchUploadHelper';

import { chargeCodeService } from '../../services/chargeCodeService';

export default function* sagas() {
  yield takeLatest('DOWNLOAD_CHARGE_CODE_TEMPLATE_SAMPLE', downloadSample);
  yield takeLatest('REQUEST_CHARGE_CODE_BATCH_UPLOAD', chargeCodeBatchUpload);
}

function downloadSample({ payload }) {
  chargeCodeService.downloadSample(payload);
}

function* chargeCodeBatchUpload({ uploadData: { file, format } }) {
  yield batchUpload(
    { file, format },
    {
      uploadApiMethod: chargeCodeService.batchUpload,
      statusCheckApiMethod: chargeCodeService.checkBatchStatus,
      events: {
        started: 'CHARGE_CODE_BATCH_UPLOAD_STARTED',
        processing: 'CHARGE_CODE_BATCH_UPLOAD_PROCESSING',
        succeeded: 'CHARGE_CODE_BATCH_UPLOAD_SUCCEEDED',
        failed: 'CHARGE_CODE_BATCH_UPLOAD_FAILED',
      },
    }
  );
}
