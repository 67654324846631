import { authService } from '@yojee/api/authService';
import { BaseService } from '@yojee/api/baseService';

export class IntegrationService extends BaseService {
  constructor({ authService }) {
    super();
    this.authService = authService;
  }

  getIntegrationTypes = () => {
    return this.authService
      .get(this.getUmbrellaApiUrlV4('company/integration/types'))
      .then((response) => {
        const result = this.extractData(response);
        return result?.['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  getMachshipProfileDetails = (id) => {
    return this.authService
      .get(this.getUmbrellaApiUrlV4(`company/integration/profiles/${id}`))
      .then((response) => {
        const result = this.extractData(response);
        return result?.['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  updateIntegrationProfiles = ({ id, payload }) => {
    return this.authService
      .put(this.getUmbrellaApiUrlV4(`company/integration/profiles/${id}`), payload)
      .then((response) => {
        const result = this.extractData(response);
        return result?.['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  createIntegrationProfiles = (payload) => {
    return this.authService
      .post(this.getUmbrellaApiUrlV4('company/integration/profile'), payload)
      .then((response) => {
        const result = this.extractData(response);
        return result?.['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  getAllIntegrationProfiles = () => {
    return this.authService
      .get(this.getUmbrellaApiUrlV4(`company/integration/profiles`))
      .then((response) => {
        const result = this.extractData(response);
        return result?.['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };
}

export const integrationService = new IntegrationService({ authService });
