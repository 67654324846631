const defaultState = {
  loading: {
    listServiceTimes: false,
    createServiceTime: false,
    updateServiceTime: false,
    deleteServiceTime: false,
    bulkDeleteServiceTime: false,
  },
  serviceTimeData: [],
  filter: { sort_by: 'id' },
  pagination: { current_page: 1, limit_value: 25, total_count: 0, total_pages: 0 },
  error: {
    failed: false,
    message: null,
  },
};

export const serviceTimeMessagesSuccessEvents = {
  CREATE_SERVICE_TIME_SUCCESSFUL: 'ServiceTime created successfully',
  UPDATE_SERVICE_TIME_SUCCESSFUL: 'ServiceTime updated successfully',
  DELETE_SERVICE_TIME_SUCCESSFUL: 'ServiceTime deleted successfully',
  REQUEST_DELETE_SERVICE_TIME_IN_PROCESS: 'Service time delete in process',
  BULK_REQUEST_DELETE_SERVICE_TIME_SUCCESS: 'Service time deleted successfully',
};

export const serviceTimeMessagesErrorEvents = {
  LIST_SERVICE_TIMES_FAILED: ({ error }) => error?.message ?? 'Failed to get service times',
  CREATE_SERVICE_TIME_FAILED: ({ error }) => error?.message ?? 'Error creating service time.',
  UPDATE_SERVICE_TIME_FAILED: 'Error updating service time.',
  DELETE_SERVICE_TIME_FAILED: 'Error deleting service time.',
};

export const init = () => {
  return defaultState;
};

const ACTION_HANDLERS = {
  LIST_SERVICE_TIMES_SUCCESSFUL: (state, sagaData) => {
    const data = sagaData.serviceTimeData;
    return {
      ...state,
      loading: {
        ...state.loading,
        listServiceTimes: false,
      },
      serviceTimeData: data && data['data'],
      pagination: data && data['pagination'],
      filter: sagaData.params,
    };
  },
  LIST_SERVICE_TIMES_FAILED: (state) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        listServiceTimes: false,
      },
      error: {
        failed: true,
      },
    };
  },
  SET_SERVICE_TIME_LOADING: (state, sagaData) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        [sagaData.key]: true,
      },
    };
  },
  CLEAR_SERVICE_TIME_MESSAGE: (state) => {
    return {
      ...state,
      successMessage: undefined,
      errorMessage: undefined,
    };
  },
  CREATE_SERVICE_TIME_FAILED: (state) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        createServiceTime: false,
      },
    };
  },
  UPDATE_SERVICE_TIME_FAILED: (state) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        updateServiceTime: false,
      },
    };
  },
  DELETE_SERVICE_TIME_FAILED: (state) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        deleteServiceTime: false,
      },
    };
  },
  CREATE_SERVICE_TIME_SUCCESSFUL: (state) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        createServiceTime: false,
      },
    };
  },
  UPDATE_SERVICE_TIME_SUCCESSFUL: (state) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        updateServiceTime: false,
      },
    };
  },
  REQUEST_DELETE_SERVICE_TIME: (state) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        deleteServiceTime: true,
      },
    };
  },
  DELETE_SERVICE_TIME_SUCCESSFUL: (state) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        deleteServiceTime: false,
      },
    };
  },
  REQUEST_DELETE_SERVICE_TIME_IN_PROCESS: (state) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        bulkDeleteServiceTime: true,
      },
    };
  },
  BULK_REQUEST_DELETE_SERVICE_TIME_SUCCESS: (state) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        bulkDeleteServiceTime: false,
      },
    };
  },
  BULK_REQUEST_DELETE_SERVICE_TIME_FAIL: (state) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        bulkDeleteServiceTime: false,
      },
    };
  },
};

const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};

export default reducer;
