import { t } from 'i18next';

import { batchUploadDefaultState, generateBatchUploadReducerMethods } from '../helpers/batchUploadHelper';
const defaultState = {
  info: {
    users: [],
    pagination: {
      current_page: 1,
      limit_value: 25,
      total_count: 0,
      total_pages: 0,
    },
    q: '',
  },
  filters: {
    list: [],
  },
  roles: {
    list: [],
    pagination: {
      current_page: 1,
      limit_value: 25,
      total_count: 0,
      total_pages: 0,
    },
  },
  permissions: {
    list: [],
  },
  errorMessage: undefined,
  successMessage: undefined,
  openDialog: false,
  inProgress: {
    requestGetUser: false,
    requestGetUsers: false,
    requestGetFilters: false,
    requestGetRoles: false,
    requestGetEnterprisePermissions: false,
    requestCreateUser: false,
    requestUpdateUser: false,
    requestDeleteUser: false,
    requestCreateRole: false,
    requestUpdateRole: false,
    requestDeleteRole: false,
  },
  batchUpload: batchUploadDefaultState,
  checkedRows: [],
};
export const init = () => {
  return defaultState;
};

const ACTION_HANDLERS = {
  UPDATE_USERS: (state, { data, pagination, q }) => {
    return {
      ...state,
      info: {
        ...state.info,
        users: data,
        pagination,
        q,
      },
      inProgress: {
        ...state.inProgress,
        requestGetUsers: false,
      },
    };
  },
  UPDATE_USER: (state, { message }) => {
    if (state.info && state.info && state.info.users) {
      return {
        ...state,
        successMessage: message,
        openDialog: false,
      };
    } else {
      return {
        ...state,
        successMessage: message,
      };
    }
  },
  UPDATE_USER_ERROR: (state, data) => {
    return { ...state, errorMessage: data.message, openDialog: true };
  },
  OPEN_DIALOG: (state) => {
    return {
      ...state,
      openDialog: true,
    };
  },
  CLOSE_DIALOG: (state) => {
    return {
      ...state,
      openDialog: false,
    };
  },
  UI_USER_MANAGEMENT_REQUEST_GET_USERS_SUCCESSFUL: (state, sagaData) => {
    return {
      ...state,
      info: {
        users: sagaData.data,
        pagination: sagaData.pagination,
        q: sagaData.q,
      },
      inProgress: {
        ...state.inProgress,
        requestGetUsers: false,
      },
    };
  },
  UI_USER_MANAGEMENT_REQUEST_GET_USER_SUCCESSFUL: (state, sagaData) => {
    return {
      ...state,
      user: sagaData.data,
      inProgress: {
        ...state.inProgress,
        requestGetUser: false,
      },
    };
  },
  UI_USER_MANAGEMENT_REQUEST_GET_FILTERS_SUCCESSFUL: (state, sagaData) => {
    return {
      ...state,
      filters: {
        list: sagaData.data,
      },
      inProgress: {
        ...state.inProgress,
        requestGetFilters: false,
      },
    };
  },
  UI_USER_MANAGEMENT_REQUEST_GET_ROLES_SUCCESSFUL: (state, { data }) => {
    return {
      ...state,
      roles: {
        list: data.data,
        pagination: data.pagination,
      },
      inProgress: {
        ...state.inProgress,
        requestGetRoles: false,
      },
    };
  },
  UI_USER_MANAGEMENT_REQUEST_GET_ENTERPRISE_PERMISSIONS_SUCCESSFUL: (state, sagaData) => {
    return {
      ...state,
      permissions: {
        list: sagaData.data,
      },
      inProgress: {
        ...state.inProgress,
        requestGetEnterprisePermissions: false,
      },
    };
  },
  UI_USER_MANAGEMENT_REQUEST_CREATE_ROLE_SUCCESSFUL: (state) => {
    return {
      ...state,
      successMessage: 'Role has been successfully created.',
      inProgress: {
        ...state.inProgress,
        requestCreateRole: false,
      },
    };
  },
  UI_USER_MANAGEMENT_REQUEST_UPDATE_ROLE_SUCCESSFUL: (state) => {
    return {
      ...state,
      successMessage: 'Role has been successfully updated.',
      inProgress: {
        ...state.inProgress,
        requestUpdateRole: false,
      },
    };
  },
  UI_USER_MANAGEMENT_REQUEST_DELETE_ROLE_SUCCESSFUL: (state) => {
    return {
      ...state,
      successMessage: 'Role has been successfully deleted.',
      inProgress: {
        ...state.inProgress,
        requestDeleteRole: false,
      },
    };
  },
  UI_USER_MANAGEMENT_REQUEST_CREATE_USER_SUCCESSFUL: (state) => {
    return {
      ...state,
      successMessage: 'User has been successfully created.',
      inProgress: {
        ...state.inProgress,
        requestCreateUser: false,
      },
    };
  },
  UI_USER_MANAGEMENT_REQUEST_UPDATE_USER_SUCCESSFUL: (state) => {
    return {
      ...state,
      successMessage: 'User has been successfully updated.',
      inProgress: {
        ...state.inProgress,
        requestUpdateUser: false,
      },
    };
  },
  UI_USER_MANAGEMENT_REQUEST_DELETE_USER_SUCCESSFUL: (state) => {
    return {
      ...state,
      successMessage: 'User has been successfully deleted.',
      inProgress: {
        ...state.inProgress,
        requestDeleteUser: false,
      },
    };
  },
  UI_USER_MANAGEMENT_SET_ERROR: (state, { message, key }) => {
    return {
      ...state,
      errorMessage: message,
      inProgress: {
        ...state.inProgress,
        [key]: false,
      },
    };
  },
  UI_USER_MANAGEMENT_CLEAR_MESSAGES: (state) => {
    return {
      ...state,
      successMessage: undefined,
      errorMessage: undefined,
    };
  },
  UI_USER_MANAGEMENT_START_PROGRESS: (state, sagaData) => {
    return {
      ...state,
      inProgress: {
        ...state.inProgress,
        [sagaData.key]: true,
      },
    };
  },
  ENTERPRISE_BATCH_UPLOAD_SUCCEEDED: (state, { total }) => {
    return {
      ...state,
      successMessage: t('Batch upload completed'),
      batchUpload: {
        ...state.batchUpload,
        state: 'completed',
        errors: {},
        total: total,
      },
    };
  },
  ...generateBatchUploadReducerMethods({ prefix: 'DISPATCHER' }),
};

export const UIUserManagementReducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};
