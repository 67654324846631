import { Grid, InputAdornment } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import MaterialUiPhoneNumber from 'material-ui-phone-number';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

import { getClassNameByRule } from '../../utils';

// TODO: consider root > height for size=small at inputMarginDense
// TODO: margin/padding when icon occur
const name = 'MuiInput-Yojee';
const PhoneNumber = withStyles(
  (theme) => ({
    root: {
      borderRadius: 5,
      height: theme.spacing(5),
      fontSize: '0.875rem',
      '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
        borderColor: 'transparent',
      },
      '&.Mui-focused > fieldset': {
        borderWidth: '1px !important',
      },
    },
    input: {
      padding: `${theme.spacing(1.5)}px ${theme.spacing(2)}px`,
      '.MuiInputAdornment-root + &': {
        paddingLeft: theme.spacing(1),
      },
    },
    inputMarginDense: {
      padding: `${theme.spacing(0.5)}px ${theme.spacing(1.5)}px`,
    },
    inputAdornedStart: {
      paddingLeft: theme.spacing(1),
    },
    labelRoot: (props) => ({
      color: `${theme.palette?.black?.['B60']} !important`,
      fontSize: '0.75rem',
      fontWeight: 600,
      '& .MuiInputLabel-asterisk': {
        color: props.error ? theme.palette?.error?.main : 'inherit',
      },
      '& + div': {
        marginTop: theme.spacing(1),
      },
    }),
    labelShrink: {
      transform: `none !important`,
      '&.MuiInputLabel-outlined': {
        transform: `none`,
      },
    },
    labelFormControl: {
      position: 'static',
    },
    helperTextContained: {
      marginLeft: 0,
    },
    colorSecondary: {
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette?.secondary?.main,
      },
      '&.Mui-focused.MuiFormLabel-root': {
        color: theme.palette?.secondary?.main,
      },
    },
    colorError: {
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette?.error?.main,
      },
      '&.Mui-focused.MuiFormLabel-root': {
        color: theme.palette?.error?.main,
      },
    },
    colorInfo: {
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette?.info?.main,
      },
      '&.Mui-focused.MuiFormLabel-root': {
        color: theme.palette?.info?.main,
      },
    },
    colorSuccess: {
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette?.success?.main,
      },
      '&.Mui-focused.MuiFormLabel-root': {
        color: theme.palette?.success?.main,
      },
    },
    colorWarning: {
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette?.warning?.main,
      },
      '&.Mui-focused.MuiFormLabel-root': {
        color: theme.palette?.warning?.main,
      },
    },
    extraLabelAlignmentRight: {
      textAlign: 'right',
      pointerEvents: 'auto',
      fontSize: '0.75rem',
    },
    disabled: {
      '&.MuiOutlinedInput-root': {
        backgroundColor: theme.palette?.action?.disabledBackground,
      },
    },
  }),
  { name }
)(
  ({
    classes,
    color,
    InputProps,
    icon,
    dataCy,
    extraLabel = null,
    extraLabelAlignment = 'right',
    autoFormat = true,
    countryCodeEditable = false,
    label,
    labelRootClassName = '',
    onChange,
    ...other
  }) => {
    const colorClass = getClassNameByRule(classes, 'color', color);
    const extraAlignmentClass = getClassNameByRule(classes, 'extraLabelAlignment', extraLabelAlignment);

    const optionalIconParam = icon
      ? {
          startAdornment: <InputAdornment position="start">{icon}</InputAdornment>,
        }
      : {};

    let labelWithExtra = label;
    if (extraLabel) {
      labelWithExtra = (
        <Grid container spacing={0}>
          <Grid item xs>
            {label}
          </Grid>
          <Grid item xs className={extraAlignmentClass}>
            {extraLabel}
          </Grid>
        </Grid>
      );
    }

    const customizedOnChange = useCallback(
      (phone) => {
        const justContainCountryCode = /^\+\d{2,3}$/.test(phone);
        const newPhone = !phone || justContainCountryCode ? '' : phone;
        if (onChange) onChange(newPhone);
      },
      [onChange]
    );

    return (
      <MaterialUiPhoneNumber
        key={other.defaultCountry}
        data-cy={dataCy}
        onChange={customizedOnChange}
        fullWidth
        defaultCountry="sg"
        disableAreaCodes
        countryCodeEditable={countryCodeEditable}
        enableLongNumbers={true}
        autoFormat={autoFormat}
        inputProps={{
          'data-cy': other?.dataCy,
        }}
        InputLabelProps={{
          shrink: true,
          classes: {
            root: clsx(classes?.labelRoot, labelRootClassName),
            shrink: classes?.labelShrink,
            formControl: classes?.labelFormControl,
          },
          className: clsx(colorClass),
        }}
        InputProps={{
          notched: false,
          classes: {
            root: classes?.root,
            input: classes?.input,
            disabled: classes?.disabled,
            inputMarginDense: classes?.inputMarginDense,
            adornedStart: classes?.inputAdornedStart,
          },
          className: clsx(colorClass),
          ...optionalIconParam,
        }}
        FormHelperTextProps={{
          classes: {
            contained: classes?.helperTextContained,
          },
        }}
        label={labelWithExtra}
        variant="outlined"
        {...other}
      />
    );
  }
);

PhoneNumber.propTypes = {
  color: PropTypes.oneOf(['primary', 'secondary', 'error', 'success', 'info', 'warning']),
  size: PropTypes.oneOf(['small', 'medium']),
  labelRootClassName: PropTypes.string,
};

export default PhoneNumber;
