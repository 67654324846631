import Grid from '@material-ui/core/Grid';
import { Trans } from 'react-i18next';

import { useLanguageOptions } from '@yojee/helpers/languages';
import Utilities from '@yojee/helpers/utilities';
import { Input, ReactSelect } from '@yojee/ui/base';
import Title from '@yojee/ui/base/furnitures/Typography/Title';

import { fixSelectedOption } from './fixSelectedOption';
import { useCompanySettingsStyles } from './helper';

const CompanyBookingPage = ({ senderLanguage, setSenderLanguage }) => {
  const classes = useCompanySettingsStyles();
  const languageOptions = useLanguageOptions();

  return (
    <Grid spacing={2} container className={classes.section}>
      <Grid item xs={12}>
        <Title variant="h4">
          <Trans>Booking page</Trans>
        </Title>
      </Grid>
      <Grid item xs={3}>
        <ReactSelect
          isRawValue
          label={<Trans>Booking page language</Trans>}
          disabled={languageOptions.length === 1}
          value={fixSelectedOption(senderLanguage, languageOptions)}
          options={languageOptions}
          onChange={(data) => {
            setSenderLanguage(data.value);
          }}
        />
      </Grid>
      <Grid item xs={3}>
        <div className={classes.inputLabel}>
          <Trans>Booking page url</Trans>
        </div>
        <Input
          disabled={true}
          variant="outlined"
          fullWidth
          margin="none"
          type="text"
          value={Utilities.getBookingUrl()}
        />
      </Grid>
    </Grid>
  );
};

export default CompanyBookingPage;
