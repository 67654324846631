import { authService } from './authService/index';
import { BaseService } from './baseService/index';

export class SenderApi extends BaseService {
  constructor({ authService }) {
    super();
    this.authService = authService;
  }

  searchOrganization = ({ searchText }) => {
    const requestPayload = {
      q: searchText,
      page_size: 100,
      page: 1,
    };
    return this.authService
      .get(this.getUmbrellaApiUrl(`dispatcher/organisations`), requestPayload)
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  getOrganisations = ({ pageSize = 100, pageNo = 1, q, axiosCancelToken } = {}) => {
    const requestPayload = {
      page_size: pageSize,
      page: pageNo,
      q,
    };
    return this.authService
      .get(this.getUmbrellaApiUrl(`dispatcher/organisations`), requestPayload, { axiosCancelToken })
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  getSenderByOrganizationId = ({ organisationId, page_size = 100 } = {}) => {
    const requestPayload = {
      page_size,
      page: 1,
    };
    return this.authService
      .get(this.getUmbrellaApiUrl(`dispatcher/organisations/${organisationId}/senders`), requestPayload)
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  getSenderById = ({ senderId } = {}) => {
    return this.authService
      .get(this.getUmbrellaApiUrl(`dispatcher/senders/${senderId}`))
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  createOrderFieldTemplate = ({ senderId, data, version = 1 } = {}) => {
    return this.authService
      .post(this.getUmbrellaApiUrl(`dispatcher/senders/${senderId}/order_field_template`), {
        data,
        version,
        sender_id: senderId,
      })
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  updateOrderFieldTemplate = ({ senderId, data, version = 1 } = {}) => {
    return this.authService
      .put(this.getUmbrellaApiUrl(`dispatcher/senders/${senderId}/order_field_template`), {
        data,
        version,
        sender_id: senderId,
      })
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  getOrderFieldTemplate = ({ senderId } = {}) => {
    return this.authService
      .get(this.getUmbrellaApiUrl(`dispatcher/senders/${senderId}/order_field_template`))
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  deleteOrderFieldTemplate = ({ senderId } = {}) => {
    return this.authService
      .delete(this.getUmbrellaApiUrl(`dispatcher/senders/${senderId}/order_field_template`))
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  getCorporateRateCards = (params) => {
    return this.authService
      .post(this.getUmbrellaApiUrlV4('/company/rate_entries/search'), params)
      .then((response) => {
        return this.extractData(response);
      })
      .catch((error) => this.handleError(error));
  };
}

export const senderApi = new SenderApi({ authService });
