const defaultState = {
  inProgress: {
    listItemTypes: false,
    createItemType: false,
    updateItemType: false,
    deleteItemType: false,
    linkItemType: false,
    bulkDelete: false,
  },
  itemTypeData: [],
  filter: {},
  pagination: {
    current_page: 1,
    limit_value: 25,
    total_count: 0,
    total_pages: 0,
  },
  bulkDeleteSuccess: false,
};

export const itemTypeMessagesSuccessEvents = {
  CREATE_ITEM_TYPE_SUCCESSFUL: 'Item type created successfully',
  UPDATE_ITEM_TYPE_SUCCESSFUL: 'Item type updated successfully',
  DELETE_ITEM_TYPE_SUCCESSFUL: 'Item type deleted successfully',
  LINK_ITEM_TYPE_SUCCESSFUL: 'Item type link successfully',
  REQUEST_DELETE_ITEM_TYPE_IN_PROCESS: 'Item type delete in process.',
  BULK_REQUEST_DELETE_ITEM_TYPE_SUCCESS: 'Item type deleted successfully.',
};

export const itemTypeMessagesErrorEvents = {
  LIST_ITEM_TYPES_FAILED: ({ error }) => error?.message ?? 'Failed to get item type info',
  CREATE_ITEM_TYPE_FAILED: ({ error }) => error?.message ?? 'Error creating item type.',
  UPDATE_ITEM_TYPE_FAILED: 'Error updating item type.',
  DELETE_ITEM_TYPE_FAILED: 'Error deleting item type.',
  LINK_ITEM_TYPE_FAILED: 'Error linking item type.',
};

export const init = () => {
  return defaultState;
};

const ACTION_HANDLERS = {
  REQUEST_DELETE_ITEM_TYPE: (state) => {
    return {
      ...state,
      inProgress: {
        ...state.inProgress,
        deleteItemType: true,
      },
    };
  },
  LIST_ITEM_TYPES_SUCCESSFUL: (state, sagaData) => {
    const data = sagaData.itemTypeData;
    return {
      ...state,
      inProgress: {
        ...state.inProgress,
        listItemTypes: false,
      },
      itemTypeData: data?.['data'],
      pagination: data?.['pagination'],
      filter: sagaData.params,
    };
  },
  LIST_ITEM_TYPES_FAILED: (state) => {
    return {
      ...state,
      inProgress: {
        ...state.inProgress,
        listItemTypes: false,
      },
    };
  },
  SET_ITEM_TYPE_LOADING: (state, sagaData) => {
    return {
      ...state,
      inProgress: {
        ...state.inProgress,
        [sagaData.key]: true,
      },
    };
  },
  CLEAR_ITEM_TYPE_MESSAGE: (state) => {
    return {
      ...state,
      errorMessage: null,
      successMessage: null,
    };
  },
  CREATE_ITEM_TYPE_FAILED: (state) => {
    return {
      ...state,
      inProgress: {
        ...state.inProgress,
        createItemType: false,
      },
    };
  },
  UPDATE_ITEM_TYPE_FAILED: (state) => {
    return {
      ...state,
      inProgress: {
        ...state.inProgress,
        updateItemType: false,
      },
    };
  },
  DELETE_ITEM_TYPE_FAILED: (state) => {
    return {
      ...state,
      inProgress: {
        ...state.inProgress,
        deleteItemType: false,
      },
    };
  },
  LINK_ITEM_TYPE_FAILED: (state) => {
    return {
      ...state,
      inProgress: {
        ...state.inProgress,
        linkItemType: false,
      },
    };
  },
  CREATE_ITEM_TYPE_SUCCESSFUL: (state) => {
    return {
      ...state,
      inProgress: {
        ...state.inProgress,
        createItemType: false,
      },
    };
  },
  UPDATE_ITEM_TYPE_SUCCESSFUL: (state) => {
    return {
      ...state,
      inProgress: {
        ...state.inProgress,
        updateItemType: false,
      },
    };
  },
  DELETE_ITEM_TYPE_SUCCESSFUL: (state) => {
    return {
      ...state,
      inProgress: {
        ...state.inProgress,
        deleteItemType: false,
      },
    };
  },
  LINK_ITEM_TYPE_SUCCESSFUL: (state) => {
    return {
      ...state,
      inProgress: {
        ...state.inProgress,
        linkItemType: false,
      },
    };
  },
  REQUEST_DELETE_ITEM_TYPE_IN_PROCESS: (state) => {
    return {
      ...state,
      inProgress: {
        bulkDelete: true,
      },
      bulkDeleteSuccess: false,
    };
  },
  BULK_REQUEST_DELETE_ITEM_TYPE_SUCCESS: (state) => {
    return {
      ...state,
      inProgress: {
        bulkDelete: false,
      },
      bulkDeleteSuccess: true,
    };
  },
  BULK_REQUEST_DELETE_ITEM_TYPE_FAIL: (state) => {
    return {
      ...state,
      inProgress: {
        bulkDelete: false,
      },
      bulkDeleteSuccess: false,
    };
  },
};

const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};

export default reducer;
