import { useEffect } from 'react';

const Redirect = ({ to }) => {
  useEffect(() => {
    window.parent.postMessage(
      {
        type: 'UPDATE_PATH',
        path: `manage/${to}`,
      },
      '*'
    );
  }, [to]);

  return null;
};

export default Redirect;
