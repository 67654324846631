import { call, put, takeLatest } from 'redux-saga/effects';

import { driverRouteService } from '../../services/driverRouteService';

export default function* sagas() {
  yield takeLatest('REQUEST_LIST_DRIVER_ROUTES', listDriverRoutes);
  yield takeLatest('REQUEST_CREATE_DRIVER_ROUTE', createDriverRoutes);
  yield takeLatest('REQUEST_UPDATE_DRIVER_ROUTE', updateDriverRoutes);
  yield takeLatest('REQUEST_DELETE_DRIVER_ROUTE', deleteDriverRoutes);
}

function* listDriverRoutes() {
  try {
    const res = yield call(driverRouteService.listDriverRoutesReq);
    const data = yield call(convertResponseToRouteData, res);
    yield put({ type: 'REQUEST_LIST_DRIVER_ROUTES_SUCCESS', data });
  } catch (error) {
    console.error(error);
  }
}

function* createDriverRoutes({ data }) {
  try {
    const mappedData = yield call(convertRouteDataToParams, data);
    const createRes = yield call(driverRouteService.createNewRouteReq, mappedData);
    const newData = {
      ...data,
      id: createRes.id,
    };
    yield put({ type: 'REQUEST_CREATE_DRIVER_ROUTE_SUCCESS', data: newData });
  } catch (error) {
    yield put({ type: 'GENERAL_DRIVER_ROUTE_ACTION_FAILED' });
  }
}

function* updateDriverRoutes({ data }) {
  try {
    const mappedData = yield call(convertRouteDataToParams, data);
    yield call(driverRouteService.updateRouteReq, mappedData);
    yield put({ type: 'REQUEST_UPDATE_DRIVER_ROUTE_SUCCESS', data });
  } catch (error) {
    yield put({ type: 'GENERAL_DRIVER_ROUTE_ACTION_FAILED' });
  }
}

function* deleteDriverRoutes({ data }) {
  try {
    yield call(driverRouteService.deleteRouteReq, data);
    yield put({ type: 'REQUEST_DELETE_DRIVER_ROUTE_SUCCESS', data });
  } catch (error) {
    yield put({ type: 'GENERAL_DRIVER_ROUTE_ACTION_FAILED' });
  }
}

const convertRouteDataToParams = (data) => ({
  id: data.id,
  name: data.routeName,
  worker_id: data.driverID,
  route_schedule_points: data.points.map((point) => ({
    address_item_id: point.addressID,
    arrival_time: point.startTime + ':00',
  })),
});

const convertResponseToRouteData = (list) =>
  list.map((route) => ({
    id: route.id,
    routeName: route.name,
    driverID: route.worker_id,
    points: route.route_schedule_points
      .map((point) => ({
        addressID: point.address_item_id,
        addressLine: point.address,
        startTime: point.arrival_time.substring(0, 5),
        position: point.position,
      }))
      .sort((a, b) => a.position - b.position),
  }));
