import './baseSettingComponent.scss';

import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import LoadingOverlay from '@yojee/ui/common/LoadingOverlay/LoadingOverlay';

const getUserInfo = () => {
  return { type: 'REQUEST_GET_USER_INFO' };
};

class BaseSettingComponent extends Component {
  componentDidMount() {
    const { accessToken, dispatcherInfo } = this.props;
    if (!accessToken || !dispatcherInfo) {
      this.props.getUserInfo();
    }
  }

  render() {
    const { isLoading, children, title, rightTitle, accessToken, dispatcherInfo, customContentPadding } = this.props;

    return (
      <Grid className="manage-content-block" data-cy="menu-item-content-details" style={customContentPadding}>
        <LoadingOverlay active={isLoading} data-cy="loading-overlay" />
        {(title || rightTitle) && (
          <div className="content-block-header">
            <div className="title">{title}</div>
            <div className="title-right-button">{rightTitle}</div>
          </div>
        )}
        <div className="content">
          {(!accessToken || !dispatcherInfo) && (
            <div className="loading-container">
              <CircularProgress disableShrink />
            </div>
          )}
          {accessToken && dispatcherInfo && children}
        </div>
      </Grid>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getUserInfo: () => dispatch(getUserInfo()),
  };
};

const mapStateToProps = (state) => {
  const accountInfo = (state.login?.accountInfo || state.auth) ?? {};

  return {
    accessToken: accountInfo.token || accountInfo.access_token,
    dispatcherInfo: accountInfo.dispatcher_info,
  };
};

BaseSettingComponent.propTypes = {
  title: PropTypes.any,
  rightTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  isLoading: PropTypes.bool,
  children: PropTypes.node,
  activeMenu: PropTypes.string,
  customContentPadding: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(BaseSettingComponent);
