import ButtonBase from '@material-ui/core/ButtonBase';
import clsx from 'clsx';

import useMenuItemLogic from '../hooks/useMenuItemLogic';
import useTopMenuStyles from './topMenuStyles';

const RootMenuItem = ({ menuItem, children }) => {
  const classes = useTopMenuStyles();
  const { isSelected, link, onClickLeafMenu } = useMenuItemLogic(menuItem);

  return (
    <li
      data-cy={`menu-item-${menuItem.key}`}
      className={clsx(classes.rootMenuItem, isSelected && classes.rootMenuItemActive)}
    >
      <ButtonBase
        component="a"
        link={link}
        onClick={(event) => {
          event.preventDefault();
          if (link) {
            onClickLeafMenu();
          }
        }}
      >
        {menuItem.icon}
        <span>{menuItem.title}</span>
      </ButtonBase>
      {children}
    </li>
  );
};

export default RootMenuItem;
