import React from 'react';
import { components } from 'react-select';

const DefaultOption =
  ({ optionDataCy }) =>
  (props) => {
    return (
      <div data-cy={optionDataCy}>
        <components.Option {...props}>
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };

export default DefaultOption;
