import { authService } from '@yojee/api/authService';
import { BaseService } from '@yojee/api/baseService';

export class BrandingService extends BaseService {
  constructor({ authService }) {
    super();
    this.authService = authService;
  }

  requestUploadImage = ({ file_type, file }) => {
    const formData = new FormData();
    formData.append('file_type', file_type);
    formData.append('file', file);
    return this.authService
      .post(this.getUmbrellaApiUrl('dispatcher/company/assets'), formData, { 'Content-Type': 'multipart/form-data' })
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  updateBrandingSetting = (data) => {
    return this.authService
      .put(this.getUmbrellaApiUrl('dispatcher/company/settings'), { settings: data })
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };
}

export const brandingService = new BrandingService({ authService });
