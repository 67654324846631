import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import DefaultFallback from './fallbacks/DefaultFallback';

const defaultResetKeys = [];

const RealErrorBoundary = ({
  children,
  resetKeys = defaultResetKeys,
  CustomFallbackComponent = DefaultFallback,
  onReset,
  setTrackPathChange,
}) => {
  const errorHandler = (error, info) => {
    setTrackPathChange?.(true);
    console.error('Error handled by Error Boundary', error, info);
  };

  return (
    <ErrorBoundary
      FallbackComponent={CustomFallbackComponent}
      onError={errorHandler}
      onReset={onReset}
      resetKeys={resetKeys}
    >
      {children}
    </ErrorBoundary>
  );
};

export default RealErrorBoundary;
