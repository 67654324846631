const defaultState = {
  loading: {},
  info: {
    sms: [],
    email: [],
  },
  dialogOpen: false,
  dialogType: '',
  dialogAction: '',
  actionCompleted: false,
  template: '',
};

export const notificationsMessagesSuccessEvents = {
  REQUEST_SAVE_TEMPLATE_SUCCESS: ({ message }) => message,
  REQUEST_UPDATE_SMS_NOTIFICATION_SUCCESS: ({ result: { message } }) => message,
  REQUEST_UPDATE_EMAIL_NOTIFICATION_SUCCESS: ({ result: { message } }) => message,
};

export const notificationsMessagesErrorEvents = {
  REQUEST_NOTIFICATION_FAIL: ({ message }) => message,
};

export const init = () => {
  return defaultState;
};

const ACTION_HANDLERS = {
  REQUEST_GET_SMS_NOTIFICATION_SUCCESS: (state, data) => {
    return {
      ...state,
      info: { ...state.info, sms: data.result },
    };
  },
  REQUEST_GET_EMAIL_NOTIFICATION_SUCCESS: (state, data) => {
    return {
      ...state,
      info: { ...state.info, email: data.result },
    };
  },
  REQUEST_SAVE_TEMPLATE_SUCCESS: (state) => {
    return {
      ...state,
      dialogOpen: false,
      dialogType: '',
      dialogAction: '',
      actionCompleted: false,
    };
  },
  REQUEST_UPDATE_SMS_NOTIFICATION_SUCCESS: (state, data) => {
    const response = data.result;
    const updatedData = response.data;
    return { ...state, info: { ...state.info, sms: updatedData }, dialogOpen: false, actionCompleted: true };
  },
  REQUEST_UPDATE_EMAIL_NOTIFICATION_SUCCESS: (state, data) => {
    const response = data.result;
    const updatedData = response.data;
    return { ...state, info: { ...state.info, email: updatedData }, dialogOpen: false, actionCompleted: true };
  },
  TOGGLE_DIALOG: (state, { payload }) => {
    return {
      ...state,
      dialogOpen: payload.dialogOpen,
      dialogType: payload.dialogType,
      dialogAction: payload.dialogAction,
      template: '',
    };
  },
  UPDATE_TEMPLATE: (state, payload) => {
    return {
      ...state,
      template: { ...payload.result },
    };
  },
  REQUEST_GET_TEMPLATE_SUCCESS: (state, payload) => {
    return {
      ...state,
      template: payload.result,
      dialogOpen: true,
    };
  },
};

const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};

export default reducer;
