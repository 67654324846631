import { push } from 'redux-first-history';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { senderApi } from '@yojee/api/senderApi';
import { batchUpload } from '@yojee/ui/onboarding/helpers/batchUploadHelper';

import { getPrefixSelector } from '../../selectors';
import { senderOrganisationService } from '../../services/senderOrganisationService';

export default function* sagas() {
  yield takeLatest('REQUEST_ALL_LIST_SENDER_ORGANISATIONS', listAllSenderOrganisations);
  yield takeLatest('REQUEST_LOAD_SENDER_ORGANISATION', loadSenderOrganisation);
  yield takeLatest('REQUEST_LIST_SENDER_ORGANISATIONS', listSenderOrganisations);
  yield takeLatest('REQUEST_CREATE_SENDER_ORGANISATION', createSenderOrganisation);
  yield takeLatest('REQUEST_UPDATE_SENDER_ORGANISATION', updateSenderOrganisation);
  yield takeLatest('REQUEST_DELETE_SENDER_ORGANISATION', deleteSenderOrganisation);
  yield takeLatest('REQUEST_CREATE_SENDER_ORGANISATION_USER', createSenderOrganisationUser);
  yield takeLatest('REQUEST_UPDATE_SENDER_ORGANISATION_USER', updateSenderOrganisationUser);
  yield takeLatest('REQUEST_DELETE_SENDER_ORGANISATION_USER', deleteSenderOrganisationUser);
  yield takeLatest('REQUEST_SENDER_ORGANISATION_BATCH_UPLOAD', senderOrganisationBatchUpload);
  yield takeLatest('DOWNLOAD_SENDER_ORGANISATION_TEMPLATE_SAMPLE', downloadSenderOrganisationTemplateSample);
  yield takeLatest('GET_CORPORATE_RATE_CARDS_REQUEST', getCorporateRateCardsRequest);
  yield takeLatest('REQUEST_RESET_PASSWORD_SENDER_ORGANISATION_USER', resetPasswordSenderOrganisationUser);
}

export const getParams = (state) => {
  return {
    page: state.senderOrganisation.pagination.current_page,
    page_size: state.senderOrganisation.pagination.limit_value,
  };
};

function* listAllSenderOrganisations({ payload = {} }) {
  try {
    yield put({ type: 'SET_SENDER_ORGANISATIONS_LOADING', key: 'listAllSenderOrganisations' });
    const senderOrganisations = yield call(senderOrganisationService.listAllSenderOrganisations, payload);
    yield put({ type: 'LIST_ALL_SENDER_ORGANISATIONS_SUCCESSFUL', senderOrganisations: senderOrganisations });
  } catch (error) {
    yield put({ type: 'LIST_ALL_SENDER_ORGANISATIONS_FAILED', error });
  }
}

function* loadSenderOrganisation(action) {
  try {
    yield put({ type: 'SET_SENDER_ORGANISATION_LOADING', key: 'loadSenderOrganisation' });
    const senderOrganisation = yield call(senderOrganisationService.loadSenderOrganisation, action.id);

    yield put({ type: 'LOAD_SENDER_ORGANISATION_SUCCESSFUL', senderOrganisation: { ...senderOrganisation } });
  } catch (error) {
    yield put({ type: 'LOAD_SENDER_ORGANISATION_FAILED', error });
  }
}

function* listSenderOrganisations(action) {
  try {
    yield put({ type: 'SET_SENDER_ORGANISATION_LOADING', key: 'listSenderOrganisations' });
    const senderOrganisations = yield call(senderOrganisationService.listSenderOrganisations, action.params);
    yield put({ type: 'LIST_SENDER_ORGANISATIONS_SUCCESSFUL', organisationData: senderOrganisations });
  } catch (error) {
    yield put({ type: 'LIST_SENDER_ORGANISATIONS_FAILED', error });
  }
}

function* createSenderOrganisation(action) {
  try {
    yield put({ type: 'SET_SENDER_ORGANISATION_LOADING', key: 'createSenderOrganisation' });
    const senderData = yield call(senderOrganisationService.createSenderOrganisation, action.sender);
    action.onSuccess?.();
    yield put({ type: 'CREATE_SENDER_ORGANISATION_SUCCESSFUL', senderData, uuid: action.uuid });
    yield put({ type: 'REQUEST_LIST_SENDER_ORGANISATIONS', params: yield select(getParams) });
    const prefix = yield select(getPrefixSelector);
    yield put(push(`${prefix}/senders/${senderData.organisation_id}`));
  } catch (error) {
    action.onError?.(error);
    yield put({ type: 'CREATE_SENDER_ORGANISATION_FAILED', error });
  }
}
function* updateSenderOrganisation(action) {
  try {
    yield put({ type: 'SET_SENDER_ORGANISATION_LOADING', key: 'updateSenderOrganisation' });
    const senderData = yield call(senderOrganisationService.updateSenderOrganisation, action.sender);
    action.onSuccess?.();
    yield put({ type: 'UPDATE_SENDER_ORGANISATION_SUCCESSFUL', senderData, uuid: action.uuid });
    yield put({ type: 'REQUEST_LIST_SENDER_ORGANISATIONS', params: yield select(getParams) });
  } catch (error) {
    action.onError?.(error);
    yield put({ type: 'UPDATE_SENDER_ORGANISATION_FAILED', error });
  }
}
function* deleteSenderOrganisation({ payload: { sender, onSuccess } }) {
  try {
    yield put({ type: 'SET_SENDER_ORGANISATION_LOADING', key: 'deleteSenderOrganisation' });
    const senderData = yield call(senderOrganisationService.deleteSenderOrganisation, sender);
    yield put({ type: 'DELETE_SENDER_ORGANISATION_SUCCESSFUL', senderData });
    yield put({ type: 'REQUEST_LIST_SENDER_ORGANISATIONS', params: yield select(getParams) });
    onSuccess?.();
  } catch (error) {
    yield put({ type: 'DELETE_SENDER_ORGANISATION_FAILED', error });
  }
}

function* resetPasswordSenderOrganisationUser({ payload: { userProfileId, listOfUsers } }) {
  try {
    yield put({ type: 'UPDATE_LIST_OF_USERS_FOR_RESET_PASSWORD', listOfUsers });
    const result = yield call(senderOrganisationService.resetPasswordSenderOrganisation, userProfileId);
    const updatedList = listOfUsers.filter((x) => x !== userProfileId);
    yield put({ type: 'RESET_PASSWORD_SENDER_ORGANISATION_SUCCESSFUL', result, updatedList });
  } catch (error) {
    const updatedList = listOfUsers.filter((x) => x !== userProfileId);
    yield put({ type: 'RESET_PASSWORD_SENDER_ORGANISATION_FAILED', error, updatedList });
  }
}

function* createSenderOrganisationUser(action) {
  try {
    yield put({ type: 'SET_SENDER_ORGANISATION_LOADING', key: 'createSenderOrganisationUser' });
    const senderData = yield call(senderOrganisationService.createSenderOrganisationUser, action.sender);
    yield put({ type: 'CREATE_SENDER_ORGANISATION_USER_SUCCESSFUL', senderData, uuid: action.uuid });
    yield put({ type: 'REQUEST_LIST_SENDER_ORGANISATIONS', params: yield select(getParams) });
  } catch (error) {
    yield put({ type: 'CREATE_SENDER_ORGANISATION_USER_FAILED', error });
  }
}
function* updateSenderOrganisationUser(action) {
  try {
    yield put({ type: 'SET_SENDER_ORGANISATION_LOADING', key: 'updateSenderOrganisationUser' });
    const senderData = yield call(senderOrganisationService.updateSenderOrganisationUser, action.sender);
    yield put({ type: 'UPDATE_SENDER_ORGANISATION_USER_SUCCESSFUL', senderData, uuid: action.uuid });
    yield put({ type: 'REQUEST_LIST_SENDER_ORGANISATIONS', params: yield select(getParams) });
  } catch (error) {
    yield put({ type: 'UPDATE_SENDER_ORGANISATION_USER_FAILED', error });
  }
}
function* deleteSenderOrganisationUser({ payload: { sender, onSuccess } }) {
  try {
    yield put({ type: 'SET_SENDER_ORGANISATION_LOADING', key: 'deleteSenderOrganisationUser' });
    const senderData = yield call(senderOrganisationService.deleteSenderOrganisationUser, sender);
    yield put({ type: 'DELETE_SENDER_ORGANISATION_USER_SUCCESSFUL', senderData });
    yield put({ type: 'REQUEST_LIST_SENDER_ORGANISATIONS', params: yield select(getParams) });
    onSuccess?.();
  } catch (error) {
    yield put({ type: 'DELETE_SENDER_ORGANISATION_USER_FAILED', error });
  }
}
function* senderOrganisationBatchUpload({ payload: { file, format, type } }) {
  yield batchUpload(
    { file, format, type },
    {
      uploadApiMethod: senderOrganisationService.batchUpload,
      statusCheckApiMethod: senderOrganisationService.checkBatchStatus,
      events: {
        started: 'SENDER_ORGANISATIONS_BATCH_UPLOAD_STARTED',
        processing: 'SENDER_ORGANISATIONS_BATCH_UPLOAD_PROCESSING',
        succeeded: 'SENDER_ORGANISATIONS_BATCH_UPLOAD_SUCCEEDED',
        failed: 'SENDER_ORGANISATIONS_BATCH_UPLOAD_FAILED',
      },
    }
  );
}

function downloadSenderOrganisationTemplateSample({ payload: { format, type } }) {
  window.open(senderOrganisationService.downloadTemplateSample({ format, type }), '_self');
}

function* getCorporateRateCardsRequest({ payload }) {
  try {
    const response = yield call(senderApi.getCorporateRateCards, payload);
    yield put({ type: 'GET_CORPORATE_RATE_CARDS_SUCCESS', payload: response });
  } catch (error) {
    yield put({ type: 'GET_CORPORATE_RATE_CARDS_FAILURE', payload: error });
  }
}
