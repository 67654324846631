import { createTheme, Theme } from '@material-ui/core/styles';

import newTheme from './newTheme.json';
import oldTheme from './oldTheme.json';

export type YojeeTheme = Theme & typeof newTheme;
export type YojeeLegacyTheme = Theme & typeof oldTheme;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const theme = createTheme(newTheme as any) as YojeeTheme;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const legacyTheme = createTheme(oldTheme as any) as YojeeLegacyTheme;
