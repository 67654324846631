export default function isSelectedMenu(menuItem, pathname, prefix) {
  const currentPath = pathname.replace(prefix, '');
  const { key, children, isDefault } = menuItem;

  if (currentPath === '/' && isDefault) {
    return true;
  }

  return (
    currentPath.startsWith(`/${key}/`) ||
    currentPath.endsWith(`/${key}`) ||
    (children && children.some((childMenu) => isSelectedMenu(childMenu, pathname, prefix)))
  );
}
