import { authService } from '@yojee/api/authService';
import { BaseService } from '@yojee/api/baseService';

export class ManifestTemplatesService extends BaseService {
  constructor({ services }) {
    super();
    this.authService = services;
  }

  getManifestTemplates = () => {
    return this.authService
      .get(this.getUmbrellaApiUrl('dispatcher/manifest_templates'))
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };

  getManifestTemplatesSections = () => {
    return this.authService
      .get(this.getUmbrellaApiUrl('dispatcher/manifest_sections'))
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };

  updateManifestTemplates = (manifestTemplates) => {
    const data = this.filterManifestTemplatesData(manifestTemplates.data);
    return this.authService
      .put(this.getUmbrellaApiUrl(`dispatcher/manifest_templates/${manifestTemplates.id}`), data)
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };

  filterManifestTemplatesData = (value) => {
    return {
      company: {
        address: value.company.address,
        phone: value.company.phone,
      },
      sections: value.sections.map((item) => {
        const { data, slug } = item;
        return !data ? { slug } : { data, slug };
      }),
    };
  };
}

export const manifestTemplatesService = new ManifestTemplatesService({ services: authService });
