import { batchUploadDefaultState, generateBatchUploadReducerMethods } from '../helpers/batchUploadHelper';
const defaultState = {
  batchUpload: batchUploadDefaultState,
};
export const init = () => {
  return defaultState;
};

const ACTION_HANDLERS = {
  ...generateBatchUploadReducerMethods({ prefix: 'ENTERPRISE' }),
};

export const UIUserEnterpriseManagementReducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};
